import { makeStyles, createStyles } from '@material-ui/core/styles';

const QASettingsStyles = makeStyles((theme: any) =>
  createStyles({
    settingsHolder: {
      width: "100%",
      height: 462,
      backgroundColor: theme.palette.sidebarHeader.main,
      marginTop: 4,
    },
    qaViewHeader: {
      fontSize: 14,
      color: theme.palette.qaViewHeader.main,
      marginBottom: 14,
    },
    radioSelected: {
      color: `${theme.palette.emojiIcon.main} !important`,
    },
    viewSelect: {
      position: "relative",
      left: 10,
    },
    settingsHelperText: {
      fontSize: 14,
      color: theme.palette.qaSettingsHelperText.main,
      width: 210,
      position: "relative",
      left: 2,
    },
    settingsHelperTextLong: {
      width: 255,
    },
    switchLabel: {
      marginRight: 40,
      position: "relative",
      right: 15,
    },
    switchLabelSeeAll: {
      marginRight: 110,
    },
  })
);

export { QASettingsStyles };