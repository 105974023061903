import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react';
import { Paper, TextField, Button, Typography, InputAdornment } from '@material-ui/core';
import $ from 'jquery';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import { Picker } from 'emoji-mart';

import { OneOnOneChatContext, OneOnOneChatContextType } from '../../../../context/OneOnOneChatContext';

import { OneOnOneChatBarStyles } from './OneOnOneChatBarStyles';

/* var Filter = require('bad-words'); */

interface Props {
}

export default function OneOnOneChatBar(props: Props): ReactElement {

  const [ message, setMessage ] = useState("");
  const [ showEmojis, toggleShowEmojis ] = useState(false);

  const hideConfirmTimeout = useRef(null);
  const jqueryTimer = useRef(null);

  const {
    messageSent,
    toggleMessageSent,
    userIDToMessage,
    setNameToMessage,
    setUserIDToMessage,
    toggleIsOneOnOneChatOpen,
    nameToMessage,
  } : OneOnOneChatContextType = useContext(OneOnOneChatContext);

  /* let filter = new Filter(); */

  useEffect(() => {

    //Focus text field
    let textField : HTMLElement = document.getElementById("oneOnOneChatTextField");

    if (textField !== null) textField.focus();

    jqueryTimer.current = setTimeout(function() {
      $("#privateChatMessageHolder").animate({
        bottom: `+=142`
      }, 300);

    }, 0);

    

    return function cleanup() {
      if (hideConfirmTimeout.current) {
        clearTimeout(hideConfirmTimeout.current);
      }
      if (jqueryTimer.current) {
        clearTimeout(jqueryTimer.current);
      }
      
      //Remove jquery listeners
      $(".chatBarSendButton").off("click");
      $(".chatBarCloseButton").off("click");
    }
    
  }, []);

  if (!messageSent && hideConfirmTimeout.current) {
    clearTimeout(hideConfirmTimeout.current);
  }

  //Message change handler
  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  }

  //SEND MESSAGE
  const handleMessageSubmit = async () => {

    if (message === "") return;

    //Ensure attendee is still in the event
    if (SHOWBOAT.RemoteAvatarDataManager.getAvatarData(userIDToMessage) !== undefined) {
      await SHOWBOAT.WebSocketController.SendChatToTarget({
        targetUserID: userIDToMessage,
        message: message
      })
    }

    $("#privateChatMessageHolder").animate({
      bottom: `-=70`
    }, 300);

    toggleShowEmojis(false);

    setMessage("");

    toggleMessageSent(true);

    hideConfirmTimeout.current = setTimeout(function() {
      $("#privateChatMessageHolder").animate({
        bottom: `-=72`
      }, 300)
      toggleMessageSent(false);
      toggleIsOneOnOneChatOpen(false);
    }, 3000);
    
  };

  useEffect(() => {
    jqueryTimer.current = setTimeout(function() {
        $(document).ready(function () {
          $('.messageSentCloseButton').on("click", function(e){
            $("#privateChatMessageHolder").animate({
              bottom: `-=72`
            }, 300)
          });
        })
    })
  }, [messageSent]);


  /*EMOJI LOGIC*/
  const handleEmojiClick = () => {
    toggleShowEmojis(!showEmojis);
  }

  const handleEmojiSelect = (e) => {
    //Parse emoji code from event object
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)

    //Append emoji code to message input
    setMessage(`${message} ${emoji}`)

    //hide emoji picker
    toggleShowEmojis(false);
  }
  /**/

  //Cancel message
  const handleCancel = () => {

    $("#privateChatMessageHolder").animate({
      bottom: `-=142`
    }, 300);

    setNameToMessage("");
    setUserIDToMessage("");
    toggleIsOneOnOneChatOpen(false);
  }

  //CLOSE CONFIRMATION
  const handleConfirmClose = () => {
    setNameToMessage("");
    setUserIDToMessage("");
    toggleIsOneOnOneChatOpen(false);
    toggleMessageSent(false);
  }

  const classes = OneOnOneChatBarStyles();

  let helperText : string = `To: ${nameToMessage}`;

  return (
    <React.Fragment>
      {messageSent
      ? (
        <Paper id="messageSent" className={classes.messageSentHolder}>
          <Typography variant="body1" className={classes.messageSentText}>
            Message Sent
          </Typography>

          <Button className={`${classes.closeButton} messageSentCloseButton`} onClick={handleConfirmClose} variant="text">
            CLOSE
          </Button>
        </Paper>
      )
      : (
        <React.Fragment>
          {showEmojis &&
            <Paper elevation={10} square classes={{ root: classes.emojiSelectHolder }}>
              <Picker 
                theme={SHOWBOAT.ApplicationSkin.theme}
                onSelect={(e) => handleEmojiSelect(e)} 
              />
            </Paper>
          }

          <Paper className={classes.chatHolder}>
          
            <TextField
              onChange={handleMessageChange}
              value={message}
              variant="outlined"
              id="oneOnOneChatTextField"
              fullWidth
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleMessageSubmit();
                }
              }}
              label="Send a message"
              className={classes.chatMessageInputHolder}
              helperText={helperText}
              FormHelperTextProps={{
                className: classes.chatMessageHelperText
              }}
              InputProps={{
                className: classes.chatMessageInput,
                endAdornment: (
                  <InputAdornment position="end">
                    <EmojiEmotionsIcon onClick={handleEmojiClick} classes={{ root: classes.emojiIcon }} fontSize="large" />
                  </InputAdornment>
                )
              }}
              inputProps={{
                maxLength: 320,
              }}
            />

            <Button variant="text" onClick={handleMessageSubmit} className={`${classes.sendButton} chatBarSendButton`}>
              SEND
            </Button>

            <Button variant="text" onClick={handleCancel} className={`${classes.cancelButton} chatBarCloseButton`}>
              CANCEL
            </Button>
        
          </Paper>
        </React.Fragment>
        
      )}

    </React.Fragment>
  )
}

