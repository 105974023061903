import { makeStyles, createStyles } from '@material-ui/core/styles';

const MovePopupStyles = makeStyles((theme: any) =>
  createStyles({
    moveHeaderHolder: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      borderRadius: 4,
      height: 40,
      position: "relative",
      color: "white",
    },
    peopleIcon: {
      position: "absolute",
      top: 7,
      left: 10,
      color: "white",
    },
    moveHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 43,
      top: 10,
    },
    urlInput: {
      backgroundColor: "#474747",
      position: "relative",
      top: 10,
    },
    closeIconButton: {
      position: "absolute",
      padding: 1,
      right: 5,
      top: 3,
    },
    closeIcon: {
      fontSize: 30,
      color: theme.palette.text.primary,
    },
    selectAllLabel: {
      color: theme.palette.text.primary,
      fontSize: 16,
      float: "left",
      position: "relative",
      left: 13,
      marginTop: 17,
      marginBottom: 17,
    },
    moveButton: {
      display: "inline-block",
      backgroundColor: "#00B6FF",
      padding: 0,
      width: 103,
      height: 34,
      bottom: 23,
      position: "relative",
      color: "white",
      "&:hover": {
        backgroundColor: "#00B6FF",
      },
    },
  })
);

export { MovePopupStyles };