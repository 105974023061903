import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SettingsIcon from "@material-ui/icons/Settings";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import { PresenterHelper } from "../../../../Utilities/PresenterHelper";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";
import { UIHelper } from "../../../../Utilities/UIHelper";

import styles from "./WebcamShare.module.css";
import { WebcamShareStyles } from "./WebcamShareStyles";

interface Props {}


export default function WebcamShare(props: Props): ReactElement {
  const {
    isPresentingWebcam,
    currentMode,
    setCurrentPresenterTool,
    toggleIsPresentingWebcam,
  }: PresenterContextType = useContext(PresenterContext);

  const { setCurrentSidebarMode }: AppContextType = useContext(AppContext);

  const [disableShareSwitch, toggleDisableShareSwitch] = useState(false);
  const [shareWebcam, toggleShareWebcam] = useState(
    PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID &&
      PresenterHelper.myCurrentPresentationMode === SHOWBOAT.JumbotronMode.webcamShare
      ? true
      : false
  );
  const [loadingSpinner, toggleLoadingSpinner] = useState(true);

  const shareWebcamRef = useRef(false);

  const isMounted = useRef(true);

  const disableShareRef = useRef(null);

  useEffect(() => {
    const handleCameraStopped = () => {
      //Close this popup if camera is stopped
      setCurrentPresenterTool("");
    }

    //Listen for camera stopping to close this component
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(handleCameraStopped);

    //On mount, put webcam feed in video preview and set to HD mode
    const insertWebcamFeedInPreview = async () => {
      const setHDModeResponse = await SHOWBOAT.StreamingUserMedia.SetHDMode(true);

      SHOWBOAT.Logger.Log("Set HD mode response:", setHDModeResponse);

      let previewVideo = document.createElement("video");

      previewVideo.srcObject = SHOWBOAT.StreamingUserMedia.getCameraStream();

      previewVideo.play();

      PresenterHelper.WebcamVideo = previewVideo;

      let container = document.getElementById("webcamPreviewHolder");

      if (container) container.appendChild(previewVideo);

      if (isMounted.current) {
        //Hide loading spinner
        toggleLoadingSpinner(false);
      }
    };

    insertWebcamFeedInPreview();

    return function cleanup() {
      isMounted.current = false;
      SHOWBOAT.StreamingUserMedia.OnCameraStopped.Remove(handleCameraStopped);

      if (shareWebcamRef.current === false) {
        //Only disable HD cam if we are not sharing webcam
        UIHelper.DisableWebcamHD();   
      }

      if (disableShareRef.current) {
        clearTimeout(disableShareRef.current);
      }
    };
  }, [setCurrentPresenterTool]);

  useEffect(() => {
    toggleShareWebcam(isPresentingWebcam);
    shareWebcamRef.current = isPresentingWebcam;
  }, [isPresentingWebcam]);

  useEffect(() => {
    //If we change modes and are no longer in presenter mode, close this component
    if (currentMode !== StringVariableHelper.ShowboatModeType.presenter)
      setCurrentPresenterTool("");
  }, [currentMode, setCurrentPresenterTool]);

  const handleToggleShareWebcam = async () => {
    //Double-check that we are in presentation mode
    if (
      !SHOWBOAT.LocalAvatarDataManager.isPresenting
    ) {
      return;
    }

    let toggleState: boolean = !isPresentingWebcam;

    //Show camera preview if starting to present webcam
    toggleDisableShareSwitch(true);

    //notify 3D world
    await PresenterHelper.OnPresentWebcamToggle(toggleState);

    //Disable toggle for 1 second
    toggleDisableShareSwitch(true);
    disableShareRef.current = setTimeout(function () {
      toggleDisableShareSwitch(false);
    }, 1000);

    //change value in context, state, and ref
    toggleIsPresentingWebcam(toggleState);
    toggleShareWebcam(toggleState);
    shareWebcamRef.current = toggleState;
  };

  const handleSettingsClick = () => {
    //Show camera settings in sidebar
    setCurrentSidebarMode("settings");
  };

  const classes = WebcamShareStyles();

  return (
    <div className={styles.webcamShareHolder}>
      {loadingSpinner && (
        <CircularProgress className={classes.loadingSpinner} />
      )}
      <div
        id="webcamPreviewHolder"
        className={styles.webcamPreviewHolder}
      ></div>

      <IconButton
        className={`${classes.settingsIconButton} cancelDrag`}
        onClick={handleSettingsClick}
      >
        <SettingsIcon />
      </IconButton>

      <FormControlLabel
        control={
          <Switch
            checked={shareWebcam}
            onChange={handleToggleShareWebcam}
            name="Mode Toggle"
            color="primary"
          />
        }
        label="SHARE"
        labelPlacement="start"
        classes={{
          root: `${classes.webcamToggle} cancelDrag`,
          label: classes.toggleLabel,
        }}
        disabled={disableShareSwitch}
      />
    </div>
  );
}
