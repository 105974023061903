import { makeStyles, createStyles } from '@material-ui/core/styles';

const QAItemStyles = makeStyles((theme: any) =>
  createStyles({
    qaItemHolder: {
      display: "inline-block",
      minHeight: 50,
      width: "98%",
      textAlign: "left",
      position: "relative",
      marginTop: 4,
      left: -2,
      backgroundColor: theme.palette.sidebarHeader.main,
    },
    qaItemHolderMobile: {
      width: "100%",
    },
    qaName: {
      fontSize: 12,
      position: "absolute",
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      right: 0,
      left: 20,
      width: 150,
      top: 8,
      color: theme.palette.qaItemHeader.main,
    },
    qaTime: {
      fontSize: 12,
      position: "absolute",
      top: 8,
      left: 211,
      width: 60,
      color: theme.palette.qaItemHeader.main,
    },
    qaTimeMobile: {
      position: "relative",
      float: "right",
      left: -13,
    },
    qaMessageHeader: {
      position: "absolute",
      width: 280,
      height: 30,
      backgroundColor: theme.palette.sidebarHeader.main,
      top: 3,
    },
    qaMessageHeaderMobile: {
      width: "100%",
    },
    qaBody: {
      marginRight: 10,
      marginBottom: 10,
      marginTop: 40,
      fontSize: 12,
      position: "relative",
      left: 20,
      width: "85%",
      wordWrap: "break-word",
    },
    qaBodyMobile: {
      fontSize: 16,
    },
    upvoteButton: {
      padding: 4,
    },
    upvoteCount: {
      fontSize: 12,
      color: "#939393",
      position: "absolute",
      left: 40,
      top: 9,
    },
    qaButton: {
      marginBottom: -5,
    },
    openMenuIconButton: {
      padding: 4,
    },
    menuPaper: {
      backgroundColor: "white",
    },
    menuItem: {
      fontSize: 12,
      color: "black",
      minHeight: 0,
    },
    qaTextField: {
      width: 270,
      left: 10,
      position: "relative",
      height: 74,
      border: "none",
      outline: "none",
      backgroundColor: theme.palette.chatInputBackground.main,
      borderRadius: 4,
      fontSize: 14,
    },
    noBorder: {
      border: "none",
    },
    qaInput: { fontSize: 14, paddingTop: 14 },
    qaInputInput: {
      "&::placeholder": {
        color: theme.palette.qaViewHeader.main,
      },
    },
    qaInputAdornedEnd: {
      paddingRight: 5,
    },
    characterCount: {
      fontSize: 14,
      color: "#A5A5A5",
    },
    replyButton: {},
    answerText: {
      fontSize: 12,
      position: "relative",
      left: 10,
      wordBreak: "break-word",
      width: 230,
    },
    answerTextMobile: {
      width: "100%",
      fontSize: 16,
    },
    answerName: {
      color: theme.palette.qaItemHeader.main,
      fontSize: 12,
      position: "relative",
      left: 10,
    },
    answerAdornment: {
      height: "93.5%",
      backgroundColor: theme.palette.primary.main,
      width: 2,
      position: "absolute",
      top: 4,
      left: 0,
    },
    copyButton: {
      margin: 0,
      position: "fixed",
      zIndex: 14,
      /* right: 0,
    top: "50%",
    transform: "translateY(-50%)", */
      backgroundColor: theme.palette.messageCopyButton.main,
      padding: 6.5,
      minWidth: 32,
      maxWidth: 32,
      minHeight: 32,
      maxHeight: 32,
      "&:hover": {
        backgroundColor: theme.palette.messageCopyButton.main,
      },
    },
    buttonIcon: {
      width: 20,
      height: 20,
      color: "black",
    },
    qaDisplayButton: {
      padding: 4,
      color: "white",
      position: "relative",
      right: 10,
    },
    qaDisplayButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    displayLoadingSpinner: {
      color: "white",
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      bottom: 6,
      right: 94,
    }
  })
);

export { QAItemStyles };