import { makeStyles, createStyles } from '@material-ui/core/styles';

const SupportSidebarStyles = makeStyles((theme: any) => createStyles({
  supportHeaderHolder: {
    width: "100%",
    backgroundColor: theme.palette.sidebarHeader.main,
    height: 90,
    borderRadius: 4,
    borderTopRadius: 0,
    position: "relative",
  },
  supportHeaderHolderNoSupport: {
    height: 40,
  },
  supportHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
    color: "white"
  },
  supportIcon: {
    position: "absolute",
    left: 10,
    top: 7,
    color: "white"
  },
  closeIconButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  supportContentHolder: {
    width: "100%",
    height: "calc(100vh - 163px)",
    marginBottom: 5,
    position: "relative",
    top: 5,
    backgroundColor: "transparent"
  },
  supportContentHolderMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 163px)",
   /*  fallbacks: {
      height: "calc(100vh - 163px)"
    } */
  },
  supportContentHolderNoSupport: {
    height: "calc(100vh - 113px)",
  },
  supportContentHolderNoSupportMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 113px)",
    /* fallbacks: {
      height: "calc(100vh - 113px)"
    } */
  },
  supportContentHolderNonPresenter: {
    height: "calc(100vh - 123px)",
  },
  supportContentHolderNonPresenterMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 123px)",
   /*  fallbacks: {
      height: "calc(100vh - 123px)"
    } */
  },
  supportContentHolderNonPresenterNoSupport: {
    height: "calc(100vh - 73px)",
  },
  supportContentHolderNonPresenterNoSupportMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 73px)",
    /* fallbacks: {
      height: "calc(100vh - 73px)"
    } */
  },  
  supportText: {
    position: "relative",
    top: 27,
    marginBottom: 60,
    textAlign: "left",
    width: 258,
    display: "inline-block"
  },
  supportTextHolder: {
    backgroundColor: theme.palette.controlCenterButton.main,
    height: "auto",
    maxHeight: "calc(100vh - 163px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  supportTextHolderNonPresenter: {
    maxHeight: "calc(100vh - 123px)",
  },
  tabs: {
    position: "absolute",
    bottom: 0,
  },
  supportTab: {
    
  },
  informationTab: {
    padding: "6px 30px"
  },
  supportHeaderTop: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    width: "100%",
    height: 40,
    borderTopRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    top: 0,
    left: 0,
  },
  supportHeaderTopNoSupport : {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    width: "100%",
    height: 40,
    color: "white",
    borderRadius: 4
  },
  sectionHolder: {
    position: "relative",
    backgroundColor: theme.palette.controlCenterButton.main,
    width: "100%",
    marginBottom: 3,
    textAlign: "left",
    overflowY: "hidden",
    overflowX: "hidden",
    "&:hover":{
      cursor: "pointer"
    }
  },
  sectionHolderActive: {
    "&:hover": {
      cursor: "default"
    }
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: "bold",
    position: "absolute",
    top: 12,
    left: 11
  }
})
)

export { SupportSidebarStyles };