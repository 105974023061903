import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileSettingsStyles = makeStyles((theme: any) =>
  createStyles({
    mobileSettingsText: {
      color: "white",
      fontSize: 14,
      position: "relative",
      top: 7,
    },
    cancelButton: {
      marginLeft: 40,
    },
    nameTagInput: {
      width: "100%",
      marginBottom: 25,
      backgroundColor: "#111112",
      borderRadius: 4,
    },
  })
);

export { MobileSettingsStyles };