import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterMenuStyles = makeStyles((theme: any) =>
  createStyles({
    presenterMenuHolder: {
      position: "absolute",
      left: 0,
      top: 0,
      backgroundColor: "white",
      height: 40,
      width: "100%",
      zIndex: 9998,
    },
    presenterMenuBackdrop: {
      display: "inline-block",
      position: "absolute",
      width: "200vw",
      zIndex: 2,
      height: 77,
      visibility: "visible",
      backgroundColor: theme.palette.intakeBackground.main,
      bottom: 0,
      transition: "bottom 215ms",
      WebkitUserSelect: "none",
      WebkitTapHighlightColor: "rgba(0,0,0,0);",
    },
    presenterMenuBackdropCollapsed: {
      bottom: -77,
    },
    presenterMenuHolderLeftOpen: {
      width: "calc(100vw - 300px)",
      left: 300,
      [theme.breakpoints.down("md")]: {
        left: 250,
        width: "calc(100vw - 250px)",
      },
      [theme.breakpoints.down("sm")]: {
        left: 200,
        width: "calc(100vw - 200px)",
      },
    },
    presenterMenuHolderLeftAndRightOpen: {
      width: "calc(100vw - 640px)",
      left: 300,
      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 550px)",
        left: 250,
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 450px)",
        left: 200,
      },
    },
    presenterMenuHolderRightOpen: {
      width: "calc(100vw - 340px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 300px)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 250px)",
      },
    },
    presenterMenuHolderCollapsed: {
      bottom: -77,
    },
    presenterMenuHolderCollapsedLeftOpen: {
      bottom: -77,
    },
    presenterMenuHolderCollapsedLeftAndRightOpen: {
      bottom: -77,
    },
    presenterToolName: {
      fontSize: 14,
      color: "#FFB700",
      position: "absolute",
      left: 0,
      top: 20,
      fontWeight: 500,
    },
    modeSelectHolder: {
      width: 273,
      height: 54,
      position: "absolute",
      right: 0,
      backgroundColor: "transparent",
      borderRadius: 5,
      zIndex: 115,
    },
    buttonGroupMode: {
      width: "100%",
      height: "100%",
      zIndex: 100,
      outline: "none",
    },
    attendeeSelect: {
      width: 117,
      borderWidth: 1.5,
      "@media (pointer: fine)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
      "@media (pointer: coarse)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
    },
    presentationSelect: {
      width: 156,
      borderWidth: 1.5,
      "@media (pointer: fine)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
      "@media (pointer: coarse)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
    },
    selectButtonSelected: {
      backgroundColor: "#707070 !important",
    },
    toggleButton: {
      width: 106,
      height: 54,
      position: "relative",
      backgroundColor: theme.palette.intakePaper.main,
      border: "1px solid #707070",
      marginRight: 4,
      "@media (pointer: fine)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
      "@media (pointer: coarse)": {
        "&:hover": {
          backgroundColor: theme.palette.intakePaper.main,
        },
      },
    },
    toggleButtonSelected: {
      backgroundColor: "#707070",
      "@media (pointer: coarse)": {
        "&:hover": {
          backgroundColor: "#707070",
        },
      },
    },
    presenterMenuToggle: {
      marginRight: 5,
    },
    presenterMenuToggleEntryModal: {
      "&:hover": {
        cursor: "default",
      },
    },
    presenterMenuSwitch: {},
    switchEntryModal: {
      "&:hover": {
        cursor: "default",
      },
    },
    presenterMenuSwitchDisabled: {
      "&:hover": {
        cursor: "default !important",
      },
    },
    presenterMenuToggleDisabled: {
      opacity: 0.4,
    },
    toggleButtonLabel: {
      lineHeight: 1.3,
    },
    toggleButtonBackstage: {
      width: 140,
    },
    toggleButtonScreenshare: {
      width: 160,
    },
    toggleButtonConnect: {
      width: 130,
    },
    toggleButtonStage: {},
    toggleButtonJumbotron: {
      width: 145,
    },
    testButton: {
      position: "fixed",
      top: 300,
      left: 900,
      zIndex: 9999,
    },
    switchLabel: {
      color: "black",
      fontWeight: 500,
      fontSize: 14,
    },
    hoverButton: {
      color: "black",
      position: "relative",
      padding: 0,
      marginLeft: 17,
      marginRight: 35,
      top: 1,
    },
    hoverButtonEntryModal: {
      "&:hover": {
        cursor: "default",
      },
    },
    hoverButtonBreakout: {
      marginLeft: 13,
    },
    hoverButtonDisplay: {},
    hoverButtonAvatarAudio: {},
    hoverButtonAvatarAudioEntryModal: {
      "&:hover": {
        cursor: "default",
      },
    },
    presenterMenuDropdown: {
      backgroundColor: theme.palette.sidebarHeader.main,
      position: "absolute",
      top: "40px !important",
      zIndex: 9999,
      fontSize: 14,
      fontWeight: 500,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      minWidth: 170,
      pointerEvents: "all",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    presenterMenuDropdownAudio: {
      minWidth: 234,
    },
    presenterMenuDropdownTeleport: {},
    popover: {
      backgroundColor: "transparent",
      pointerEvents: "none",
    },
    presenterDropdownMenuItem: {
      fontSize: 14,
      fontWeight: 500,
      "&:focused": {},
    },
    presenterDropdownMenuItemDisabled: {
      opacity: 0.4,
      "&:hover": {
        cursor: "default",
        backgroundColor: theme.palette.sidebarHeader.main,
      },
    },
    bubbleMenuItem: {
      paddingBottom: 50,
      paddingTop: 14,
      position: "relative",
      marginBottom: 30,
      backgroundColor: "transparent !important",
    },
    bubbleMenuItemFocused: {
      backgroundColor: theme.palette.sidebarHeader.main,
    },
    bubbleSlider: {
      position: "absolute",
      width: 198,
      bottom: 10,
      left: 16,
    },
    autoBubbleToggle: {
      position: "absolute",
      left: 168,
    },
    presenterDropdownMenuItemActive: {},
    switchTrack: {
      backgroundColor: "#818181",
    },
    buttonActiveIcon: {
      color: theme.palette.primary.main,
      position: "absolute",
      fontSize: 20,
      right: -16,
      top: 9,
    },
    buttonActiveIconAvatar: {
      right: -19.5,
    },
    activeIconMenuItem: {
      color: theme.palette.primary.main,
      position: "relative",
      fontSize: 20,
      left: 5,
      marginRight: -10,
    },
    buttonIconInactive: {
      color: "#B2B2B2",
    },
    menuDivider: {
      width: 198,
      left: 16,
      position: "relative",
      bottom: 32,
      height: 1.5,
      marginBottom: 12,
      backgroundColor: "#707070",
    },
    twoDModeHeader: {
      color: "#868686",
      fontWeight: 500,
      fontSize: 14,
      position: "absolute",
      left: 17,
      bottom: 113,
    },
  })
);

export { PresenterMenuStyles };