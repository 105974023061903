import { makeStyles, createStyles } from '@material-ui/core/styles';

const CameraAndMicSettingsStyles = makeStyles((theme: any) =>
  createStyles({
    avHeader: {
      fontSize: 24,
      position: "absolute",
      top: 34,
      left: 50,
      fontWeight: 400,
      fontFamily: "Open Sans, sans-serif",
      [theme.breakpoints.down("sm")]: {
        left: 30,
      },
    },
    cameraSelectHeader: {
      fontSize: 24,
      position: "absolute",
      top: 0,
      left: 0,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {},
    },
    speakerSelectHeader: {
      position: "relative",
      top: 0,
      left: 0,
      fontSize: 24,
      fontWeight: 500,
    },
    cameraSelectHolder: {
      left: 0,
      top: 45,
      minWidth: "82%",
      maxWidth: "82%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        minWidth: "93%",
        maxWidth: "93%",
      },
    },
    speakerSelectHolder: {
      position: "relative",
      left: 0,
      top: 45,
      float: "left",
      minWidth: "82%",
      maxWidth: "82%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        minWidth: "93%",
        maxWidth: "93%",
      },
    },
    micSelectHeader: {
      fontSize: 24,
      position: "absolute",
      top: 0,
      left: 0,
      fontWeight: 500,
    },
    micSelectHolder: {
      left: 0,
      top: 40,
      minWidth: "82%",
      maxWidth: "82%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        minWidth: "93%",
        maxWidth: "93%",
      },
    },
    testMicHeader: {
      fontSize: 24,
      fontWeight: 500,
      position: "absolute",
      top: 0,
      left: 0,
    },
    backButton: {
      height: 46,
      width: 115,
    },
    enterButton: {
      backgroundColor: theme.palette.intakeBlue.main,
      height: 46,
      width: 115,
      color: "white",
    },
    muteCameraButton: {
      borderRadius: "52%",
      height: 63,
      width: 52,
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      right: 0,
      bottom: 30,
      [theme.breakpoints.down("sm")]: {
        bottom: 65,
      },
    },
    muteCameraButtonPopup: {
      bottom: 0,
      top: 105,
      right: 110,
      [theme.breakpoints.down("sm")]: {
        right: 30,
      },
    },
    muteCameraButtonJustMic: {
      backgroundColor: "#7B7B7B",
      color: "white",
    },
    cameraIcon: {
      fontSize: 35,
    },

    muteMicButton: {
      borderRadius: "52%",
      height: 63,
      width: 52,
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      right: 0,
      bottom: 30,
      [theme.breakpoints.down("sm")]: {
        bottom: 65,
      },
    },
    muteMicButtonPopup: {
      bottom: 0,
      top: 90,
      right: 110,
      [theme.breakpoints.down("sm")]: {
        right: 30,
      },
    },
    micIcon: {
      fontSize: 35,
    },
    deviceErrorMsg: {
      fontSize: 12,
      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
    },
    testSpeakerButtonIntake: {
      position: "absolute",
      top: -5,
      left: 117,
      backgroundColor: "#00B6FF",
      color: "white",
      width: 77,
    },
    testSpeakerButtonIntakePlaying: {
      width: 110,
    },
    echoWarning: {
      color: "#00B6FF",
      display: "inline-block",
      width: 400,
      position: "relative",
      top: 105,
      left: 116,
      textAlign: "left",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        width: 260,
        left: 90,
      },
    },
    echoWarningNonChrome: {
      top: 0,
    },
    echoWarningSidebar: {
      display: "inline-block",
      width: 315,
      left: 11,
      top: 10,
      marginBottom: 22,
    },
    warningIcon: {
      position: "absolute",
      bottom: -85,
      left: 48,
      fontSize: 40,
      color: "#FFB700",
      [theme.breakpoints.down("sm")]: {
        left: 26,
      },
    },
    warningIconSidebar: {
      top: 0,
      position: "relative",
      left: -10,
      fontSize: 44,
    },
    warningIconSidebarLong: {
      top: -12
    },
    warningIconNonChrome: {
      bottom: 20, 
    },
    avHeaderSettings: {
      fontSize: 14,
      position: "absolute",
      top: 16,
      left: 15,
      fontWeight: 500,
    },
    avSettingsHolder: {
      backgroundColor: theme.palette.settingsPaper.main,
      width: "100%",
      position: "relative",
    },
    cameraSettingsHolder: {
      height: 111,
      marginBottom: 4,
      marginTop: 5,
    },
    micSettingsHolder: {
      height: 102,
      marginBottom: 4,
    },
    speakersSettingsHolder: {
      height: 162,
    },
    deviceSelectSettings: {
      left: -4,
      top: 50,
      minWidth: 370,
      maxWidth: 370,
      textAlign: "left",
    },
    testButtonSidebar: {
      position: "absolute",
      backgroundColor: theme.palette.intakeBlue.main,
      left: 15,
      bottom: 20,
      padding: 0,
      width: 77,
      height: 34,
      color: "white",
    },
    testButtonSidebarPlaying: {
      width: 100,
    },
    errorSidebar: {
      color: "#00B6FF",
      display: "inline-block",
      position: "relative",
      textAlign: "left",
      fontSize: 16,
      width: 315,
      left: 11,
      top: -17,
    },
    errorSidebarLong: {
      top: -4
    }
  })
);

export { CameraAndMicSettingsStyles }; 