import { makeStyles, createStyles } from '@material-ui/core/styles';

const HangUpSidebarStyles = makeStyles((theme: any) => createStyles({
  hangUpHeaderHolder: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    width: "100%",
    height: 40,
    color: "white"
  },
  hangUpHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
    color: "white"
  },
  hangUpIcon: {
    position: "absolute",
    left: 10,
    top: 7,
    color: "white"
  },
  closeIconButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  hangUpButton: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.sidebarHeader.main,
    width: 170,
    height: 60,
    position: "relative",
    color: theme.palette.text.primary,
    fontSize: 18,
    top: 20,
    borderRadius: 10,
    boxShadow: "none"
  },
  hangUpIconButton: {
    marginRight: 8 
  },
  hangUpContentHolder: {
    position: "relative",
    height: "calc(100vh - 113px)",
    width: "100%",
    backgroundColor: "transparent",
    top: 5,
    marginBottom: 5,
  },
  hangUpContentHolderMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 113px)",
    /* fallbacks: {
      height: "calc(100vh - 113px)"
    } */
  },
  hangUpContentHolderNonPresenter: {
    height: "calc(100vh - 73px)"
  },
  hangUpContentHolderNonPresenterMobile: {
    height: "calc(var(--vh, 1vh) * 100 - 73px)",
   /*  fallbacks: {
      height: "calc(100vh - 73px"
    } */
  },
  hangUpButtonsHolder: {
    backgroundColor: theme.palette.controlCenterButton.main,
    width: "100%",
    height: 180,
  },
  closeButton: {
    boxSizing: "border-box",
    width: 170,
    height: 60,
    color: theme.palette.text.primary,
    fontSize: 18,
    borderRadius: 10,
    top: 40,
  },
})
)

export { HangUpSidebarStyles };