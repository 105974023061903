import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Paper, Typography } from "@material-ui/core";

import { ServerHelper } from "../../Utilities/ServerHelper";
import AvatarLoadingScreen from "../../LoadingScreen/AvatarLoadingScreen/AvatarLoadingScreen";

import styles from "./AvatarHolder.module.css";
import { AvatarHolderStyles } from "./AvatarHolderStyles";
import { IntakeContext, IntakeContextType } from "../../context/IntakeContext";

interface Props {}

export default function AvatarHolder(props: Props): ReactElement {
  const { toggleAvatarLoadComplete }: IntakeContextType =
    useContext(IntakeContext);

  const [showLoadingScreen, toggleShowLoadingScreen] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState("");

  const mounted = useRef(true);

  useEffect(() => {
    document
      .getElementById("canvasHolder")
      .appendChild((window as any).babylonCanvas);
    document
      .getElementById("showboatCanvas")
      .classList.add(`${styles.avatarCanvas}`);

    const loadAvatar = async () => {
      let audioContext = SHOWBOAT.AudioContextManager.AudioContext;
      let audioDestination = null;
      if (ServerHelper.useAudioManager) {
        await SHOWBOAT.AudioManager.Init(ServerHelper.useAudioManagerLoopback);
        audioDestination = SHOWBOAT.AudioManager.getAudioDestination();
      }

      //Initialize the Showboat Loader
      SHOWBOAT.ShowboatLoader.Init(
        "showboatCanvas",
        "./core/" + SHOWBOAT.ApplicationSkin.showboatCore,
        "./core/" + SHOWBOAT.ApplicationSkin.showboatCoreConfig,
        audioContext,
        audioDestination,
        ServerHelper.useAudioManager
      )
        .then(async () => {
          //Load the welcome avatar scene
          SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Add(handleShowLoadingScreen);
          SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Add(handleHideLoadingScreen);
          SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Add(
            setLoadingScreenProgress
          );

          let sceneSupplement = SHOWBOAT.ApplicationSkin.sceneSupplement ? "./scenes/" + SHOWBOAT.ApplicationSkin.sceneSupplement : null;

          await SHOWBOAT.ShowboatLoader.Load(
            "showboatCanvas",
            "./core/" + SHOWBOAT.ApplicationSkin.intakeScene,
            "./core/" + SHOWBOAT.ApplicationSkin.intakeSceneConfig,
            sceneSupplement,
            {}
          );

          //Enable entry into 3D
          toggleAvatarLoadComplete(true);
        })
        .catch((error) => {
          //Enable entry into 3D
          toggleAvatarLoadComplete(true);

          SHOWBOAT.Logger.Error("Error initializing Showboat Loader");
          SHOWBOAT.Logger.Error(error);
        });
    };
    loadAvatar();

    return function cleanup() {
      mounted.current = false;

      (window as any).babylonCanvas.classList.remove(`${styles.avatarCanvas}`);

      SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Remove(handleShowLoadingScreen);
      SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Remove(handleHideLoadingScreen);
      SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Remove(
        setLoadingScreenProgress
      );
    };
  }, [toggleAvatarLoadComplete]);

  const handleShowLoadingScreen = () => {
    if (mounted.current) {
      toggleShowLoadingScreen(true);
    }
  };

  const handleHideLoadingScreen = () => {
    if (mounted.current) {
      toggleShowLoadingScreen(false);
    }
  };

  const setLoadingScreenProgress = (loadingProgress: string) => {
    if (mounted.current) {
      setLoadingProgress(loadingProgress);
    }
  };

  const classes = AvatarHolderStyles();

  return (
    <Paper
      id="canvasHolder"
      elevation={0}
      classes={{ root: classes.avatarHolder }}
    >
      {showLoadingScreen && (
        <AvatarLoadingScreen loadingProgress={loadingProgress} />
      )}

      <Typography variant="h1" classes={{ root: classes.avatarHeader }}>
        Your Avatar
      </Typography>
    </Paper>
  );
}
