import {
  Avatar,
  Button,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { ServerHelper } from "../../../../../Utilities/ServerHelper";

import styles from "./AvatarSettings.module.css";
import { AvatarSettingsStyles } from "./AvatarSettingsStyles";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";

interface Props {}

export default function AvatarSettings(props: Props): ReactElement {
  const [firstName, setFirstName] = useState(
    SHOWBOAT.LocalAvatarDataManager.firstName
  );
  const [lastName, setLastName] = useState(
    SHOWBOAT.LocalAvatarDataManager.lastName
  );
  const [company, setCompany] = useState(
    SHOWBOAT.LocalAvatarDataManager.company
  );

  const [selectedColor, setSelectedColor] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.color
  );
  const [selectedFace, setSelectedFace] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.face
  );

  const [saveCompleted, setSaveCompleted] = useState(false);

  const hideCheckIconTimeout = useRef(null);

  useEffect(() => {
    return function cleanup() {
      if (hideCheckIconTimeout.current)
        clearTimeout(hideCheckIconTimeout.current);
    };
  }, []);

  const handleColorChange = (color: number) => {
    if (selectedColor === color) return;

    //Send server side
    SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(color);

    //Cache the color
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.PreferredColorHex,
      SHOWBOAT.ApplicationSkin.primaryAvatarColors[color]
    );

    SHOWBOAT.LocalAvatarDataManager.color = color;
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.ColorNumber);

    setSelectedColor(color);
  };

  const handleFaceChange = (face: number) => {
    //Ensure no duplicates
    if (selectedFace === face) return;

    SHOWBOAT.UIEventManager.OnAvatarFaceChanged.Raise(face);

    //Cache the face index
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.PreferredFaceIndex,
      face.toString()
    );

    SHOWBOAT.LocalAvatarDataManager.face = face;
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.FaceNumber);

    setSelectedFace(face);
  };

  const handleFirstNameChange = (e) => {
    setSaveCompleted(false);
    if (hideCheckIconTimeout.current)
      clearTimeout(hideCheckIconTimeout.current);
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setSaveCompleted(false);
    if (hideCheckIconTimeout.current)
      clearTimeout(hideCheckIconTimeout.current);
    setLastName(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setSaveCompleted(false);
    if (hideCheckIconTimeout.current)
      clearTimeout(hideCheckIconTimeout.current);
    setCompany(e.target.value);
  };

  const handleSaveNameTagClick = () => {
    SHOWBOAT.UIEventManager.OnNameTagChanged.Raise(
      firstName,
      lastName,
      company
    );

    SHOWBOAT.LocalAvatarDataManager.firstName = firstName;
    SHOWBOAT.LocalAvatarDataManager.lastName = lastName;
    SHOWBOAT.LocalAvatarDataManager.company = company;
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.NameTag);

    setSaveCompleted(true);

    hideCheckIconTimeout.current = setTimeout(function () {
      setSaveCompleted(false);
    }, 3000);
  };

  const classes = AvatarSettingsStyles();

  return (
    <div className={styles.avatarSettingsWrapper}>
      <Paper
        className={`${classes.faceHolder} ${classes.nameTagHolder}`}
        style={{ marginBottom: "-1px" }}
      >
        <div className={styles.faceWrapper}>
          <Typography variant="h2" className={classes.settingsHeader}>
            NAME TAG
          </Typography>

          <div className={styles.nameTagInputsHolder}>
            <TextField
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              onChange={handleFirstNameChange}
              value={firstName}
              className={classes.nameTagInput}
              fullWidth
              key="First Name"
              label="First Name"
            />

            <TextField
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              onChange={handleLastNameChange}
              value={lastName}
              className={classes.nameTagInput}
              fullWidth
              key="Last Name"
              label="Last Name"
            />

            <TextField
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              onChange={handleCompanyChange}
              fullWidth
              className={classes.nameTagInput}
              value={company}
              key="Company"
              label="Company"
            />
          </div>

          <Button
            className={classes.saveButton}
            onClick={handleSaveNameTagClick}
          >
            SAVE
          </Button>

          {saveCompleted && <CheckIcon className={classes.checkIcon} />}
        </div>
      </Paper>

      <Paper className={classes.colorHolder}>
        <div className={styles.colorWrapper}>
          <Typography variant="h2" className={classes.settingsHeader}>
            COLOR
          </Typography>

          <div className={styles.optionSelectHolder}>
            <form className={styles.optionForm}>
              {SHOWBOAT.ApplicationSkin.primaryAvatarColors.map((color, i) => {
                if (SHOWBOAT.ApplicationSkin.primaryAvatarColors[i] === "") {
                  return null;
                } else {
                  if (i === selectedColor) {
                    return (
                      <Paper
                        tabIndex={-1}
                        key={i}
                        classes={{
                          root: `${classes.colorInputHolder} ${classes.colorInputHolderSelected}`,
                        }}
                      >
                        <input
                          type="button"
                          onClick={() => handleColorChange(i)}
                          onFocus={() => handleColorChange(i)}
                          className={styles.colorInput}
                          id={`${i.toString()}COLORSELECT`}
                        ></input>

                        <div
                          className={styles.colorDisplay}
                          style={{ backgroundColor: color }}
                        ></div>
                      </Paper>
                    );
                  } else {
                    return (
                      <Paper
                        tabIndex={-1}
                        key={i}
                        classes={{ root: classes.colorInputHolder }}
                      >
                        <input
                          type="button"
                          onClick={() => handleColorChange(i)}
                          onFocus={() => handleColorChange(i)}
                          className={styles.colorInput}
                          id={`${i.toString()}COLORSELECT`}
                        ></input>
                        <div
                          className={styles.colorDisplay}
                          style={{ backgroundColor: color }}
                        ></div>
                      </Paper>
                    );
                  }
                }
              })}
            </form>
          </div>
        </div>
      </Paper>

      <Paper className={classes.faceHolder} style={{ top: "1px" }}>
        <div className={styles.faceWrapper}>
          <Typography variant="h2" className={classes.settingsHeader}>
            FACE
          </Typography>

          <div className={styles.optionSelectHolder}>
            <form className={styles.optionForm}>
              {SHOWBOAT.ApplicationSkin.faceThumbnailURLs.map((faceSrc, i) => {
                if (faceSrc === "") {
                  return null;
                } else {
                  if (selectedFace === i) {
                    return (
                      <Paper
                        id={`${i.toString()}FACESELECT`}
                        tabIndex={i + 1}
                        key={i}
                        className={`${classes.faceAvatarHolder} ${classes.selectedAvatarHolder}`}
                        elevation={0}
                      >
                        <Avatar
                          onClick={() => handleFaceChange(i)}
                          onFocus={() => handleFaceChange(i)}
                          key={i}
                          tabIndex={-1}
                          style={{
                            backgroundColor:
                              SHOWBOAT.ApplicationSkin.secondaryAvatarColors[
                                SHOWBOAT.LocalAvatarDataManager.avatarData.color
                              ],
                          }}
                          variant="rounded"
                          src={`assets/images/faces/${faceSrc}`}
                          className={classes.faceAvatar}
                        >
                          <input
                            type="button"
                            className={styles.faceInput}
                          ></input>
                        </Avatar>
                      </Paper>
                    );
                  } else {
                    return (
                      <Paper
                        className={classes.faceAvatarHolder}
                        onClick={() => handleFaceChange(i)}
                        onFocus={() => handleFaceChange(i)}
                        key={i}
                        id={`${i.toString()}FACESELECT`}
                        tabIndex={i + 1}
                        elevation={0}
                      >
                        <Avatar
                          key={i}
                          variant="rounded"
                          src={`assets/images/faces/${faceSrc}`}
                          className={classes.faceAvatar}
                          style={{
                            backgroundColor:
                              SHOWBOAT.ApplicationSkin.secondaryAvatarColors[
                                SHOWBOAT.LocalAvatarDataManager.avatarData.color
                              ],
                          }}
                        >
                          <input
                            type="button"
                            className={styles.faceInput}
                          ></input>
                        </Avatar>
                      </Paper>
                    );
                  }
                }
              })}
            </form>
          </div>

          <Typography variant="body1" className={classes.faceText}>
            Select a face to be used when your camera is not active.
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
