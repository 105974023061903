import { makeStyles, createStyles } from "@material-ui/core/styles";

const MobilePresenterFeedsStyles = makeStyles((theme: any) =>
  createStyles({
    noPresentersText: {
      fontSize: 20,
      color: "#808080",
      position: "absolute",
      top: 0,
      bottom: 50,
      right: 0,
      left: 0,
      margin: "auto",
      textAlign: "center",
      width: 280,
      height: 52,
      display: "inline-block"
    }
  })
)

export { MobilePresenterFeedsStyles };