import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";

import { MobilePresenterStreamHelper } from "../../../Utilities/MobilePresenterStreamHelper";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";
import MobilePresenterFeed from "./MobilePresenterFeed/MobilePresenterFeed";

import styles from "./MobilePresenterFeeds.module.css";
import { MobilePresenterFeedsStyles } from "./MobilePresenterFeedsStyles";

type Props = {};

export default function MobilePresenterFeeds({}: Props) {
  const {
    setAudioUnlockPressedForPresenters,
    currentMobileMode,
  }: MobileContextType = useContext(MobileContext);

  const [presenterCameraItems, setPresenterCameraItems] = useState([]);
  const [itemWidth, setItemWidth] = useState(null);

  const presenterCameraItemsRef = useRef([]);
  const firstRender = useRef(true);

  useEffect(() => {
    //Get initial presenter videos
    getInitialPresenterVideos();

    //Listen for presenters starting/stopping their video
    MobilePresenterStreamHelper.OnPresenterStarted.Add(
      handlePresenterStartVideo
    );

    MobilePresenterStreamHelper.OnPresenterStopped.Add(
      handlePresenterStopVideo
    );

    return function cleanup() {
      MobilePresenterStreamHelper.OnPresenterStarted.Remove(
        handlePresenterStartVideo
      );

      MobilePresenterStreamHelper.OnPresenterStopped.Remove(
        handlePresenterStopVideo
      );
    };
  }, []);

  useEffect(() => {
    //When presenter view is opened, attempt to play each of the presenter videos
    if (
      currentMobileMode ===
        StringVariableHelper.MobileModeValues.presenterFeeds &&
      presenterCameraItems.length
    ) {
      presenterCameraItems.forEach(async (item) => {
        if (item.downstreamController && item.downstreamController.play) {
          await item.downstreamController.play();
        }
      });
    }
  }, [currentMobileMode]);

  useEffect(() => {
    //Determine width for presenter camera items
    if (presenterCameraItems.length < 3) {
      setItemWidth(47.5);
    } else {
      setItemWidth(31);
    }

    if (firstRender.current === true) {
      firstRender.current = false;
      return;
    }

    //If length goes back down to 0, allow showing of audio unlock popup again
    if (presenterCameraItems.length === 0 && firstRender.current === false) {
      setAudioUnlockPressedForPresenters(false);
    }
  }, [presenterCameraItems.length]);

  const getInitialPresenterVideos = () => {
    let currentSharingControllers =
      MobilePresenterStreamHelper.getCurrentDownstreamControllers();

    SHOWBOAT.Logger.Log("Current sharing controllers:", currentSharingControllers)

    let objArray = [];
    for (let i = 0; i < currentSharingControllers.length; i++) {
      objArray.push({
        userID: currentSharingControllers[i].getUserID(),
        downstreamController: currentSharingControllers[i],
      });
    }

    presenterCameraItemsRef.current = objArray;
    setPresenterCameraItems(objArray);
  };

  const handlePresenterStartVideo = (
    userID: string,
    downstreamController: SHOWBOAT.LiveswitchDownstreamController
  ) => {
    setPresenterCameraItems((presenterCameraItems) => [
      ...presenterCameraItems,
      { userID, downstreamController },
    ]);

    presenterCameraItemsRef.current = [
      ...presenterCameraItemsRef.current,
      { userID, downstreamController },
    ];
  };

  const handlePresenterStopVideo = (userID: string) => {
    presenterCameraItemsRef.current = presenterCameraItemsRef.current.filter(
      (item) => item.userID !== userID
    );

    setPresenterCameraItems(presenterCameraItemsRef.current);
  };

  const classes = MobilePresenterFeedsStyles();

  return (
    <React.Fragment>
      {!presenterCameraItems.length &&
        currentMobileMode ===
          StringVariableHelper.MobileModeValues.presenterFeeds && (
          <Typography className={classes.noPresentersText}>
            There are currently no in-room presenters
          </Typography>
        )}

      <div
        className={styles.mobilePresenterFeedsHolder}
        style={{
          justifyContent:
            presenterCameraItems.length === 1 ? "flex-start" : "space-around",
          display:
            currentMobileMode ===
            StringVariableHelper.MobileModeValues.presenterFeeds
              ? "flex"
              : "none",
        }}
      >
        {itemWidth !== null &&
          presenterCameraItems.map((item, i) => {
            return (
              <React.Fragment key={item.userID}>
                <MobilePresenterFeed
                  userID={item.userID}
                  downstreamController={item.downstreamController}
                  width={itemWidth}
                />
                {presenterCameraItems.length > 3 &&
                  (presenterCameraItems.length - 1) % 3 === 0 &&
                  i === presenterCameraItems.length - 1 && (
                    <React.Fragment>
                      <div
                        className={styles.fakePresenterItem}
                        style={{ width: `${itemWidth}%` }}
                        key={SHOWBOAT.LiveSwitchClientController.generateUUID()}
                      ></div>
                      <div
                        className={styles.fakePresenterItem}
                        style={{ width: `${itemWidth}%` }}
                        key={SHOWBOAT.LiveSwitchClientController.generateUUID()}
                      ></div>
                    </React.Fragment>
                  )}

                {presenterCameraItems.length > 4 &&
                  (presenterCameraItems.length - 2) % 3 === 0 &&
                  i === presenterCameraItems.length - 1 && (
                    <div
                      className={styles.fakePresenterItem}
                      style={{ width: `${itemWidth}%` }}
                      key={SHOWBOAT.LiveSwitchClientController.generateUUID()}
                    ></div>
                  )}
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
}
