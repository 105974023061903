import { Paper, Typography } from '@material-ui/core';
import React from 'react'
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { MobileNotSupportedErrorStyles } from './MobileNotSupportedErrorStyles';

import styles from "./MobileNotSupportedError.module.css";

type Props = {
  holderClasses: string;
}

export default function MobileNotSupportedError(props: Props) {

  const classes = MobileNotSupportedErrorStyles();
  
  return (
    <Paper className={props.holderClasses}>
      <div className={styles.welcomeElementWrapperMobile}>
        <WarningIcon className={classes.warningIcon} />

        <Typography className={classes.mobileNotSupportedMessage}>
          This meeting does not support mobile devices. Please join us on a PC
          or Mac.
        </Typography>
      </div>
    </Paper>
  );
}