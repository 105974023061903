import { makeStyles, createStyles } from '@material-ui/core/styles';

const SlidePresenterToolStyles = makeStyles((theme: any) => createStyles({
  slideToolHeaderHolder: {
    backgroundColor: theme.palette.plain.main,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 42,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  slideToolHeader: {
    fontSize: 14,
    fontWeight: 500,
    position: "absolute",
    top: 14,
    left: 22,
  },
  iconButton: {
    position: "relative",
    padding: 4,
    color: theme.palette.text.primary
  },
  slideFooterHolder: {
    width: "100%",
    height: 75,
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    bottom: -70,
    left: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  showSlidesButton: {
    position: "absolute",
    left: "5%",
    bottom: "26%",
  },
  buttonArrowIcon: {
    position: "relative",
    left: 10,
  },
  shareToggle: {
    position: "absolute",
    bottom: "26%",
    right: "5%"
  },
  toggleLabel: {
    fontWeight: 500,
    fontSize: 14,
  },
  nextSlideButton: {
    minWidth: 30,
    height: 80,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    padding: 0,
    color: "white"
  },
  previousSlideButton: {
    minWidth: 30,
    height: 80,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    padding: 0,
    color: "white"
  },
  slideText: {
    top: "45%",
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    margin: "auto",
    color: "white",
    fontSize: 14,
    marginRight: "10%",
    marginLeft: "10%"
  },
  slideHolder: {
    width: "100%",
    height: "auto",
    maxHeight: 300,
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    marginTop: "56%",
    backgroundColor: theme.palette.toAttendeeSelect.main,
    textAlign: "center",
    borderRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "&:hover": {
      cursor: "default"
    }
  },
  previousSlideLabel: {
    position: "relative",
    left: 10,
  },
  activeIconMenuItem: {
    color: theme.palette.primary.main,
    position: "relative",
    fontSize: 20,
    left: 5,
    marginRight: -10
  },
  presentationsMenu: {

  },
  slideFooterHolderViewSlides: {
    bottom: -75,
  },
  slideFooterHolderNoPresentation: {
    top: 280,
  },
  loadingSpinner: {
    color: theme.palette.text.primary,
    width: "17px !important",
    height: "17px !important",
    position: "absolute",
    left: -10,
    top: 6

  }
})
)

export { SlidePresenterToolStyles };