import { makeStyles, createStyles } from '@material-ui/core/styles';

const LocalVideoControlsStyles = makeStyles((theme: any) => createStyles({
  localVideoControlsHolder: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "rgb(0, 0, 0, 0.84)",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    position: "absolute",
    minHeight: 47,
    bottom: 0,
    left: 0,
    borderRadius: 0,
    zIndex: 10,
    "&:hover": {
      cursor: "default"
    }
  },
  playButton: {
    padding: 4,
    position: "relative",
    color: "white",
    display: "inline-block",
    opacity: 1,
    zIndex: 10,
  },
  volumeButton: {
    padding: 4,
    position: "relative",
    color: "white",
    display: "inline-block",
    opacity: 1
  },
  controlsSlider: {
    opacity: 1,
    width: "100%",
  },
  volumeSliderHolder: {
    height: 82,
    width: 22,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    bottom: "100%",
    right: 5,
    position: "absolute",
    backgroundColor: "rgb(0, 0, 0, 0.84)",
  },
  volumeSlider: {
    height: "80% !important",
    top: 9,
    position: "relative",
    right: 3,
  },
  volumeThumb: {
    width: 8,
    height: 8,
    marginLeft: "-3px !important",
  },
  videoTime: {
    color: "white",
    fontSize: 13,
    position: "relative",
  }
})
)

export { LocalVideoControlsStyles }; 