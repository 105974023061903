import { useRef, useEffect } from "react";
import axios from "axios";
import { ServerHelper } from "../Utilities/ServerHelper";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";

const useCallAppIntakePost = () => {

  const postIntakeRef = useRef(null);
  const backoffTimeRef = useRef(null);

  useEffect(() => {

    //Clear timeouts on dismount
    return function cleanup() {
      if (postIntakeRef.current) {
        clearInterval(postIntakeRef.current);
      }
      if (backoffTimeRef.current) {
        clearInterval(backoffTimeRef.current);
      }
    }
  }, [])
  
  const callAppIntakePost = async (intakeData) => {
    let localAttendeesIDArray: string[];

    let attendeesIDStringVariable =
      StringVariableHelper.LocalStorageProperties.AttendeesIDArray;

    //send attendeesID along if it is there
    if (localStorage.getItem(attendeesIDStringVariable)) {
      localAttendeesIDArray = JSON.parse(
        localStorage.getItem(attendeesIDStringVariable)
      );
    } else {
      //create empty array in local storage
      let newStorageArray = [];
      localStorage.setItem(
        attendeesIDStringVariable,
        JSON.stringify(newStorageArray)
      );

      //send empty array along
      localAttendeesIDArray = [];
    }

    try {
      let response = await axios.post("/intake", {
        socketID: SHOWBOAT.LocalAvatarDataManager.userID,
        intakeData,
        loginCode: ServerHelper.loginCode,
        attendeesIDArray: localAttendeesIDArray,
        attendeesID: ServerHelper.attendeesID,
        token: ServerHelper.attendeesIDToken
      });

      if (postIntakeRef.current) {
        clearInterval(postIntakeRef.current);
      }

      //set attendeesID in localStorage
      if (response.data.attendeesID) {
        let arrayAlreadyInStorage = JSON.parse(
          localStorage.getItem(attendeesIDStringVariable)
        );

        //max array out at 10 total ID's
        arrayAlreadyInStorage = [
          response.data.attendeesID,
          ...arrayAlreadyInStorage.slice(0, 9),
        ];

        localStorage.setItem(
          attendeesIDStringVariable,
          JSON.stringify(arrayAlreadyInStorage)
        );
      }

      SHOWBOAT.Logger.Log("Intake-post success"); 

      return;
    } catch (err) {
      SHOWBOAT.Logger.Error("Error posting intake data, retrying", err);
      backoffTimeRef.current = backoffTimeRef.current + 10000;
      postIntakeRef.current = setTimeout(function () {
        callAppIntakePost(intakeData);
      }, backoffTimeRef.current);
    }
  };

  return {
    callAppIntakePost
  };
};

export default useCallAppIntakePost;
