import { ServerHelper } from "./ServerHelper";
import axios from "axios";

import { StringVariableHelper } from "./StringVariableHelper";

export class ARSHelper {
  public static async sendUpdate(type: string, data: any) {
    try {
      let msg = {
        type,
        loginCode: ServerHelper.loginCode,
        bookingID: ServerHelper.loginResult.eventID,
      };

      //Switch off the type, constructing the message accordingly
      switch (type) {
        case StringVariableHelper.ARSType.question:
          (msg as any).text = data.text;
          (msg as any).askerName = data.askerName;
          break;
      }

      await axios.post(
        process.env.REACT_APP_ARS_CMS + "/update",
        JSON.stringify(msg)
      );

      return true;
    } catch (error) {
      SHOWBOAT.Logger.Log("Error sending ARS update:", error);
      return false;
    }
  }
}

(window as any).ARSHelper = ARSHelper;
