import React, { ReactElement } from "react";
import {
  Paper,
  Stepper,
  StepLabel,
  Step,
  StepConnector,
  withStyles,
} from "@material-ui/core";

import { IntakeStepperStyles } from "./IntakeStepperStyles";

const CustomConnector = withStyles({
  completed: {
    "& $line": {
      borderColor: "#00B6FF",
      borderTopWidth: 2,
    },
  },
  active: {
    "& $line": {
      borderColor: "#00B6FF",
      borderTopWidth: 2,
    },
  },
  line: {},
})(StepConnector);

interface Props {
  currentStepNumber: number;
}

export default function IntakeStepper(props: Props): ReactElement {
  const steps: Array<string> = ["Name Tag", "Color", "Confirm", "AV"];

  //materialUI classes
  const classes = IntakeStepperStyles();

  return (
    <Paper elevation={0} classes={{ root: classes.intakeStepperWrapper }}>
      <Stepper
        activeStep={props.currentStepNumber}
        classes={{ root: classes.stepper }}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map((label, i) => {
          if (i === 2) {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.circleIconActive,
                      completed: classes.circleIconCompleted,
                      root: classes.circleIconConfirm,
                      text: classes.circleIconText,
                    },
                  }}
                ></StepLabel>
              </Step>
            );
          } else {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.circleIconActive,
                      completed: classes.circleIconCompleted,
                      root: classes.circleIcon,
                      text: classes.circleIconText,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          }
        })}
      </Stepper>
    </Paper>
  );
}
