import React, { useState, useContext, useEffect, useRef } from "react";
import {
  IconButton,
  InputAdornment,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Close";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import PeopleIcon from "@material-ui/icons/People";

import AttendeeItem from "./AttendeeItem/AttendeeItem";
import {
  AttendeeContext,
  AttendeeContextType,
} from "../../../../context/AttendeeContext";
import { AppContext, AppContextType } from "../../../../context/AppContext";

import styles from "./AttendeeList.module.css";
import { AttendeeListStyles } from "./AttendeeListStyles";
import { isMobile } from "react-device-detect";
import { useMemo } from "react";
import { ServerHelper } from "../../../../Utilities/ServerHelper";

interface Props {
  roomSwitchVariant: boolean;
  mobile?: boolean;
}

export default function AttendeeList(props: Props) {
  /*Context */
  const { attendeesInEvent }: AttendeeContextType = useContext(AttendeeContext);

  const { setSelectedAttendeeIDContext }: AppContextType =
    useContext(AppContext);

  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const { handleInitialEventAttendeesLoad }: AttendeeContextType =
    useContext(AttendeeContext);
  /**/

  /*State store */
  const [selectedAttendee, changeSelected] = useState("");
  const [audienceSearchFieldValue, changeSearchFieldValue] = useState("");
  const [isSearching, toggleIsSearching] = useState(false);

  const [attendeeSearchResults, setAttendeeSearchResults] = useState([]);

  const [page, setPage] = useState(0);
  /**/

  const firstRender = useRef(true);
  const pageRef = useRef(0);
  const dirtyTimeRef = useRef(0);
  const pageCheckTimeoutRef = useRef(null);
  const attendeesInEventRef = useRef([]);
  const isSearchingRef = useRef(false);
  const attendeeSearchResultsRef = useRef([]);

  const classes = AttendeeListStyles();

  /* Attendee select menu logic */
  const handleAttendeeSelect = (attendeeId: string) => {
    //Change value in context
    setSelectedAttendeeIDContext(attendeeId);

    changeSelected(attendeeId);
  };

  useEffect(() => {
    //Check if mobile to add CSS hack for vh unit
    let attendeeListHolder = document.getElementById("attendeeListHolder");
    if (attendeeListHolder) {
      if (isMobile) {
        if (props.roomSwitchVariant) {
          if (isPresenter) {
            attendeeListHolder.classList.add(
              `${styles.attendeeListRoomSwitchMobile}`
            );
          } else {
            attendeeListHolder.classList.add(
              `${styles.attendeeListRoomSwitchNonPresenterMobile}`
            );
          }
        } else {
          if (isPresenter) {
            attendeeListHolder.classList.add(`${styles.attendeeListMobile}`);
          } else {
            attendeeListHolder.classList.add(
              `${styles.attendeeListNonPresenterMobile}`
            );
          }
        }
      }

      if (ServerHelper.useMobileApp) {
        attendeeListHolder.classList.add(`${styles.attendeeListMobileApp}`);
      }
    }

    //Populate initial attendee list on mount
    handleInitialEventAttendeesLoad();

    //Listen for disconnects to see if we need to programmatically change page #
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
      handlePlayerDisconnected
    );

    return function cleanup() {
      changeSelected("");
      setSelectedAttendeeIDContext(null);

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(
        handlePlayerDisconnected
      );

      if (pageCheckTimeoutRef.current) {
        clearTimeout(pageCheckTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    } else {
      if (selectedAttendee == null) {
        return;
      } else {
        let div = document.getElementById(selectedAttendee);
        let containerDiv = document.getElementById("attendeeListHolder");
        
        //Execute different logic for desktop vs. mobile app
        if (div !== null) {
          if (ServerHelper.useMobileApp) {
             SHOWBOAT.Logger.Log("Offset top:", div.offsetTop);
             SHOWBOAT.Logger.Log("Scroll top:", containerDiv.scrollTop);
             SHOWBOAT.Logger.Log("Client height:", containerDiv.clientHeight);
            
            if (div.offsetTop + 50 - containerDiv.scrollTop > containerDiv.clientHeight) {
              containerDiv.scrollBy(0,100);
            } else if (
              div.offsetTop + 70 - containerDiv.scrollTop >
              containerDiv.clientHeight
            ) {
              containerDiv.scrollBy(0, 70);
            } else if (
              div.offsetTop + 100 - containerDiv.scrollTop >
              containerDiv.clientHeight
            ) {
              containerDiv.scrollBy(0, 30);
            }
          } else {
            if (
              div.offsetTop + 80 - containerDiv.scrollTop >
              containerDiv.clientHeight
            ) {
              containerDiv.scrollBy(0, 110);
            } else if (
              div.offsetTop + 50 - containerDiv.scrollTop >
              containerDiv.clientHeight
            ) {
              containerDiv.scrollBy(0, 125);
            } else if (
              div.offsetTop + 20 - containerDiv.scrollTop >
              containerDiv.clientHeight
            ) {
              containerDiv.scrollBy(0, 125);
            }
          }
        }
      }
    }

    return function cleanup() {};
  }, [selectedAttendee]);

  useEffect(() => {
    attendeesInEventRef.current = attendeesInEvent;

    //Safeguard to return page to 0 if no attendees
    if (!attendeesInEvent.length) {
      setPage(0);
      pageRef.current = 0;
    }
  }, [attendeesInEvent]);

  /**/

  //Player disconnect handler
  const handlePlayerDisconnected = () => {
    if (dirtyTimeRef.current === 0) {
      dirtyTimeRef.current = Date.now();

      pageCheckTimeoutRef.current = setTimeout(
        handleCheckPageNumberOnDisconnect,
        1000
      );
    } else {
      let elapsed = Date.now() - dirtyTimeRef.current;

      if (elapsed < 2500) {
        clearTimeout(pageCheckTimeoutRef.current);

        //Reset redraw timeout for .5 seconds from now
        pageCheckTimeoutRef.current = setTimeout(
          handleCheckPageNumberOnDisconnect,
          1000
        );
      } else {
        //Do nothing
      }
    }
  };

  const handleCheckPageNumberOnDisconnect = () => {
    //If there are no attendees left, set to page 1
    if (!attendeesInEventRef.current.length) {
      setPage(0);
      pageRef.current = 0;
      dirtyTimeRef.current = 0;
      return;
    }

    //Check if page would still exist
    let currentPageNumber = pageRef.current;

    if (
      attendeesInEventRef.current.slice(
        currentPageNumber * 20,
        currentPageNumber * 20 + 20
      ).length === 0
    ) {
      //Page would not exist, so we need to reduce page number.
      let lastAvailablePage =
        Math.ceil(attendeesInEventRef.current.length / 20) - 1;
      setPage(lastAvailablePage);
      pageRef.current = lastAvailablePage;
    }

    dirtyTimeRef.current = 0;
  };

  /*Search Field Logic */
  const handleAttendeeSearchChange = (e) => {
    //Ensure page gets set to 0
    setPage(0);

    toggleIsSearching(e.target.value === "" ? false : true);
    isSearchingRef.current = e.target.value === "" ? false : true;

    //Create array to determine length of search results
    let searchResultArray = attendeesInEvent.filter(
      (attendee) =>
        `${attendee.firstName} ${attendee.lastName}`
          .toLocaleLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        `${attendee.company}`
          .toLocaleLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    attendeeSearchResultsRef.current = searchResultArray;
    setAttendeeSearchResults(searchResultArray);

    changeSearchFieldValue(e.target.value);
  };

  //clear search field value if close button is clicked OR 'in-room'/'nearby' tabs are clicked
  const handleClearSearchField = () => {
    //Set page back to 0
    setPage(0);

    isSearchingRef.current = false;
    toggleIsSearching(false);
    changeSearchFieldValue("");
  };

  //Page change
  const handlePageChange = (e, page: number) => {
    pageRef.current = page;
    setPage(page);
  };

  //change search icon to x if is searching
  let searchIcon;

  if (isSearching) {
    searchIcon = (
      <CancelIcon
        classes={{
          root: props.mobile
            ? `${classes.cancelIcon} ${classes.cancelIconMobile}`
            : classes.cancelIcon,
        }}
        onClick={handleClearSearchField}
      />
    );
  } else {
    searchIcon = (
      <SearchIcon className={props.mobile ? classes.searchIconMobile : null} />
    );
  }
  /**/

  //Assemble pagination display text
  let paginationDisplayText;

  let attendeesOnPageLowerBoundary;
  let attendeesOnPageUpperBoundary;

  let attendeesOnPageLowerBoundarySearch;
  let attendeesOnPageUpperBoundarySearch;

  attendeesOnPageLowerBoundary = attendeesInEvent.length
    ? page === 0
      ? 1
      : 20 * page
    : 0;

  attendeesOnPageUpperBoundary = attendeesInEvent.length
    ? attendeesInEvent.length > attendeesOnPageLowerBoundary + 20
      ? 20 * (page + 1)
      : attendeesInEvent.length
    : 0;

  attendeesOnPageLowerBoundarySearch = attendeeSearchResults.length
    ? page === 0
      ? 1
      : 20 * page
    : 0;

  attendeesOnPageUpperBoundarySearch = attendeeSearchResults.length
    ? attendeeSearchResults.length > attendeesOnPageLowerBoundarySearch + 20
      ? 20 * (page + 1)
      : attendeeSearchResults.length
    : 0;

  if (isSearching) {
    paginationDisplayText = (
      <Typography
        className={
          props.mobile
            ? `${classes.paginationHeader} ${classes.paginationHeaderMobile}`
            : classes.paginationHeader
        }
      >
        Showing {attendeesOnPageLowerBoundarySearch}-
        {attendeesOnPageUpperBoundarySearch} of {attendeeSearchResults.length}
      </Typography>
    );
  } else {
    paginationDisplayText = (
      <Typography
        className={
          props.mobile
            ? `${classes.paginationHeader} ${classes.paginationHeaderMobile}`
            : classes.paginationHeader
        }
      >
        Showing {attendeesOnPageLowerBoundary}-{attendeesOnPageUpperBoundary} of{" "}
        {attendeesInEvent.length + 1}
      </Typography>
    );
  }

  let inputLabelProps = useMemo(
    () =>
      isSearching
        ? {
            classes: {
              focused: classes.inputLabelFocused,
              root: props.mobile
                ? `${classes.inputLabel} ${classes.inputLabelMobile}`
                : classes.inputLabel,
            },
            shrink: false,
            style: {
              display: "none",
            },
          }
        : {
            classes: {
              focused: classes.inputLabelFocused,
              root: props.mobile
                ? `${classes.inputLabel} ${classes.inputLabelMobile}`
                : classes.inputLabel,
            },
            shrink: false,
          },
    [isSearching, classes.inputLabel, classes.inputLabelFocused]
  );

  return (
    <React.Fragment>
      {!props.roomSwitchVariant && !props.mobile && (
        <Paper className={classes.attendeesHeader}>
          <PeopleIcon className={classes.peopleIcon}></PeopleIcon>

          <Typography variant="h1" className={classes.directoryHeader}>
            Directory
          </Typography>

          <IconButton
            className={classes.closeSidebarButton}
            onClick={() => setCurrentSidebarMode("")}
          >
            <ArrowIcon />
          </IconButton>
        </Paper>
      )}

      <Paper
        className={
          props.mobile
            ? `${classes.searchBarHolder} ${classes.searchBarHolderMobile}`
            : classes.searchBarHolder
        }
      >
        <TextField
          fullWidth
          onChange={handleAttendeeSearchChange}
          value={audienceSearchFieldValue}
          classes={{ root: classes.searchBar }}
          id="outlined-search"
          label="Search Directory"
          autoComplete="off"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{searchIcon}</InputAdornment>
            ),
            classes: {
              notchedOutline: classes.noBorder,
              input: props.mobile
                ? `${classes.inputText} ${classes.inputTextMobile}`
                : classes.inputText,
            },
          }}
          InputLabelProps={inputLabelProps}
        />
      </Paper>

      <div
        className={
          props.mobile
            ? `${styles.paginationHolder} ${styles.paginationHolderMobile}`
            : styles.paginationHolder
        }
      >
        <div className={styles.paginationHeaderHolder}>
          {paginationDisplayText}
        </div>

        <TablePagination
          component="div"
          count={
            isSearching ? attendeeSearchResults.length : attendeesInEvent.length
          }
          rowsPerPage={20}
          rowsPerPageOptions={[20]}
          onChangePage={handlePageChange}
          page={page}
          classes={{
            caption: classes.paginationCaption,
            selectRoot: classes.paginationSelect,
            actions: props.mobile
              ? `${classes.paginationActions} ${classes.paginationActionsMobile}`
              : classes.paginationActions,
            toolbar: classes.paginationToolbar,
          }}
        />
      </div>

      <div
        id="attendeeListHolder"
        className={
          isPresenter
            ? props.roomSwitchVariant
              ? `${styles.attendeeList} ${styles.attendeeListRoomSwitch}`
              : styles.attendeeList
            : props.roomSwitchVariant
            ? `${styles.attendeeList} ${styles.attendeeListRoomSwitchNonPresenter}`
            : `${styles.attendeeList} ${styles.attendeeListNonPresenter}`
        }
        style={{
          top: props.mobile ? "56px" : "11px",
          padding: props.mobile ? "4px" : "0px",
          boxSizing: props.mobile ? "border-box" : "content-box",
        }}
      >
        {/*  Change pagination setup based on if searching or not */}
        {isSearching
          ? attendeeSearchResults
              .slice(page * 20, page * 20 + 20)
              .map((attendee, i) => {
                return (
                  <AttendeeItem
                    key={attendee.userID}
                    currentlySelected={selectedAttendee}
                    handleSelect={handleAttendeeSelect}
                    attendeeCompany={attendee.company}
                    attendeeUserID={attendee.userID}
                    attendeeFace={attendee.face}
                    attendeeRoomID={attendee.roomID}
                    attendeeColor={attendee.color}
                    attendeeLastName={attendee.lastName}
                    attendeeFirstName={attendee.firstName}
                    attendeeAvailableForTeleport={
                      attendee.isAvailableForTeleport
                    }
                    attendeeMobile={attendee.isMobileUser}
                    roomSwitchVariant={props.roomSwitchVariant}
                    mobile={props.mobile}
                  />
                );
              })
          : attendeesInEvent
              .slice(page * 20, page * 20 + 20)
              .map((attendee, i) => {
                return (
                  <AttendeeItem
                    key={attendee.userID}
                    currentlySelected={selectedAttendee}
                    handleSelect={handleAttendeeSelect}
                    attendeeCompany={attendee.company}
                    attendeeUserID={attendee.userID}
                    attendeeFace={attendee.face}
                    attendeeRoomID={attendee.roomID}
                    attendeeColor={attendee.color}
                    attendeeAvailableForTeleport={
                      attendee.isAvailableForTeleport
                    }
                    attendeeMobile={attendee.isMobileUser}
                    attendeeLastName={attendee.lastName}
                    attendeeFirstName={attendee.firstName}
                    roomSwitchVariant={props.roomSwitchVariant}
                    mobile={props.mobile}
                  />
                );
              })}
      </div>
    </React.Fragment>
  );
}
