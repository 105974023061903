import {
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LocationIcon from "@material-ui/icons/LocationOn";
import Close from "@material-ui/icons/Close";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

import { RoomNamePopupStyles } from "./RoomNamePopupStyles";
import styles from "./RoomNamePopup.module.css";
import { UIHelper } from "../../../../Utilities/UIHelper";

interface Props {
  handleGetRoomList: () => void;
}

export default function RoomNamePopup(props: Props) {
  const { showRoomNameMenu, setShowRoomNameMenu }: AppContextType =
    useContext(AppContext);

  const { currentMode, toggleIsOnStage, toggleIsBackstage }: PresenterContextType =
    useContext(PresenterContext);

  const [roomName, setRoomName] = useState("");
  const [error, setError] = useState(false);

  const handleCloseRoomNamePopup = () => {
    setShowRoomNameMenu(false);
  };

  const handleRoomNameChange = (e) => {
    setRoomName(e.target.value);
  };

  const handleCancelClick = () => {
    setRoomName("");
    setShowRoomNameMenu(false);
  };

  const handleOpenClick = async () => {
    //Call method to open new room, and then call to enter new room
    SHOWBOAT.UIEventManager.RoomChangeInProgress = true;
    try {
      //TODO: New room name - UI text field?
      let openNewRoom = await SHOWBOAT.WebSocketController.CreateNamedRoom({
        newRoomName: roomName,
      });

      //Check for error in creating named room
      if (openNewRoom.errorData.error) {
        //Check if duplicate room error
        if (openNewRoom.isNameTaken) {
          throw new Error("duplicate")
        }
        
        throw new Error("Error creating room");
      }

      //Enter the named room
      //TODO: Get target room ID
      let enterRoom = await SHOWBOAT.WebSocketController.MoveToTargetRoom({
        targetRoomID: openNewRoom.roomID,
        isTeleportationRoomChange: false,
      });

      if (!enterRoom.errorData.error) {
        //Get room list again after room open
        props.handleGetRoomList();

        if (currentMode !== StringVariableHelper.ShowboatModeType.presenter) {
          toggleIsOnStage(false);
        }

        //Leave from backstage
        toggleIsBackstage(false);

        UIHelper.HandleSuccessfulRoomChange(enterRoom.newRoomID)
      } else {
        //Failed room change - show error and close popup
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error creating room");
        SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
      }

      setShowRoomNameMenu(false);
    } catch (e) {
      SHOWBOAT.Logger.Error("RoomOptionsSideBar.OpenNewRoomAndEnter", e);
      SHOWBOAT.UIEventManager.RoomChangeInProgress = false;

      if (e.message === "duplicate") {
        SHOWBOAT.UIEventManager.OnUIError.Raise("Duplicate room name");
      } else {
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error opening room");
      }

      setShowRoomNameMenu(false);
    }
  };

  const classes = RoomNamePopupStyles();

  if (showRoomNameMenu) {
    return (
      <div className={styles.roomNamePopupWrapper}>
        <Paper className={classes.roomNameHeaderHolder}>
          <LocationIcon className={classes.locationIcon}></LocationIcon>

          <Typography variant="h1" className={classes.roomNameHeader}>
            Create Named Room
          </Typography>

          <IconButton
            onClick={handleCloseRoomNamePopup}
            className={classes.closeIconButton}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </Paper>

        <TextField
          value={roomName}
          onChange={handleRoomNameChange}
          label="Room Name"
          fullWidth
          variant="outlined"
          className={classes.roomNameInput}
          error={error}
        />

        <div className={styles.buttonHolderBottom}>
          <Button
            className={`${classes.button} ${classes.buttonCancel}`}
            onClick={handleCancelClick}
            variant="contained"
            classes={{
              label: classes.buttonLabel,
            }}
          >
            CANCEL
          </Button>

          <Button
            className={classes.button}
            onClick={handleOpenClick}
            variant="contained"
            classes={{
              label: classes.buttonLabel,
            }}
          >
            OPEN
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
