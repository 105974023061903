import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileChatNewMessageStyles = makeStyles((theme: any) =>
  createStyles({
    newMessageHeader: {
      color: "white",
      fontSize: 14,
    },
    chatTextField: {
      width: "100%",
      height: 130,
      top: 15,
      border: "none",
      outline: "none",
      backgroundColor: "#373737",
      borderRadius: 4,
      fontSize: 14,
    },
    noBorder: {
      border: "none",
    },
    chatInput: {
      fontSize: 14,
      paddingTop: 14,
      "& textarea": {
        height: "100px !important",
      },
    },
    chatInputAdornedEnd: {
      paddingRight: 5,
    },
    newMessageHelperText: {
      fontSize: 14,
      color: "white",
      position: "relative",
      top: 18,
      marginBottom: 8,
    },
    chatButton: {
      position: "relative",
      padding: "14px 22px"
    },
    chatButtonDismiss: {
      marginLeft: 13,
    },
    characterCount: {
      fontSize: 12,
      color: "white",
      position: "absolute",
      right: 29,
      top: 223,
    },
  })
);

export { MobileChatNewMessageStyles }