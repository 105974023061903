import React from "react";

type Props = {};

export default function MobileIcon({}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.006"
      height="12.581"
      viewBox="0 0 8.006 12.581"
      style={{
        position: "relative",
        top: -2,
        left: 37
      }}
    >
      <path
        id="smartphone_FILL0_wght400_GRAD0_opsz48"
        d="M10.858,14.581A.879.879,0,0,1,10,13.723V2.858a.823.823,0,0,1,.257-.6.823.823,0,0,1,.6-.257h6.29a.823.823,0,0,1,.6.257.823.823,0,0,1,.257.6V13.723a.879.879,0,0,1-.858.858Zm0-2.144h6.29V4.144h-6.29Zm0,.858v.429h6.29v-.429Zm0-10.007h6.29V2.858h-6.29Zm0-.429v0Zm0,10.865v0Z"
        transform="translate(-10 -2)"
        fill="#fff"
      />
    </svg>
  );
}
