import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";
import {
  OneOnOneChatContext,
  OneOnOneChatContextType,
} from "../../../context/OneOnOneChatContext";
import PrivateMessage from "../../components/Popups/PrivateMessagesWrapper/PrivateMessage/PrivateMessage";

import styles from "./MobileMessages.module.css";
import { MobileMessagesStyles } from "./MobileMessagesStyles";

type Props = {};

export default function MobileMessages({}: Props) {
  const {
    setNameToMessage,
    setUserIDToMessage,
    setRespondMessageID,
  }: OneOnOneChatContextType = useContext(OneOnOneChatContext);

  const {
    mobilePrivateMessages,
    setMobilePrivateMessages,
    setMobileShowSendPrivateMessage,
    decrementMessageCount,
  }: MobileContextType = useContext(MobileContext);

  const [privateChatMessageLength, setPrivateChatMessageLength] = useState(0);

  const containerRef = useRef(null);

  const scrollTimeout = useRef(null);

  useEffect(() => {
    let container = document.getElementById("mobilePrivateMessagesHolder");

    if (container) {
      containerRef.current = container;
    }

    //On mount, scroll to the bottom
    scrollToBottom();

    return function cleanup() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    }
  }, []);

  useEffect(() => {
    //When a messages in context change, scroll to bottom only if the list is longer (new item)
    if (mobilePrivateMessages.length > privateChatMessageLength) {
      scrollToBottom();
    }

    //Set value in state and context
    setPrivateChatMessageLength(mobilePrivateMessages.length);
  }, [mobilePrivateMessages]);

  const scrollToBottom = () => {
    scrollTimeout.current = setTimeout(function() {
      if (containerRef.current) {
        containerRef.current.scrollTo(
          0,
          containerRef.current.scrollHeight + 100
        );
      }
    }, 10)
    
  };

  const handleMessageDismiss = (messageID) => {
    SHOWBOAT.Logger.Log(
      "Dismissing private message:",
      messageID,
      mobilePrivateMessages
    );
    setMobilePrivateMessages(
      mobilePrivateMessages.filter((msg) => (msg as any).id !== messageID)
    );
    decrementMessageCount();
  };

  const handleMessageRespond = (
    respondToName: string,
    respondToUserID: string,
    respondToMessageID
  ) => {
    setNameToMessage(respondToName);
    setUserIDToMessage(respondToUserID);
    setMobileShowSendPrivateMessage(true);
    setRespondMessageID(respondToMessageID);

    let textField = document.getElementById("mobileNewPrivateMessageTextField");

    if (textField) {
      textField.style.visibility = "visible";
      textField.focus();
      textField.click();
    }
  };

  const classes = MobileMessagesStyles();

  return (
    <div
      className={
        mobilePrivateMessages.length === 0
          ? `${styles.mobileMessagesHolder} ${styles.mobileMessagesHolderNoMessages}`
          : styles.mobileMessagesHolder
      }
      id="mobilePrivateMessagesHolder"
    >
      {mobilePrivateMessages.length === 0 && (
        <Typography className={classes.noMessagesText}>0 messages</Typography>
      )}

      {mobilePrivateMessages.map((msg, i) => {
        return (
          <PrivateMessage
            key={msg.id}
            message={msg.message}
            userID={msg.userID}
            id={msg.id}
            handleMessageDismiss={handleMessageDismiss}
            handleMessageRespond={handleMessageRespond}
            mobile={true}
          />
        );
      })}
    </div>
  );
}
