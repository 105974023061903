import { makeStyles, createStyles } from "@material-ui/core/styles";

const MobilePresenterFeedStyles = makeStyles((theme: any) =>
  createStyles({
    mobilePresenterName: {
      fontSize: 14,
      bottom: 0,
      left: 0,
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      wordBreak: "break-word"
    },
    playButton: {
      zIndex: 9999
    }
  })
);

export { MobilePresenterFeedStyles };