import { Button, IconButton } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import BoxIcon from "@material-ui/icons/Crop54";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";
import { UIHelper } from "../../../../Utilities/UIHelper";

import styles from "./MobileJumbotronViewFullscreenOverlay.module.css";
import { MobileJumbotronViewFullscreenOverlayStyles } from "./MobileJumbotronViewFullscreenOverlayStyles";

type Props = {};

export default function MobileJumbotronViewFullscreenOverlay({}: Props) {
  const {
    jumbotronContentType,
    setJumbotronContentType,
    jumbotronElement,
    setJumbotronElement,
    jumbotronElementSrc,
    setJumbotronElementSrc,
    mobileJumbotronFullscreen,
    setMobileJumbotronFullscreen,
  }: MobileContextType = useContext(MobileContext);

  const [showPlayButton, setShowPlayButton] = useState(false);

  useEffect(() => {}, []);

  const handleImageVideoOnLoad = (e) => {
    let naturalWidth = e.currentTarget.naturalWidth;
    let naturalHeight = e.currentTarget.naturalHeight;

    const scale =
      naturalWidth > naturalHeight ? naturalHeight / naturalWidth : 1;
    const yshift = -100 * scale;

    const transform = "transform:rotate(90deg)";
    const translateY = `translateY(${yshift})`;

    const scaleProp = `scale(${scale})`;
    const transformOrigin = "transform-origin: top left";

    let img = document.getElementById("mobileJumbotronImage");

    if (img) {
      img.style.transform = `${transform} ${translateY}`;
      img.style.scale = scaleProp;
      img.style.transformOrigin = transformOrigin;
    } else {
      let vid = document.getElementById("mobileJumbotronFullscreenVideo");
    }
  };

  useEffect(() => {
    //Listen for jumbotron changes
    SHOWBOAT.JumbotronHelper.OnDisplayJumbotronContent.Add(
      handleJumbotronDisplayChange
    );
  }, []);

  const handleJumbotronDisplayChange = (
    type: SHOWBOAT.JumbotronContentType,
    content: string | HTMLVideoElement
  ) => {
    setJumbotronContentType(type);

    //Check if type is image or video
    if (type === SHOWBOAT.JumbotronContentType.Image) {
      //Set image src in state
      setJumbotronElementSrc(content as string);

      //Create dummy image element to force state change
      let newImg: HTMLImageElement = document.createElement("img");
      (newImg as HTMLImageElement).crossOrigin = "anonymous";
      (newImg as HTMLImageElement).src = content as string;

      setJumbotronElement(newImg);
    } else if (
      type === SHOWBOAT.JumbotronContentType.Video ||
      type === SHOWBOAT.JumbotronContentType.Canvas
    ) {
      SHOWBOAT.Logger.Log("Setting attributes");

      //Set styles for the new video
      (content as HTMLVideoElement).style.height = "100%";
      (content as HTMLVideoElement).style.width = "auto";
      (content as HTMLVideoElement).style.display = "inlineBlock";
      (content as HTMLVideoElement).style.position = "relative";
      (content as HTMLVideoElement).style.maxWidth = "100%";
      (content as HTMLVideoElement).style.objectFit = "contain";

      //Set state with the passed video element
      setJumbotronElement(content);
    }
  };

  useEffect(() => {
    clearJumbotronPreviewAndAddContent();
  }, [jumbotronElement]);

  const clearJumbotronPreviewAndAddContent = () => {
    let container = document.getElementById("mobileFullscreenVideoHolder");

    if (container) {
      //Ensure the container div is cleared of any non-images and non-buttons
      for (let i = 0; i < container.childNodes.length; i++) {
        if (
          container.childNodes[i].nodeName !== "IMG" &&
          container.childNodes[i].nodeName !== "BUTTON"
        ) {
          container.removeChild(container.childNodes[i]);
        }
      }

      if (
        jumbotronElement !== null &&
        (jumbotronContentType === SHOWBOAT.JumbotronContentType.Video ||
          jumbotronContentType === SHOWBOAT.JumbotronContentType.Canvas)
      ) {
        if (
          jumbotronContentType === SHOWBOAT.JumbotronContentType.Video &&
          jumbotronElement.play !== undefined
        ) {
          jumbotronElement.play();
        }

        //Set fullscreen attributes
        (jumbotronElement as HTMLVideoElement).id =
          "mobileJumbotronFullscreenVideo";
        (jumbotronElement as HTMLVideoElement).onload = handleImageVideoOnLoad;
        (jumbotronElement as HTMLVideoElement).setAttribute(
          "playsinline",
          "false"
        );
        (jumbotronElement as HTMLVideoElement).setAttribute(
          "webkit-playsinline",
          "false"
        );
        (jumbotronElement as HTMLVideoElement).setAttribute("controls", "true");
        (jumbotronElement as HTMLVideoElement).pause();

        container.appendChild(jumbotronElement);

        setShowPlayButton(true);
      }
    }
  };

  const handlePlayVideo = () => {
    if (jumbotronElement.play) {
      jumbotronElement.play();
    }

    setShowPlayButton(false);
  };

  const handleMobileJumbotronFullscreenClick = () => {
    let toggleState = !mobileJumbotronFullscreen;

    UIHelper.OnMobileJumbotronFullscreen.Raise(toggleState);
    setMobileJumbotronFullscreen(toggleState);
  };

  const classes = MobileJumbotronViewFullscreenOverlayStyles();

  return (
    <div
      className={styles.fullscreenOverlayHolder}
      id="mobileFullscreenOverlay"
    >
      <div
        className={styles.mobileFullscreenVideoHolder}
        id="mobileFullscreenVideoHolder"
      >
        {showPlayButton && (
          <Button className={classes.jumbotronButton} onClick={handlePlayVideo}>
            <BoxIcon className={classes.boxIcon} />
            START FEED
          </Button>
        )}

        <IconButton
          onClick={handleMobileJumbotronFullscreenClick}
          className={classes.fullscreenIconButton}
          id="mobileJumbotronFullscreenButton"
        >
          {mobileJumbotronFullscreen ? (
            <FullscreenExitIcon className={classes.fullscreenIcon} />
          ) : (
            <FullscreenIcon className={classes.fullscreenIcon} />
          )}
        </IconButton>

        {jumbotronContentType === SHOWBOAT.JumbotronContentType.Image &&
          jumbotronElementSrc && (
            <img
              src={jumbotronElementSrc}
              className={styles.twoDJumbotronImage}
              crossOrigin="anonymous"
              onLoad={handleImageVideoOnLoad}
              id="mobileJumbotronImage"
            />
          )}
      </div>
    </div>
  );
}
