import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React, {
  ReactElement,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import MinimizeIcon from "@material-ui/icons/RemoveCircle";
import SelectedDeviceIcon from "@material-ui/icons/FiberManualRecord";

import { AppContext, AppContextType } from "../../../../context/AppContext";

import styles from "./DebugPopup.module.css";
import { DebugPopupStyles } from "./DebugPopupStyles";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { AdvancedLoggingHelper } from "../../../../Utilities/AdvancedLoggingHelper";
import { cpuUsage } from "process";
import { debug } from "console";

interface Props {}

export default function DebugPopup(props: Props): ReactElement {
  const {
    selectedAttendeeIDContext,
    setSelectedAttendeeIDContext,
  }: AppContextType = useContext(AppContext);

  const {
    advancedLoggingUserIDs,
    setAdvancedLoggingUserIDs,
  }: PresenterContextType = useContext(PresenterContext);

  const [currentCameraName, setCurrentCameraName] = useState("");
  const [currentMicName, setCurrentMicName] = useState("");
  const [currentSpeakerName, setCurrentSpeakerName] = useState("");
  const [micDeviceList, setMicDeviceList] = useState("");
  const [cameraDeviceList, setCameraDeviceList] = useState("");
  const [speakerDeviceList, setSpeakerDeviceList] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [osName, setOsName] = useState("");
  const [graphicsDriver, setGraphicsDriver] = useState("");
  const [isCameraEnabledState, toggleIsCameraEnabledState] = useState(false);
  const [isMicEnabledState, toggleIsMicEnabledState] = useState(false);
  const [, setAvatarDataForUserID] = useState(null);
  const [loadingSpinner, toggleLoadingSpinner] = useState(true);
  
  const [advancedLogging, setAdvancedLogging] = useState(advancedLoggingUserIDs.includes(selectedAttendeeIDContext));
    
  const [minimize, toggleMinimize] = useState(false);

  const previousUserIDRef = useRef(null);
  const getAvatarDataTimeout = useRef(null);
  const displayTimeoutRef = useRef(null);

  const getAvatarDataForUserID = async (userID: string) => {
    try {
      
      let getAllDebugData: SHOWBOAT.GetFullDebugPlayerData =  await SHOWBOAT.WebSocketController.GetAllFullDebugPlayerData();

      if(getAllDebugData.errorData.error) {
        throw new Error("Error getting debug data from server. Err: " + getAllDebugData.errorData.errorMessage);
      }

      let debugDataWithUserIDs: SHOWBOAT.FullDebugUserData[] = [];
      for(let i = 0; i < getAllDebugData.userDatas.length; i++) {
        let currData: SHOWBOAT.FullDebugUserData = getAllDebugData.userDatas[i];
        if(currData.userID == userID) {
          debugDataWithUserIDs.push(currData);
        }
      }

      if(debugDataWithUserIDs.length != 1) {
        throw new Error("Error getting correct number of debug datas from server.");
      }

      //Set initial state values using returned userID
      setInitialDeviceAndSystemInformation(debugDataWithUserIDs[0]);

      toggleLoadingSpinner(false);
    } catch (err) {
      toggleLoadingSpinner(false);
      SHOWBOAT.UIEventManager.OnUIError.Raise("Attendee not found.");
    }
  };

  useEffect(() => {
    //On mount, add transition class after timeout
    displayTimeoutRef.current = setTimeout(function () {
      document
        .getElementById("debugPopup")
        .classList.add(classes.debugPopupTransition);
    }, 200);

    previousUserIDRef.current = selectedAttendeeIDContext;

    //Add listener for selected device change, check if matches userID that this popup is for
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      selectedAttendeeIDContext,
      SHOWBOAT.ChangeReason.DeviceDebug,
      handleSelectedDeviceChange
    );

    //Add listeners for enabling/disabling camera and mic
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      selectedAttendeeIDContext,
      SHOWBOAT.ChangeReason.Mic,
      handleEnableMicChange
    );

    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      selectedAttendeeIDContext,
      SHOWBOAT.ChangeReason.Camera,
      handleEnableCameraChange
    );

    //Add listener for attendee leaving the event
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
      handleRemotePlayerDisconnected
    );

    //TODO: TODO: Query for avatarData list in event
    getAvatarDataForUserID(selectedAttendeeIDContext);

    //Set up timeout to get avatar data every 4 seconds
    getAvatarDataTimeout.current = setInterval(async function () {
      await getAvatarDataForUserID(selectedAttendeeIDContext);
    }, 4000);

    return function cleanup() {
      if (getAvatarDataTimeout.current) {
        clearInterval(getAvatarDataTimeout.current);
      }

      if (displayTimeoutRef.current) {
        clearTimeout(displayTimeoutRef.current);
      }

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.DeviceDebug,
        handleSelectedDeviceChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Mic,
        handleEnableMicChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Camera,
        handleEnableCameraChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(
        handleRemotePlayerDisconnected
      );
    };
  }, []);

  //Monitor for changes to selectedUserIDContext variable
  useEffect(() => {
    //Make sure ID is not null
    if (selectedAttendeeIDContext !== null) {
      //Access previous userID to remove listeners
      let previousUserID: string = previousUserIDRef.current;

      //Remove old listeners
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        previousUserID,
        SHOWBOAT.ChangeReason.DeviceDebug,
        handleSelectedDeviceChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        previousUserID,
        SHOWBOAT.ChangeReason.Mic,
        handleEnableMicChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        previousUserID,
        SHOWBOAT.ChangeReason.Camera,
        handleEnableCameraChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(
        handleRemotePlayerDisconnected
      );

      //Add new listeners
      previousUserIDRef.current = selectedAttendeeIDContext;

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.DeviceDebug,
        handleSelectedDeviceChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Mic,
        handleEnableMicChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Camera,
        handleEnableCameraChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(
        handleRemotePlayerDisconnected
      );

      if (advancedLoggingUserIDs.includes(selectedAttendeeIDContext)) {
        setAdvancedLogging(true);
      } else {
        setAdvancedLogging(false);
      }

      //Re-show loading spinner
      toggleLoadingSpinner(true);

      const handleGetNewAvatarData = async () => {
        try {
          let getAllDebugData: SHOWBOAT.GetFullDebugPlayerData =  await SHOWBOAT.WebSocketController.GetAllFullDebugPlayerData();

          if(getAllDebugData.errorData.error) {
            throw new Error("Error getting debug data from server. Err: " + getAllDebugData.errorData.errorMessage);
          }

          let debugDataWithUserIDs: SHOWBOAT.FullDebugUserData[] = [];
          for(let i = 0; i < getAllDebugData.userDatas.length; i++) {
            let currData: SHOWBOAT.FullDebugUserData = getAllDebugData.userDatas[i];
            if(currData.userID == selectedAttendeeIDContext) {
              debugDataWithUserIDs.push(currData);
            }
          }

          if(debugDataWithUserIDs.length != 1) {
            throw new Error("Error getting correct number of debug datas from server.");
          }

          if (debugDataWithUserIDs[0] !== undefined) {
            toggleLoadingSpinner(false);

            //Set state values with new avatar object
            setInitialDeviceAndSystemInformation(debugDataWithUserIDs[0]);
          } else {
            toggleLoadingSpinner(false);
          }
        } catch {
          //TODO: Error handling
          toggleLoadingSpinner(false);
        }
      };
      handleGetNewAvatarData();

      //Clear previous ID timeout and add timeout to get every 4 seconds for new userID
      clearInterval(getAvatarDataTimeout.current);

      getAvatarDataTimeout.current = setInterval(async function () {
        await getAvatarDataForUserID(selectedAttendeeIDContext);
      }, 4000);
    }

    return function cleanup() {
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.DeviceDebug,
        handleSelectedDeviceChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Mic,
        handleEnableMicChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        selectedAttendeeIDContext,
        SHOWBOAT.ChangeReason.Camera,
        handleEnableCameraChange
      );
      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(
        handleRemotePlayerDisconnected
      );
    };
  }, [selectedAttendeeIDContext]);

  const setInitialDeviceAndSystemInformation = (userData: SHOWBOAT.FullDebugUserData) => {
    //Set initial state values for all device information
    setCurrentCameraName(userData.currentCameraName);

    setCurrentMicName(userData.currentMicName);

    setCurrentSpeakerName(userData.currentSpeakerName);

    let micDeviceListString: string = "";
    for(let i = 0; i < userData.micDeviceList.length; i++) {
      micDeviceListString += userData.micDeviceList[i];
    }

    let camDeviceListString: string = "";
    for(let i = 0; i < userData.cameraDeviceList.length; i++) {
      camDeviceListString += userData.cameraDeviceList[i];
    }

    let speakerDeviceListString: string = "";
    for(let i = 0; i < userData.speakerDeviceList.length; i++) {
      speakerDeviceListString += userData.speakerDeviceList[i];
    }

    setMicDeviceList(micDeviceListString);
    setCameraDeviceList(camDeviceListString);
    setSpeakerDeviceList(speakerDeviceListString);

    setBrowserName(userData.browser);
    setOsName(userData.operatingSystem);
    setGraphicsDriver(userData.graphicsDriver);

    toggleIsCameraEnabledState(userData.cameraEnabled);
    toggleIsMicEnabledState(userData.micEnabled);
  };

  const handleSelectedDeviceChange = (avatarData: SHOWBOAT.AvatarData) => {
    if (avatarData.userID !== selectedAttendeeIDContext) return;

    //Change selected devices using passed avatarData object
    setCurrentCameraName(avatarData.currentCameraName);
    setCurrentMicName(avatarData.currentMicName);
    setCurrentSpeakerName(avatarData.currentSpeakerName);
  };

  //Listen for disconnects
  const handleRemotePlayerDisconnected = (userID: string) => {
    if (userID === selectedAttendeeIDContext) {
      //Null out attendeeID in context, as the person we are viewing has left
      setSelectedAttendeeIDContext(null);
    }
  };

  //Enabling/disabling camera and mic
  const handleEnableMicChange = (avatarData: SHOWBOAT.AvatarData) => {
    toggleIsMicEnabledState(avatarData.micEnabled);
  };

  const handleEnableCameraChange = (avatarData: SHOWBOAT.AvatarData) => {
    toggleIsCameraEnabledState(avatarData.cameraEnabled);
  };

  //Minimize debug popup
  const handleMinimizeDebugPopup = () => {
    toggleMinimize(!minimize);
  };

  //Advanced logging
  const handleToggleAdvancedLogging = () => {
    let userIDListClone = advancedLoggingUserIDs;

    //Add this user's ID to the userID array to send with the socket message
    let userIDArray = [];
    userIDArray.push(selectedAttendeeIDContext);

    if (userIDListClone.includes(selectedAttendeeIDContext)) {
      setAdvancedLogging(false);

      //Toggle is on, so toggle it off
      userIDListClone = userIDListClone.filter(
        (userID) => userID !== selectedAttendeeIDContext
      );

      setAdvancedLoggingUserIDs(userIDListClone);
      
      //Stop logging in helper class
      AdvancedLoggingHelper.StopLogging(selectedAttendeeIDContext);
    } else {
      //Toggle is off, so add userID to the list and start advanced logging
      userIDListClone.push(selectedAttendeeIDContext);

      setAdvancedLogging(true);

      setAdvancedLoggingUserIDs(userIDListClone);

      AdvancedLoggingHelper.StartLogging(selectedAttendeeIDContext);
    }
  };

  const classes = DebugPopupStyles();

  return (
    <Paper
      className={
        minimize
          ? `${classes.debugPopup} ${classes.debugPopupMinimize}`
          : classes.debugPopup
      }
      id="debugPopup"
    >
      <Paper className={classes.debugPopupHeaderHolder}>
        <Typography variant="h2" className={classes.debugPopupHeader}>
          TECH SUPPORT VIEW
        </Typography>

        <IconButton
          onClick={handleMinimizeDebugPopup}
          className={classes.minimizeButton}
        >
          <MinimizeIcon />
        </IconButton>
      </Paper>

      <FormControlLabel
        control={
          <Switch
            checked={advancedLogging}
            onChange={handleToggleAdvancedLogging}
            name="Advanced Logging Toggle"
            color="primary"
            classes={{
              root: classes.advancedLoggingSwitch,
            }}
          />
        }
        label="ADVANCED LOGGING"
        labelPlacement="start"
        classes={{
          root: classes.advancedLoggingToggle,
          label: classes.advancedLoggingLabel,
        }}
      />

      {loadingSpinner ? (
        <CircularProgress className={classes.loadingSpinner} />
      ) : (
        <React.Fragment>
          <div className={styles.videoHolder}>
            <Typography variant="h2" className={classes.deviceHeader}>
              VIDEO
            </Typography>

            {cameraDeviceList !== undefined &&
              cameraDeviceList !== "" &&
              cameraDeviceList.split("|").map((device) => {
                if (device === currentCameraName) {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={`${classes.deviceName} ${classes.deviceNameSelected}`}
                    >
                      {device}

                      <SelectedDeviceIcon
                        className={
                          isCameraEnabledState
                            ? classes.selectedDeviceIcon
                            : `${classes.selectedDeviceIcon} ${classes.selectedDeviceIconDisabled}`
                        }
                      ></SelectedDeviceIcon>
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={classes.deviceName}
                    >
                      {device}
                    </Typography>
                  );
                }
              })}
          </div>

          <div className={styles.audioHolder}>
            <Typography variant="h2" className={classes.deviceHeader}>
              AUDIO
            </Typography>

            {micDeviceList !== undefined &&
              micDeviceList !== "" &&
              micDeviceList.split("|").map((device) => {
                if (device === currentMicName) {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={`${classes.deviceName} ${classes.deviceNameSelected}`}
                    >
                      {device}

                      <SelectedDeviceIcon
                        className={
                          isMicEnabledState
                            ? classes.selectedDeviceIcon
                            : `${classes.selectedDeviceIcon} ${classes.selectedDeviceIconDisabled}`
                        }
                      ></SelectedDeviceIcon>
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={classes.deviceName}
                    >
                      {device}
                    </Typography>
                  );
                }
              })}
          </div>

          <div className={styles.speakersHolder}>
            <Typography variant="h2" className={classes.deviceHeader}>
              SPEAKERS
            </Typography>

            {speakerDeviceList !== undefined &&
              speakerDeviceList !== "" &&
              speakerDeviceList.split("|").map((device) => {
                if (device === currentSpeakerName) {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={`${classes.deviceName} ${classes.deviceNameSelected}`}
                    >
                      {device}

                      <SelectedDeviceIcon
                        className={classes.selectedDeviceIcon}
                      ></SelectedDeviceIcon>
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      key={device}
                      variant="body1"
                      className={classes.deviceName}
                    >
                      {device}
                    </Typography>
                  );
                }
              })}
          </div>

          <div className={styles.systemInfoHolder}>
            <Typography variant="h2" className={classes.deviceHeader}>
              SYSTEM INFORMATION
            </Typography>

            <Typography
              variant="body1"
              className={classes.deviceName}
            >
              Browser: {browserName}
            </Typography>

            <Typography
              variant="body1"
              className={classes.deviceName}
            >
              Operating System: {osName}
            </Typography>

            <Typography
              variant="body1"
              className={classes.deviceName}
            >
              Graphics: {graphicsDriver}
            </Typography>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
}
