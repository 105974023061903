import { makeStyles, createStyles } from '@material-ui/core/styles';

const CloseIconButtonStyles = makeStyles((theme: any) => createStyles({
  closeIconButton: {
    position: "absolute",
    padding: 1,
    right: 5,
    top: 3,
  },
  arrowUpIcon: {
    fontSize: 30,
    color: theme.palette.text.primary
  },
})
)

export { CloseIconButtonStyles };