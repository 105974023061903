import { makeStyles, createStyles } from "@material-ui/core/styles";

const EntryModalStyles = makeStyles((theme: any) =>
  createStyles({
    entryModal: {
      backgroundColor: "black",
      borderRadius: 10,
      width: 540,
      height: "auto",
      padding: 10,
      zIndex: 9,
      pointerEvents: "all"
    },
    entryModalHeaderHolder: {
      backgroundColor: "#404040",
      borderRadius: 3,
      textAlign: "center",
      width: "100%",
      height: "auto",
      position: "relative",
      paddingTop: 10,
      paddingBottom: 10,
    },
    entryModalHeader: {
      fontSize: 14,
      display: "inline-block",
      color: "#A09B9B",
      fontWeight: 500,
      marginTop: 9,
      marginBottom: 5,
    },
    entryModalBookingName: {
      fontSize: 36,
      color: "white",
      display: "block",
    },
    instructionHeader: {
      color: "#00B6FF",
      fontSize: 24,
      fontWeight: 500,
      display: "inline-block",
      width: 262,
      marginLeft: 5,
    },
    enterButton: {
      padding: 0,
      backgroundColor: "#1CB6FF",
      position: "relative",
      display: "inline-block",
      width: 194,
      height: 44,
      marginBottom: 15,
      "&:hover": {
        backgroundColor: "#d5d5d5",
      },
    },
    enterButtonLabel: {
      position: "relative",
    }
  })
);

export { EntryModalStyles };
