import React, { ReactElement } from "react";
import { Typography, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";

import styles from "./DeviceToggle.module.css";
import { DeviceToggleStyles } from "./DeviceToggleStyles";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

interface Props {
  onDeviceToggleBack: () => void;
  onDeviceToggleSkip: () => void;
  onDeviceToggleAccept: (cameraAndMic: boolean) => void;
  onDeviceToggleDecline: () => void;
}

export default function DeviceToggle(props: Props): ReactElement {
  //Camera and Mic
  const handleCameraAndMicClick = () => {
    props.onDeviceToggleAccept(true);
  };

  //Just mic
  const handleMicClick = () => {
    props.onDeviceToggleAccept(false);
  };

  const classes = DeviceToggleStyles();

  return (
    <IntakeContentHolder marginBottom={"344px"} autoHeight={true}>
      <Typography classes={{ root: classes.deviceToggleHeader }} variant="h1">
        Showboat works best when everyone can see and hear each other.
      </Typography>

      <Typography classes={{ root: classes.deviceToggleText }} variant="body1">
        Turn on camera and mic{" "}
        <span style={{ color: "#00B6FF" }}>(recommended)</span>
      </Typography>

      <Button
        onClick={handleCameraAndMicClick}
        className={classes.cameraAndMicButton}
        variant="contained"
      >
        TURN ON CAMERA AND MIC
      </Button>

      <Typography
        classes={{
          root: `${classes.deviceToggleText} ${classes.deviceToggleTextBottom}`,
        }}
        variant="body1"
      >
        Turn on microphone only
      </Typography>

      <Button
        variant="text"
        onClick={handleMicClick}
        className={classes.micButton}
      >
        TURN ON MIC ONLY
      </Button>

      <div className={styles.buttonHolder}>
        <Button
          classes={{ root: classes.noButton }}
          onClick={props.onDeviceToggleBack}
          variant="text"
        >
          BACK
        </Button>
        <Button
          classes={{ root: classes.yesButton }}
          onClick={props.onDeviceToggleSkip}
          variant="contained"
          id="camAndMicSkip"
          disabled={isMobile}
        >
          SKIP
        </Button>
      </div>
    </IntakeContentHolder>
  );
}
