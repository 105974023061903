import React from "react";
import ReactDOM from "react-dom";
import dotenv from "dotenv";
import inobounce from "inobounce";
import { isMobile, isMobileOnly, isIOS, isTablet, isSafari } from "react-device-detect";
import $ from "jquery";

import "./index.css";
import App from "./App";
import { ServerHelper } from "./Utilities/ServerHelper";
import TimeoutErrorPage from "./Intake/Pages/TimeoutErrorPage/TimeoutErrorPage";
import FailedLoginErrorPage from "./Intake/Pages/FailedLoginErrorPage/FailedLoginErrorPage";
import MobileAndTabletErrorPage from "./Intake/Pages/MobileAndTabletErrorPage/MobileAndTabletErrorPage";
import { StringVariableHelper } from "./Utilities/StringVariableHelper";
import { UIHelper } from "./Utilities/UIHelper";

//Create canvas for Babylon and assign ID
(window as any).babylonCanvas = document.createElement("canvas");
(window as any).babylonCanvas.id = "showboatCanvas";

//Start up dotenv
dotenv.config();

//If we are in dev environment, increase logging level
if (process.env.REACT_APP_API_STAGE === "dev") {
  SHOWBOAT.Logger.ConsoleLogLevel = SHOWBOAT.LogLevel.Debug;
  SHOWBOAT.Logger.WebLogLevel = SHOWBOAT.LogLevel.None;
}

//If we are on mobile, set up the viewport hack and add resize listener
if (isMobile) {
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  //Set static property for 3D to read off of
  SHOWBOAT.UIEventManager.isMobile = isMobile;

  //Set RemotePlayersZoneConfig variable for iPads/mobile
  SHOWBOAT.RemotePlayersZoneConfig.Count_Visible = 10;
}

//If we are on a mobile device, add special style rule to index.css
//And add bounce rule (for iOS)
if (isMobileOnly) {
  UIHelper.appendSpecialMobileStylesheet();

  //Enable bounce fix
  if (isIOS) {
    inobounce.enable();
  } else {
    inobounce.disable();
  }
}

const renderMobileTabletErrorPage = () => {
  //Add event listener to prevent overscrolling
  document.body.addEventListener("touchmove", function (e) {
    e.preventDefault();
  });

  ReactDOM.render(
    <MobileAndTabletErrorPage />,
    document.getElementById("root")
  );
};

const checkForBan = (): boolean => {
  let loginCode = ServerHelper.getLoginCode();

  if (
    localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.BannedLoginCodes
    ) === null
  ) {
    return false;
  } else {
    let bannedLoginCodesArray = JSON.parse(
      localStorage.getItem(
        StringVariableHelper.LocalStorageProperties.BannedLoginCodes
      )
    );

    //If this code is on the banned login code list, return true
    if (bannedLoginCodesArray.includes(loginCode)) {
      ReactDOM.render(
        <TimeoutErrorPage failCopy="You have been banned from this event." />,
        document.getElementById("root")
      );

      return true;
    } else {
      return false;
    }
  }
};

const handleConnectionTimeout = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <TimeoutErrorPage failCopy="" />,
    document.getElementById("root")
  );

  return false;
};

const handleLoginResultSuccess = async (loginResult) => {
  document.title = loginResult.bookingName;

  //Store booking name in ServerHelper
  ServerHelper.bookingName = loginResult.bookingName;

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <App
      primaryThemeColor={SHOWBOAT.ApplicationSkin.primaryThemeColor}
      theme={SHOWBOAT.ApplicationSkin.theme}
      systemCheck={false}
    />,
    document.getElementById("root")
  );
};

//System check login result
const handleSystemCheckLoginResult = () => {
  document.title = "Showboat System Check";

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <App
      primaryThemeColor={SHOWBOAT.ApplicationSkin.primaryThemeColor}
      theme={"dark"}
      systemCheck={true}
    />,
    document.getElementById("root")
  );
};

const handleLoginResultFailure = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);
  ReactDOM.render(<FailedLoginErrorPage />, document.getElementById("root"));
};

//Check for ban before executing login logic
let isBanned = checkForBan();

let allowLogin = true;

if (isBanned) {
  allowLogin = false;
}

//Don't allow login with tablets
if (isTablet) {
  allowLogin = false;
  renderMobileTabletErrorPage();
}

//Don't allow iOS login on browsers other than safari
if (isIOS && !isSafari) {
  allowLogin = false;
  renderMobileTabletErrorPage();
}

//Only add timeout event listener if we are logging in
if (allowLogin === true) {
  ServerHelper.OnServerConnectionTimeout.Add(handleConnectionTimeout);
}

if (allowLogin === true) {
  ServerHelper.Login()
    .then((loginResult) => {
      SHOWBOAT.Logger.Log("Login result:", loginResult);
      
      //Configure Logger
      SHOWBOAT.Logger.ConfigureWorker(
        loginResult.eventID,
        loginResult.attendeesID,
        ServerHelper.awsLoggingUrl + `?loginCode=${ServerHelper.loginCode}`,
        ServerHelper.awsLoggingUrl + `?loginCode=${ServerHelper.loginCode}`
      );

      //Store token from login result
      if (loginResult.token) {
        ServerHelper.attendeesIDToken = loginResult.token;
      }

      //Store attendeesID from login result
      if (loginResult.attendeesID) {
        ServerHelper.attendeesID = loginResult.attendeesID;
      }

      //Store userID from loginResult
      if (loginResult.userID) {
        SHOWBOAT.LocalAvatarDataManager.userID = loginResult.userID;
      }

      SHOWBOAT.SystemInformation.Init();

      //Configure the Logger with required information
      /* SHOWBOAT.Logger.ConfigureWorker(loginResult.bookingID, ) */

      if (loginResult && loginResult.success) {
        //Disable pinch-to-zoom behavior if on mobile/tablet

        if (isMobile) {
          document.addEventListener(
            "touchmove",
            function (e) {
              if (e.touches.length > 1) {
                e.preventDefault();
              }
            },
            { passive: false }
          );

          document.addEventListener(
            "touchstart",
            function (e) {
              if (e.touches.length > 1) {
                e.preventDefault();
              }
            },
            { passive: false }
          );

          document.addEventListener("gesturestart", function (e) {
            e.preventDefault();
          });

          document.addEventListener("gesturechange", function (e) {
            e.preventDefault();
          });

          document.addEventListener("gestureend", function (e) {
            e.preventDefault();
          });

          if (ServerHelper.useMobileApp) {
            //uses document because document will be topmost level in bubbling
            $(document).on("touchmove", function (e) {
              e.preventDefault();
            });
            //uses body because jquery on events are called off of the element they are
            //added to, so bubbling would not work if we used document instead.
            $("body").on("touchstart", ".scrollable", function (e) {
              if (e.currentTarget.scrollTop === 0) {
                e.currentTarget.scrollTop = 1;
              } else if (
                e.currentTarget.scrollHeight ===
                e.currentTarget.scrollTop + e.currentTarget.offsetHeight
              ) {
                e.currentTarget.scrollTop -= 1;
              }
            });
            //prevents preventDefault from being called on document if it sees a scrollable div
            $("body").on("touchmove", ".scrollable", function (e) {
              e.stopPropagation();
            });
          }
        }

        handleLoginResultSuccess(loginResult);
      } else if (
        !loginResult.success &&
        (loginResult.failReason ===
          StringVariableHelper.LoginFailReasons.NotStarted ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.Ended ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.DeletedLoginCode ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.CapacityFull)
      ) {
        handleLoginResultSuccess(loginResult);
      }
      //System check
      else if (
        !loginResult.success &&
        loginResult.failReason ===
          StringVariableHelper.LoginFailReasons.SystemCheck
      ) {
        handleSystemCheckLoginResult();
      } else {
        handleLoginResultFailure();
      }
    })
    .catch((err) => {
      SHOWBOAT.Logger.Error("Error logging in user", err);
    });
}
