import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import EnterIcon from '@material-ui/icons/ExitToApp';

import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './SwitchRooms.module.css';
import { SwitchRoomsStyles } from './SwitchRoomsStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function SwitchRooms(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = SwitchRoomsStyles();
  
  return (
    <React.Fragment>
      {props.currentSection === 5 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>
          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }

      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px",
          top: "40px"
        }}
      >
        If more than one room is open, you have the option of moving from room to room.
      </Typography>
      
      <img 
        className={styles.switchRoomsImage} 
        alt="Room button" 
        src={`assets/images/SwitchRooms${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        Press the location button in the main menu.
      </Typography>
      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        A list of the available rooms will be displayed.
      </Typography>

      <EnterIcon 
        className={classes.enterIcon}
        style={{
          marginBottom: "7px"
        }}
      ></EnterIcon>

      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        Pick a room to switch to and press the arrow next to the room number.
      </Typography>

      <Typography
        variant="h4"
        className={classes.switchRoomsText}
        style={{
          fontWeight: "bold",
          top: 10,
          marginBottom: "25px"
        }}
      >
        TO PERSON
      </Typography>

      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        If you want to move to a room with a specific person in it, press the TO PERSON tab
        and use the search bar to find them.
      </Typography>
      
      <EnterIcon 
        className={classes.enterIcon}
        style={{ 
          marginBottom: "7px"
        }}
      ></EnterIcon>

      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        Press the arrow next to the attendee's name to move to that room.
      </Typography>
      
      <Typography
        variant="body1"
        className={classes.switchRoomsText}
        style={{
          width: "250px"
        }}
      >
        You will be placed in the room but not taken directly to the attendee.
      </Typography>

    </React.Fragment>
  )
}
