import { StringVariableHelper } from "./StringVariableHelper";

export class MobilePresenterDownstreamWrapper {

    public constructor(public userID : string, public downstreamController : SHOWBOAT.LiveswitchDownstreamController){
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(this.userID, SHOWBOAT.ChangeReason.Camera, this.OnRemoteCameraUpdate);
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(this.userID, SHOWBOAT.ChangeReason.Mic, this.OnRemoteMicUpdate);
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(this.userID, SHOWBOAT.ChangeReason.CameraAndMic, this.OnRemoteCamAndMicUpdate);    
        
        downstreamController.OnCameraFeedAvailable.Add(this.OnCameraFeedAvailable);
    }

    public destroy = () => {
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(this.userID, SHOWBOAT.ChangeReason.Camera, this.OnRemoteCameraUpdate);
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(this.userID, SHOWBOAT.ChangeReason.Mic, this.OnRemoteMicUpdate);
        SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(this.userID, SHOWBOAT.ChangeReason.CameraAndMic, this.OnRemoteCamAndMicUpdate);     
        
        this.downstreamController.OnCameraFeedAvailable.Remove(this.OnCameraFeedAvailable);
    }

    private OnRemoteCameraUpdate = (avatarData : SHOWBOAT.AvatarData) => {
        this.downstreamController.setCamera(avatarData.cameraEnabled);
    }

    private OnRemoteMicUpdate = (avatarData : SHOWBOAT.AvatarData) => {
        console.warn("OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate OnRemoteMicUpdate", avatarData.micEnabled);
        this.downstreamController.setMicrophone(avatarData.micEnabled);
        /* MobilePresenterStreamHelper.OnRequestAudioUnlockScreen.Raise(); */
    }

    private OnRemoteCamAndMicUpdate = (avatarData : SHOWBOAT.AvatarData) => {
        this.downstreamController.setCamera(avatarData.cameraEnabled);
        this.downstreamController.setMicrophone(avatarData.micEnabled);
    }

    private OnCameraFeedAvailable = (isAvailable : boolean, videoElement : HTMLVideoElement, videoStream : MediaStream, audioStream : MediaStream) => {
        
    }

}

export class MobilePresenterStreamHelper {

    public static OnPresenterStarted : NSM.Delegate = new NSM.Delegate(); // userID : string, downstreamController : LiveswitchDownstreamController
    public static OnPresenterStopped : NSM.Delegate = new NSM.Delegate(); // userID : string

    private static PresenterDownstreamMap : Map<string, SHOWBOAT.LiveswitchDownstreamController> = new Map<string, SHOWBOAT.LiveswitchDownstreamController>();
    private static MobilePresenterDownstreamWrapperMap : Map<string, MobilePresenterDownstreamWrapper> = new Map<string, MobilePresenterDownstreamWrapper>();

    public static OnRequestAudioUnlockScreen: NSM.Delegate = new NSM.Delegate();// void

    public static async Init(){

        /* SHOWBOAT.RemoteAvatarDataManager.OnPartitionChange.Add(MobilePresenterStreamHelper.OnPartitionChange); */
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
            SHOWBOAT.UpdateType.indexOf(
                StringVariableHelper.ChangeEventNames.IsPresenting
            ),
            MobilePresenterStreamHelper.OnIsPresentingChange
        );
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(MobilePresenterStreamHelper.OnRemotePlayerDisconnected);
        SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerConnected.Add(MobilePresenterStreamHelper.OnRemotePlayerConnected);

        if(SHOWBOAT.RemoteAvatarDataManager.initialLoadComplete){
            MobilePresenterStreamHelper.LoadCurrentPresenters();
        } else {            
            SHOWBOAT.RemoteAvatarDataManager.OnInitialLoadComplete.Add(MobilePresenterStreamHelper.OnRemoteAvatarDataManagerLoadComplete);
        }
        

    }

    public static OnRemoteAvatarDataManagerLoadComplete(){
        SHOWBOAT.RemoteAvatarDataManager.OnInitialLoadComplete.Remove(MobilePresenterStreamHelper.OnRemoteAvatarDataManagerLoadComplete);
        MobilePresenterStreamHelper.LoadCurrentPresenters();
    }

    public static LoadCurrentPresenters(){
        let presenters : SHOWBOAT.AvatarData[] = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEventByRole(StringVariableHelper.ShowboatRoles.presenter);
        for(let i = 0; i < presenters.length; ++i){
            if(presenters[i].isPresenting){
                MobilePresenterStreamHelper.OnIsPresentingChange(presenters[i],[],[]);
            }
        }
    }

    public static getCurrentPresenterUserIDs() : string[] {
        return Array.from(MobilePresenterStreamHelper.PresenterDownstreamMap.keys());
    }

    public static getCurrentDownstreamControllers() : SHOWBOAT.LiveswitchDownstreamController[] {
        return Array.from(MobilePresenterStreamHelper.PresenterDownstreamMap.values());
    }

    private static OnRemotePlayerDisconnected(userID : string){
        MobilePresenterStreamHelper.removePresenter(userID);
    }

    private static OnRemotePlayerConnected(avatarData : SHOWBOAT.AvatarData){
        MobilePresenterStreamHelper.OnIsPresentingChange(avatarData,[],[]);
    }

    private static async OnIsPresentingChange(avatarData : SHOWBOAT.AvatarData, position : number[], rotation : number[]){

        if(!avatarData) return;

        
        if(avatarData.isPresenting){
            if(MobilePresenterStreamHelper.PresenterDownstreamMap.has(avatarData.userID)) return;


            let silenceAudience : any = await SHOWBOAT.ServerVariableManager.getBookingVariable(StringVariableHelper.SilenceAudienceEventName, { muted: false });
            let infiniteAudio : any = await SHOWBOAT.ServerVariableManager.getBookingVariable(StringVariableHelper.SpatialAudioEventName, { value: true });
            let myChannelType : SHOWBOAT.ShowboatChannelType = MobilePresenterStreamHelper.GetChannelFromAvatarData(SHOWBOAT.LocalAvatarDataManager.avatarData);

            SHOWBOAT.Logger.Log("Infinite audio:", infiniteAudio);
            
            let downstreamController : SHOWBOAT.LiveswitchDownstreamController = 
                new SHOWBOAT.LiveswitchDownstreamController(
                    avatarData.userID,
                    SHOWBOAT.ShowboatChannelType.Presenter,
                    SHOWBOAT.RemoteStreamingMode.Connected,
                    avatarData.cameraEnabled,
                    true,/* avatarData.micEnabled, */
                    false,
                    silenceAudience.data.muted,
                    myChannelType,
                    MobilePresenterStreamHelper.OnCameraFeedUpdate,
                    avatarData.userID,
                    !infiniteAudio.data.spatialAudio
                );
            
            MobilePresenterStreamHelper.PresenterDownstreamMap.set(avatarData.userID, downstreamController);
            MobilePresenterStreamHelper.OnPresenterStarted.Raise(avatarData.userID, downstreamController);

            let mobilePresenterDownstreamWrapper : MobilePresenterDownstreamWrapper = new MobilePresenterDownstreamWrapper(avatarData.userID, downstreamController);
            MobilePresenterStreamHelper.MobilePresenterDownstreamWrapperMap.set(avatarData.userID, mobilePresenterDownstreamWrapper);


        } else {
            MobilePresenterStreamHelper.removePresenter(avatarData.userID);
        } 

    }

    private static removePresenter(userID : string){

        
        let downstreamController : SHOWBOAT.LiveswitchDownstreamController = MobilePresenterStreamHelper.PresenterDownstreamMap.get(userID);
            
        if(downstreamController){
            downstreamController.destroy();
        }
        MobilePresenterStreamHelper.PresenterDownstreamMap.delete(userID);

        let mobilePresenterDownstreamWrapper : MobilePresenterDownstreamWrapper = MobilePresenterStreamHelper.MobilePresenterDownstreamWrapperMap.get(userID);
        if(mobilePresenterDownstreamWrapper){
            mobilePresenterDownstreamWrapper.destroy();
        }
        MobilePresenterStreamHelper.MobilePresenterDownstreamWrapperMap.delete(userID);
        
        MobilePresenterStreamHelper.OnPresenterStopped.Raise(userID);
    }

    private static OnCameraFeedUpdate (isAvailable : boolean, videoElement : HTMLVideoElement, videoStream : MediaStream, audioStream : MediaStream){
        
    }

    /* private static GetChannelFromPartitionString(partitionString : string) : SHOWBOAT.ShowboatChannelType {
        switch(partitionString){
            case StringVariableHelper.ShowboatPartitions.attendees:
                return SHOWBOAT.ShowboatChannelType.Attendees;
            case StringVariableHelper.ShowboatPartitions.presenter:
                return SHOWBOAT.ShowboatChannelType.Presenter;
            case StringVariableHelper.ShowboatPartitions.backstage:
                return SHOWBOAT.ShowboatChannelType.Backstage;
            default:
                SHOWBOAT.Logger.Error("Failed to find partition based on given string: " + partitionString);
                return SHOWBOAT.ShowboatChannelType.Attendees;
        }
    } */

    private static GetChannelFromAvatarData(avatarData : SHOWBOAT.AvatarData) : SHOWBOAT.ShowboatChannelType {
        if (avatarData.isPresenting) {
            return SHOWBOAT.ShowboatChannelType.Presenter;
        } else if (avatarData.roomID === "backstage") {
            return SHOWBOAT.ShowboatChannelType.Backstage;
        } else {
            return SHOWBOAT.ShowboatChannelType.Attendees;
        }
    }


}

(window as any).MobilePresenterStreamHelper = MobilePresenterStreamHelper;