import { makeStyles, createStyles } from "@material-ui/core/styles";

const PrivateMessageStyles = makeStyles((theme: any) =>
  createStyles({
    privateMessageHolder: {
      width: 436,
      minHeight: 95,
      height: "auto",
      pointerEvents: "all",
      display: "inline-block",
      position: "absolute",
      textAlign: "left",
      visibility: "hidden",
      bottom: 0,
      right: 0,
      zIndex: 10,
      borderRadius: 10,
      [theme.breakpoints.down("md")]: {
        width: 325,
      },
      [theme.breakpoints.down("sm")]: {
        width: 275,
      },
    },
    privateMessageHolderMobile: {
      width: "100%",
      position: "relative",
      padding: 14,
      boxSizing: "border-box",
      borderRadius: 4,
      marginBottom: 4,
      zIndex: 9,
      visibility: "visible"
    },
    privateMessageHolderWithResponseBar: {
      width: 436,
      minHeight: 203,
      height: "auto",
      pointerEvents: "all",
      display: "inline-block",
      position: "absolute",
      bottom: 0,
      right: 0,
      textAlign: "left",
      zIndex: 15,
      borderRadius: 10,
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        width: 375,
        right: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
    },
    privateMessageHolderWithResponseBarRightOpen: {
      [theme.breakpoints.down("sm")]: {
        right: 0,
      },
    },
    messageSenderName: {
      fontSize: 14,
      position: "relative",
      marginLeft: 19,
      top: 9,
    },
    messageSenderNameMobile: {
      marginLeft: 0,
      top: -3,
    },
    messageText: {
      fontSize: 14,
      position: "relative",
      marginLeft: 19,
      width: 405,
      top: 10,
      wordWrap: "break-word",
      paddingBottom: 60,
      [theme.breakpoints.down("md")]: {
        width: 292,
      },
      [theme.breakpoints.down("sm")]: {
        width: 242,
      },
    },
    messageTextMobile: {
      width: "100%",
      fontSize: 16,
      marginLeft: 0,
      top: -5,
    },
    messageTextResponse: {
      fontSize: 14,
      position: "relative",
      marginLeft: 19,
      width: 405,
      top: 10,
      wordWrap: "break-word",
      paddingBottom: 25,
      [theme.breakpoints.down("md")]: {
        width: 340,
      },
      [theme.breakpoints.down("sm")]: {
        width: 265,
      },
    },
    respondButton: {
      width: 93,
      height: 36,
      position: "absolute",
      right: 102,
      bottom: 10,
    },
    dismissButton: {
      width: 93,
      height: 36,
      position: "absolute",
      right: 10,
      zIndex: 20,
      bottom: 10,
    },
    dismissButtonRespond: {
      [theme.breakpoints.down("sm")]: {
        bottom: 5,
        height: 27,
        padding: "2px 8px",
      },
    },
    senderNameHolder: {
      position: "relative",
      width: "100%",
      minHeight: 71,
      height: "auto",
      left: 0,
      borderRadius: 10,
    },
    responseBarHolder: {
      position: "relative",
      width: "100%",
      backgroundColor: theme.palette.intakeBackground.main,
      height: 132,
      zIndex: 15,
      bottom: 0,
      left: 0,
      borderRadius: 10,
      [theme.breakpoints.down("sm")]: {
        height: 132,
      },
    },
    respondMessageInputHolder: {
      width: 401,
      height: 83,
      position: "absolute",
      left: 17,
      top: 17,
      [theme.breakpoints.down("md")]: {
        width: 340,
      },
      [theme.breakpoints.down("sm")]: {
        width: 265,
      },
    },
    respondMessageInput: {
      backgroundColor: theme.palette.background.paper,
      /*  [theme.breakpoints.down('sm')]: {
       height: 45
     } */
    },
    emojiIcon: {
      color: theme.palette.emojiIcon.main,
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 25,
      },
    },
    sendButton: {
      position: "absolute",
      bottom: 10,
      right: 102,
      width: 93,
      height: 36,
      zIndex: 20,
      [theme.breakpoints.down("sm")]: {
        bottom: 5,
        height: 27,
        padding: "2px 8px",
      },
    },
    emojiSelectHolder: {
      position: "absolute",
      right: 0,
      bottom: 60,
      width: 300,
      pointerEvents: "all",
      textAlign: "center",
      zIndex: 10,
      "& section": {
        width: "100% !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: 200,
      },
    },
    responseHelperText: {
      display: "inline-block",
      width: 200,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        width: 150,
      },
      [theme.breakpoints.down("sm")]: {
        width: 255,
      },
    },
  })
);

export { PrivateMessageStyles };
