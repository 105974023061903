import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileMessagesBadgeStyles = makeStyles((theme: any) => createStyles({
  number: {
    color: "white",
    fontSize: 14,
  }
})
)

export { MobileMessagesBadgeStyles };