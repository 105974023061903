import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeListStyles = makeStyles((theme: any) =>
  createStyles({
    searchBar: {
      display: "inline-block",
      position: "relative",
      height: "100%",
      width: "100%",
      border: "none",
      [theme.breakpoints.down("md")]: {},
      /* ['@media(max-height: 750px)']: {
      top: 215,
    }, */
    },
    input: {
      height: 45,
      position: "relative",
      top: 5,
      border: "none",
    },
    noBorder: {
      border: "none",
    },
    inputLabel: {
      transition: "none",
    },
    inputLabelMobile: {
      color: "#404040",
    },
    inputLabelFocused: {
      display: "none",
    },
    searchIcon: {},
    searchWarning: {
      position: "relative",
      top: 170,
      float: "left",
      left: 25,
      fontSize: 12,
      color: theme.palette.text.disabled,
      [theme.breakpoints.down("md")]: {
        fontSize: 11,
        top: 170,
      },
      /* ['@media(max-height: 750px)']: {
      top: 215,
    }, */
    },
    focusedInput: {
      borderBottomColor: "white",
    },
    cancelIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    cancelIconMobile: {
      position: "absolute",
      right: 17.5,
      color: "#404040",
    },
    attendeesHeader: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      borderRadius: 4,
      height: 40,
      position: "relative",
      color: "white",
    },
    directoryHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 43,
      top: 10,
    },
    peopleIcon: {
      position: "absolute",
      top: 7,
      left: 10,
      color: "white",
    },
    closeSidebarButton: {
      padding: 4,
      position: "absolute",
      top: 3,
      right: 7,
      color: "white",
    },
    searchBarHolder: {
      width: "100%",
      height: 58,
      backgroundColor: theme.palette.controlCenterButton.main,
      position: "relative",
      top: 4,
    },
    searchBarHolderMobile: {
      backgroundColor: "white",
      color: "#404040",
      borderRadius: 0,
      position: "absolute",
      top: 0,
      left: 0,
    },
    paginationCaption: {
      display: "none",
    },
    paginationSelect: {
      display: "none",
    },
    paginationActions: {
      width: "95%",
      marginLeft: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "relative",
      bottom: 6,
      right: 7,
      color: "white",
      "& button": {
        padding: 4,
      },
    },
    paginationActionsMobile: {
      color: "black",
      width: "100%",
      right: 0,
      bottom: 0,
      "& button": {
        color: "black",
        padding: 14,
        borderRight: "1px solid #707070",
        borderLeft: "1px solid #707070",
        borderRadius: 0,
      },
      "& button:disabled": {
        color: "#D6D6D6"
      }
    },
    paginationToolbar: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paginationHeader: {
      position: "relative",
      display: "inline-block",
      color: "white",
    },
    paginationHeaderMobile: {
      color: "black",
      position: "relative",
      top: 6,
    },
    inputText: {},
    inputTextMobile: {
      color: "#404040",
    },
    searchIconMobile: {
      color: "#404040",
      position: "absolute",
      right: 17.5
    },
  })
);

export { AttendeeListStyles }