import { makeStyles, createStyles } from "@material-ui/core/styles";

const ForceMovePopupStyles = makeStyles((theme: any) =>
  createStyles({
    forceMovePopup: {
      position: "absolute",
      textAlign: "left",
      marginBottom: 10,
      top: 10,
      left: 0,
      width: 311,
      height: 90,
      display: "none",
      background: theme.palette.background.paper,
      pointerEvents: "all",
      borderRadius: 10,
    },
    forceMoveText: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.primary,
      position: "absolute",
      width: 200,
      wordWrap: "break-word",
      left: 24,
      top: 34,
    },
    loadingSpinner: {
      color: "grey",
      position: "absolute",
      right: 36,
      top: 24,
    }
  })
);

export { ForceMovePopupStyles };
