import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';

import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './Teleport.module.css';
import { TeleportStyles } from './TeleportStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function Teleport(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = TeleportStyles();
  
  return (
    <React.Fragment>

      {props.currentSection === 4 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>

          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }

      <img 
        className={styles.directoryImage} 
        src={`assets/images/FindPeople${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
        alt="Directory button"
      />

      <Typography 
        variant="body1" 
        className={classes.teleportText}
        style={{
          width: "250px"
        }}
      >
        Press the directory button, then search for and find the attendee you wish 
        to communicate with.
      </Typography>

      <Typography 
        variant="body1" 
        className={classes.teleportText}
        style={{
          width: "250px"
        }}
        >
        Click on the attendee's name to access the teleport controls.
      </Typography>

      <img alt="Teleport button" className={styles.teleportImage} src={`assets/images/Teleport${SHOWBOAT.ApplicationSkin.theme === " light" ? "Light" : "Dark"}.svg`} />

      <Typography variant="body1" className={classes.teleportText}>
        Click the teleport button.
      </Typography>

      <Typography 
        variant="body1" 
        className={classes.teleportText}
        style={{
          width: "260px"
        }}
      >
        A teleport request is sent to the attendee. Once they accept the teleport request you will be
        teleported from your current location to their location.
      </Typography>

    </React.Fragment>
  )
}
