import React, {useEffect, useState} from 'react';

export interface OneOnOneChatContextType {
  isOneOnOneChatOpen : boolean,
  toggleIsOneOnOneChatOpen : (isOneOnOneChatOpen : boolean) => void,
  handleToggleIsOneOnOneChatOpen : (attendeeUserID : string, attendeeName : string) => void,
  userIDToMessage : string,
  setUserIDToMessage : (userID : string) => void,
  nameToMessage : string,
  setNameToMessage : (name : string) => void,
  messageSent : boolean,
  toggleMessageSent : (messageSent : boolean) => void,
  isChatOff : boolean,
  toggleIsChatOff : (isChatOff : boolean) => void,
  respondMessageID: string,
  setRespondMessageID: (respondMessageID: string) => void
}

export const OneOnOneChatContext = React.createContext(null);

export const OneOnOneChatContextProvider = (props) => {
  const [ userIDToMessage, setUserIDToMessage ] = useState("");
  const [ nameToMessage, setNameToMessage ] = useState("");
  const [ isChatOff, toggleIsChatOff ] = useState(false);
  const [ isOneOnOneChatOpen, toggleIsOneOnOneChatOpen ] = useState(false);
  const [ messageSent, toggleMessageSent ] = useState(false);
  const [respondMessageID, setRespondMessageID] = useState("");

  const handleToggleIsOneOnOneChatOpen = (attendeeUserID: string, attendeeName: string) => {

    toggleIsOneOnOneChatOpen(false);

    setUserIDToMessage(attendeeUserID);
    setNameToMessage(attendeeName)

    toggleMessageSent(false);
    
    toggleIsOneOnOneChatOpen(true);
  }

  const oneOnOneChatContextProviderValue : OneOnOneChatContextType = {
    isOneOnOneChatOpen,
    toggleIsOneOnOneChatOpen,
    handleToggleIsOneOnOneChatOpen,
    userIDToMessage,
    setUserIDToMessage,
    nameToMessage,
    setNameToMessage,
    messageSent,
    toggleMessageSent,
    isChatOff, 
    toggleIsChatOff,
    respondMessageID,
    setRespondMessageID
  }  

  return (
    <OneOnOneChatContext.Provider
      value={oneOnOneChatContextProviderValue}
    >
      {props.children}
    </OneOnOneChatContext.Provider>
  )
}