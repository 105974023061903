import { makeStyles, createStyles } from '@material-ui/core/styles';

const QASidebarStyles = makeStyles((theme: any) =>
  createStyles({
    qaMessageInputHolder: {
      backgroundColor: theme.palette.controlCenterButton.main,
      position: "absolute",
      bottom: -5,
      left: -5,
      width: 300,
      height: 208,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      zIndex: 15,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
      padding: 10,
      boxSizing: "border-box",
    },
    qaTextField: {
      width: 280,
      height: 74,
      border: "none",
      outline: "none",
      backgroundColor: theme.palette.chatInputBackground.main,
      borderRadius: 4,
      fontSize: 14,
    },
    emojiIcon: {
      position: "relative",
      bottom: 16,
      color: theme.palette.emojiIcon.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    noBorder: {
      border: "none",
    },
    qaInput: { fontSize: 14, paddingTop: 14 },
    qaInputInput: {
      "&::placeholder": {
        color: theme.palette.qaViewHeader.main,
      },
    },
    qaInputAdornedEnd: {
      paddingRight: 5,
    },
    submitButton: {
      width: "100%",
      height: 40,
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    qaHelperText: {
      fontSize: 12,
      color: theme.palette.text.main,
      position: "relative",
      top: 5,
    },
    anonymousLabel: {
      fontSize: 12,
    },
    anonymousCheckbox: {
      position: "relative",
      bottom: 2,
    },
    submitButtonSubmitted: {
      backgroundColor: "#0D7200",
      "&:hover": {
        backgroundColor: "#0D7200",
      },
    },
    characterCount: {
      fontSize: 14,
    },
  })
);

export {QASidebarStyles}