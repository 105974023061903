import { IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TwoDHelper } from "../../../Utilities/TwoDHelper";

import styles from "./TwoDCamerasBadge.module.css";
import { TwoDCamerasBadgeStyles } from "./TwoDCamerasBadgeStyles";

type Props = {
  count: number;
  resizePanelSize: number;
};

export default function TwoDCamerasBadge(props: Props) {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    TwoDHelper.OnHideCameraBadge.Add(handleChangeHiddenCount);

    //Set initial bottom value
    document.getElementById("twoDCamerasBadge").style.bottom = `${
      props.resizePanelSize - 18
    }px`;

    return function cleanup() {
      TwoDHelper.OnHideCameraBadge.Remove(handleChangeHiddenCount);
    };
  }, []);

  const handleChangeHiddenCount = (hide: boolean) => {
    setHide(hide);
  };

  const classes = TwoDCamerasBadgeStyles();
  return (
    <div
      className={styles.hiddenBadge}
      id="twoDCamerasBadge"
      style={{ visibility: !hide ? "visible" : "hidden" }}
    >
      <Typography className={classes.badgeText}>+MORE</Typography>
    </div>
  );
}
