import { makeStyles, createStyles } from '@material-ui/core/styles';

const NewRoomPopupStyles = makeStyles((theme: any) => createStyles({
  newRoomPopup: {
    position: "absolute",
    textAlign: "left",
    marginBottom: 10,
    top: 10,
    left: 0,
    width: 321,
    height: 90,
    display: "none",
    background: theme.palette.background.paper,
    pointerEvents: "all",
    borderRadius: 10,
  },
  newRoomText: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.primary,
    position: "absolute",
    width: 210,
    wordWrap: "break-word",
    left: 24,
    top: 15,
  },
  dismissButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
  }
})
)

export { NewRoomPopupStyles };