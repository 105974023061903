import { Button, Dialog, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import BoxIcon from "@material-ui/icons/Crop54";

import { MobileAudioUnlockPopupStyles } from "./MobileAudioUnlockPopupStyles";
import styles from "./MobileAudioUnlockPopup.module.css";
import { MobileContext, MobileContextType } from "../../../context/MobileContext";

type Props = {};

export default function MobileAudioUnlockPopup({}: Props) {
  const {
    showAudioUnlockDialog,
    setShowAudioUnlockDialog,
    handleShowAudioUnlock,
    unlockShownForPresenters,
    setUnlockShownForPresenters
  } : MobileContextType = useContext(MobileContext);

  const unlockShownForPresentersRef = useRef(unlockShownForPresenters)
  
  useEffect(() => {
    SHOWBOAT.JumbotronHelper.OnJumbotronPlayed.Add(handleJumbotronPlayed);

    return function cleanup() {
      SHOWBOAT.JumbotronHelper.OnJumbotronPlayed.Remove(handleJumbotronPlayed);
    }
  }, [])

  useEffect(() => {
    unlockShownForPresentersRef.current = unlockShownForPresenters;
  }, [unlockShownForPresenters])

  const handleJumbotronPlayed = () => {
    //Close the popup, only if not shown for presenters
    if (!unlockShownForPresentersRef.current) {
      setShowAudioUnlockDialog(false);
    }
  }
  
  const handleDialogClose = () => {
    handleShowAudioUnlock(false);
  };

  const handleUnlockAudio = () => {
    SHOWBOAT.UIEventManager.OnAudioUnlockEvent.Raise();

    SHOWBOAT.JumbotronHelper.playCurrentJumbotronElement();

    setUnlockShownForPresenters(false);

    handleShowAudioUnlock(false);
  };

  const classes = MobileAudioUnlockPopupStyles();

  return (
    <Dialog
      open={showAudioUnlockDialog}
      onClose={handleDialogClose}
      classes={{ paper: classes.audioUnlockPopup }}
      disableBackdropClick
    >
      <div className={styles.audioUnlockPopupWrapper}>
        <Typography className={classes.audioUnlockText}>
          Your browser is requesting permission to start the presentation feeds.
        </Typography>

        <Button
          className={classes.button}
          variant="contained"
          onClick={handleUnlockAudio}
          onTouchStart={handleUnlockAudio}
        >
          <BoxIcon className={classes.boxIcon} />
          START FEEDS
        </Button>
      </div>
    </Dialog>
  );
}
