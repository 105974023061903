import { makeStyles, createStyles } from '@material-ui/core/styles';

const ControlCenterStyles = makeStyles((theme: any) =>
  createStyles({
    controlCenter: {
      width: 120,
      height: 200,
      backgroundColor: "rgb(0, 0, 0,0.84)",
      position: "absolute",
      right: 10,
      top: 10,
      borderRadius: 10,
      zIndex: 10,
      transition: "right 200ms cubic-bezier(0, 0, 0.2, 1)",
    },
    controlCenterSettings: {
      right: 435,
    },
    controlCenterChat: {
      right: 315,
    },
    controlCenterAttendees: {
      right: 305,
    },
    controlCenterExit: {
      right: 235,
    },
    controlCenterSupport: {},
    controlCenterRoom: {
      right: 305,
    },
    controlCenterClassesPresenter: {
      top: 50,
    },
    controlCenterQA: {
      right: 315,
    },
  })
);

export { ControlCenterStyles}