import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const InactivePopupStyles = makeStyles((theme: Theme) => createStyles({
  dialogPaper: {
    width: 514,
    height: 225,
    borderRadius: 0,
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down('sm')]: {
    }
  },
  popup: {
    position: 'absolute',
    width: "100%",
    height: 225,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    outline: "none",
    color: theme.palette.text.primary,
  },
  inactiveText: {
    fontSize: 35,
    position: "absolute",
    top: 40,
    left: 40,
  },
  yesButton: {
    position: "absolute",
    bottom: 30,
    right: 30,
    padding: 0,
    width: 95,
    height: 44,
  }
})
)

export { InactivePopupStyles };