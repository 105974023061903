import { makeStyles, createStyles } from '@material-ui/core/styles';

const ChatSidebarMessageStyles = makeStyles((theme: any) => createStyles({
  chatBody: {
    marginRight: 10,
    marginBottom: 10,
    marginTop: 40,
    fontSize: 12,
    position: "relative",
    left: 20,
    width: "85%",
    wordWrap: "break-word",
  },
  chatBodyMobile: {
    width: "100%",
    left: 0,
    marginRight: 0,
    marginTop: 0,
    position: "relative",
    top: 40,
    marginBottom: 37,
    fontSize: 16,
  },
  chatMessageHolder: {
    display: "inline-block",
    minHeight: 50,
    width: "98%",
    textAlign: "left",
    position: "relative",
    marginTop: 2,
    left: -2,
    backgroundColor: theme.palette.sidebarHeader.main,
  },
  chatMessageHolderMobile: {
    width: "100%",
    left: 0,
    padding: 16,
    boxSizing: "border-box"
  },
  chatMessageHeader: {
    position: "absolute",
    width: 280,
    height: 30,
    backgroundColor: theme.palette.sidebarHeader.main,
  },
  chatMessageHeaderMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box"
  },
  chatName: {
    fontSize: 12,
    position: "absolute",
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    right: 0,
    left: 20,
    width: 170,
    top: 8,
  },
  chatNameMobile: {
    position: "relative",
    right: 0,
    left: 0,
    top: 0,
    width: "auto",
  },
  chatTime: {
    fontSize: 12,
    position: "absolute",
    top: 8,
    left: 211,
    width: "auto"
  },
  chatTimeMobile: {
    position: "relative",
    top: 0,
    left: -33,
  },
  copyButton: {
    margin: 0,
    position: "fixed",
    zIndex: 14,
    /* right: 0,
    top: "50%",
    transform: "translateY(-50%)", */
    backgroundColor: theme.palette.messageCopyButton.main,
    padding: 6.5,
    minWidth: 32,
    maxWidth: 32,
    minHeight: 32,
    maxHeight: 32,
    "&:hover": {
      backgroundColor: theme.palette.messageCopyButton.main,
    }
  },
  buttonIcon: {
    width: 20,
    height: 20,
    color: "black"
  }
})
)

export { ChatSidebarMessageStyles };