import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileBioIntakeStyles = makeStyles((theme: any) =>
  createStyles({
    bioFormHelperText: {
      color: "white",
      fontSize: 14,
      top: -36,
      position: "relative",
      fontWeight: 500,
      zIndex: 10,
    },
  })
);

export { MobileBioIntakeStyles };