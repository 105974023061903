import { CircularProgress, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ForceMovePopupStyles } from './ForceMovePopupStyles';

interface Props {
  forceMoveID: number;
  countdownSeconds: number;
  handleForceMoveClose: (forceMoveID: number) => void,
}

export default function ForceMovePopup(props: Props): ReactElement {
  
  const displayTimer = useRef(null);

  useEffect(() => {
    displayTimer.current = setTimeout(function () {
      document.getElementById(`${props.forceMoveID.toString()}FORCEMOVE`).style.display =
        "inline-block";
    }, 300);

    return function cleanup() {
      if (displayTimer.current) {
        clearTimeout(displayTimer.current);
      }
    }
  }, [])
  
  const classes = ForceMovePopupStyles();
  
  return (
    <Paper
      elevation={5}
      classes={{ root: classes.forceMovePopup }}
      className="notification"
      id={`${props.forceMoveID.toString()}FORCEMOVE`}
    >
      <Typography variant="h1" className={classes.forceMoveText}>
        Moving to new location...
      </Typography>

      <CircularProgress className={classes.loadingSpinner} />
    </Paper>
  );
}
