import { ServerHelper } from "./ServerHelper";
import { StringVariableHelper } from "./StringVariableHelper";

export class UserPermissionsHelper {
  public static CanPresent;

  public static CanUseLaser;

  public static CanControlAvatarAudio;

  public static CanTeleport;

  public static CanBreakout;

  public static CanShareWebcam;

  public static CanScreenshare;

  public static CanShareSlides;

  public static CanShareLocalVideo;

  public static CanShareLivestream;

  public static CanShareDefault;

  public static CanAdmitOthers;

  public static CanDoTechSupport;

  public static CanOpenNewRooms;

  public static CanRemoveOthers;

  public static Init() {
    UserPermissionsHelper.CanPresent = ServerHelper.clientPermissions.includes(
      StringVariableHelper.ClientPermissions.CAN_PRESENT
    );

    UserPermissionsHelper.CanUseLaser = ServerHelper.clientPermissions.includes(
      StringVariableHelper.ClientPermissions.CAN_USE_LASER
    );

    UserPermissionsHelper.CanControlAvatarAudio =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_CONTROL_AVATAR_AUDIO
      );

    UserPermissionsHelper.CanTeleport = ServerHelper.clientPermissions.includes(
      StringVariableHelper.ClientPermissions.CAN_TELEPORT
    );

    UserPermissionsHelper.CanBreakout = ServerHelper.clientPermissions.includes(
      StringVariableHelper.ClientPermissions.CAN_BREAKOUT
    );

    UserPermissionsHelper.CanShareWebcam =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SHARE_WEBCAM
      );

    UserPermissionsHelper.CanScreenshare =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SCREENSHARE
      );

    UserPermissionsHelper.CanShareSlides =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SHARE_SLIDES
      );

    UserPermissionsHelper.CanShareLocalVideo =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SHARE_LOCAL_VIDEO
      );

    UserPermissionsHelper.CanShareLivestream =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SHARE_LIVESTREAM
      );

    UserPermissionsHelper.CanShareDefault =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_SHARE_DEFAULT
      );

    UserPermissionsHelper.CanAdmitOthers =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_ADMIT_OTHERS
      );

    UserPermissionsHelper.CanDoTechSupport =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_DO_TECH_SUPPORT
      );

    UserPermissionsHelper.CanOpenNewRooms =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_OPEN_NEW_ROOMS
      );

    UserPermissionsHelper.CanRemoveOthers =
      ServerHelper.clientPermissions.includes(
        StringVariableHelper.ClientPermissions.CAN_REMOVE_OTHERS
      );
  }
}

(window as any).UserPermissionsHelper = UserPermissionsHelper;
