import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import Linkify from "react-linkify";
import ThumbIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbIconOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import OpenMenuIcon from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";

import styles from "./QAItem.module.css";

import { UIHelper } from "../../../../../Utilities/UIHelper";
import { QAItemStyles } from "./QAItemStyles";
import { QAContext, QAContextType } from "../../../../../context/QAContext";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";
import { ServerHelper } from "../../../../../Utilities/ServerHelper";
import { ARSHelper } from "../../../../../Utilities/ARSHelper";

interface Props {
  qaItem: SHOWBOAT.Question;
  tab: string;
}

/* let Filter = require("bad-words"); */

export default function QAItem(props: Props): ReactElement {
  const {
    upvotedQuestionIDs,
    setUpvotedQuestionIDs,
    qaRole,
    attendeesSeeAllQs,
    setDisplayedQuestionID,
    displayedQuestionID,
  }: QAContextType = useContext(QAContext);

  const [showCopyButton, setShowCopyButton] = useState(false);
  const [copied, setCopied] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);

  const [buttonTop, setButtonTop] = useState(0);
  const [buttonRight, setButtonRight] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showReply, setShowReply] = useState(false);

  const [replyText, setReplyText] = useState("");

  const [displayLoadingSpinner, setDisplayLoadingSpinner] = useState(false);

  /* let filter = new Filter(); */

  const handleMouseEnter = (e) => {
    //Refresh position information
    setPositionInformation();

    //Show custom right click menu

    setShowCopyButton(true);
  };

  const handleCopy = () => {
    //Safety check
    if (!showCopyButton) return;

    let copyText;

    if (props.qaItem.answerText && props.qaItem.answerText !== undefined) {
      copyText = `${props.qaItem.questionText} Reply: ${props.qaItem.answerText}`;
    } else {
      copyText = props.qaItem.questionText;
    }

    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyText);
      } else {
        throw new Error("");
      }
    } catch {
      //Attempt old variant of copy to clipboard

      let dummy = document.createElement("textarea");

      dummy.style.right = "9999px";

      document.getElementById("chatContentHolder").appendChild(dummy);

      dummy.value = copyText;

      dummy.select();
      dummy.setSelectionRange(0, 99999);

      document.execCommand("copy");

      document.getElementById("chatContentHolder").removeChild(dummy);
    }

    setCopied(true);
  };

  const handleMouseLeave = (e) => {
    setCopied(false);
    setShowCopyButton(false);
  };

  useEffect(() => {
    setPositionInformation();
  }, []);

  useEffect(() => {
    //Add event listener for scrolling
    if (showCopyButton) {
      document.addEventListener("scroll", setPositionInformation);
      document.addEventListener("wheel", setPositionInformation);
    } else {
      document.removeEventListener("wheel", setPositionInformation);
      document.removeEventListener("scroll", setPositionInformation);
    }

    return function cleanup() {
      document.removeEventListener("wheel", setPositionInformation);
      document.removeEventListener("scroll", setPositionInformation);
    };
  }, [showCopyButton]);

  const setPositionInformation = () => {
    setShowCopyButton(false);

    let messageHolder = document.getElementById(
      `${props.qaItem.ID.toString()}`
    );

    if (messageHolder) {
      setButtonTop(messageHolder.getBoundingClientRect().top);
      setButtonRight(messageHolder.getBoundingClientRect().right);

      setContainerHeight(messageHolder.offsetHeight);
    }
  };

  const getMessageDateFormatted = () => {
    let messageDate = new Date(
      props.qaItem.lastUpdatedTime
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let messageDateFormatted = messageDate.replace(/^0(?:0:0?)?/, "");

    return messageDateFormatted;
  };

  const handleUpvoteClick = async () => {
    let questionID = props.qaItem.ID;

    if (upvotedQuestionIDs.includes(questionID)) {
      //Remove question ID from upvoted list
      setUpvotedQuestionIDs((upvotedQuestionIDs) =>
        upvotedQuestionIDs.filter((id) => id !== questionID)
      );

      await SHOWBOAT.WebSocketController.VoteForQuestion({
        questionID: props.qaItem.ID,
        isUpVote: false,
      });
    } else {
      //Add this question ID to upvotedQuestionIDs
      setUpvotedQuestionIDs((upvotedQuestionIDs) => [
        ...upvotedQuestionIDs,
        questionID,
      ]);

      //Send to server
      await SHOWBOAT.WebSocketController.VoteForQuestion({
        questionID: props.qaItem.ID,
        isUpVote: true,
      });
    }
  };
  /* 
  const modifyUpvoteCountForQuestion = (questionID, incrementOrDecrement) => {
    //Question is in either unapproved or approved lists
    let matchingQuestionID;

    //TODO: probably can raise socket event of some sort
  }; */

  const handlePassClick = async () => {
    setAnchorEl(null);

    await SHOWBOAT.WebSocketController.UpdateQuestionStatus({
      questionID: props.qaItem.ID,
      newStatus: SHOWBOAT.QuestionStatus.Deleted,
    });
  };

  const handleDoneClick = async () => {
    setAnchorEl(null);

    await SHOWBOAT.WebSocketController.UpdateQuestionStatus({
      questionID: props.qaItem.ID,
      newStatus: SHOWBOAT.QuestionStatus.Answered,
    });
  };

  const handleRestoreClick = async () => {
    //Send approved or unapproved event, depending on moderation status
    await SHOWBOAT.WebSocketController.UpdateQuestionStatus({
      questionID: props.qaItem.ID,
      newStatus: attendeesSeeAllQs
        ? SHOWBOAT.QuestionStatus.Approved
        : SHOWBOAT.QuestionStatus.Unapproved,
    });
  };

  const handleReplyClick = () => {
    //Close menu
    setAnchorEl(null);

    //Ensure we are a moderator
    if (qaRole === UIHelper.QARole.MODERATOR) {
      //Show reply text field
      setShowReply(true);

      //Add previous answer text to text field
      if (props.qaItem.answerText && props.qaItem.answerText !== undefined) {
        setReplyText(props.qaItem.answerText);
      }
    }
  };

  const handleReplyTextChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();

    //TODO: Answer anonymously?
    await SHOWBOAT.WebSocketController.AnswerQuestion({
      questionID: props.qaItem.ID,
      answerText: replyText,
      isAnonymous: false,
    });

    //Hide reply text box
    setShowReply(false);

    //Hide copy button
    setShowCopyButton(false);
  };

  const handleReplyCancel = () => {
    //Close reply text field and clear it
    setReplyText("");
    setShowReply(false);
  };

  const handleUnapproveClick = async () => {
    //Close menu
    setAnchorEl(null);

    await SHOWBOAT.WebSocketController.UpdateQuestionStatus({
      questionID: props.qaItem.ID,
      newStatus: SHOWBOAT.QuestionStatus.Unapproved,
    });
  };

  const handleApproveClick = async () => {
    //Close menu
    setAnchorEl(null);
    
    await SHOWBOAT.WebSocketController.UpdateQuestionStatus({
      questionID: props.qaItem.ID,
      newStatus: SHOWBOAT.QuestionStatus.Approved,
    });
  };

  const handleOpenMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDisplayQuestionClick = async () => {
    setDisplayLoadingSpinner(true);
    try {
      //Send update to ARS cms server
      const dataSendUpdate = await ARSHelper.sendUpdate(
        StringVariableHelper.ARSType.question,
        {
          askerName:
            displayedQuestionID === props.qaItem.ID
              ? ""
              : props.qaItem.askerName,
          text:
            displayedQuestionID === props.qaItem.ID
              ? ""
              : props.qaItem.questionText,
        }
      );

      setDisplayLoadingSpinner(false);

      if (!dataSendUpdate) {
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error displaying question");
        return;
      }

      await SHOWBOAT.WebSocketController.SetHighlightedQuestionID({
        questionID:
          displayedQuestionID === props.qaItem.ID ? "" : props.qaItem.ID,
      });

      setDisplayedQuestionID(
        displayedQuestionID === props.qaItem.ID ? "" : props.qaItem.ID
      );
    } catch (error) {
      SHOWBOAT.Logger.Log("Error displaying QA item:", error);
      setDisplayLoadingSpinner(false);
      SHOWBOAT.UIEventManager.OnUIError.Raise("Error displaying question");
    }
  };

  const classes = QAItemStyles();

  const renderBottomRightMenu = () => {
    //If presenter and not in moderator view, show "Pass" and "Done"
    if (
      SHOWBOAT.LocalAvatarDataManager.role ===
        StringVariableHelper.ShowboatRoles.presenter &&
      !ServerHelper.useMobileApp
    ) {
      //As a presenter, the user can change roles
      if (qaRole === UIHelper.QARole.MODERATOR) {
        //MODERATOR ROLE

        //Moderators can change tabs
        //Tabs each have different bottom-right buttons for QA Items

        //For both DISMISSED & ANSWERED, show RESTORE button
        if (
          props.tab === UIHelper.QATabEnum.PASS ||
          props.tab === UIHelper.QATabEnum.DONE
        ) {
          return (
            <div className={styles.bottomRightButtonHolder}>
              <Button
                className={classes.qaButton}
                onClick={handleRestoreClick}
                variant="text"
              >
                RESTORE
              </Button>
            </div>
          );
        }
        //For approved tab and open tab, show expandable menu button
        else {
          return (
            <div className={styles.bottomRightButtonHolder}>
              {props.qaItem.status === "approved" && (
                <React.Fragment>
                  {displayLoadingSpinner && (
                    <CircularProgress
                      className={classes.displayLoadingSpinner}
                    />
                  )}

                  <IconButton
                    onClick={handleDisplayQuestionClick}
                    className={
                      displayedQuestionID === props.qaItem.ID
                        ? `${classes.qaDisplayButton} ${classes.qaDisplayButtonSelected}`
                        : classes.qaDisplayButton
                    }
                    disabled={displayLoadingSpinner}
                  >
                    <img
                      src="assets/images/PublishIcon.svg"
                      alt="qa publish"
                      className={styles.qaPublishIcon}
                    />
                  </IconButton>
                </React.Fragment>
              )}

              <IconButton
                onClick={handleOpenMenuClick}
                className={classes.openMenuIconButton}
              >
                <OpenMenuIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                classes={{
                  paper: classes.menuPaper,
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleReplyClick}
                >
                  Reply
                </MenuItem>
                {!attendeesSeeAllQs && props.qaItem.status === "unapproved" && (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleApproveClick}
                  >
                    Approve
                  </MenuItem>
                )}
                {!attendeesSeeAllQs && props.qaItem.status === "approved" && (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleUnapproveClick}
                  >
                    Unapprove
                  </MenuItem>
                )}
                <MenuItem
                  className={classes.menuItem}
                  onClick={handlePassClick}
                >
                  Pass
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleDoneClick}
                >
                  Done
                </MenuItem>
              </Menu>
            </div>
          );
        }
      } else if (qaRole === UIHelper.QARole.PRESENTER) {
        //PRESENTER ROLE
        return (
          <div className={styles.bottomRightButtonHolder}>
            <Button
              className={classes.qaButton}
              onClick={handlePassClick}
              variant="text"
            >
              PASS
            </Button>

            <Button
              className={classes.qaButton}
              onClick={handleDoneClick}
              variant="text"
            >
              DONE
            </Button>
          </div>
        );
      } else {
        //ATTENDEE ROLE
      }
    } else {
      //As a non-presenter, there are no bottom-right buttons
      return <div></div>;
    }
  };

  return (
    <Paper
      className={
        ServerHelper.useMobileApp
          ? `${classes.qaItemHolder} ${classes.qaItemHolderMobile}`
          : classes.qaItemHolder
      }
      id={props.qaItem.ID}
      key={props.qaItem.ID}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showCopyButton && !ServerHelper.useMobileApp && (
        <IconButton
          onClick={handleCopy}
          className={classes.copyButton}
          style={{
            visibility: showCopyButton ? "visible" : "hidden",
            top: buttonTop + containerHeight / 2 - 16,
            left: window.innerWidth - 37,
          }}
        >
          {copied ? (
            <CheckIcon className={classes.buttonIcon}></CheckIcon>
          ) : (
            <span
              className="material-icons"
              style={{ color: "black", fontSize: "20px" }}
            >
              content_copy
            </span>
          )}
        </IconButton>
      )}

      <Box
        className={
          ServerHelper.useMobileApp
            ? `${classes.qaMessageHeader} ${classes.qaMessageHeaderMobile}`
            : classes.qaMessageHeader
        }
      >
        <Typography className={classes.qaName} variant="body1">
          {props.qaItem.askerName}
        </Typography>

        <Typography
          className={
            ServerHelper.useMobileApp
              ? `${classes.qaTime} ${classes.qaTimeMobile}`
              : classes.qaTime
          }
          variant="body1"
        >
          {getMessageDateFormatted()}
        </Typography>
      </Box>

      <Typography
        variant="body1"
        className={
          ServerHelper.useMobileApp
            ? `${classes.qaBody} ${classes.qaBodyMobile} selectableText`
            : `${classes.qaBody} selectableText`
        }
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target={
                decoratedHref.includes("visit.showboat.live")
                  ? "_self"
                  : "_blank"
              }
              href={UIHelper.checkAddBypass(decoratedHref)}
              key={key}
              style={{ wordWrap: "break-word" }}
            >
              {decoratedText}
            </a>
          )}
        >
          {props.qaItem.questionText}
        </Linkify>
      </Typography>

      <div
        className={
          ServerHelper.useMobileApp
            ? `${styles.qaBottomHolder} ${styles.qaBottomHolderMobile}`
            : styles.qaBottomHolder
        }
      >
        {props.tab === UIHelper.QATabEnum.APPROVED ||
        props.tab === UIHelper.QATabEnum.PENDING ? (
          <React.Fragment>
            <IconButton
              className={classes.upvoteButton}
              onClick={handleUpvoteClick}
              disabled={qaRole === UIHelper.QARole.MODERATOR}
            >
              {upvotedQuestionIDs.includes(props.qaItem.ID) ? (
                <ThumbIcon />
              ) : (
                <ThumbIconOutlined />
              )}
            </IconButton>

            <Typography variant="body1" className={classes.upvoteCount}>
              {props.qaItem.votes}
            </Typography>
          </React.Fragment>
        ) : (
          <div></div>
        )}

        {/* Show correct bottom-right menu items based on tab/role */}
        {renderBottomRightMenu()}
      </div>

      {/* If we are in Attendee view and question has reply text, show the reply text */}
      {props.qaItem.answerText !== undefined &&
        (qaRole !== UIHelper.QARole.PRESENTER || ServerHelper.useMobileApp) && (
          <div className={styles.answerTextHolder}>
            <Box className={classes.answerAdornment}></Box>

            <Typography variant="body1" className={classes.answerName}>
              {props.qaItem.answererName}
            </Typography>

            <Typography
              variant="body1"
              className={
                ServerHelper.useMobileApp
                  ? `${classes.answerText} ${classes.answerTextMobile}`
                  : classes.answerText
              }
            >
              {props.qaItem.answerText}
            </Typography>
          </div>
        )}

      {/* If reply is true, show the reply text field */}
      {showReply && (
        <div className={styles.replyHolder}>
          <TextField
            multiline
            rows={3}
            value={replyText}
            variant="outlined"
            onChange={handleReplyTextChange}
            className={classes.qaTextField}
            placeholder="Reply to Question"
            inputProps={{
              maxLength: 240,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleReplySubmit(e);
              }
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.noBorder,
                root: classes.qaInput,
                inputAdornedEnd: classes.qaInputAdornedEnd,
                input: classes.qaInputInput,
              },
              type: "text",
            }}
          />

          <div className={styles.replyBottomHolder}>
            <Typography variant="body1" className={classes.characterCount}>
              {`${replyText.length} / 240`}
            </Typography>

            <div className={styles.replyButtonHolder}>
              <Button
                variant="text"
                onClick={handleReplyCancel}
                className={classes.replyButton}
              >
                CANCEL
              </Button>
              <Button
                variant="text"
                onClick={handleReplySubmit}
                className={classes.replyButton}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      )}
    </Paper>
  );
}
