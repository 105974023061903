import { makeStyles, createStyles } from "@material-ui/core/styles";

const MovePopupItemStyles = makeStyles((theme: any) =>
  createStyles({
    attendeeHolder: {
      width: "100%",
      height: 50,
      marginTop: 4,
      position: "relative",
      textAlign: "left",
      backgroundColor: theme.palette.controlCenterButton.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    attendeeName: {
      position: "absolute",
      width: 217,
      top: 5,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      left: 47,
      fontSize: 16,
      fontWeight: 500,
    },
    attendeeCompany: {
      fontSize: 12,
      position: "absolute",
      left: 47,
      top: 26,
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 217,
      color: theme.palette.attendeeCompany.main,
    },
    attendeeCheckbox: {
      position: "absolute",
      left: 2,
      top: 4.5
    }
  })
);

export { MovePopupItemStyles };
