import { makeStyles, createStyles } from '@material-ui/core/styles';

const BioIntakeStyles = makeStyles((theme: any) => createStyles({
  bioIntakeWrapper: {
    display: "inline-block",
    position: "relative",
    left: 200,
    top: 133,
    width: 560,
    minHeight: 582,
    backgroundColor: theme.palette.intakePaper.main,
    textAlign: "left",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      left: 162,
      width: 400,
    }
  },
  formHeader: {
    fontSize: 24,
    color: theme.palette.text.primary,
    position: "relative",
    top: 34,
    fontFamily: "Open Sans, sans-serif",
    left: 50,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      left: 30,
    }
  },
  bioForm: {
    
  },
  
})
)

export { BioIntakeStyles };