import React, { ReactElement } from "react";

import styles from "./IntakeContentHolder.module.css";

export default function IntakeContentHolder(props): ReactElement {
  return (
    <div
      style={{
        marginBottom: props.marginBottom,
        position: props.positionRelative ? "relative" : "initial",
        height: props.autoHeight ? "auto" : "100%"
      }}
      className={styles.intakeHolder}
    >
      {props.children}
    </div>
  );
}
