import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarSidebarStyles = makeStyles((theme: any) => createStyles({
  calendarHeaderHolder: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    width: "100%",
    height: 40,
  },
  calendarHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
    color: "white"
  },
  calendarIcon: {
    position: "absolute",
    left: 10,
    top: 7,
    color: "white"
  },
  closeIconButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
})
)

export { CalendarSidebarStyles };