import { MenuItem, Paper, Select, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import SortIcon from "@material-ui/icons/Sort";

import { UIHelper } from "../../../../../Utilities/UIHelper";

import { QASortSelectStyles } from "./QASortSelectStyles";

interface Props {
  sort: string;
  handleSortChange: (sort: string) => void;
}

export default function QASortSelect(props: Props): ReactElement {
  const handleSortChange = (e) => {
    props.handleSortChange(e.target.value);
  };

  const classes = QASortSelectStyles();

  return (
    <Paper className={classes.qaSortHolder}>
      <SortIcon className={classes.sortIcon}></SortIcon>

      <Select
        value={props.sort}
        onChange={handleSortChange}
        className={classes.qaSelect}
        classes={{
          icon: classes.qaSelectIcon,
        }}
        disableUnderline
      >
        <MenuItem
          className={classes.qaSelectItem}
          value={UIHelper.QASortEnum.NEWEST}
        >
          NEWEST
        </MenuItem>
        <MenuItem
          className={classes.qaSelectItem}
          value={UIHelper.QASortEnum.UPVOTES}
        >
          UPVOTES
        </MenuItem>
      </Select>
    </Paper>
  );
}
