import { IconButton } from "@material-ui/core";
import React, { useContext } from "react";
import HangUpIcon from "@material-ui/icons/CallEnd";
import SettingsIcon from "@material-ui/icons/Settings";

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";

import { MobileHeaderMenuStyles } from "./MobileHeaderMenuStyles";
import styles from "./MobileHeaderMenu.module.css";

type Props = {
  handleCloseMenu: () => void;
};

export default function MobileHeaderMenu(props: Props) {
  const {
    setShowMobileLeaveMeeting,
    setShowMobileSettings,
    setMobileShowSendPrivateMessage,
    setShowNewQAItem,
    setShowNewMessage,
  }: MobileContextType = useContext(MobileContext);

  const handleHangUpClick = () => {
    //Close other overlays
    setMobileShowSendPrivateMessage(false);
    setShowNewQAItem(false);
    setShowNewMessage(false);

    //Show leave meeting overlay
    setShowMobileLeaveMeeting(true);

    //Close the header menu
    props.handleCloseMenu();
  };

  const handleSettingsClick = () => {
    //Close other overlays
    setMobileShowSendPrivateMessage(false);
    setShowNewQAItem(false);
    setShowNewMessage(false);

    //Show settings overlay
    setShowMobileSettings(true);

    //Close the header menu
    props.handleCloseMenu();
  };

  const classes = MobileHeaderMenuStyles();

  return (
    <div className={styles.mobileHeaderMenuHolder}>
      <IconButton
        className={classes.mobileHeaderButton}
        onClick={handleHangUpClick}
      >
        <HangUpIcon />
      </IconButton>
      <IconButton
        className={`${classes.mobileHeaderButton} ${classes.mobileHeaderButtonSettings}`}
        onClick={handleSettingsClick}
      >
        <SettingsIcon />
      </IconButton>
    </div>
  );
}
