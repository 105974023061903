import React, { ReactElement } from 'react'

interface Props {
  disabled: boolean;
}

export default function ExitIcon(props: Props): ReactElement {
  return (
    <svg
      id="logout_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.disabled ? "rgba(255, 255, 255, 0.3)" : "white"}
    >
      <g id="Group_561" data-name="Group 561">
        <path
          id="Path_2222"
          data-name="Path 2222"
          d="M0,0H24V24H0Z"
          fill="none"
        />
      </g>
      <g id="Group_562" data-name="Group 562">
        <path
          id="Path_2223"
          data-name="Path 2223"
          d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z"
          fill={props.disabled ? "rgba(255, 255, 255, 0.3)" : "white"}
        />
      </g>
    </svg>
  );
}
