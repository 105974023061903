import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './ExitEvent.module.css';
import { ExitEventStyles } from './ExitEventStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function ExitEvent(props: Props): ReactElement {
  
  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = ExitEventStyles();

  return (
    <React.Fragment>

      {props.currentSection === 6 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>
          
          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }

      <img 
        alt="Exit event button" 
        className={styles.exitEventImage} 
        src={`assets/images/ExitEvent${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography
        variant="body1"
        className={classes.exitEventText}
      >
        Press the call icon.
      </Typography>
      
      <Typography
        variant="body1"
        className={classes.exitEventText}
      >
        Confirm you want to leave the event by pressing the EXIT button.
      </Typography>

    </React.Fragment>
  )
}
