import { Badge, IconButton } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import BoxIcon from "@material-ui/icons/Crop54";
import ChatIcon from "@material-ui/icons/Chat";
import PeopleIcon from "@material-ui/icons/People";
import MailIcon from "@material-ui/icons/Mail";
import PresenterFeedIcon from "@material-ui/icons/AccountBoxOutlined";

import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";
import { ServerHelper } from "../../../../Utilities/ServerHelper";
import MobileMessagesBadge from "../../MobileMessages/MobileMessagesBadge/MobileMessagesBadge";
import {
  MessageContext,
  MessageContextType,
} from "../../../../context/MessageContext";
import MobilePlusButton from "../../MobilePlusButton/MobilePlusButton";

import styles from "./MobileToolbar.module.css";
import { MobileToolbarStyles } from "./MobileToolbarStyles";

type Props = {};

export default function MobileToolbar({}: Props) {
  const {
    currentMobileMode,
    setCurrentMobileMode,
    mobileJumbotronView,
    setMobileJumbotronView,
  }: MobileContextType = useContext(MobileContext);

  const handleShowJumbotronViewClick = () => {
    setMobileJumbotronView(!mobileJumbotronView);
  };

  const { newMessage }: MessageContextType = useContext(MessageContext);

  const classes = MobileToolbarStyles();

  return (
    <React.Fragment>
      <div className={styles.mobileToolbarHolder}>
        {(currentMobileMode === StringVariableHelper.MobileModeValues.chat ||
          currentMobileMode === StringVariableHelper.MobileModeValues.qa) && (
          <MobilePlusButton />
        )}

        <div className={styles.mobileToolbarButtonsHolder}>
          {ServerHelper.useMobileHybridApp === false && (
            <React.Fragment>
              <IconButton
                className={
                  mobileJumbotronView
                    ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                    : classes.toolbarButton
                }
                onClick={handleShowJumbotronViewClick}
              >
                <BoxIcon className={classes.boxIcon} />
              </IconButton>

              <IconButton
                className={
                  currentMobileMode ===
                  StringVariableHelper.MobileModeValues.presenterFeeds
                    ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                    : classes.toolbarButton
                }
                onClick={() =>
                  setCurrentMobileMode(
                    currentMobileMode ===
                      StringVariableHelper.MobileModeValues.presenterFeeds
                      ? StringVariableHelper.MobileModeValues.none
                      : StringVariableHelper.MobileModeValues.presenterFeeds
                  )
                }
              >
                <PresenterFeedIcon />
              </IconButton>
            </React.Fragment>
          )}

          <IconButton
            className={
              currentMobileMode === StringVariableHelper.MobileModeValues.chat
                ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                : classes.toolbarButton
            }
            onClick={() =>
              setCurrentMobileMode(
                currentMobileMode === StringVariableHelper.MobileModeValues.chat
                  ? StringVariableHelper.MobileModeValues.none
                  : StringVariableHelper.MobileModeValues.chat
              )
            }
          >
            <ChatIcon className={classes.chatIcon} />
            {newMessage && <Badge className={classes.badge}></Badge>}
          </IconButton>

          <IconButton
            className={
              currentMobileMode ===
              StringVariableHelper.MobileModeValues.attendees
                ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                : classes.toolbarButton
            }
            onClick={() =>
              setCurrentMobileMode(
                currentMobileMode ===
                  StringVariableHelper.MobileModeValues.attendees
                  ? StringVariableHelper.MobileModeValues.none
                  : StringVariableHelper.MobileModeValues.attendees
              )
            }
          >
            <PeopleIcon />
          </IconButton>

          <IconButton
            className={
              currentMobileMode ===
              StringVariableHelper.MobileModeValues.messages
                ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                : classes.toolbarButton
            }
            onClick={() =>
              setCurrentMobileMode(
                currentMobileMode ===
                  StringVariableHelper.MobileModeValues.messages
                  ? StringVariableHelper.MobileModeValues.none
                  : StringVariableHelper.MobileModeValues.messages
              )
            }
          >
            <MobileMessagesBadge />

            <MailIcon />
          </IconButton>

          {ServerHelper.enableQA && (
            <IconButton
              className={
                currentMobileMode === StringVariableHelper.MobileModeValues.qa
                  ? `${classes.toolbarButton} ${classes.toolbarButtonActive}`
                  : classes.toolbarButton
              }
              onClick={() =>
                setCurrentMobileMode(
                  currentMobileMode === StringVariableHelper.MobileModeValues.qa
                    ? StringVariableHelper.MobileModeValues.none
                    : StringVariableHelper.MobileModeValues.qa
                )
              }
            >
              <img
                src="assets/images/QAIcon.svg"
                alt="qa icon"
                style={{
                  width: "24px",
                  height: "auto",
                }}
              />
            </IconButton>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
