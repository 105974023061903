import { makeStyles, createStyles } from '@material-ui/core/styles';

const PhotoPopupStyles = makeStyles((theme: any) =>
  createStyles({
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: 30,
      width: "100%",
      backgroundColor: theme.palette.intakeBackground.main,
    },
    photoPreviewHeaderHolder: {
      backgroundColor: theme.palette.plain.main,
      position: "absolute",
      top: 1,
      left: 0,
      width: "100%",
      height: 42,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 10,
      minWidth: 355,
      borderTopRightRadius: 10,
    },
    photoPreviewHeader: {
      fontSize: 14,
      fontWeight: 500,
      position: "absolute",
      top: 14,
      left: 22,
    },
    closeButton: {
      position: "absolute",
      top: 4,
      right: 2,
      padding: 4,
      color: theme.palette.text.primary,
    },
    photoPreviewContentHolder: {
      position: "relative",
      backgroundColor: theme.palette.background.default,
      height: "auto",
      top: 42,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      textAlign: "center",
      minHeight: 200,
      marginBottom: 42,
      minWidth: 355,
    },
    photoButton: {
      margin: 10,
      color: "white",
    },
    successMessage: {
      color: theme.palette.text.primary,
      width: "85%",
      display: "inline-block",
      top: 40,
      position: "relative",
      fontSize: 20,
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none !important"
    },
    loadingSpinner: {
      width: "25px !important",
      height: "25px !important",
      position: "relative",
      color: "grey",
      bottom: 22,
    },
    photoButtonKeep: {
      backgroundColor: "#3DAF00",
      color: "white",
      "&:hover": {
        backgroundColor: "white",
        color: "#3DAF00",
      },
    },
    photoButtonDelete: {
      backgroundColor: "red",
      color: "white",
      "&:hover": {
        backgroundColor: "white",
        color: "red",
      },
    },
    viewAlbumButton: {
      backgroundColor: "#2D2D2E",
      width: 125,
      textTransform: "none",
      margin: 10,
      textDecoration: "none",
      color: "white"
    },
    doneButton: {
      backgroundColor: "#3DAF00",
      textTransform: "none",
      width: 125,
      margin: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#3DAF00",
      },
    },
  })
);

export { PhotoPopupStyles };