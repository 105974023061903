import { makeStyles, createStyles } from '@material-ui/core/styles';

const AvatarSettingsStyles = makeStyles((theme: any) =>
  createStyles({
    colorHolder: {
      backgroundColor: theme.palette.settingsPaper.main,
      height: 275,
      position: "relative",
      borderRadius: 4,
      marginTop: 5,
      marginBottom: 3,
      "@media (max-height: 800px)": {
        height: 255,
      },
    },
    faceHolder: {
      backgroundColor: theme.palette.settingsPaper.main,
      position: "relative",
      height: 328,
      borderRadius: 4,
      "@media (max-height: 800px)": {
        height: 270,
      },
    },
    settingsHeader: {
      fontSize: 14,
      left: 4,
      fontWeight: "bold",
      position: "relative",
      float: "left",
      letterSpacing: ".1em",
    },
    faceText: {
      fontSize: 16,
      position: "absolute",
      left: 14,
      bottom: 25,
      textAlign: "left",
      "@media (max-height: 800px)": {
        fontSize: 14,
        bottom: 5,
      },
    },
    colorInputHolder: {
      width: 92,
      height: 92,
      borderRadius: 11,
      boxShadow: "none",
      backgroundColor: theme.palette.colorBorder.main,
      marginRight: 5,
      marginBottom: 5,
      position: "relative",
      boxSizing: "border-box",
      pointerEvents: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    colorInputHolderSelected: {
      border: "4px solid",
      borderColor: theme.palette.colorSelectedBorder.main,
      boxSizing: "border-box",
    },
    faceAvatar: {
      width: 78,
      height: 78,
      borderRadius: 5,
      position: "absolute",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      outline: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    faceAvatarSelected: {},
    selectedAvatarHolder: {
      border: "4px solid white",
      borderColor: theme.palette.avatarSelectedBorder.main,
      boxSizing: "border-box",
    },
    faceAvatarHolder: {
      width: 92,
      height: 92,
      borderRadius: 10,
      backgroundColor: theme.palette.avatarBorder.main,
      position: "relative",
      marginRight: 5,
      marginBottom: 5,
      outline: "none",
    },
    saveButton: {
      backgroundColor: "#00B6FF",
      position: "absolute",
      bottom: 23,
      left: 14,
      padding: 0,
      width: 103,
      height: 34,
      "&:hover": {
        backgroundColor: "#D5D5D5",
      },
    },
    nameTagInput: {
      marginBottom: 13,
      backgroundColor: theme.palette.nameTagInputField.main,
    },
    loadingSpinner: {
      height: "25px !important",
      width: "25px !important",
      color: "grey",
      position: "absolute",
      left: 138,
      bottom: 45,
    },
    checkIcon: {
      color: "#3DAF00",
      position: "absolute",
      left: 136,
      bottom: 28,
      fontSize: 29,
    },
    nameTagHolder: {
      "@media (max-height: 800px)": {
        height: 328
      }
    }
  })
);

export { AvatarSettingsStyles };