import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";

import BioIntakeForm from "../../BioIntakeForm/BioIntakeForm";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

import { BioIntakeStyles } from "./BioIntakeStyles";

interface Props {
  onBioIntakeComplete: () => any;
}

export default function BioIntake(props: Props): ReactElement {
  //material UI classes
  const classes = BioIntakeStyles();

  return (
    <IntakeContentHolder marginBottom={"0px"}>
      <Typography variant="h1" classes={{ root: classes.formHeader }}>
        Enter your information
      </Typography>

      <BioIntakeForm
        standalone={false}
        onBioFormSubmit={props.onBioIntakeComplete}
      />
    </IntakeContentHolder>
  );
}
