import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import $ from "jquery";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import PrivateMessage from "./PrivateMessage/PrivateMessage";
import OneOnOneChatBar from "../OneOnOneChatBar/OneOnOneChatBar";

import styles from "./PrivateMessagesWrapper.module.css";
import {
  OneOnOneChatContext,
  OneOnOneChatContextType,
} from "../../../../context/OneOnOneChatContext";
import { ServerHelper } from "../../../../Utilities/ServerHelper";

interface Props {}

interface ReflowObj {
  operation: string;
  height: number;
  elementID: string;
}

interface ReflowHeightObj {
  elementID: string;
  height: number;
}

export default function PrivateMessagesWrapper(props: Props): ReactElement {
  const [privateMessages, setPrivateMessages] = useState([]);

  const [rightOffset, setRightOffset] = useState(10);

  const { isChatOff, isOneOnOneChatOpen }: OneOnOneChatContextType =
    useContext(OneOnOneChatContext);

  const { currentSidebarMode }: AppContextType = useContext(AppContext);

  const chatSettingRef = useRef();
  const msgID = useRef(0);

  const reflowQueue = useRef([] as Array<ReflowObj>);
  const privateMessageHeights = useRef([] as Array<ReflowHeightObj>);
  const reflowInProgress = useRef(false);
  const currentBottom = useRef(0);
  const reflowTimeout = useRef(null);

  //Adds an "add" animation to the reflow queue
  const add = (id: string, height: number) => {
    reflowQueue.current.push({
      operation: "add",
      elementID: id,
      height: height + 10,
    });
    doReflow();
  };

  //Adds a "modify" animation to the reflow queue
  const modify = (id: string, height: number) => {
    //Modify element's stashed height to its' new height
    for (let i = 0; i < privateMessageHeights.current.length; ++i) {
      if (privateMessageHeights.current[i].elementID == id) {
        privateMessageHeights.current[i].height = height;
      }
    }

    //Queue animation with the element's new height
    reflowQueue.current.push({
      operation: "modify",
      height: height,
      elementID: id,
    });
    doReflow();
  };

  //Adds a "remove" downward animation to the reflow queue
  const remove = (elementID: string, msgNumber: number) => {
    //Remove the private message from state
    setPrivateMessages(privateMessages.filter((msg) => msg.id !== msgNumber));

    //Remove this elements stashed height
    privateMessageHeights.current = privateMessageHeights.current.filter(
      (item) => item.elementID != elementID
    );

    reflowQueue.current.push({
      operation: "remove",
      height: 0,
      elementID: elementID,
    });
    doReflow();
  };

  const doReflow = () => {
    //Ensure no simultaneous animations
    if (reflowInProgress.current) return;

    //Do nothing if no operations queued
    if (reflowQueue.current.length == 0) {
      return;
    }

    reflowInProgress.current = true;

    //Grab the first queued operation from the reflow queue
    let reflowOperation: ReflowObj = reflowQueue.current.shift();

    //For add operations, stash the element's height
    if (reflowOperation.operation == "add") {
      privateMessageHeights.current.push({
        height: reflowOperation.height,
        elementID: reflowOperation.elementID,
      });
    }

    //Loop through the items in the stashed height array backwards
    let currentBottom: number = 0;
    for (let i = privateMessageHeights.current.length - 1; i >= 0; --i) {
      let reflowHeightObj: ReflowHeightObj = privateMessageHeights.current[i];

      //Animate the stashed item by the currentBottom property
      $(`#${reflowHeightObj.elementID}`).animate(
        {
          bottom: currentBottom,
        },
        300
      );

      currentBottom += reflowHeightObj.height;
    }

    //Set timer so that after animation is done, we check for more queued items
    reflowTimeout.current = setTimeout(function () {
      reflowInProgress.current = false;
      doReflow();
    }, 310);
  };

  //RECEIVE PRIVATE MESSAGE
  const handleReceivePrivateMessage = useCallback(
    async (data: SHOWBOAT.GroupChat) => {
      SHOWBOAT.Logger.Log("Received private message:", data);
      if (chatSettingRef.current) {
        await SHOWBOAT.WebSocketController.SendChatToTarget({
          targetUserID: data.senderUserID,
          message:
            "Sorry, chat is disabled for this attendee and your message was not seen.",
        });

        return;
      } else {
        let newMsg = {
          userID: data.senderUserID,
          message: data.message,
          id: msgID.current,
        };

        setPrivateMessages((privateMessages) => [...privateMessages, newMsg]);

        //Set value in context
        msgID.current = msgID.current + 1;
      }
    },
    []
  );

  useEffect(() => {
    SHOWBOAT.WebSocketController.OnTargetedChat.Add(
      handleReceivePrivateMessage
    );

    return function cleanup() {
      SHOWBOAT.WebSocketController.OnTargetedChat.Remove(
        handleReceivePrivateMessage
      );

      $(".messageCloseButton").off("click");

      if (reflowTimeout.current) {
        clearTimeout(reflowTimeout.current);
      }
    };
  }, [handleReceivePrivateMessage]);

  useEffect(() => {
    //Change right offset of messages depending on what is selected for the sidebar
    let rightOffset;

    switch (currentSidebarMode) {
      case "":
        rightOffset = 10;
        break;
      case "settings":
        rightOffset = 435;
        break;
      case "chat":
      case "QA":
        rightOffset = 315;
        break;
      case "room":
      case "support":
      case "attendees":
      case "calendar":
        rightOffset = 305;
        break;
      case "exit":
        rightOffset = 235;
        break;
    }

    setRightOffset(rightOffset);
  }, [currentSidebarMode]);

  useEffect(() => {
    (chatSettingRef.current as any) = isChatOff;
  }, [isChatOff]);

  return (
    <div
      className={styles.chatMessagesHolder}
      style={{
        right: rightOffset,
      }}
    >
      <div id="privateChatMessageHolder" className={styles.chatMessageWrapper}>
        {privateMessages.map((msg, i) => {
          return (
            <PrivateMessage
              key={msg.id}
              message={msg.message}
              userID={msg.userID}
              id={msg.id}
              privateMessages={privateMessages}
              add={add}
              modify={modify}
              remove={remove}
            />
          );
        })}
      </div>

      {isOneOnOneChatOpen === true ? <OneOnOneChatBar /> : null}
    </div>
  );
}
