import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorPopupStyles = makeStyles((theme: any) => createStyles({
  errorPopup: {
    display: "none",
    position: "absolute",
    textAlign: "left",
    marginBottom: 10,
    left: 0,
    top: 10,
    width: 311,
    height: 70,
    background: theme.palette.background.paper,
    pointerEvents: "all",
    borderRadius: 10,
  },
  errorPopupDisconnect: {
    height: 90,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.primary,
    position: "absolute",
    width: 190,
    wordWrap: "break-word",
    left: 24,
    top: 15,
  },
  dismissButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  errorPopupBackstageTeleport: {
    height: 90,
  }
})
)

export { ErrorPopupStyles };