import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobilePlusButtonStyles = makeStyles((theme: any) =>
  createStyles({
    mobilePlusButton: {
      width: "100%",
      height: 52,
      padding: 0,
      backgroundColor: "white",
      color: "black",
      position: "absolute",
      bottom: 52,
      left: 0,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "white",
      }
    },
    plusIcon: {
      fontSize: 35,
    }
  })
);

export { MobilePlusButtonStyles };