import { makeStyles, createStyles } from '@material-ui/core/styles';

const OneOnOneChatBarStyles = makeStyles((theme: any) => createStyles({
  chatHolder: {
    width: 436,
    height: 132,
    display: "block",
    zIndex: 10,
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    bottom: 0,
    pointerEvents: "all",
    overflowX: "hidden",
    right: 0,
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {
      width: 375,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  },
  chatMessageInputHolder: {
    width: 402,
    height: 82,
    position: "absolute",
    left: 17,
    top: 13,
    borderColor: "#3E4044",
    [theme.breakpoints.down('md')]: {
      width: 342,
    },
    [theme.breakpoints.down('sm')]: {
      width: 265,
    }
  },
  chatMessageInput: {
    backgroundColor: theme.palette.inputBackgroundColor.main,
  },
  emojiSelectHolder: {
    position: "absolute",
    right: 0,
    bottom: 60,
    width: 300,
    pointerEvents: "all",
    "& section": {
      width: "100% !important"
    },
    [theme.breakpoints.down('md')]: {
      width: 250
    },
    [theme.breakpoints.down('sm')]: {
      width: 200,
    }
  },
  emojiIcon: {
    color: theme.palette.emojiIcon.main,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  sendButton: {
    position: "absolute",
    bottom: 5,
    right: 102,
  },
  cancelButton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
  messageSentText: {
    fontSize: 14,
    position: "absolute",
    left: 19,
    top: 19,
  },
  closeButton: {
    position: "absolute",
    width: 93,
    height: 36,
    right: 4,
    top: 12,
  },
  messageSentHolder: {
    position: "absolute",
    right: 0,
    pointerEvents: "all",
    bottom: 0,
    zIndex: 10,
    display: "block",
    width: 343,
    height: 60,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      width: 275,
    }
  },
  chatMessageHelperText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
  }
})
)

export { OneOnOneChatBarStyles };