import React, { ReactElement, useContext } from 'react';
import {Typography} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import StarIcon from '@material-ui/icons/Star';

import { StringVariableHelper } from '../../../../Utilities/StringVariableHelper';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import { PresenterContext, PresenterContextType } from '../../../../context/PresenterContext';

import { PresenterMicStatusTextStyles } from './PresenterMicStatusTextStyles';
import styles from './PresenterMicStatusText.module.css';

export default function PresenterMicStatusText(): ReactElement {
  //access to presenter context
  const { 
    isPresenter 
  } : AppContextType = useContext(AppContext);

  const {
    currentMode,
    isBackstage
  } : PresenterContextType = useContext(PresenterContext);

  const classes = PresenterMicStatusTextStyles();
  
  let presenterPopup = (
    <div className={styles.statusTextHolder}>
      <WarningIcon classes={{ root: classes.micIcon }} />
      <Typography classes={{ root: classes.text}} variant="body1">
        You can be heard by everyone
      </Typography>
    </div>
  );

  let presenterOnlyPopup = (
    <div className={`${styles.statusTextHolder} ${styles.statusTextHolderBackstage}`}>
      <StarIcon classes={{ root: classes.starIcon }} />
      <Typography classes={{ root: `${classes.text} ${classes.textBackstage}`}} variant="body1">
        You are <b>backstage</b>
      </Typography>
    </div>
  )

  if (isPresenter) {
    if (currentMode === StringVariableHelper.ShowboatModeType.presenter && !isBackstage) {
      return (presenterPopup);
    } else if (isBackstage) {
      return (presenterOnlyPopup);
    } else if (currentMode === StringVariableHelper.ShowboatModeType.attendees) {
      return null;
    } else {
      return null;
    }
  } else {
    return (
      null
    )
  }
}
