import { makeStyles, createStyles } from "@material-ui/core/styles";

const RoomNamePopupStyles = makeStyles((theme: any) =>
  createStyles({
    roomNameHeaderHolder: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      borderRadius: 4,
      height: 40,
      position: "relative",
      color: "white",
    },
    roomNameHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 43,
      top: 10,
    },
    locationIcon: {
      position: "absolute",
      top: 7,
      left: 10,
      color: "white",
    },
    roomNameInput: {
      backgroundColor: "#474747",
      position: "relative",
      top: 10,
    },
    closeIconButton: {
      position: "absolute",
      padding: 1,
      right: 5,
      top: 3,
    },
    closeIcon: {
      fontSize: 30,
      color: theme.palette.text.primary,
    },
    button: {
      display: "inline-block",
      backgroundColor: "#00B6FF",
      padding: 0,
      width: 103,
      height: 34,
      bottom: 23,
      position: "relative",
      color: "white",
      "&:hover": {
        backgroundColor: "#00B6FF",
      },
    },
    buttonCancel: {
      backgroundColor: "#464646",
      "&:hover": {
        backgroundColor: "#464646",
      },
    },
    buttonLabel: {
      position: "relative",
      top: 2
    }
  })
);

export { RoomNamePopupStyles };