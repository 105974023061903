import React, { ReactElement } from "react";
import { Typography, Paper } from "@material-ui/core";

import styles from "./FailedLoginErrorPage.module.css";
import { FailedLoginErrorPageStyles } from "./FailedLoginErrorPageStyles";
import { ServerHelper } from "../../../Utilities/ServerHelper";

export default function FailedLoginErrorPage(): ReactElement {
  const classes = FailedLoginErrorPageStyles();

  return (
    <div className={styles.errorPageWrapper}>
      <Paper className={ServerHelper.useMobileApp 
        ? `${classes.errorMessageHolder} ${classes.errorMessageHolderMobile}`
        : classes.errorMessageHolder
      }>
        <img
          className={ServerHelper.useMobileApp
            ? `${styles.showboatLogo} ${styles.showboatLogoMobile}`
            : styles.showboatLogo
          }
          alt="showboat logo"
          src="assets/images/sb-logo.png"
        />

        <Typography variant="body1" className={ServerHelper.useMobileApp
          ? `${classes.errorMessage} ${classes.errorMessageMobile}`
          :  classes.errorMessage
          }>
          An error occurred logging in.
        </Typography>
      </Paper>
    </div>
  );
}
