import { Button, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import PhoneIcon from "@material-ui/icons/CallEnd";

import { UIHelper } from "../../../Utilities/UIHelper";
import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";

import styles from "./MobileLeaveMeeting.module.css";
import { MobileLeaveMeetingStyles } from "./MobileLeaveMeetingStyles";

type Props = {};

export default function MobileLeaveMeeting({}: Props) {
  const { setShowMobileLeaveMeeting }: MobileContextType =
    useContext(MobileContext);

  const handleLeaveMeetingClick = () => {
    UIHelper.handleHangUpClick();
  };

  const handleCancelClick = () => {
    //Close the overlay
    setShowMobileLeaveMeeting(false);
  };

  const classes = MobileLeaveMeetingStyles();

  return (
    <div className={styles.mobileLeaveMeetingWrapper}>
      <Typography className={classes.mobileLeaveMeetingText}>
        LEAVE MEETING
      </Typography>

      <div className={styles.mobileLeaveMeetingButtonHolder}>
        <Button
          variant="contained"
          className={`${classes.mobileLeaveMeetingButton} ${classes.mobileLeaveMeetingButtonExit}`}
          onClick={handleLeaveMeetingClick}
        >
          <PhoneIcon className={classes.phoneIcon} />
          EXIT
        </Button>
        <Button
          className={classes.mobileLeaveMeetingButton}
          onClick={handleCancelClick}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
