import { makeStyles, createStyles } from "@material-ui/core/styles";

const MobileNewQAItemStyles = makeStyles((theme: any) =>
  createStyles({
    newQAItemText: {
      color: "white",
      fontSize: 14,
    },
    qaItemHelperText: {
      fontSize: 14,
      color: "white",
      position: "relative",
      top: 10,
    },
    noBorder: {
      border: "none",
    },
    emojiIcon: {
      position: "relative",
      bottom: 16,
      color: theme.palette.emojiIcon.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    chatInput: {
      fontSize: 14,
      paddingTop: 14,
      "& textarea": {
        height: "100px !important",
      },
    },
    chatInputAdornedEnd: {
      paddingRight: 5,
    },
    qaTextField: {
      width: "100%",
      height: 130,
      top: 15,
      border: "none",
      outline: "none",
      backgroundColor: "#373737",
      borderRadius: 4,
      fontSize: 14,
    },
    anonymousLabel: {
      fontSize: 16,
      color: "white",
    },
    anonymousCheckbox: { position: "relative", top: 18, left: 5 },
    characterCount: {
      fontSize: 12,
      color: "white",
      position: "absolute",
      right: 29,
      top: 221,
    },
    chatButton: {
      padding: "14px 22px",
    },
    chatButtonDismiss: {
      marginLeft: 13,
    },
  })
);

export { MobileNewQAItemStyles };
