import { makeStyles, createStyles } from '@material-ui/core/styles';

const QAHeaderStyles = makeStyles((theme: any) =>
  createStyles({
    qaHeaderHolder: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      minHeight: 40,
      position: "relative",
      height: "auto",
    },
    qaHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 43,
      top: 11,
      color: "white",
    },
    qaIcon: {
      position: "absolute",
      left: 10,
      top: 7,
      color: "white",
    },
    closeIconButton: {
      padding: 4,
      position: "relative",
      top: 3,
      right: 7,
      color: "white",
    },
    closeIconButtonSettingsActive: {
      backgroundColor: "#9B0059",
      "&:hover": {
        backgroundColor: "#9B0059",
      },
    },
    tabs: {
      backgroundColor: "black",
      height: 50,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      position: "relative",
      top: 40,
      marginBottom: 40,
      overflowX: "visible",
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    qaTab: {
      color: "white",
      opacity: 1,
      padding: "6px 12px",
    },
    qaTabNoModeration: {
      padding: "6px 23px",
    },
    flexContainer: {
      display: "inline-block",
    },
  })
);

export { QAHeaderStyles};