import { Button, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { MobileContext, MobileContextType } from '../../../context/MobileContext';
import { ServerHelper } from '../../../Utilities/ServerHelper';

import styles from "./MobileSettings.module.css";
import { MobileSettingsStyles } from "./MobileSettingsStyles";

type Props = {}

export default function MobileSettings({}: Props) {

  const {
    setShowMobileSettings
  } : MobileContextType = useContext(MobileContext);
  
  const [firstName, setFirstName] = useState(
    SHOWBOAT.LocalAvatarDataManager.firstName
  );
  const [lastName, setLastName] = useState(
    SHOWBOAT.LocalAvatarDataManager.lastName
  );
  const [company, setCompany] = useState(
    SHOWBOAT.LocalAvatarDataManager.company
  );
  

  const handleSaveClick = () => {
    //Submit nametag change
    SHOWBOAT.UIEventManager.OnNameTagChanged.Raise(
      firstName,
      lastName,
      company
    );

    SHOWBOAT.LocalAvatarDataManager.firstName = firstName;
    SHOWBOAT.LocalAvatarDataManager.lastName = lastName;
    SHOWBOAT.LocalAvatarDataManager.company = company;
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.NameTag);

    //Close mobile settings
    setShowMobileSettings(false);
  }

  const handleCancelClick = () => {
    //Close mobile settings
    setShowMobileSettings(false);
  } 

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  }

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  }
  
  const classes = MobileSettingsStyles();
  
  return (
    <div className={styles.mobileSettingsWrapper}>
      <Typography className={classes.mobileSettingsText}>
        UPDATE NAME TAG
      </Typography>

      <div className={styles.nameTagChangeHolder}>
        <TextField
          variant="outlined"
          inputProps={{ maxLength: 50 }}
          onChange={handleFirstNameChange}
          value={firstName}
          className={classes.nameTagInput}
          fullWidth
          key="First Name"
          label="First Name"
        />

        <TextField
          variant="outlined"
          inputProps={{ maxLength: 50 }}
          onChange={handleLastNameChange}
          value={lastName}
          className={classes.nameTagInput}
          fullWidth
          key="Last Name"
          label="Last Name"
        />

        <TextField
          variant="outlined"
          inputProps={{ maxLength: 50 }}
          onChange={handleCompanyChange}
          fullWidth
          className={classes.nameTagInput}
          value={company}
          key="Company"
          label="Company"
        />
      </div>

      <div className={styles.buttonHolder}>
        <Button onClick={handleSaveClick}>SAVE</Button>
        <Button onClick={handleCancelClick} className={classes.cancelButton}>
          CANCEL
        </Button>
      </div>
    </div>
  );
}