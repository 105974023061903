import React, { ReactElement, useEffect, useState, useRef } from "react";
import MobileEventHeader from "../../../MobileIntake/MobileEventHeader/MobileEventHeader";

import { ServerHelper } from "../../../Utilities/ServerHelper";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";
import IntakeHeader from "../../IntakeHeader/IntakeHeader";
import WelcomeElement from "../../WelcomeElement/WelcomeElement";

interface Props {
  onWelcomePageComplete: () => void;
}

export default function WelcomePage(props: Props): ReactElement {
  const [failReason, setFailReason] = useState("");

  const isMountedRef = useRef(false);

  //CHECK IF EVENT HASN'T STARTED YET
  useEffect(() => {
    //CHECK SERVER HELPER FOR FAIL REASONS
    if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.DeletedLoginCode
    ) {
      setFailReason(StringVariableHelper.LoginFailReasons.DeletedLoginCode);
    } else if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.NotStarted
    ) {
      setFailReason(StringVariableHelper.LoginFailReasons.NotStarted);
    } else if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.Ended
    ) {
      setFailReason(StringVariableHelper.LoginFailReasons.Ended);
    } else if (
      ServerHelper.loginFailReason ===
      StringVariableHelper.LoginFailReasons.CapacityFull
    ) {
      setFailReason(StringVariableHelper.LoginFailReasons.CapacityFull);
    } else {
      //Set fail reason to null, since there is no fail reason
      setFailReason(null);
    }

    isMountedRef.current = true;
  }, []);

  const handleEnterClick = () => {
    props.onWelcomePageComplete();
  };

  if (isMountedRef.current) {
    return (
      <React.Fragment>
        {!ServerHelper.useMobileApp && (
          <IntakeHeader
            failReason={failReason}
            onEnterClick={handleEnterClick}
            welcome={true}
          />
        )}

        <WelcomeElement
          failReason={failReason}
          onEnterClick={handleEnterClick}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
}
