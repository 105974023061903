import { makeStyles, createStyles } from '@material-ui/core/styles';

const ScreenshareStyles = makeStyles((theme: any) => createStyles({
  screenshareToggle: {
    position: "absolute",
    bottom: "5.5%",
    right: "6%",
  },
  toggleLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  chooseWindowButton: {
    position: "absolute",
    fontSize: 14,
    left: "4.3%",
    bottom: "5.5%"
  },
  checkbox: {
    position: "absolute",
    left: "4%",
    top: "2%",
    color: "white"
  },
  checkboxLabel: {
    color: theme.palette.text.primary
  },
  checkboxBox: {
  }
})
)

export { ScreenshareStyles };