import { Typography } from '@material-ui/core';
import React from 'react';

import styles from "./MobileEventCountdown.module.css";
import { MobileEventCountdownStyles } from './MobileEventCountdownStyles';

type Props = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export default function MobileEventCountdown(props: Props) {

  const classes = MobileEventCountdownStyles();
  
  return (
    <div className={styles.mobileCountdownHolder}>
      <div className={styles.itemHolder}>
        <Typography className={classes.number}>
          {props.days <= 0 ? 0 : props.days}
        </Typography>
        <Typography className={classes.numberLabel}>Days</Typography>
      </div>
      <div className={styles.itemHolder}>
        <Typography className={classes.number}>
          {props.hours <= 0 ? 0 : props.hours}
        </Typography>
        <Typography className={classes.numberLabel}>Hours</Typography>
      </div>
      <div className={styles.itemHolder}>
        <Typography className={classes.number}>
          {props.minutes <= 0 ? 0 : props.minutes}
        </Typography>
        <Typography className={classes.numberLabel}>Minutes</Typography>
      </div>
      <div className={styles.itemHolder}>
        <Typography className={classes.number}>
          {props.seconds <= 0 ? 0 : props.seconds}
        </Typography>
        <Typography className={classes.numberLabel}>Seconds</Typography>
      </div>
    </div>
  );
}