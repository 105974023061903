import React, { useState } from "react";

export interface PopupContextType {
  toolXPosition: number;
  setToolXPosition: (position: number) => void;
  toolYPosition: number;
  setToolYPosition: (position: number) => void;
  toolWidth: number;
  setToolWidth: (width: number) => void;
  toolHeight: number;
  setToolHeight: (height: number) => void;
}

export const PopupContext = React.createContext(null);

export const PopupContextProvider = (props) => {

  const [toolXPosition, setToolXPosition] = useState(null);
  const [toolYPosition, setToolYPosition] = useState(null);

  const [toolWidth, setToolWidth] = useState(null);
  const [toolHeight, setToolHeight] = useState(null);

  let val: PopupContextType = {
    toolXPosition,
    setToolXPosition,
    toolYPosition,
    setToolYPosition,
    toolWidth,
    setToolWidth,
    toolHeight,
    setToolHeight,
  };
  
  return (
    <PopupContext.Provider value={val}>
      {props.children}
    </PopupContext.Provider>
  )
}