import { makeStyles, createStyles } from '@material-ui/core/styles';

const MainAppCanvasStyles = makeStyles((theme: any) =>
  createStyles({
    dragIcon: {
      color: "white",
      zIndex: 9999,
      pointerEvents: "none",
    },
    mainCanvasContentHolder: {
      backgroundColor: "black",
      position: "relative",
    },
  })
);

export { MainAppCanvasStyles };