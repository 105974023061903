import React, { ReactElement, useEffect, useState } from "react";
import { Paper, Typography, Button, Card } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import styles from "./ColorSelect.module.css";
import { ColorSelectStyles } from "./ColorSelectStyles";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";
import { UIHelper } from "../../../Utilities/UIHelper";

interface Props {
  onColorSelectComplete: () => void;
  onColorSelectBackClick: () => void;
}

export default function ColorSelect(props: Props): ReactElement {
  /** state store  */
  const [selectedColor, setSelectedColor] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.color
  );
  /**/

  const avatarColors = SHOWBOAT.ApplicationSkin.primaryAvatarColors;

  useEffect(() => {
    //Set focus in menu on currently-selected color
    document
      .getElementById(
        SHOWBOAT.LocalAvatarDataManager.avatarData.color.toString()
      )
      .focus();
  }, []);

  /* change color handler */
  const handleColorChange = (i: number) => {
    //Send to 3D
    SHOWBOAT.UIEventManager.OnAvatarColorChanged.Raise(i);

    //Store the selection in cache
    localStorage.setItem(
      "preferredColorHex",
      SHOWBOAT.ApplicationSkin.primaryAvatarColors[i]
    );

    setSelectedColor(i);
  };
  /**/

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13) {
      props.onColorSelectComplete();
    }
  };

  //material UI classes
  const classes = ColorSelectStyles();

  return (
    <IntakeContentHolder marginBottom={"230px"} positionRelative={true}>
      <Typography variant="h1" classes={{ root: classes.colorSelectHeader }}>
        Choose a color
      </Typography>

      <form className={styles.colorForm} onKeyDown={onKeyDownHandler}>
        {avatarColors.map((color, i) => {
          if (avatarColors[i] === "") {
            return null;
          } else {
            if (i === selectedColor) {
              return (
                <Paper
                  tabIndex={-1}
                  key={i}
                  style={{ backgroundColor: color }}
                  classes={{ root: classes.colorInputPaperSelected }}
                >
                  <input
                    type="button"
                    onClick={() => handleColorChange(i)}
                    onFocus={() => handleColorChange(i)}
                    className={styles.colorInput}
                    id={i.toString()}
                  ></input>

                  <Card
                    tabIndex={-1}
                    elevation={0}
                    className={classes.selectedIcon}
                  >
                    <CheckIcon classes={{ root: classes.checkIcon }} />
                  </Card>
                </Paper>
              );
            } else {
              return (
                <Paper
                  tabIndex={-1}
                  key={i}
                  style={{ backgroundColor: color }}
                  classes={{ root: classes.colorInputPaper }}
                >
                  <input
                    type="button"
                    onClick={() => handleColorChange(i)}
                    onFocus={() => handleColorChange(i)}
                    className={styles.colorInput}
                    id={i.toString()}
                  ></input>
                </Paper>
              );
            }
          }
        })}
      </form>

      <div className={styles.buttonHolder}>
        <Button
          classes={{ root: classes.backButton }}
          onClick={props.onColorSelectBackClick}
          variant="text"
        >
          BACK
        </Button>
        <Button
          classes={{ root: classes.nextButton }}
          onClick={props.onColorSelectComplete}
          variant="contained"
          id="colorNext"
        >
          NEXT
        </Button>
      </div>
    </IntakeContentHolder>
  );
}
