import { makeStyles, createStyles } from '@material-ui/core/styles';

const BioIntakeFormStyles = makeStyles((theme: any) =>
  createStyles({
    bioInput: {
      width: 460,
      height: 56,
      backgroundColor: theme.palette.intakeInput.main,
      marginBottom: 37,
      [theme.breakpoints.down("sm")]: {
        width: 340,
      },
    },
    bioNext: {
      backgroundColor: theme.palette.intakeBlue.main,
      color: "white",
      height: 46,
      width: 115,
      position: "absolute",
      right: 50,
      bottom: 54,
    },
    bioNextMobile: {
      width: "100%",
      bottom: -67,
      right: 0,
      height: 50,
      borderRadius: 4,
      fontSize: 24,
    },
    bioInputNotRequired: {
      width: 460,
      height: 56,
      backgroundColor: theme.palette.intakeInput.main,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        width: 340,
      },
    },
    errorText: {
      fontSize: 12,
    },
    input: {
      height: 460,
      display: "inline-block",
    },
    bioInputMobile: {
      width: "100%",
      height: 56,
      marginBottom: 37,
      borderRadius: 4,
      backgroundColor: theme.palette.intakeInput.main,
    },
    bioInputMobileNotRequired: {
      width: "100%",
      height: 56,
      marginBottom: 20,
      backgroundColor: theme.palette.intakeInput.main,
      borderRadius: 4,
    },
    bioInputLabel: {
      color: "black",
    },
    inputDisabled: {
      color: "white !important"
    }
  })
);

export { BioIntakeFormStyles };