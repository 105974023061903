import { makeStyles, createStyles } from "@material-ui/core/styles";

const TwoDCamerasBadgeStyles = makeStyles((theme: any) => createStyles({
  iconButton: {
    backgroundColor: "white",
    color: "black",
    fontSize: 41,
    position: "absolute",
    right: 0,
    bottom: 100,
    padding: 0,
    width: 91,
    height: 91,
    "&:hover": {
      backgroundColor: "white",
    }
  },
  badgeText: {
    color: "white",
    fontSize: 19,
    fontFamily: "Roboto Condensed, sans-serif"
  }
}));

export {TwoDCamerasBadgeStyles};
