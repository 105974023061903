import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import { QASettingsStyles } from "./QASettingsStyles";
import styles from "./QASettings.module.css";
import { UIHelper } from "../../../../../Utilities/UIHelper";
import { QAContext, QAContextType } from "../../../../../context/QAContext";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";

interface Props {}

export default function QASettings(props: Props): ReactElement {
  const {
    qaRole,
    setQARole,
    allowAnonymousQs,
    attendeesSeeAllQs,
  }: QAContextType = useContext(QAContext);

  const classes = QASettingsStyles();

  const handleRoleChange = (e) => {
    setQARole(e.target.value);
  };

  const handleAllowAnonymousChange = async () => {
    let toggleState = !allowAnonymousQs;

    SHOWBOAT.Logger.Log("Allow anonymous toggle state:", toggleState)

    await SHOWBOAT.WebSocketController.UpdateGroupVariable({
      variableID: StringVariableHelper.AllowAnonymousQuestionsName,
      permissionKey: "",
      groupType: SHOWBOAT.GroupTypes.booking,
      newData: {
        value: toggleState
      },
    });
  };

  const handleToggleSeeAllQs = async () => {
    let toggleState = !attendeesSeeAllQs;

    //If toggling on, raise server event to set all Qs to approved
    if (toggleState) {
      await SHOWBOAT.WebSocketController.SetAllQuestionsToApproved();
    }

    //Change moderation setting on server
    await SHOWBOAT.WebSocketController.SetQuestionAnswerModerationStatus({
      isModerated: !toggleState,
    });

    //Raise server variable event
    await SHOWBOAT.WebSocketController.UpdateGroupVariable({
      variableID: StringVariableHelper.AttendeesSeeAllQuestionsName,
      permissionKey: "",
      groupType: SHOWBOAT.GroupTypes.booking,
      newData: {
        value: toggleState
      },
    });
  };


  return (
    <Paper className={classes.settingsHolder}>
      <div className={styles.qaViewHolder}>
        <Typography variant="h3" className={classes.qaViewHeader}>
          Q&A VIEW
        </Typography>

        <RadioGroup
          className={classes.viewSelect}
          value={qaRole}
          onChange={handleRoleChange}
        >
          <FormControlLabel
            value={UIHelper.QARole.ATTENDEE}
            control={<Radio classes={{ checked: classes.radioSelected }} />}
            label="Attendee"
          />
          <FormControlLabel
            value={UIHelper.QARole.MODERATOR}
            control={<Radio classes={{ checked: classes.radioSelected }} />}
            label="Moderator"
          />
          <FormControlLabel
            value={UIHelper.QARole.PRESENTER}
            control={<Radio classes={{ checked: classes.radioSelected }} />}
            label="Presenter"
          />
        </RadioGroup>
      </div>

      <div className={`${styles.qaViewHolder} ${styles.qaSettingsHolder}`}>
        <Typography variant="h3" className={classes.qaViewHeader}>
          Q&A SETTINGS
        </Typography>

        <div className={styles.qaSettingsOptions}>
          <FormControlLabel
            value={allowAnonymousQs}
            control={<Switch color="primary" checked={allowAnonymousQs} />}
            label="Allow anonymous Qs"
            labelPlacement="start"
            classes={{
              label: classes.switchLabel,
            }}
            onChange={handleAllowAnonymousChange}
          />

          <Typography variant="body1" className={classes.settingsHelperText}>
            Attendees will be able to submit questions anonymously.
          </Typography>

          <FormControlLabel
            control={<Switch color="primary" checked={!attendeesSeeAllQs} />}
            label="Moderation"
            labelPlacement="start"
            onChange={handleToggleSeeAllQs}
            classes={{
              label: `${classes.switchLabel} ${classes.switchLabelSeeAll}`,
            }}
          />

          <Typography
            variant="body1"
            className={`${classes.settingsHelperText} ${classes.settingsHelperTextLong}`}
          >
            If off, all questions are automatically approved.
          </Typography>
        </div>
      </div>
    </Paper>
  );
}
