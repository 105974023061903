import { Button, Paper } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef } from 'react';
import PlayArrow from '@material-ui/icons/PlayArrow';

import styles from './MobileControls.module.css';
import { MobileControlsStyles } from './MobileControlsStyles';

interface Props {
  
}

export default function MobileControls(props: Props): ReactElement {
  
  const isLeftDown = useRef(false);
  const isRightDown = useRef(false);
  const isDownDown = useRef(false);
  const isUpDown = useRef(false);

  //Handler for touchUp
  const handleTouchUp = () => {
    
    //Check if any presses are active. If so, call corresponding up event
    if (isLeftDown.current) {
      SHOWBOAT.UIEventManager.OnLeftVirtualArrowKeyUp.Raise();
    }
    if (isRightDown.current) {
      SHOWBOAT.UIEventManager.OnRightVirtualArrowKeyUp.Raise();
    }
    if (isDownDown.current) {
      SHOWBOAT.UIEventManager.OnDownVirtualArrowKeyUp.Raise();
    }
    if (isUpDown.current) {
      if (!(isLeftDown.current || isRightDown.current)) {
        SHOWBOAT.UIEventManager.OnUpVirtualArrowKeyUp.Raise();
        isUpDown.current = false;
      }
    }
    
    //Mark all downs to false
    isLeftDown.current = false;
    isRightDown.current = false;
    isDownDown.current = false;
  }
  
  useEffect(() => {
    //Add event listener for touchUp to check for button release
    document.addEventListener("touchend", handleTouchUp);

    /* document.addEventListener("mouseup", handleTouchUp); */

    return function cleanup() {
      document.removeEventListener("touchend", handleTouchUp);
     /*  document.removeEventListener("mouseup", handleTouchUp); */
    }
  }, []);

  //UP
  const handleUpArrowPress = () => {
    isUpDown.current = true;
    SHOWBOAT.UIEventManager.OnUpVirtualArrowKeyDown.Raise();
  };

  //LEFT
  const handleLeftArrowPress = () => {
    isLeftDown.current = true;
    SHOWBOAT.UIEventManager.OnLeftVirtualArrowKeyDown.Raise();
  };

  //DOWN
  const handleDownArrowPress = () => {
    isDownDown.current = true;
    SHOWBOAT.UIEventManager.OnDownVirtualArrowKeyDown.Raise();
  };
  
  //RIGHT
  const handleRightArrowPress = () => {
    isRightDown.current = true;
    SHOWBOAT.UIEventManager.OnRightVirtualArrowKeyDown.Raise();
  };
  
  const classes = MobileControlsStyles();
  
  //Set up controls holder classes
  let arrowKeyHolderClasses : string;

  arrowKeyHolderClasses = `${styles.arrowKeyHolder}`

  return (
    <div className={arrowKeyHolderClasses}>
      {/* Col 1 Row 1 */}
      <Paper style={{ backgroundColor: "transparent" }} elevation={0}>
      </Paper>

      {/* Col 2 Row 1 */}
      <Button className={classes.arrowKeyHolder} onTouchStart={handleUpArrowPress} /* onMouseDown={handleUpArrowPress} */>
        <PlayArrow className={`${classes.icon} ${classes.iconUp}`} />
      </Button>

      {/* Col 3 Row 1 */}
      <Paper style={{ backgroundColor: "transparent" }} elevation={0}>
        
      </Paper>

      {/* Col 1 Row 2 */}
      <Button className={classes.arrowKeyHolder} onTouchStart={handleLeftArrowPress}/*  onMouseDown={handleLeftArrowPress} */>
        <PlayArrow className={`${classes.icon} ${classes.iconLeft}`} />
      </Button>

      {/* Col 2 Row 2 */}
      <Button className={classes.arrowKeyHolder} onTouchStart={handleDownArrowPress} /* onMouseDown={handleDownArrowPress} */>
        <PlayArrow className={`${classes.icon} ${classes.iconDown}`} />
      </Button>

      {/* Col 3 Row 2 */}
      <Button className={classes.arrowKeyHolder} onTouchStart={handleRightArrowPress} /* onMouseDown={handleRightArrowPress} */>
        <PlayArrow className={`${classes.icon} ${classes.iconRight}`} />
      </Button>
    </div>
  )
}
