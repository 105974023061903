import { makeStyles, createStyles } from '@material-ui/core/styles';

const DeviceToggleWarningStyles = makeStyles((theme: any) => createStyles({
  deviceToggleWarningHeader: {
    color: "#00B6FF",
    fontSize: 20,
    position: "relative",
    top: 59,
    left: 118,
    display: "inline-block",
    width: 390,
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      left: 95,
      fontSize: 20,
      width: 265
    }
  },
  warningIcon: {
    position: "absolute",
    top: 64,
    left: 50,
    fontSize: 40,
    color: "#FFB700",
    [theme.breakpoints.down('sm')]: {
      left: 35,
    }
  },
  cameraAndMicButton: {
    padding: 0,
    position: 'absolute',
    display: "inline-block",
    top: 181,
    left: 80,
    width: 269,
    height: 47,
    backgroundColor: theme.palette.intakeBlue.main,
    color: "white",
    [theme.breakpoints.down('sm')]: {
      top: 161,
      left: 35,
    }
  },
  nextButton: {
    padding: 0,
    width: 136,
    height: 47,
    display: "inline-block",
    position: "absolute",
    left: 374,
    top: 181,
    color: "white",
    backgroundColor: "#7B7B7B",
    [theme.breakpoints.down('sm')]: {
      top: 230,
      left: 35,
    }
  }
})
)

export { DeviceToggleWarningStyles };