import React, { ReactElement, useEffect, useState } from "react";

import UnmuteIcon from "@material-ui/icons/VolumeUp";
import MuteIcon from "@material-ui/icons/VolumeOff";

import { AttendeeMuteIconStyles } from "./AttendeeMuteIconStyles";
import { useCallback } from "react";

interface Props {
  teleportSent?: boolean;
  userID: string;
  showVoiceActivity: boolean;
  attendeeRoomID: string;
}

export default function AttendeeMuteIcon(props: Props): ReactElement {
  const [isAttendeeMuted, toggleIsAttendeeMuted] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID) !== undefined
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
          .isForceMuted
      : false
  );

  const [attendeeInDifferentRoom, setAttendeeInDifferentRoom] = useState(false);

  const handleAttendeeMicMuteAndUnmute = useCallback(
    (avatarData: SHOWBOAT.AvatarData) => {
      if (avatarData.userID !== props.userID) return;

      toggleIsAttendeeMuted(avatarData.isForceMuted);
    },
    [props.userID]
  );

  useEffect(() => {
    //Listen in for mic mutes/unmutes for remote players
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.userID,
      SHOWBOAT.ChangeReason.ForceMute,
      handleAttendeeMicMuteAndUnmute
    );

    return function cleanup() {
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.userID,
        SHOWBOAT.ChangeReason.ForceMute,
        handleAttendeeMicMuteAndUnmute
      );
    };
  }, [handleAttendeeMicMuteAndUnmute, props.userID]);

  const handleAttendeeMuteToggle = async (e: any) => {
    let toggleState: boolean = !isAttendeeMuted;

    e.persist();
    if (!e) {
      var eParam: any = window.event;
      eParam.cancelBubble = true;
      if (eParam.stopPropagation) {
        eParam.stopPropagation();
      }
    }
    e.cancelBubble = true;
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    //If we are muting, send a true and show muted icon
    if (toggleState) {
      const forceMuteResponse = await SHOWBOAT.WebSocketController.BasicDirectMessage({
        targetUserIDs: [props.userID],
        payload: {
          forceAudioMute: true,
        },
        messageName: SHOWBOAT.MessageNameEnum.forceMute
      })
      
      SHOWBOAT.Logger.Log("Force mute response:", forceMuteResponse);

      toggleIsAttendeeMuted(true);
    } else {
      const unforceMuteResponse = await SHOWBOAT.WebSocketController.BasicDirectMessage({
        targetUserIDs: [props.userID],
        payload: {
          forceAudioMute: false,
        },
        messageName: SHOWBOAT.MessageNameEnum.unforceMute
      })

      SHOWBOAT.Logger.Log("Unforce mute response:", unforceMuteResponse);
      
      toggleIsAttendeeMuted(false);
    }
  };

  useEffect(() => {
    //Show no voice activity if attendee is in different room
    if (props.attendeeRoomID == SHOWBOAT.LocalAvatarDataManager.roomID) {
      setAttendeeInDifferentRoom(false);
    } else {
      setAttendeeInDifferentRoom(true);
    }
  }, [props.attendeeRoomID])

  const classes = AttendeeMuteIconStyles();

  if (isAttendeeMuted) {
    return (
      <MuteIcon
        className={classes.muteIcon}
        onClick={(e) => handleAttendeeMuteToggle(e)}
      />
    );
  } else {
    return (
      <UnmuteIcon
        className={
          (props.showVoiceActivity && !attendeeInDifferentRoom)
            ? `${classes.muteIcon} ${classes.muteIconVoiceActivity}`
            : classes.muteIcon
        }
        onClick={(e) => handleAttendeeMuteToggle(e)}
      />
    );
  }
}
