import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileSendPrivateMessageStyles = makeStyles((theme: any) =>
  createStyles({
    newMessageHeader: {
      color: "white",
      fontSize: 14,
    },
    privateMessageTextField: {
      width: "100%",
      height: 130,
      top: 19,
      border: "none",
      outline: "none",
      backgroundColor: "#373737",
      borderRadius: 4,
      fontSize: 14,
    },
    privateMessageTextFieldRespond: {},
    noBorder: {
      border: "none",
    },
    chatInput: {
      fontSize: 14,
      paddingTop: 14,
      "& textarea": {
        height: "100px !important",
      },
    },
    chatInputAdornedEnd: {
      paddingRight: 5,
    },
    characterCount: {
      fontSize: 12,
      color: "white",
      position: "absolute",
      right: 29,
      top: 199,
    },
    privateMessageSubtext: {
      fontSize: 12,
      color: "white",
      position: "absolute",
      left: 29,
      top: 199,
    },
    privateMessageButton: {
      padding: "14px 22px",
    },
    privateMessageButtonDismiss: {
      marginLeft: 13,
    },
  })
);

export { MobileSendPrivateMessageStyles };