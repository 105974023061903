import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import MessageIcon from '@material-ui/icons/Email';

import CloseIconButton from "../CloseIconButton/CloseIconButton";

import styles from './PrivateMessageSection.module.css';
import { PrivateMessageSectionStyles } from './PrivateMessageSectionStyles';

interface Props {
  handleSectionClose: () => void
  currentSection: number
}

export default function PrivateMessageSection(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = PrivateMessageSectionStyles();
  
  return (  
    <React.Fragment>

      {props.currentSection === 3 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>

          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }
      
      
      <img 
        className={styles.privateMessageImage} 
        alt="People button" 
        src={`assets/images/FindPeople${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography 
        variant="body1" 
        className={classes.privateMessageText}
        style={{
          width: "260px"
        }}
      >
        Press the directory button, then search for and find the attendee you wish to communicate with.
      </Typography>

      <MessageIcon className={classes.messageIcon} />

      <Typography variant="body1" className={classes.privateMessageText}>
        Press the envelope next to the attendee name to start a private message.
      </Typography>

      <Typography 
        variant="body1" 
        className={classes.privateMessageText}
        style={{
          width: "250px"
        }}
      >
        These messages can only be seen by the attendee and are not archived.
      </Typography>

    </React.Fragment>
  )
}
