import { makeStyles, createStyles } from '@material-ui/core/styles';

const IntakeStepperStyles = makeStyles((theme: any) => createStyles({
  intakeStepperWrapper: {
    width: 960,
    height: 85,
    position: "relative",
    top: 100,
    display: "inline-block",
    backgroundColor: theme.palette.intakePaper.main,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 720,
    }
  },
  stepper: {
    backgroundColor: "transparent",
    position: "relative",
    bottom: 5,
  },
  circleIcon: {
    height: 15,
    width: 15,
    position: "relative",
    top: 5,
  },
  circleIconConfirm: {
    height: 8,
    width: 11,
    position: "relative",
    top: 7.75,
  },
  circleIconActive: {
    color: `${theme.palette.intakeBlue.main} !important`,
  },
  circleIconCompleted: {
    color: `${theme.palette.text.primary} !important`,
  },
  circleIconText: {
    display: "none !important",
  },
  
  connector: {
    borderTopWidth: 2,
    
  },
  connectorCompleted: {
    "& $line": {
      borderColor: theme.palette.intakeBlue.main,
    }
  }
})
)

export { IntakeStepperStyles };