import React, {
    ReactElement,
    useContext,
    useEffect,
    useState,
    useRef,
} from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import Linkify from "react-linkify";
import ErrorIcon from "@material-ui/icons/Error";
import { Textfit } from "react-textfit";

import { ServerHelper } from "../../Utilities/ServerHelper";
import { IntakeContext, IntakeContextType } from "../../context/IntakeContext";

import styles from "./IntakeHeader.module.css";
import { IntakeHeaderStyles } from "./IntakeHeaderStyles";
import { StringVariableHelper } from "../../Utilities/StringVariableHelper";

interface Props {
    welcome?: boolean;
    failReason?: string;
    onEnterClick?: () => any;
    handleHomeClick?: () => any;
}

export default function IntakeHeader(props: Props): ReactElement {
    const { unblockEnter, disableButtons }: IntakeContextType =
        useContext(IntakeContext);

    const [showTooltip, toggleShowTooltip] = useState(false);

    const [buttonDisable, toggleButtonDisable] = useState(true);

    const [ disableHome, setDisableHome ] = useState(true);

    const tooltipTimerRef = useRef(null);

    const homeDisableTimeoutRef = useRef(null);

    useEffect(() => {
        //Check if button should be disabled
        if (
            ServerHelper.loginFailReason !==
            StringVariableHelper.LoginFailReasons.NotStarted
        ) {
            toggleButtonDisable(false);
        }

        //Tooltip event listener
        if (tooltipTimerRef.current) {
            clearTimeout(tooltipTimerRef.current);
        }

        return function cleanup() {
            if (tooltipTimerRef.current) {
                clearTimeout(tooltipTimerRef.current);
            }
        }
    }, []);

    useEffect(() => {
        //As soon as "enter" is clicked, disable home button for a second
        //This remedies a possible 3D error from clicking home too fast
        if (!props.welcome) {
            homeDisableTimeoutRef.current = setTimeout(function() {
                setDisableHome(false);
            }, 1000)
        }

        return function cleanup() {
            if (homeDisableTimeoutRef.current) {
                clearTimeout(homeDisableTimeoutRef.current);
            }
        }
    }, [props.welcome])

    const handleHomeClick = () => {
        props.handleHomeClick();
    };

    const handleShowTooltip = () => {
        if (
            ServerHelper.loginFailReason !==
                StringVariableHelper.LoginFailReasons.NotStarted ||
            unblockEnter
        ) {
            return;
        }

        if (tooltipTimerRef.current) {
            clearTimeout(tooltipTimerRef.current);
        }

        toggleShowTooltip(true);
        tooltipTimerRef.current = setTimeout(function () {
            toggleShowTooltip(false);
        }, 2000);
    };

    const handleEnterClick = () => {
        if (tooltipTimerRef.current) {
            clearTimeout(tooltipTimerRef.current);
        }

        props.onEnterClick();
    };

    const checkIfHideEnterButton = (): boolean => {
        //If fail reason is DeletedLoginCode, hide enter button
        if (
            ServerHelper.loginFailReason ===
            StringVariableHelper.LoginFailReasons.DeletedLoginCode
        )
            return true;

        //Do not hide for any other fail reasons aside from EventEnded (or no fail reason)
        if (
            ServerHelper.loginFailReason !==
            StringVariableHelper.LoginFailReasons.Ended
        )
            return false;

        //FailReason is equal to "Ended"

        //Check if doors closed is enabled and if the current time is after that time

        //Hide the button if we are not using doors closed time
        if (!ServerHelper.enableDoors) return true;

        //Hide button if no doors closed time in ServerHelper
        if (!ServerHelper.doorsClosed) return true;

        let currentTime = new Date().getTime();
        let doorsClosedTime = new Date(ServerHelper.doorsClosed).getTime();

        //If current time is less than doors closed time, show enter button
        if (currentTime < doorsClosedTime) {
            return false;
        } else {
            //Hide button if current time is the same or greater than doors closed time
            return true;
        }
    };

    //material UI classes
    const classes = IntakeHeaderStyles();

    return (
        <React.Fragment>
            <div className={styles.intakeHeaderWrapper}>
                <Paper elevation={0} classes={{ root: classes.logoHolder }}>
                    <img
                        className={styles.showboatLogo}
                        src={SHOWBOAT.ApplicationSkin.landingPageThumbnailURL}
                        alt="header logo"
                    />
                </Paper>
                <Typography
                    variant="h2"
                    classes={{ root: classes.eventWelcome }}
                >
                    Welcome to
                </Typography>
                <div className={styles.eventNameHolder}>
                    <Typography
                        variant="h1"
                        classes={{ root: classes.eventName }}
                    >
                        <Textfit 
                            mode="single" 
                            max={30}
                            className={classes.textfit}
                        >
                          {SHOWBOAT.ApplicationSkin.eventName}
                        </Textfit>
                    </Typography>
                </div>
                <Typography variant="h2" classes={{ root: classes.eventDate }}>
                    {SHOWBOAT.ApplicationSkin.eventTime}
                </Typography>

                {props.welcome !== true && (
                    <Button
                        disabled={disableButtons || disableHome}
                        classes={{ root: classes.homeButton }}
                        onClick={handleHomeClick}
                        variant="text"
                    >
                        HOME
                    </Button>
                )}

                {ServerHelper.loginFailReason ===
                    StringVariableHelper.LoginFailReasons.NotStarted &&
                    showTooltip && (
                        <div className={styles.buttonTooltipWrapper}>
                            <Paper className={classes.buttonTooltip}>
                                <Typography
                                    variant="body1"
                                    className={classes.tooltipText}
                                >
                                    Event has not started
                                </Typography>
                            </Paper>
                        </div>
                    )}

                {props.welcome && !checkIfHideEnterButton() && (
                    <div
                        onClick={handleShowTooltip}
                        className={styles.enterButtonHolder}
                    >
                        <Button
                            id="enterButton"
                            disabled={!unblockEnter && buttonDisable}
                            classes={{ root: classes.enterButton }}
                            variant="contained"
                            onClick={handleEnterClick}
                        >
                            ENTER
                        </Button>
                    </div>
                )}
            </div>

            {ServerHelper.loginFailReason ===
                StringVariableHelper.LoginFailReasons.DeletedLoginCode && (
                <Paper className={classes.deadLinkErrorMessageHolder}>
                    <Typography
                        variant="body1"
                        className={classes.deadLinkErrorMessage}
                    >
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="_blank"
                                    href={decoratedHref}
                                    key={key}
                                    style={{
                                        color: "#FFB700",
                                        textDecoration: "none",
                                    }}
                                >
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {ServerHelper.errorMsg}
                        </Linkify>
                    </Typography>
                </Paper>
            )}

            {ServerHelper.loginFailReason ===
                StringVariableHelper.LoginFailReasons.Ended &&
                checkIfHideEnterButton() && (
                    <Paper className={classes.eventEndedErrorMessageHolder}>
                        <Typography
                            variant="body1"
                            className={classes.eventEndedErrorMessage}
                        >
                            This event has ended.
                        </Typography>
                    </Paper>
                )}

            {ServerHelper.loginFailReason ===
                StringVariableHelper.LoginFailReasons.CapacityFull && (
                <Paper className={classes.capacityErrorMessageHolder}>
                    <Typography
                        variant="body1"
                        className={classes.capacityErrorMessage}
                    >
                        <ErrorIcon className={classes.errorIcon} />
                        Event currently at capacity. This page will update if
                        space becomes available.
                    </Typography>
                </Paper>
            )}
        </React.Fragment>
    );
}
