import { Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";

import styles from "./MobilePresenterFeed.module.css";
import { MobilePresenterFeedStyles } from "./MobilePresenterFeedStyles";

type Props = {
  userID: string;
  downstreamController: SHOWBOAT.LiveswitchDownstreamController;
  width: number;
};

export default function MobilePresenterFeed(props: Props) {
  const { handleShowAudioUnlock , audioUnlockPressedForPresenters, setUnlockShownForPresenters}: MobileContextType =
    useContext(MobileContext);

  const [presenterCameraEnabled, setPresenterCameraEnabled] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
          .cameraEnabled
      : false
  );
  const [userName, setUserName] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
      ? `${
          SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID).firstName
        } ${
          SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID).lastName
        }`
      : ""
  );
  const [userAvatarFace, setUserAvatarFace] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID).face
      : 0
  );
  const [userColor, setUserColor] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID).color
      : 0
  );

  const [showElement, setShowElement] = useState(false);

  const cameraContainerRef = useRef(null);
  const firstCameraChangeEvent = useRef(true);

  const cameraEnabledRef = useRef(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
          .cameraEnabled
      : false
  );

  const cameraVideoRef = useRef(
    props.downstreamController.getHTMLVideoElement()
  );

  useEffect(() => {
    //Add listener for user turning off/on camera
    let container = document.getElementById(
      `mobilePresenterFeedHolder-${props.userID}`
    );

    if (container) {
      cameraContainerRef.current = container;
    }

    //Attempt to play audio element
    playPresenterAudioElement();

    //Check if camera is enabled initially. If so, append the video
    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID).cameraEnabled
    ) {
      //Append the video
      appendCameraVideo(props.downstreamController.getHTMLVideoElement());
    }

    //Listen for camera feed becoming available
    props.downstreamController.OnCameraFeedAvailable.Add(
      handleCameraFeedAvailable
    );

    //Listen for color changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.userID,
      SHOWBOAT.ChangeReason.ColorNumber,
      handleColorChange
    );

    //Listen for nametag changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.userID,
      SHOWBOAT.ChangeReason.NameTag,
      handleNametagChange
    );

    //Listen for face changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.userID,
      SHOWBOAT.ChangeReason.FaceNumber,
      handleFaceChange
    );

    return function cleanup() {
      props.downstreamController.OnCameraFeedAvailable.Remove(
        handleCameraFeedAvailable
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.userID,
        SHOWBOAT.ChangeReason.ColorNumber,
        handleColorChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.userID,
        SHOWBOAT.ChangeReason.NameTag,
        handleNametagChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.userID,
        SHOWBOAT.ChangeReason.FaceNumber,
        handleFaceChange
      );
    };
  }, []);

  useEffect(() => {
    let cameraVideo = document.getElementById(
      `mobilePresenterVideo-${props.userID}`
    );
    if (cameraVideo) {
      if (presenterCameraEnabled) {
        cameraVideo.style.display = "inline-block";
      } else {
        cameraVideo.style.display = "none";
      }
    }
  }, [presenterCameraEnabled]);

  const clearCameraContainer = (container) => {
    for (let i = 0; i < container.childNodes.length; i++) {
      if (
        container.childNodes[i].nodeName !== "IMG" &&
        container.childNodes[i].nodeName !== "BUTTON"
      ) {
        container.removeChild(container.childNodes[i]);
      }
    }
  };

  const appendCameraVideo = async (video) => {
    if (cameraContainerRef.current && video !== null) {
      //Ensure the container is cleared
      clearCameraContainer(cameraContainerRef.current);

      video.id = `mobilePresenterVideo-${props.userID}`;

      cameraContainerRef.current.appendChild(video);

      setShowElement(true);
    }
  };

  const handleCameraFeedAvailable = (
    showVideo: boolean,
    el: HTMLVideoElement
  ) => {
    //Ignore duplicates
    if (showVideo === cameraEnabledRef.current && firstCameraChangeEvent.current === false) return;

    firstCameraChangeEvent.current = false;

    if (showVideo) {
      setPresenterCameraEnabled(true);
      cameraEnabledRef.current = true;

      //Check if element is not the same as previous
      if (cameraVideoRef.current != el) {
        cameraVideoRef.current = el;
      }

      appendCameraVideo(cameraVideoRef.current);
    } else {
      setPresenterCameraEnabled(false);
      cameraEnabledRef.current = false;
    }

    //Attempt to play audio element
    playPresenterAudioElement();
  };

  const playPresenterAudioElement = () => {
    if (
      props.downstreamController.getHTMLAudioElement()
    ) {
      props.downstreamController
        .getHTMLAudioElement()
        .play()
        .catch((e) => {
          if (!audioUnlockPressedForPresenters) {
            handleShowAudioUnlock(true, "presenters");
            setUnlockShownForPresenters(true);
          }
        });
    }
  };

  const handleColorChange = (avatarData: SHOWBOAT.AvatarData) => {
    setUserColor(avatarData.color);
  };

  const handleFaceChange = (avatarData: SHOWBOAT.AvatarData) => {
    setUserAvatarFace(avatarData.face);
  };

  const handleNametagChange = (avatarData: SHOWBOAT.AvatarData) => {
    setUserName(`${avatarData.firstName} ${avatarData.lastName}`);
  };

  const handleElementLoad = () => {
    setShowElement(true);
  };

  const classes = MobilePresenterFeedStyles();

  return (
    <div
      className={styles.presenterItemWrapper}
      style={{
        width: `${props.width}vw`,
        height: `${props.width}vw`,
        backgroundColor: !presenterCameraEnabled
          ? SHOWBOAT.ApplicationSkin.secondaryAvatarColors[userColor]
          : "#9D9D9D",
      }}
    >
      <div
        className={styles.presenterFeedHolder}
        id={`mobilePresenterFeedHolder-${props.userID}`}
      >
        <img
          className={styles.cameraFace}
          src={`assets/images/faces/${SHOWBOAT.ApplicationSkin.faceThumbnailURLs[userAvatarFace]}`}
          style={{
            backgroundColor:
              SHOWBOAT.ApplicationSkin.secondaryAvatarColors[userColor],
          }}
          onLoad={handleElementLoad}
        />
      </div>

      <div className={styles.nameHolder}>
        <Typography className={classes.mobilePresenterName}>
          {userName}
        </Typography>
      </div>
    </div>
  );
}
