import React, { useContext, useState, useRef, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';

import AttendeeList from '../ControlCenter/AttendeeList/AttendeeList';
import { AppContext, AppContextType } from '../../../context/AppContext';
import DebugPopup from '../Popups/DebugPopup/DebugPopup';
import SettingsSidebar from '../ControlCenter/SettingsSidebar/SettingsSidebar';
import RoomOptionsSidebar from '../ControlCenter/RoomOptionsSidebar/RoomOptionsSidebar';
import HangUpSidebar from '../ControlCenter/HangUpSidebar/HangUpSidebar';
import SupportSidebar from '../ControlCenter/SupportSidebar/SupportSidebar';
import ChatSidebar from '../ControlCenter/ChatSidebar/ChatSidebar';
import CalendarSidebar from '../ControlCenter/CalendarSidebar/CalendarSidebar';
import { StringVariableHelper } from '../../../Utilities/StringVariableHelper';

import { RightBarStyles } from './RightBarStyles';
import styles from './RightBar.module.css';
import QASidebar from '../ControlCenter/QASidebar/QASidebar';

export default function LeftBar() {
  /*State store*/
  const [ drawerVisibilityProperty, setDrawerVisibilityProperty ] = useState("hidden");
  const [ drawerWidth, setDrawerWidth ] = useState(340);
  /**/

  //Access context
  const {
    currentSidebarMode,
    selectedAttendeeIDContext,
    isDebugModeActive
  } : AppContextType = useContext(AppContext);

  const leaveMeetingTimeout = useRef(null);

  //MaterialUI classes
  const classes = RightBarStyles();

  useEffect(() => {
    return function cleanup() {
      if (leaveMeetingTimeout.current) clearTimeout(leaveMeetingTimeout.current);
    }
  }, []);

  useEffect(() => {

    //Change sidebar styles based on current mode
    let drawerWidth;
    
    switch (currentSidebarMode) {
      case "":
        break;
      case "settings":
        drawerWidth = 420;
        break;
      case "room":
      case "support":
      case "attendees":
      case "calendar":
        drawerWidth = 290;
        break;
      case "QA":
      case "chat":
        drawerWidth = 300;
        break;
      case "exit":
        drawerWidth = 220;
        break;
    };
    
    //If we are moving to "", hide right sidebar. Otherwise, show it
    if (currentSidebarMode === "") {
      setDrawerVisibilityProperty("hidden");
    } else {
      setDrawerVisibilityProperty("visible");
    }

    setDrawerWidth(drawerWidth);
  }, [currentSidebarMode]);

  return (
    <React.Fragment>
      <Drawer
        variant="persistent"
        classes={{
          paper: classes.paper,
        }}
        PaperProps={{
          style: {
            width: drawerWidth,
            visibility: drawerVisibilityProperty,
          } as React.CSSProperties,
        }}
        anchor="right"
        open={currentSidebarMode !== ""}
      >
        <div
          className={
            SHOWBOAT.LocalAvatarDataManager.role ===
            StringVariableHelper.ShowboatRoles.presenter
              ? `${styles.drawerContentHolder} ${styles.drawerContentHolderPresenter}`
              : styles.drawerContentHolder
          }
          style={{ width: drawerWidth }}
        >
          {/*  Change content of drawer based on currentSidebarMode */}

          {
            {
              settings: <SettingsSidebar />,
              attendees: <AttendeeList roomSwitchVariant={false} />,
              room: <RoomOptionsSidebar />,
              exit: <HangUpSidebar />,
              support: <SupportSidebar />,
              chat: <ChatSidebar />,
              calendar: <CalendarSidebar />,
              QA: <QASidebar />,
            }[currentSidebarMode]
          }
        </div>
      </Drawer>

      {/*  //Debug popup */}
      {/*  Make sure we are in debug mode and that an attendee is selected */}

      {selectedAttendeeIDContext !== null &&
        isDebugModeActive &&
        currentSidebarMode === "attendees" && <DebugPopup />}

    </React.Fragment>
  );
}
