import React, { ReactElement } from "react";
import { Paper, Typography } from "@material-ui/core";

import styles from "./AvatarLoadingScreen.module.css";
import { AvatarLoadingScreenStyles } from "./AvatarLoadingScreenStyles";

interface Props {
  loadingProgress: string;
}

export default function AvatarLoadingScreen(props: Props): ReactElement {
  const classes = AvatarLoadingScreenStyles();

  return (
    <Paper elevation={0} square className={classes.loadingHolder}>
      <div className={styles.loadingSpinnerHolder}>
        <img
          src="assets/images/sb-logo.png"
          alt="showboat logo"
          className={styles.imageBack}
        ></img>
        <img
          src="assets/images/sb-spinner.png"
          alt="showboat logo spinner"
          className={styles.imageSpinner}
        ></img>

        <Typography variant="h1" className={classes.loadingProgress}>
          {props.loadingProgress}
        </Typography>
      </div>
    </Paper>
  );
}
