import { makeStyles, createStyles } from '@material-ui/core/styles';

const FullScreenLoadingScreenStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.intakeBackground.main,
      zIndex: 9997,
      position: "absolute",
      top: 0,
      left: 0,
    },
    loadingProgress: {
      position: "relative",
      fontSize: 18,
      display: "inline-block",
      fontWeight: 500,
      top: 360,
    },
    joinButton: {
      backgroundColor: "#FF0092",
      color: "white",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      width: 120,
      height: 40,
    },
    loadingProgressMobile: {
      top: 303
    }
  })
);

export { FullScreenLoadingScreenStyles };