import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileAppMainStyles = makeStyles((theme: any) => createStyles({
  mobileMainText: {
    fontSize: 20,
    color: "#808080",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 50,
    margin: "auto",
    width: 302,
    textAlign: "center",
    height: 52,
    top: 0,
    display: "inline-block"
  }
}));

export { MobileAppMainStyles }