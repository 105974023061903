import { makeStyles, createStyles } from '@material-ui/core/styles';

const QASortSelectStyles = makeStyles((theme: any) =>
  createStyles({
    qaSortHolder: {
      width: "100%",
      height: 40,
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: 4,
      backgroundColor: theme.palette.intakePaper.main,
    },
    sortIcon: {
      color: theme.palette.qaViewHeader.main,
      marginLeft: 15,
      marginRight: 12,
    },
    qaSelect: {
      fontSize: 14,
      position: "relative",
      top: 3,
    },
    qaSelectItem: {
      fontSize: 12,
      minHeight: 0,
    },
    qaSelectIcon: {
      top: "calc(50% - 14px)",
    },
  })
);

export { QASortSelectStyles };