import React, { ReactElement, useContext } from 'react';
import { FormControlLabel, Paper, Switch } from '@material-ui/core';

import { OneOnOneChatContext, OneOnOneChatContextType } from '../../../../../context/OneOnOneChatContext';
import { AppContext, AppContextType } from '../../../../../context/AppContext';
import { StringVariableHelper } from '../../../../../Utilities/StringVariableHelper';

import { MiscSettingsStyles } from './MiscSettingsStyles';
import styles from './MiscSettings.module.css';
import { ServerHelper } from '../../../../../Utilities/ServerHelper';
import { PresenterContext, PresenterContextType } from '../../../../../context/PresenterContext';

export default function MiscSettings(): ReactElement {

  //Context
  const {
    isTeleportationBlocked,
    toggleIsTeleportationBlocked,
    isDebugModeActive,
    toggleIsDebugModeActive
  } : AppContextType = useContext(AppContext);

  const { 
    isChatOff,
    toggleIsChatOff
  } : OneOnOneChatContextType = useContext(OneOnOneChatContext);

  const { isBackstage, isOnStage }: PresenterContextType =
    useContext(PresenterContext);

  //Teleportation toggle
  const handleTeleportOffChange = (e) => {
    if (!isTeleportationBlocked) {
      //Blocking teleport - raise block event
      ServerHelper.updateIsAvailableForTeleport(false);
    } else {
      //Enabling teleport - raise unblock event, only if we are not on-stage and not backstage
      if (!isBackstage && !isOnStage) {
        ServerHelper.updateIsAvailableForTeleport(true);
      }
    }
    
    toggleIsTeleportationBlocked(!isTeleportationBlocked);
  };

  //Chat toggle
  const handleChatOffChange = (e) => {
    toggleIsChatOff(!isChatOff);
  }

  //Debug toggle
  const handleDebugModeChange = (e) => {

    let toggleState = !isDebugModeActive;

    //Double check that we are a presenter
    if (SHOWBOAT.LocalAvatarDataManager.avatarData.role === StringVariableHelper.ShowboatRoles.presenter) {
      SHOWBOAT.LiveswitchDownstreamController.SetVolumeEventsActive(toggleState);
      toggleIsDebugModeActive(toggleState);
    }
  }
  
  const classes = MiscSettingsStyles();
  
  return (
    <Paper className={SHOWBOAT.LocalAvatarDataManager.role === "presenter" ? classes.miscSettingsHolder : `${classes.miscSettingsHolder} ${classes.miscSettingsHolderNotPresenter}`}>
      <div className={styles.miscSettingsWrapper}>
        <FormControlLabel
          className={classes.chatLabel}
          control={
            <Switch
              color="primary"
              checked={isChatOff}
              onChange={(e) => handleChatOffChange(e)}
              classes={{ root: classes.chatSwitch }}
            />
          }
          label="Block Attendee Chat"
        />

        <FormControlLabel
          className={classes.teleportLabel}
          control={
            <Switch
              color="primary"
              checked={isTeleportationBlocked}
              onChange={(e) => handleTeleportOffChange(e)}
              classes={{ root: classes.teleportSwitch }}
            />
          }
          label="Block Teleport Requests"
        />

        {/*  Make sure we are a presenter before showing debug toggle */}
        {SHOWBOAT.LocalAvatarDataManager.avatarData.role === StringVariableHelper.ShowboatRoles.presenter &&
          <FormControlLabel
            className={classes.debugLabel}
            control={
              <Switch
                color="primary"
                checked={isDebugModeActive}
                onChange={(e) => handleDebugModeChange(e)}
                classes={{ root: classes.debugModeSwitch }}
              />
            }
            label="Tech Support Mode"
          />
        }
      </div>
    </Paper>
  )
}
