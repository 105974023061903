import { makeStyles, createStyles } from '@material-ui/core/styles';

const CookieNotificationStyles = makeStyles((theme: any) => createStyles({
  cookieNotification: {
    width: "100vw",
    height: 107,
    backgroundColor: theme.palette.intakePaper.main,
    border: "none",
    overflow: "hidden",
    textAlign: "center"
  },
  cookieText: {
    fontSize: 16,
    position: "absolute",
    display: "inline-block",
    width: 714,
    left: 0,
    top: 30,
    textAlign: "left",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 540,
    }
  },
  okButton: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.intakePaper.main,
    width: 93,
    height: 63,
    position: "absolute",
    display: "inline-block",
    top: 22,
    right: 0,
    zIndex: 999,
    fontSize: 28,
  },
  link: {
    marginRight: 5,
  }
})
)

export { CookieNotificationStyles }