import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './HowToMove.module.css';
import { HowToMoveStyles } from './HowToMoveStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function HowToMove(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = HowToMoveStyles();
  
  return (
    
    <React.Fragment>

      {props.currentSection === 0 &&
        <React.Fragment>

          <div 
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>
          
          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }

      <img 
        alt="arrow key diagram" 
        className={styles.arrowKeyImage} 
        src={`assets/images/ArrowKey${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`} />

      <Typography variant="body1" className={classes.howToMoveText}>
        Up/down arrow keys move forward and back.
      </Typography>

      <Typography variant="body1" className={classes.howToMoveText}>
        Left/right arrow keys rotate to change direction.
      </Typography>

      <img 
        alt="Look around diagram" 
        className={styles.lookAroundImage} 
        src={`assets/images/LookAround${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography variant="body1" className={classes.howToMoveText}>
        Click and drag to look around.
      </Typography>
      
    </React.Fragment>
  )
}
