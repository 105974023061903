import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileEventCountdownStyles = makeStyles((theme: any) =>
  createStyles({
    number: {
      fontSize: 16,
      color: "white",
      display: "inline-block",
      position: "relative",
      top: 2,
      marginRight: 5
    },
    numberLabel: {
      fontSize: 12,
      color: "white",
      display: "inline-block",
      position: "relative",
      top: 2,
    }
  })
)

export { MobileEventCountdownStyles };