import { makeStyles, createStyles } from '@material-ui/core/styles';

const WebcamShareStyles = makeStyles((theme: any) => createStyles({
  webcamToggle: {
    position: "absolute",
    bottom: "4%",
    right: "6%",
  },
  toggleLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  settingsIconButton: {
    padding: 6,
    position: "absolute",
    left: "5%",
    bottom: "4.5%"
  },
  loadingSpinner: {
    width: "80px !important",
    height: "80px !important",
    position: "absolute",
    top: "-15%",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    color: theme.palette.text.primary
  }
})
)

export { WebcamShareStyles };