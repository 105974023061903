import React, { useContext } from 'react';

import AttendeeList from '../../components/ControlCenter/AttendeeList/AttendeeList';

import styles from "./MobileAttendees.module.css";

type Props = {}

export default function MobileAttendees({}: Props) {
  //Render default app attendee list with mobile prop passed
  return (
    <div className={styles.mobileAttendeesWrapper}>
      <AttendeeList roomSwitchVariant={false} mobile={true} />
    </div>
  )
}