import { orderBy } from "natural-orderby";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  MessageContext,
  MessageContextType,
} from "../../../context/MessageContext";
import ChatMessage from "../../components/ControlCenter/ChatSidebar/ChatSidebarMessage/ChatSidebarMessage";

import styles from "./MobileChat.module.css";

type Props = {};

export default function MobileChat({}: Props) {
  const { chatMessages, toggleNewMessage , chatMessageInfoMap}: MessageContextType =
    useContext(MessageContext);

  const [chatMessagesState, setChatMessagesState] = useState(chatMessages);

  const containerRef = useRef(null);
  const chatScrollTimer = useRef(null);
  const chatScrollTimerRunning = useRef(false);
  const scrollFunctionCalled = useRef(false);

  const scrollToTop = () => {
    if (containerRef.current) {
      scrollFunctionCalled.current = true;
      containerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    let container = document.getElementById("mobileChatHolder");

    //Assign container to ref to reference in scrollToBottom function
    if (container) {
      containerRef.current = container;
    }

    //On component mount, scroll to top
    scrollToTop();

    //Listen for scrolling the chat message holder
    container.addEventListener("scroll", function () {
      setScrollTimer();
    });

    return function cleanup() {
      toggleNewMessage(false);

      container.removeEventListener("scroll", function () {
        setScrollTimer();
      });
    };
  }, []);

  useEffect(() => {
    //When a message comes in, scroll down if:
    // 1) There is no timer running
    // 2) We sent the message
    if (chatMessages.length > chatMessagesState.length) {
      if (
        chatMessages[chatMessages.length - 1].userID ===
          SHOWBOAT.LocalAvatarDataManager.userID ||
        chatScrollTimerRunning.current === false
      ) {
        scrollToTop();
      }
    }
  }, [chatMessages]);

  const setScrollTimer = () => {
    //Do not set timer for function calls that scroll the div, only user actions
    if (scrollFunctionCalled.current) {
      scrollFunctionCalled.current = false;
      return;
    }

    if (chatScrollTimer.current) {
      clearTimeout(chatScrollTimer.current);
    }

    chatScrollTimerRunning.current = true;

    chatScrollTimer.current = setTimeout(function () {
      chatScrollTimerRunning.current = false;
    }, 10000);
  };

  //Order so that newest chat messages appear at the top
  let chatItemsOrdered;

  chatItemsOrdered = orderBy(
    chatMessages,
    [(c) => (c as any).timeCreated],
    ["desc"]
  );

  let chatItemsComponentArray = [];

  chatItemsOrdered.forEach((item) => {
    chatItemsComponentArray.push(item);
  });

  return (
    <div className={styles.mobileChatHolder} id="mobileChatHolder">
      <div className={styles.mobileChatMessageHolder}>
        {chatItemsComponentArray.map((chatMessage : SHOWBOAT.Chat, i) => {
          return (
            <ChatMessage
              key={chatMessage.ID}
              msg={chatMessage.message}
              senderUserID={chatMessage.senderUserID}
              date={chatMessage.timeCreated}
              ID={chatMessage.ID}
              mobile={true}
              chatMessageInfoMap={chatMessageInfoMap}
              senderName={chatMessage.senderName}
              senderColor={chatMessage.senderColor}
            />
          );
        })}
      </div>
    </div>
  );
}
