import React, { ReactElement, useEffect, useState, useContext } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import { UIHelper } from "../../../../Utilities/UIHelper";
import { ServerHelper } from "../../../../Utilities/ServerHelper";

import styles from "./PhotoPopup.module.css";
import { PhotoPopupStyles } from "./PhotoPopupStyles";

interface Props {}

export default function PhotoPopup(props: Props): ReactElement {
  const { photoPreview, setPhotoPreview }: AppContextType =
    useContext(AppContext);

  const [previewImage, setPreviewImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [uploadComplete, setUploadComplete] = useState(false);

  useEffect(() => {
    SHOWBOAT.UIEventManager.OnImageCaptureComplete.Add(
      handleImageCaptureComplete
    );

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnImageCaptureComplete.Remove(
        handleImageCaptureComplete
      );
    };
  }, []);

  const handleImageCaptureComplete = (base64Img) => {
    let htmlImage = document.createElement("img");
    htmlImage.src = base64Img;
    setPreviewImage(htmlImage);
  };

  const handleClosePhotoPreview = () => {
    //Ensure error is cleared and popup is reset
    setError(false);
    setUploadComplete(false);
    setPhotoPreview(false);
  };

  const convertBase64ToFile = async (base64: string, fileName: string) => {
    const res = await fetch(base64);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  const handleKeepClick = async () => {
    let date = new Date();
    let stringDate =
      date.getFullYear() +
      "/" +
      date.getMonth() +
      "/" +
      date.getDate() +
      "-" +
      date.getHours() +
      ":" +
      date.getMinutes();
    let fileName = "Showboat-" + stringDate + ".png";

    let imgFile = await convertBase64ToFile(UIHelper.imgBase64, fileName);

    let imgFileToSend = {
      name: imgFile.name,
      type: imgFile.type,
    };

    //Upload to booking's photo album
    let photoToPost = {
      bookingID: ServerHelper.loginResult.eventID,
      file: imgFileToSend,
      loginCode: ServerHelper.loginCode,
      uploadedBy: `${SHOWBOAT.LocalAvatarDataManager.firstName} ${SHOWBOAT.LocalAvatarDataManager.lastName}`,
      visibleInPicture: "",
    };

    try {
      setIsLoading(true);

      let assetRequestResponse = await axios.post("/photo", photoToPost);

      //Post object to S3 pre-signed URL in response
      let s3URL = assetRequestResponse.data.url;

      await axios.put(s3URL, imgFile);

      //Upload success, display link

      setUploadComplete(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const handleDiscardClick = () => {
    setPhotoPreview(false);
  };

  const classes = PhotoPopupStyles();

  if (photoPreview) {
    return (
      <div
        className={
          isLoading || uploadComplete
            ? SHOWBOAT.LocalAvatarDataManager.role === "presenter"
              ? `${styles.photoPreviewWrapper} ${styles.photoPreviewWrapperUploaded} ${styles.photoPreviewWrapperUploadedPresenter}`
              : `${styles.photoPreviewWrapper} ${styles.photoPreviewWrapperUploaded}`
            : styles.photoPreviewWrapper
        }
      >
        <Paper
          className={classes.photoPreviewHeaderHolder}
          id="presenterToolsHeaderHolder"
        >
          <Typography variant="h2" className={classes.photoPreviewHeader}>
            PHOTO PREVIEW
          </Typography>

          {(uploadComplete || error) && (
            <IconButton
              onClick={handleClosePhotoPreview}
              className={`${classes.closeButton} cancelDrag`}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Paper>
        <Paper
          className={classes.photoPreviewContentHolder}
          style={{
            paddingTop: 0,
          }}
          id="photoPreviewContentHolder"
        >
          {error === false ? (
            uploadComplete ? (
              <>
                <Typography className={classes.successMessage}>
                  Your photo has been saved.
                </Typography>

                <div className={styles.finishedButtonHolder}>
                  <Link
                    target="_blank"
                    href={`https://photos.showboat.live/albums/${ServerHelper.loginResult.eventID}`}
                    className={classes.link}
                  >
                    <Button className={classes.viewAlbumButton}>
                      View Album
                    </Button>
                  </Link>

                  <Button
                    onClick={handleClosePhotoPreview}
                    className={classes.doneButton}
                  >
                    Done
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.photoHolder}>
                  {previewImage !== null && (
                    <img
                      alt="new photo"
                      id="previewPhoto"
                      src={previewImage.src}
                      className={`${styles.photoPreview} cancelDrag`}
                      /* onLoad={handleImageLoad} */
                    />
                  )}
                </div>

                <div className={styles.buttonHolder}>
                  {isLoading ? (
                    <CircularProgress
                      style={{ visibility: isLoading ? "visible" : "hidden" }}
                      className={classes.loadingSpinner}
                    />
                  ) : (
                    <>
                      <IconButton
                        className={`${classes.photoButton} ${classes.photoButtonDelete}`}
                        onClick={handleDiscardClick}
                        disabled={isLoading}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        className={`${classes.photoButton} ${classes.photoButtonKeep}`}
                        onClick={handleKeepClick}
                        disabled={isLoading}
                      >
                        <CheckIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </>
            )
          ) : (
            <Typography className={classes.successMessage}>
              An error occurred.
            </Typography>
          )}
        </Paper>
      </div>
    );
  } else {
    return null;
  }
}
