import React, {useState } from 'react';

export interface ErrorContextType {
  hasError : boolean,
  toggleHasError : (hasError : boolean) => void,
  errorMessage : string,
  setErrorMessage : (errorMessage : string) => void,
  hasServerError : boolean,
  toggleHasServerError : (hasServerError : boolean) => void,
  hasVideoError : boolean,
  toggleHasVideoError : (hasVideoError : boolean) => void
}

export const ErrorContext = React.createContext(null);

export const ErrorContextProvider = (props) => {

  const [ hasError, toggleHasError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState("");

  const [ hasServerError, toggleHasServerError ] = useState(false);
  const [ hasVideoError, toggleHasVideoError ] = useState(false);

  const messagesProviderValue : ErrorContextType = {
    hasError,
    toggleHasError,
    errorMessage,
    setErrorMessage,
    hasServerError,
    toggleHasServerError,
    hasVideoError,
    toggleHasVideoError
  }

  return (
    <ErrorContext.Provider
      value={messagesProviderValue}
    >
      {props.children}
    </ErrorContext.Provider>
  )
}