import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileJumbotronViewStyles = makeStyles((theme: any) =>
  createStyles({
    fullscreenIconButton: {
      position: "absolute",
      top: 15,
      right: 15,
      padding: 4,
      zIndex: 9,
    },
    fullscreenIcon: {
      fontSize: 30,
    },
    jumbotronButton: {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      height: 62,
      width: 170,
      fontSize: 16,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: "auto",
      zIndex: 9,
      "&:focused": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    boxIcon: {
      marginRight: 5,
      fontSize: 32,
      position: "relative",
      bottom: 2,
    },
  })
);

export { MobileJumbotronViewStyles };