import { IconButton, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useRef } from "react";
import EnterIcon from "@material-ui/icons/ExitToApp";

import {
  AttendeeContext,
  AttendeeContextType,
} from "../../../../../context/AttendeeContext";
import { PeopleAlt } from "@material-ui/icons";
import {
  PresenterContextType,
  PresenterContext,
} from "../../../../../context/PresenterContext";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";

import styles from "./RoomOptionsSidebarItem.module.css";
import { RoomOptionsSidebarItemStyles } from "./RoomOptionsSidebarItemStyles";
import { UIHelper } from "../../../../../Utilities/UIHelper";
import { ServerHelper } from "../../../../../Utilities/ServerHelper";
import { AppContext, AppContextType } from "../../../../../context/AppContext";

interface Props {
  roomName: string;
  roomCount: number;
  roomID: string;
  roomCapacity: number;
  handleChangeRoom: () => any;
  attendeeRoomID: string;
}

export default function RoomOptionsSidebarItem(props: Props): ReactElement {
  const { handleChangeRoomID }: AttendeeContextType =
    useContext(AttendeeContext);

  const { toggleIsOnStage, currentMode, toggleIsBackstage, isOnStage }: PresenterContextType =
    useContext(PresenterContext);

  const { isTeleportationBlocked} : AppContextType = useContext(AppContext);

  const isOnStageRef = useRef(isOnStage);

  const handleChangeRoomClick = async () => {
    //Change room to roomID sent down via props
    //Call room change method
    SHOWBOAT.UIEventManager.RoomChangeInProgress = true;
    try {
      const roomChangeResponse =
        await SHOWBOAT.WebSocketController.MoveToTargetRoom({
          targetRoomID: props.roomID,
          isTeleportationRoomChange: false
        });

      if (roomChangeResponse.errorData.error) {
        if (roomChangeResponse.errorData.errorMessage === "Room Full") {
          //Show fail message, do not execute room change logic
          SHOWBOAT.UIEventManager.OnUIError.Raise("Sorry, room is full!");
        } else {
          //Show generic fail reason
          SHOWBOAT.UIEventManager.OnUIError.Raise("Error changing rooms");
        }

        SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
      } else {
        //If we are not in presentation mode, ensure onStage is toggled to false
        if (currentMode !== StringVariableHelper.ShowboatModeType.presenter) {
          toggleIsOnStage(false);
          isOnStageRef.current = false;
        }
          
        if (props.roomID !== "backstage" && !isTeleportationBlocked && !isOnStageRef.current) {
          ServerHelper.updateIsAvailableForTeleport(true);
          toggleIsBackstage(false);
        }

        props.handleChangeRoom();

        //Set local roomID equal to selected roomID
        UIHelper.HandleSuccessfulRoomChange(props.roomID);
        handleChangeRoomID(props.roomID);
      }
    } catch (e) {
      SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
      SHOWBOAT.Logger.Error("RoomOptionsSidebarItems.ChangeToSpecificRoom", e);
    }
  };

  useEffect(() => {
    isOnStageRef.current = isOnStage;
  }, [isOnStage])

  const classes = RoomOptionsSidebarItemStyles();

  return (
    <Paper className={classes.roomItemHolder}>
      <Typography variant="h2" className={classes.roomOptionName}>
        {props.roomName}
      </Typography>

      <div className={styles.roomInfoHolder}>
        <PeopleAlt className={classes.peopleIcon} />

        <Typography variant="body1" className={classes.roomOptionCount}>
          {props.roomCount}
        </Typography>

        <IconButton
          className={classes.changeRoomIconButton}
          onClick={handleChangeRoomClick}
          disabled={
            props.attendeeRoomID === props.roomID ||
            SHOWBOAT.LocalAvatarDataManager.roomID === props.roomID ||
            props.roomCount >= props.roomCapacity
          }
        >
          <EnterIcon />
        </IconButton>
      </div>
    </Paper>
  );
}
