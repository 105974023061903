import {
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import RightArrow from "@material-ui/icons/ArrowForward";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { PresenterHelper } from "../../../../Utilities/PresenterHelper";
import { UIHelper } from "../../../../Utilities/UIHelper";

import styles from "./LivestreamShare.module.css";
import { LivestreamShareStyles } from "./LivestreamShareStyles";
import LivestreamScreenshareVolume from "../LivestreamScreenshareVolume/LivestreamScreenshareVolume";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

interface Props {}

export default function LivestreamShare(props: Props): ReactElement {
  const {
    livestreamURLContext,
    setLivestreamURLContext,
    jumbotronFullscreenView,
    toggleShareLivestream
  }: PresenterContextType = useContext(PresenterContext);

  const [shareLivestreamState, toggleShareLivestreamState] = useState(
    PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID &&
      PresenterHelper.myCurrentPresentationMode ===
        SHOWBOAT.JumbotronMode.livestream
      ? true
      : false
  );
  const [disableShareLivestream, toggleDisableShareLivestream] = useState(true);
  const [livestreamURL, setLivestreamURL] = useState(livestreamURLContext);
  const [, toggleLivestreamError] = useState(false);
  const [hasLivestream, setHasLivestream] = useState(false);
  const [livestreamVolume, setLivestreamVolume] = useState(100);
  const [hideLivestreamPreview, setHideLivestreamPreview] = useState(
    jumbotronFullscreenView && shareLivestreamState
  );

  const livestreamURLRef = useRef("");
  const livestreamVideoRef = useRef(null);
  const shareLivestreamRef = useRef(
    PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID &&
      PresenterHelper.myCurrentPresentationMode ===
        SHOWBOAT.JumbotronMode.livestream
      ? true
      : false
  );

  const volumeRef = useRef(100);
  const dragTimeoutRef = useRef(null);
  const disableShareToggleRef = useRef(null);

  useEffect(() => {
    //When this is toggled true, hide the preview and remove preview video
    //Only remove the preview if we are sharing our own livestream
    if (hideLivestreamPreview && shareLivestreamState && hasLivestream) {
      PresenterHelper.removeVideoPreviewFromPopup("livestreamPreviewHolder");
    } else if (hasLivestream) {
      //Add the preview back
      appendLivestreamPreviewVideo(
        !jumbotronFullscreenView ||
          (jumbotronFullscreenView && !shareLivestreamState)
      );
    }
  }, [hideLivestreamPreview, shareLivestreamState, hasLivestream]);

  useEffect(() => {
    //Determine if we need to hide the preview on mount
    if (jumbotronFullscreenView && shareLivestreamState) {
      setHideLivestreamPreview(true);
    } else {
      setHideLivestreamPreview(false);
    }
  }, [jumbotronFullscreenView, shareLivestreamState]);

  useEffect(() => {
    UIHelper.OnForceLivestreamOff.Add(handleLivestreamForceOff);

    //Instantiate LivestreamController (if not already instantiated)
    if (PresenterHelper.LivestreamController === undefined) {
      PresenterHelper.LivestreamController =
        new SHOWBOAT.LivestreamController();
      SHOWBOAT.LivestreamController.currentLocalPresenterLivestreamController =
        PresenterHelper.LivestreamController;
    }

    //Check if a livestream preview video already exists in PresenterHelper
    if (PresenterHelper.LivestreamVideo) {
      setHasLivestream(true);

      //Enable share toggle
      toggleDisableShareLivestream(false);
    }

    if (PresenterHelper.LivestreamVideo) {
      let container = document.getElementById("livestreamPreviewHolder");
      if (container && !(hideLivestreamPreview && shareLivestreamState))
        container.appendChild(PresenterHelper.LivestreamVideo);

      //Assign the video to the video ref and unmute
      livestreamVideoRef.current = PresenterHelper.LivestreamVideo;

      PresenterHelper.LivestreamVideo.muted = false;

      //Set the volume values
      setLivestreamVolume(PresenterHelper.LivestreamVideo.volume * 100);
      volumeRef.current = PresenterHelper.LivestreamVideo.volume * 100;
    }

    return function cleanup() {
      UIHelper.OnForceLivestreamOff.Remove(handleLivestreamForceOff);

      //Mute livestream preview if we are not sharing to room
      if (!shareLivestreamRef.current && livestreamVideoRef.current) {
        livestreamVideoRef.current.muted = true;
      }

      if (disableShareToggleRef.current) {
        clearTimeout(disableShareToggleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    //Ensure local ref/state change when context value changes
    toggleShareLivestreamState(shareLivestreamState);
    toggleShareLivestream(shareLivestreamState);
    shareLivestreamRef.current = shareLivestreamState;
  }, [shareLivestreamState]);

  const appendLivestreamPreviewVideo = (append: boolean) => {
    let livestreamVideoElement;

    if (PresenterHelper.LivestreamController) {
      livestreamVideoElement =
        PresenterHelper.LivestreamController.getHTMLVideoElement();
    }

    if (!livestreamVideoElement) return;

    livestreamVideoElement.id = "livestreamVideoPreview";

    livestreamVideoRef.current = livestreamVideoElement;

    livestreamVideoElement.addEventListener(
      "pause",
      handlePauseLivestreamPreview
    );

    //Ensure video is unmuted
    livestreamVideoElement.muted = false;
    livestreamVideoElement.volume = livestreamVolume / 100;

    //Assign video element to PresenterHelper
    PresenterHelper.LivestreamVideo = livestreamVideoElement;

    let divToAppend = document.getElementById("livestreamPreviewHolder");

    if (divToAppend && append) {
      divToAppend.appendChild(livestreamVideoElement);
    }
  };

  //Livestream URL Submit
  const handleLivestreamURLSubmit = async () => {
    setHasLivestream(false);

    //Clear any videos that are already in the holder div
    let videoHolder = document.getElementById("livestreamPreviewHolder");

    //Stop any livestream that was previously running
    if (PresenterHelper.LivestreamController) {
      await PresenterHelper.LivestreamController.stopLivestream();
    }

    //Clear out video holder
    if (videoHolder) {
      while (videoHolder.firstChild) {
        if (videoHolder.firstChild.nodeType)
          //Skip the volume slider
          videoHolder.removeChild(videoHolder.firstChild);
      }
    }

    //Set livestream URL in context
    setLivestreamURLContext(livestreamURL);

    //Clear out any existing error
    toggleLivestreamError(false);

    const startLivestream =
      await PresenterHelper.LivestreamController.startLiveStream(livestreamURL);

    //Make sure HLS promise resolves before doing anything else
    if (startLivestream) {
      setHasLivestream(true);

      appendLivestreamPreviewVideo(true);

      //Enable "Share Livestream" toggle
      toggleDisableShareLivestream(false);
    } else {
      //On fail, remove loading spinner, disable share toggle, and display error
      toggleDisableShareLivestream(true);
      toggleLivestreamError(true);
    }
  };

  //Livestream share toggle
  const handleShareLivestreamToggle = () => {
    let toggleState = !shareLivestreamState;

    //Disable toggle for 1 second
    toggleDisableShareLivestream(true);
    disableShareToggleRef.current = setTimeout(function () {
      toggleDisableShareLivestream(false);
    }, 1000);

    if (toggleState) {
      //Set volume to whatever is currently set for the livestream

      SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(
        livestreamVolume / 100
      );

      PresenterHelper.OnHLSToggle(true, livestreamURL);
    } else {
      PresenterHelper.OnHLSToggle(false, "");
    }

    toggleShareLivestreamState(toggleState);
    toggleShareLivestreamState(toggleState);
    shareLivestreamRef.current = toggleState;
  };

  //Livestream URL change
  const handleLivestreamURLChange = (e) => {
    livestreamURLRef.current = e.target.value;
    setLivestreamURL(e.target.value);
  };

  const handlePauseLivestreamPreview = () => {
    livestreamVideoRef.current.play();
  };

  const handleLivestreamForceOff = () => {
    //Change toggle to off and disable
    toggleShareLivestreamState(false);
    shareLivestreamRef.current = false;
  };

  const handleVolumeChange = (volume: number) => {
    //Set volume of the preview video
    let video = document.getElementById(
      "livestreamVideoPreview"
    ) as HTMLVideoElement;
    if (video) {
      video.volume = volume / 100;
    }

    setLivestreamVolume(volume);

    volumeRef.current = volume;

    clearTimeout(dragTimeoutRef.current);

    dragTimeoutRef.current = setTimeout(handleDragTimeout, 500);
  };

  const handleDragTimeout = async () => {
    //Send SocketIO variable event if we are currently streaming
    if (shareLivestreamRef.current) {
      await SHOWBOAT.WebSocketController.UpdateGroupVariable({
        variableID: StringVariableHelper.LocalVideoVolumeEventName,
        permissionKey: "",
        groupType: SHOWBOAT.GroupTypes.booking,
        newData: {
          volume: volumeRef.current / 100,
        },
      });
    }
  };

  const classes = LivestreamShareStyles();

  return (
    <React.Fragment>
      <TextField
        value={livestreamURL}
        className={`${classes.livestreamURLTextField} cancelDrag`}
        onChange={handleLivestreamURLChange}
        size="small"
        variant="outlined"
        label="Enter Livestream URL"
        InputProps={{
          className: classes.livestreamURLInput,
        }}
        onDragStart={(e) => e.preventDefault()}
      ></TextField>

      <IconButton
        className={`${classes.arrowIconButton} cancelDrag`}
        onClick={handleLivestreamURLSubmit}
        disabled={livestreamURL === "" || shareLivestreamState}
      >
        <RightArrow className={classes.arrowIcon} />
      </IconButton>

      <div
        id="livestreamPreviewHolder"
        className={styles.livestreamPreviewHolder}
      >
        {hasLivestream && (
          <LivestreamScreenshareVolume
            localVideoVolume={livestreamVolume}
            handleVolumeChange={handleVolumeChange}
          />
        )}
      </div>

      {hideLivestreamPreview && hasLivestream && (
        <Typography variant="body1" className={classes.twoDModeInfo}>
          Playing on presentation display
        </Typography>
      )}

      <FormControlLabel
        control={
          <Switch
            checked={shareLivestreamState}
            onChange={handleShareLivestreamToggle}
            color="primary"
          />
        }
        label="SHARE"
        classes={{
          label: classes.shareLivestreamLabel,
          root: `${classes.shareLivestreamToggle} cancelDrag`,
        }}
        disabled={disableShareLivestream}
        labelPlacement="start"
      />
    </React.Fragment>
  );
}
