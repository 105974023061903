import { Typography } from "@material-ui/core";
import React, { useContext } from "react";

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";
import MobileJumbotronView from "../MobileJumbotronView/MobileJumbotronView";
import MobileChat from "../MobileChat/MobileChat";
import MobileToolbar from "./MobileToolbar/MobileToolbar";
import MobileChatNewMessage from "../MobileChat/MobileChatNewMessage/MobileChatNewMessage";
import MobileSettings from "../MobileSettings/MobileSettings";
import MobileLeaveMeeting from "../MobileLeaveMeeting/MobileLeaveMeeting";
import MobileAppQA from "../MobileQA/MobileQA";
import MobileNewQAItem from "../MobileQA/MobileNewQAItem/MobileNewQAItem";
import MobileMessages from "../MobileMessages/MobileMessages";
import MobileAttendees from "../MobileAttendees/MobileAttendees";
import MobileSendPrivateMessage from "../MobileSendPrivateMessage/MobileSendPrivateMessage";

import styles from "./MobileAppMain.module.css";
import { MobileAppMainStyles } from "./MobileAppMainStyles";
import MobilePresenterFeeds from "../MobilePresenterFeeds/MobilePresenterFeeds";

type Props = {};

export default function MobileAppMain({}: Props) {
  const {
    currentMobileMode,
    mobileJumbotronView,
    showNewMessage,
    showMobileLeaveMeeting,
    showMobileSettings,
    showNewQAItem,
    mobileShowSendPrivateMessage
  }: MobileContextType = useContext(MobileContext);

  const classes = MobileAppMainStyles();

  return (
    <React.Fragment>
      {showMobileSettings && <MobileSettings />}

      {showMobileLeaveMeeting && <MobileLeaveMeeting />}

      <MobileChatNewMessage />
      {/* {showNewMessage && <MobileChatNewMessage />} */}

      {/* {mobileShowSendPrivateMessage && (
        <MobileSendPrivateMessage
          isRespond={
            currentMobileMode === StringVariableHelper.MobileModeValues.messages
          }
        />
      )} */}

      <MobileSendPrivateMessage
        isRespond={
          currentMobileMode === StringVariableHelper.MobileModeValues.messages
        }
      />

      <MobileNewQAItem />
      {/* {showNewQAItem && <MobileNewQAItem />} */}

      <div
        className={
          currentMobileMode === StringVariableHelper.MobileModeValues.chat ||
          currentMobileMode === StringVariableHelper.MobileModeValues.qa
            ? `${styles.mobileMainWrapper} ${styles.mobileMainWrapperChatQA}`
            : styles.mobileMainWrapper
        }
      >
        <MobileJumbotronView />

        <div className={styles.mobileMainHolder}>
          {/* Keep MobilePresenterFeeds component mounted */}
          <MobilePresenterFeeds />
          
          {
            {
              [StringVariableHelper.MobileModeValues.none]: (
                <Typography className={classes.mobileMainText}>
                  {!mobileJumbotronView &&
                    "Please select an option from the menu below"}
                </Typography>
              ),
              [StringVariableHelper.MobileModeValues.chat]: <MobileChat />,
              [StringVariableHelper.MobileModeValues.qa]: <MobileAppQA />,
              [StringVariableHelper.MobileModeValues.messages]: (
                <MobileMessages />
              ),
              [StringVariableHelper.MobileModeValues.attendees]: (
                <MobileAttendees />
              ),
            }[currentMobileMode]
          }
        </div>
      </div>

      <MobileToolbar />
    </React.Fragment>
  );
}
