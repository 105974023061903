import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import PlusIcon from "@material-ui/icons/AddCircle";

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";

import { MobilePlusButtonStyles } from "./MobilePlusButtonStyles";

type Props = {};

export default function MobilePlusButton(props: Props) {
  const {
    currentMobileMode,
    setShowNewMessage,
    setShowNewQAItem,
    showNewMessage,
  }: MobileContextType = useContext(MobileContext);

  const clickAndFocusTextField = () => {
    let textField;

    SHOWBOAT.Logger.Log("Current mode:", currentMobileMode);

    textField =
      currentMobileMode === StringVariableHelper.MobileModeValues.chat
        ? document.getElementById("mobileNewMessageTextField")
        : document.getElementById("mobileNewQAItemTextField");

    SHOWBOAT.Logger.Log("Text field:", textField);

    if (textField) {
      //Ensure the text field is visible
      textField.style.visibility = "visible";
      textField.focus();
      textField.click();
    }
  }

  const handlePlusClick = () => {
    if (currentMobileMode === StringVariableHelper.MobileModeValues.chat) {
      setShowNewMessage(true);
    } else {
      setShowNewQAItem(true);
    }

    //Show keyboard and focus text field
    clickAndFocusTextField()
  };

  const classes = MobilePlusButtonStyles();

  return (
    <Button className={classes.mobilePlusButton} onClick={handlePlusClick}>
      <PlusIcon className={classes.plusIcon} />
    </Button>
  );
}
