import React, { useState, useEffect, useRef } from "react";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import { UIHelper } from "../Utilities/UIHelper";

import { testQAItems } from "../Utilities/QATestStore";

export interface QAContextType {
  qaRole: string;
  setQARole: (qaRole: string) => void;
  qaItems: Array<any>;
  setQAItems: (qaItems: Array<any>) => void;
  allowAnonymousQs: boolean;
  setAllowAnonymousQs: (allowAnonymousQs: any) => void;
  attendeesSeeAllQs: boolean;
  setAttendeesSeeAllQs: (attendeesSeeAllQs: boolean) => void;
  upvotedQuestionIDs: Array<string>;
  setUpvotedQuestionIDs: (upvotedQuestionIDs: any) => void;
  handleGetAllQuestions: () => Promise<void>;
  questionIDsPosted: Array<any>;
  setQuestionIDsPosted: (questionIDsPosted: Array<any>) => void;
  submitAnonymously: boolean;
  setSubmitAnonymously: (submitAnonymously: boolean) => void,
  displayedQuestionID: string,
  setDisplayedQuestionID: (displayedQuestionID: string) => void
}

export const QAContext = React.createContext(null);

export const QAContextProvider = (props) => {
  const [qaRole, setQARole] = useState(
    SHOWBOAT.LocalAvatarDataManager.role ===
      StringVariableHelper.ShowboatRoles.presenter
      ? UIHelper.QARole.PRESENTER
      : UIHelper.QARole.ATTENDEE
  );

  const [qaItems, setQAItems] = useState([]);
  const [questionIDsPosted, setQuestionIDsPosted] = useState([]);
  const [allowAnonymousQs, setAllowAnonymousQs] = useState(false);
  const [attendeesSeeAllQs, setAttendeesSeeAllQs] = useState(false);
  const [submitAnonymously, setSubmitAnonymously] = useState(false);

  const [upvotedQuestionIDs, setUpvotedQuestionIDs] = useState([]);

  const [displayedQuestionID, setDisplayedQuestionID] = useState("");

  const qaItemsRef = useRef([]);
  const questionIDsPostedRef = useRef([]);

  useEffect(() => {
    questionIDsPostedRef.current = questionIDsPosted;
  }, [questionIDsPosted]);

  useEffect(() => {
    //Get all questions on reconnections
    SHOWBOAT.WebSocketController.OnLocalPlayerLoggedIn.Add(handleGetAllQuestions);

    //Listen for questions being updated
    SHOWBOAT.WebSocketController.OnQuestionAddedOrUpdated.Add(
      handleQuestionAddedOrUpdated
    );

    //Listen for changes to allow anonymous Qs
    SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Add(
      StringVariableHelper.AllowAnonymousQuestionsName,
      handleAllowAnonymousQsChange
    );

    //Listen for changes to attendees see all Qs
    SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Add(
      StringVariableHelper.AttendeesSeeAllQuestionsName,
      handleAttendeesSeeAllQsChange
    );

    //Listen for nametag changes
    SHOWBOAT.UIEventManager.OnNameTagChanged.Add(handleNameTagChange);

    //Listen for changes to currently-displayed questionID
    SHOWBOAT.WebSocketController.OnHighlightedQuestionIDUpdated.Add(handleDisplayedQuestionChange)

    return function cleanup() {
      SHOWBOAT.WebSocketController.OnQuestionAddedOrUpdated.Remove(
        handleQuestionAddedOrUpdated
      );

      SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Remove(
        StringVariableHelper.AllowAnonymousQuestionsName,
        handleAllowAnonymousQsChange
      );

      SHOWBOAT.ServerVariableManager.OnBookingVariableUpdate.Remove(
        StringVariableHelper.AttendeesSeeAllQuestionsName,
        handleAttendeesSeeAllQsChange
      );

      SHOWBOAT.UIEventManager.OnNameTagChanged.Remove(handleNameTagChange);

      SHOWBOAT.WebSocketController.OnHighlightedQuestionIDUpdated.Remove(
        handleDisplayedQuestionChange
      );
    };
  }, []);
  
  const handleDisplayedQuestionChange = (
    data: SHOWBOAT.HighlightedQuestionIDSetReportData
  ) => {
    SHOWBOAT.Logger.Log("Displayed questionID change:", data.questionID);
    setDisplayedQuestionID(data.questionID);
  };

  const handleAllowAnonymousQsChange = (updateData : SHOWBOAT.VariableData) => {
    setAllowAnonymousQs(updateData.data.value);
  };

  const handleAttendeesSeeAllQsChange = (updateData : SHOWBOAT.VariableData) => {
    setAttendeesSeeAllQs(updateData.data.value);
  };

  const handleNameTagChange = async (firstName, lastName) => {
    let newName = `${firstName} ${lastName}`;
    
    //Loop through QA Items that we have posted and call the socket event
    for (let i = 0; i < questionIDsPostedRef.current.length; i++) {
      //Raise server event to update
      let data = await SHOWBOAT.WebSocketController.SetQuestionAskerName(
        {
          questionID: questionIDsPostedRef.current[i],
          name: newName
        }
      )

      SHOWBOAT.Logger.Log("Update asker name response:", data);
    }

    //Update any questions we have answered
    for (let i = 0; i < qaItemsRef.current.length; i++) {
      if (
        qaItemsRef.current[i].answererUserID ===
        SHOWBOAT.LocalAvatarDataManager.userID
      ) {
        //Raise server event if this avatar data is our own
        await SHOWBOAT.WebSocketController.SetQuestionAnswererName({
          questionID: qaItemsRef.current[i].ID,
          name: newName,
        });
      }
    }
  };

  const handleQuestionAddedOrUpdated = (data : SHOWBOAT.OnQuestionAddedOrUpdatedReportData) => {
    SHOWBOAT.Logger.Log("Question added/updated", data.questionData);

    const newQuestion = data.questionData;
    
    //Check if QA list contains this question
    if (
      qaItemsRef.current.some(
        (item: SHOWBOAT.Question) => item.ID === newQuestion.ID
      )
    ) {
      let currentQAList = qaItemsRef.current;
      let newQAList = currentQAList.map((item: SHOWBOAT.Question) =>
        item.ID === newQuestion.ID ? newQuestion : item
      );

      qaItemsRef.current = newQAList;
      setQAItems(newQAList);
    } else {
      //Just add the item to the QA list
      qaItemsRef.current = [...qaItemsRef.current, newQuestion];
      setQAItems((qaItems) => [...qaItems, newQuestion]);
    }
  };

  const handleGetAllQuestions = async () => {
    try {
      let dataGetQuestions = await SHOWBOAT.WebSocketController.GetAllQuestionAnswerData();

      SHOWBOAT.Logger.Log("Data get questions:", dataGetQuestions);

      if (dataGetQuestions.errorData.error) {
        throw dataGetQuestions.errorData.errorMessage
      }
      
      if (dataGetQuestions.questions) {
        
        let questions = dataGetQuestions.questions;

        for (const question of questions) {
          if (!qaItemsRef.current.some(q => q.ID === question.ID)) {
            setQAItems((qaItems) => [...qaItems, question]);
            qaItemsRef.current = [...qaItemsRef.current, question];
          }
        }
      }
    } catch (error) {
      SHOWBOAT.Logger.Error("Error getting questions", error);
    }
    
    try {
      //Also get currently-displayed question
      const currentQuestion =
        await SHOWBOAT.WebSocketController.GetHighlightedQuestionID();

      SHOWBOAT.Logger.Log("Current question response:", currentQuestion);

      if (currentQuestion.errorData.error) {
        throw currentQuestion.errorData.errorMessage
      }
      
      if (currentQuestion.questionID !== undefined) {
        setDisplayedQuestionID(currentQuestion.questionID);
      }
    } catch (error) {
      SHOWBOAT.Logger.Error("Error getting currently-displayed question", error)
    }
    
  };

  let val: QAContextType = {
    qaRole,
    setQARole,
    qaItems,
    setQAItems,
    allowAnonymousQs,
    setAllowAnonymousQs,
    attendeesSeeAllQs,
    setAttendeesSeeAllQs,
    upvotedQuestionIDs,
    setUpvotedQuestionIDs,
    handleGetAllQuestions,
    questionIDsPosted,
    setQuestionIDsPosted,
    submitAnonymously,
    setSubmitAnonymously,
    displayedQuestionID,
    setDisplayedQuestionID
  };

  return <QAContext.Provider value={val}>{props.children}</QAContext.Provider>;
};
