import { Paper } from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import AvatarPreview from "./AvatarPreview/AvatarPreview";
import ControlCenterButtons from "./ControlCenterButtons/ControlCenterButtons";
import { AppContext, AppContextType } from "../../../context/AppContext";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";

import { ControlCenterStyles } from "./ControlCenterStyles";
import styles from "./ControlCenter.module.css";
import { ServerHelper } from "../../../Utilities/ServerHelper";
import ProductSpecialistButton from "../Popups/ProductSpecialistButton/ProductSpecialistButton";

interface Props {}

export default function ControlCenter(props: Props): ReactElement {
  
  const { currentSidebarMode }: AppContextType = useContext(AppContext);

  const classes = ControlCenterStyles();
  
  let controlCenterClasses;

  //Assemble class list of Control Center based on selected mode
  switch (currentSidebarMode) {
    case "":
      controlCenterClasses = classes.controlCenter;
      break;
    case "settings":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterSettings}`;
      break;
    case "room":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterRoom}`;
      break;
    case "chat":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterChat}`;
      break;
    case "support":
    case "attendees":
    case "calendar":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterAttendees}`;
      break;
    case "exit":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterExit}`;
      break;
    case "QA":
      controlCenterClasses = `${classes.controlCenter} ${classes.controlCenterQA}`;
      break;
  }

  //If we are a presenter, add that class to class list
  if (
    SHOWBOAT.LocalAvatarDataManager.role ===
    StringVariableHelper.ShowboatRoles.presenter
  ) {
    controlCenterClasses = `${controlCenterClasses} ${classes.controlCenterClassesPresenter}`;
  }


  return (
    <Paper className={controlCenterClasses}>
      <div className={styles.controlCenterContentHolder}>
        <AvatarPreview />

        <ControlCenterButtons />
      </div>

     
      {/* <ProductSpecialistButton /> */}
    </Paper>
  );
}
