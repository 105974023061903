import { makeStyles, createStyles } from "@material-ui/core/styles";

const ControlCenterButtonStyles = makeStyles((theme: any) =>
  createStyles({
    controlCenterButton: {
      padding: 0,
      borderRadius: 4,
      position: "relative",
      height: 35,
      backgroundColor: theme.palette.controlCenterButton.main,
      color: theme.palette.text.primary,
      minWidth: 0,
      boxShadow: "none",
      width: 52.4,
      flexGrow: 1,
      justifyContent: "space-between",
      display: "inline-block",
      marginRight: 4,
      marginBottom: 4,
      paddingTop: 5,
      overflow: "hidden",
      boxSizing: "border-box",
      border: "2px solid transparent",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.controlCenterButton.main,
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "@media(hover: none)": {
        "&:hover": {
          backgroundColor: theme.palette.controlCenterButton.main,
          borderColor: "transparent !important",
        },
      },
    },
    "@keyframes pulse": {
      "0%": {
        boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
      },
      "100%": {
        boxShadow: `0 0 0 100px ${theme.palette.primary.main}`,
      },
    },
    controlCenterButtonMuted: {
      backgroundColor: theme.palette.controlCenterButtonMuted.main,
      color: "black",
      "&:hover": {
        backgroundColor: theme.palette.controlCenterButtonMuted.main,
      },
    },
    controlCenterButtonAudienceMuted: {
      backgroundColor: "#B7B7B7",
      color: "#777777",
      "&:hover": {
        backgroundColor: "#B7B7B7",
      },
    },
    controlCenterButtonBottom: {
      width: 109,
    },
    controlCenterButtonActivated: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    controlCenterButtonChat: {
      display: "flex",
      alignContent: "flex-start",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonLabel: {
      color: theme.palette.text.primary,
    },
    tooltip: {
      fontSize: 14,
      borderRadius: 4,
      border: "2px solid",
      borderColor: "white",
      backgroundColor: "#CCCCCC",
      right: -17,
      top: 0,
      color: "#313131",
    },
    tooltipPopover: {
      opacity: 1,
      left: 20,
    },
    tooltipArrow: {
      "&::before": {
        border: "2px solid",
        borderColor: "white",
        boxSizing: "border-box",
        backgroundColor: "#CCCCCC",
        position: "relative",
        bottom: 2,
      },
    },
  })
);

export { ControlCenterButtonStyles };
