import React, { useContext, useEffect, useRef, useState } from "react";
import PanelGroup from "react-panelgroup";
import DragIcon from "@material-ui/icons/DragHandle";
import { AppContext, AppContextType } from "../../context/AppContext";
import {
  PresenterContext,
  PresenterContextType,
} from "../../context/PresenterContext";

import styles from "./MainAppCanvas.module.css";
import { MainAppCanvasStyles } from "./MainAppCanvasStyles";
import TwoDCameras from "./TwoDCameras/TwoDCameras";
import { Paper } from "@material-ui/core";
import { isSafari } from "react-device-detect";
import { TwoDHelper } from "../../Utilities/TwoDHelper";
import TwoDCamerasBadge from "./TwoDCamerasBadge/TwoDCamerasBadge";

type Props = {};

export default function MainAppCanvas({}: Props) {
  const { currentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const { jumbotronFullscreenView }: PresenterContextType =
    useContext(PresenterContext);

  const [rightOffset, setRightOffset] = useState(140);
  const [resizePanelSize, setResizePanelSize] = useState(250);

  const [resizeMinSize, setResizeMinSize] = useState(250);

  const [hiddenCount, setHiddenCount] = useState(1);

  const [hideCanvas, setHideCanvas] = useState(false);

  const resizeMinSizeRef = useRef(250);
  const resizePanelSizeRef = useRef(250);
  const firstUpdateRef = useRef(true);
  const dragHandleRef = useRef(null);
  const isCollapsedRef = useRef(false);
  const isUsingDefaultSize = useRef(false);

  useEffect(() => {
    let rightOffset;

    //Change value of rightOffset based on currently-selected sidebar mode
    switch (currentSidebarMode) {
      case "":
        rightOffset = 150;
        break;
      case "settings":
        rightOffset = 575;
        break;
      case "chat":
      case "QA":
        rightOffset = 455;
        break;
      case "room":
      case "support":
      case "attendees":
      case "calendar":
        rightOffset = 445;
        break;
      case "exit":
        rightOffset = 375;
        break;
    }

    setRightOffset(rightOffset);

    //Listen for changes in camera items height/width
    TwoDHelper.OnCameraItemResize.Add(handleCameraItemResize);

    //Reposition camera items badge
    let cameraBadge = document.getElementById("twoDCamerasBadge");

    if (cameraBadge) {
      cameraBadge.style.right = `${rightOffset - 85}px`;
    }
  }, [currentSidebarMode]);

  useEffect(() => {
    SHOWBOAT.ShowboatLoader.getEngine().resize();
  }, [rightOffset]);

  useEffect(() => {
    SHOWBOAT.ShowboatLoader.getEngine().resize();

    //Append drag icon to divider container
    let divider = document.getElementsByClassName("divider")[0];
    let dragHandle = document.getElementById("dragHandleIcon");
    let canvasHolder = document.getElementById("mainCanvasContentHolder");

    if (divider && (dragHandle || dragHandleRef.current)) {
      if (!dragHandle) {
        dragHandle = dragHandleRef.current;
      }

      if (dragHandleRef.current === null) {
        dragHandleRef.current = dragHandle;
      }

      if (jumbotronFullscreenView) {
        dragHandle.style.visibility = "visible";
        dragHandle.style.bottom = "0px";

        divider.appendChild(dragHandle);
      } else {
        dragHandle.style.visibility = "hidden";
      }
    }
  }, [jumbotronFullscreenView]);

  useEffect(() => {
    if (!jumbotronFullscreenView) {
      //Ensure canvas is focused
      (window as any).babylonCanvas.focus();
    }

    if (!isSafari) {
      //Add border radius to container when jumbotronFullscreenView is active
      if (jumbotronFullscreenView) {
        let container = document.getElementsByClassName("panelWrapper")[0];

        if (container) {
          (container as HTMLElement).style.borderTopRightRadius = "10px";
          (container as HTMLElement).style.borderTopLeftRadius = "10px";
        } else {
          //Otherwise, remove the border radius
          (container as HTMLElement).style.borderTopRightRadius = "0px";
          (container as HTMLElement).style.borderTopLeftRadius = "0px";
        }
      }
    }
  }, [jumbotronFullscreenView]);

  const handleOnUpdate = (data) => {
    //Position drag icon
    SHOWBOAT.ShowboatLoader.resize();

    let cameraBadge = document.getElementById("twoDCamerasBadge");

    if (data && data[1] && data[1].size) {
      //If size is 0 and data is less than 0, do nothing
      if (data[1].size < 0) {
        return;
      }

      if (cameraBadge) {
        cameraBadge.style.bottom = `${data[1].size - 18}px`;
      }

      resizePanelSizeRef.current = data[1].size;
      setResizePanelSize(data[1].size);
    }

    //Check if height of canvas < 100px
    //If so, hide it
    let canvasHolder = document.getElementById("canvasWrapper");
    let canvas = document.getElementById("showboatCanvas");
    if (canvasHolder && canvas) {
      if (canvasHolder.clientHeight < 100) {
        canvas.style.visibility = "hidden";
      } else {
        canvas.style.visibility = "visible";
      }
    }

    //Raise resize event
    TwoDHelper.OnCameraViewResize.Raise();
  };

  const handleOnDragEnd = () => {
    SHOWBOAT.ShowboatLoader.resize();
  };

  const handleCameraItemResize = (heightWidth: number) => {
    if (firstUpdateRef.current) {
      if (isNaN(heightWidth)) return;

      setResizePanelSize(heightWidth + 5);
      firstUpdateRef.current = false;

      isUsingDefaultSize.current = true;
    }

    if (isNaN(heightWidth)) return;

    let newValue = heightWidth - heightWidth / 2;
    setResizeMinSize(newValue);
    resizeMinSizeRef.current = newValue;
  };

  const classes = MainAppCanvasStyles();

  return (
    <React.Fragment>
      <div id="twoDOverlay" className={styles.mainCanvasHolder} key="2DHolder">
        <TwoDCamerasBadge count={hiddenCount} resizePanelSize={resizePanelSize} />

        <Paper
          className={classes.mainCanvasContentHolder}
          id="mainCanvasContentHolder"
          elevation={0}
          style={{
            width: jumbotronFullscreenView
              ? `calc(100vw - ${rightOffset}px)`
              : "100%",
            height: jumbotronFullscreenView
              ? isPresenter
                ? "calc(100vh - 60px)"
                : "calc(100vh - 20px)"
              : "100%",
            left: jumbotronFullscreenView ? "10px" : "0px",
            top: jumbotronFullscreenView
              ? isPresenter
                ? "50px"
                : "10px"
              : "0px",
          }}
        >
          <div id="dragHandleIcon" className={styles.dragIconHolder}>
            <DragIcon className={classes.dragIcon} />
          </div>

          <PanelGroup
            panelWidths={[
              { resize: "stretch" },
              {
                size: resizePanelSize,
                minSize: 0,
                resize: "dynamic",
              },
            ]}
            direction="column"
            spacing={20}
            borderColor="#9A9A9A"
            onUpdate={handleOnUpdate}
            onResizeEnd={handleOnDragEnd}
          >
            <div
              id="canvasWrapper"
              className={
                jumbotronFullscreenView
                  ? `${styles.canvasWrapper} ${styles.canvasWrapperJumbotronView}`
                  : styles.canvasWrapper
              }
              style={{
                backgroundColor: jumbotronFullscreenView
                  ? "black"
                  : "transparent",
                position: jumbotronFullscreenView ? "absolute" : "fixed",
              }}
            ></div>

            {jumbotronFullscreenView && (
              <TwoDCameras resizePanelSize={resizePanelSize} />
            )}
          </PanelGroup>
        </Paper>
      </div>
    </React.Fragment>
  );
}
