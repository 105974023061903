import { Button, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { ServerHelper } from "../../../../Utilities/ServerHelper";

import styles from "./EntryModal.module.css";
import { EntryModalStyles } from "./EntryModalStyles";

type Props = {
  handleEntryModalClose: () => void;
};

export default function EntryModal(props: Props) {
  useEffect(() => {
    if (ServerHelper.bypass) {
      //Add listener for arrow key press or WASD key press
      document.addEventListener("keydown", handleKeyDown);

      SHOWBOAT.UIEventManager.OnUpVirtualArrowKeyDown.Add(
        handleMobileControlPress
      );
      SHOWBOAT.UIEventManager.OnLeftVirtualArrowKeyDown.Add(
        handleMobileControlPress
      );
      SHOWBOAT.UIEventManager.OnDownVirtualArrowKeyDown.Add(
        handleMobileControlPress
      );
      SHOWBOAT.UIEventManager.OnRightVirtualArrowKeyDown.Add(
        handleMobileControlPress
      );
    }
    

    return function cleanup() {
      if (ServerHelper.bypass) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const handleMobileControlPress = () => {
    executeClose();
  };

  const handleKeyDown = (e) => {
    let keyCodes = [37, 38, 39, 40, 87, 65, 83, 68, 81, 69];

    if (keyCodes.includes(e.keyCode)) {
      executeClose();
    }
  };

  const handleEnterRoomButtonClick = () => {
    executeClose();
  };

  const executeClose = () => {
    //Close the popup and unlock audio/movement
    SHOWBOAT.UIEventManager.OnAudioUnlockEvent.Raise();
    SHOWBOAT.UIEventManager.OnPlayerMovementUnlocked.Raise();

    props.handleEntryModalClose();
  };

  const classes = EntryModalStyles();

  return (
    <div className={styles.entryModalWrapper}>
      <Paper className={classes.entryModal}>
        <Paper className={classes.entryModalHeaderHolder}>
          <Typography className={classes.entryModalHeader}>
            WELCOME TO
          </Typography>

          <Typography className={classes.entryModalBookingName}>
            {ServerHelper.bookingName}
          </Typography>
        </Paper>

        <div className={styles.instructionHolder}>
          <img
            className={styles.arrowKeys}
            src="assets/images/arrowkeys.svg"
            alt="arrow keys"
          />

          {isMobile ? (
            <Typography className={classes.instructionHeader}>
              Use the on-screen controls to move
            </Typography>
          ) : (
            <Typography className={classes.instructionHeader}>
              Use your keyboard arrow keys to move
            </Typography>
          )}
        </div>

        <Button
          className={classes.enterButton}
          onClick={handleEnterRoomButtonClick}
          classes={{
            label: classes.enterButtonLabel,
          }}
        >
          ENTER ROOM
        </Button>
      </Paper>
    </div>
  );
}
