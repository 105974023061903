import React, {useState } from 'react';

export interface IntakeContextType {
  unblockEnter : boolean,
  toggleUnblockEnter : (unblockEnter : boolean) => void,
  disableButtons : boolean,
  toggleDisableButtons : (disableButtons : boolean) => void,
  homeWasClicked : boolean,
  toggleHomeWasClicked : (homeWasClicked : boolean) => void,
  avatarLoadComplete: boolean,
  toggleAvatarLoadComplete: (loadComplete: boolean) => void
}

export const IntakeContext = React.createContext(null);

export const IntakeContextProvider = (props) => {

  const [ unblockEnter, toggleUnblockEnter ] = useState(false);

  const [ disableButtons, toggleDisableButtons ] = useState(false);

  const [ homeWasClicked, toggleHomeWasClicked ] = useState(false);

  const [ avatarLoadComplete, toggleAvatarLoadComplete ] = useState(false);
  
  const IntakeContextProviderValue : IntakeContextType = {
    unblockEnter,
    toggleUnblockEnter,
    disableButtons,
    toggleDisableButtons,
    homeWasClicked,
    toggleHomeWasClicked,
    avatarLoadComplete,
    toggleAvatarLoadComplete
  }

  return (
    <IntakeContext.Provider
      value={IntakeContextProviderValue}
    >
      {props.children}
    </IntakeContext.Provider>
  )
}