import { makeStyles, createStyles } from '@material-ui/core/styles';

const IntakeHeaderStyles = makeStyles((theme: any) => createStyles({
  "@keyframes fadein": {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  "@keyframes fadeout": {
    from: {opacity: 1},
    to: {opacity: 0},
  },
  logoHolder: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 145,
    width: 145,
    backgroundColor: theme.palette.intakePaper.main,
    borderRadius: 10,

  },
  eventWelcome: {
    fontSize: 20,
    position: "absolute",
    left: 169,
    top: 27,
    fontWeight: 400,
    fontFamily: "Open Sans, sans-serif"
  },
  eventName: {
    fontSize: 30,
    position: "relative",
    top: 3,
    fontWeight: 400,
    display: "inline-block",
    width: 595,
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontFamily: "Open Sans, sans-serif",
    color: "#FFB700",
    [theme.breakpoints.down('sm')]: {
      display: "inline-block",
      width: 363,
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  },
  eventDate: {
    fontSize: 24,
    position: "absolute",
    left: 169,
    bottom: 10,
    fontWeight: 400,
    fontFamily: "Open Sans, sans-serif"
  },
  homeButton: {
    position: "absolute",
    top: 33,
    right: 0,
    width: 114,
    height: 56,
  },
  enterButton: {
    backgroundColor: theme.palette.intakeBlue.main,
    color: "white",
    width: 180,
    padding: 0,
    height: 63,
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      right: 0,
    }
  },
  deadLinkErrorMessageHolder: {
    width: 960,
    minHeight: 80,
    backgroundColor: theme.palette.intakePaper.main,
    textAlign: "center",
    position: "relative",
    top: 75,
    marginBottom: 5,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 720,
    }
  },
  deadLinkErrorMessage: {
    fontSize: 16,
    position: "relative",
    display: "inline-block",
    top: 28,
    width: 875,
    paddingBottom: 55,
    [theme.breakpoints.down('sm')]: {
      width: 650
    }
  },
  eventEndedErrorMessage: {
    fontSize: 16,
    position: "relative",
    display: "inline-block",
    top: 28,
    [theme.breakpoints.down('sm')]: {
    }
  },
  eventEndedErrorMessageHolder: {
    width: 960,
    minHeight: 80,
    backgroundColor: theme.palette.intakePaper.main,
    position: "relative",
    top: 75,
    marginBottom: 5,
    textAlign: "center",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 720,
    }
  },
  buttonTooltip: {
    position: "absolute",
    backgroundColor: theme.palette.tooltip.main,
    width: "100%",
    textAlign: "center",
    height: 40,
    borderRadius: 25,
    top: 0,
    right: 0
  },
  tooltipText: {
    color: theme.palette.intakePaper.main,
    fontSize: 16,
    position: "relative",
    top: 9,
  },
  capacityErrorMessageHolder: {
    width: 960,
    minHeight: 80,
    backgroundColor: theme.palette.intakePaper.main,
    position: "relative",
    top: 75,
    marginBottom: 5,
    textAlign: "center",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 720,
    }
  },
  capacityErrorMessage: {
    color: "#FFB700",
    fontSize: 16,
    position: "relative",
    display: "inline-block",
    top: 23,
  },
  errorIcon: {
    color: "#FFB700",
    display: "inline-block",
    position: "relative",
    right: 12,
    top: 4,
  },
  textfit: {
    position: "relative",
    bottom: 3
  }
})
)

export { IntakeHeaderStyles };