import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileAudioUnlockPopupStyles = makeStyles((theme: any) =>
  createStyles({
    audioUnlockPopup: {
      width: "90%",
      height: 240,
      display: "inline-block",
      margin: 0,
      overflow: "hidden",
      backgroundColor: "#DDDDDD",
      borderRadius: 10,
    },
    button: {
      padding: 0,
      width: "100%",
      height: 62,
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontSize: 16,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    audioUnlockText: {
      fontSize: 20,
      color: "#272727",
    },
    boxIcon: {
      position: "relative",
      right: 5,
      bottom: 1,
      fontSize: 30,
    },
  })
);

export { MobileAudioUnlockPopupStyles };