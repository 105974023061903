export class StringVariableHelper {
  //Server variables
  public static LocalVideoVolumeEventName = "PRESENTER#LocalVideoVolume";
  public static PresentationEventName = "PRESENTER#Presentation";
  public static SilenceAudienceEventName = "PRESENTER#SilenceAudience";
  public static SpatialAudioEventName = "PRESENTER#SpatialAudio";
  public static AudioDistanceEventName = "PRESENTER#AudioDistance";
  public static EventMuteEventName = "PRESENTER#EventMute";
  public static AdvancedDebuggingMessageName = "AdvancedDebugging";
  public static ForceMoveMessageName = "ForceMove";
  public static AttendeesSeeAllQuestionsName = "PRESENTER#AttendeesSeeAllQs";
  public static AllowAnonymousQuestionsName = "PRESENTER#AllowAnonymousQs";
  public static Force2DModeName = "PRESENTER#Force2DMode";
  public static PresentersOnlyInTwoDMode = "PRESENTER#PresentersOnlyInTwoDMode";

  public static BackstageRoomID = "backstage";

  public static ShowboatPartitions = {
    backstage: "backstage",
    attendees: "attendees",
    presenter: "presenter",
  };

  public static ShowboatRoles = {
    presenter: "presenter",
    attendees: "attendees",
  };

  //UI Variables
  public static ShowboatModeType = {
    attendees: "attendees",
    presenter: "presenter",
    backstage: "backstage",
  };

  public static PresenterToolNames = {
    livestream: "livestream",
    localVideo: "localVideo",
    screenshare: "screenshare",
    slides: "slides",
    webcam: "webcam",
  };

  public static ClientPermissions = {
    CAN_SCREENSHARE: "CanScreenshare",
    CAN_SHARE_LOCAL_VIDEO: "CanShareLocalVideo",
    CAN_SHARE_WEBCAM: "CanShareWebcam",
    CAN_SHARE_LIVESTREAM: "CanShareLivestream",
    CAN_SHARE_SLIDES: "CanShareSlides",
    CAN_CONTROL_AVATAR_AUDIO: "CanControlAvatarAudio",
    CAN_USE_LASER: "CanUseLaser",
    CAN_TELEPORT: "CanTeleport",
    CAN_SHARE_DEFAULT: "CanShareDefault",
    CAN_ADMIT_OTHERS: "CanAdmitOthers",
    CAN_DO_TECH_SUPPORT: "CanDoTechSupport",
    CAN_OPEN_NEW_ROOMS: "CanOpenNewRooms",
    CAN_REMOVE_OTHERS: "CanRemoveOthers",
    CAN_BREAKOUT: "CanBreakout",
    CAN_PRESENT: "CanPresent",
    CAN_ACCESS_BRIDGE: "CanAccessBridge",
  };

  public static ServerPermissions = {
    CAN_GO_BACKSTAGE: "CanGoBackstage",
    CAN_OPEN_ROOMS: "CanOpenRooms",
    CAN_CLOSE_ROOMS: "CanCloseRooms",
    CAN_SET_RESTRICTED_VARIABLES: "CanSetRestrictedVariables",
    CAN_SET_OTHER_USERS_PERMISSIONS: "CanSetOtherUsersPermissions",
    CAN_ANSWER_QUESTIONS: "CanAnswerQuestions",
    CAN_SET_QUESTION_MODERATION_STATUS: "CanSetQuestionModerationStatus",
    CAN_APPROVE_QUESTIONS: "CanApproveQuestions",
    CAN_SET_QUESTION_INTERACTER_GAME: "CanSetQuestionInteracterGame",
    CAN_SET_LOW_IMPORTANCE_VARIABLES: "CanSetLowImportanceVariables",
    CAN_SET_HIGH_IMPORTANCE_VARIABLES: "CanSetHighImportanceVariables",
    CAN_REPORT_GROUP_CHATS: "CanReportGroupChats",
    CAN_ALLOW_IN_PARTIAL_LOGIN_USERS: "CanAllowInPartialLoginUsers",
    CAN_SEND_SECURE_MESSAGE: "CanSendSecureMessage",
    CAN_CHANGE_PARTITIONS: "CanChangePartitions",
    CAN_CLAIM_NETWORKED_TRANSFORMS: "CanClaimNetworkedTransforms",
    CAN_CLAIM_NETWORKED_DATA: "CanClaimNetworkedData",
    CAN_SET_HIGHLIGHTED_QUESTION: "CanSetHighlightedQuestion",
    CAN_ACCESS_DEBUG_DATA: "CanAccessDebugData",
    CAN_BECOME_PRESENTER: "CanBecomePresenter",
  };

  public static UserPermissions = {
    IS_SUPER_ADMIN: "IsSuperAdmin",
    IS_TENANT_MANAGER: "IsTenantManager",
    CAN_VIEW_BOOKINGS: "CanViewBookings",
    CAN_CREATE_BOOKINGS: "CanCreateBookings",
    CAN_ADD_USERS: "CanAddUsers",
  };

  public static LoginFailReasons = {
    DeletedLoginCode: "DeletedLoginCode",
    Ended: "Ended",
    CapacityFull: "CapacityFull",
    NotStarted: "NotStarted",
    SystemCheck: "SystemCheck",
    SystemCheckLoginCodes: [
      "systemcheck",
      "SystemCheck",
      "Systemcheck",
      "systemCheck",
    ],
  };

  public static PopupErrorText = {
    socketDisconnect:
      "Temporarily disconnected from server, attempting auto reconnect.",
    webcamDisconnect:
      "Your video stream is temporarily interrupted, attempting auto reconnect.",
  };

  public static LocalStorageProperties = {
    SystemCheckID: "systemCheckID",
    BannedLoginCodes: "bannedLoginCodes",
    PreferredCameraID: "preferredCameraID",
    PreferredCameraState: "preferredCameraState",
    PreferredMicID: "preferredMicrophoneID",
    PreferredMicState: "preferredMicrophoneState",
    PreferredSpeakerID: "preferredSpeakerID",
    CookieWarning: "hasBeenShownCookieWarning",
    AttendeesIDArray: "attendeesIDArray",
    IntakeData: "intakeData",
    HasCompletedIntakeData: "hasCompletedIntakeData",
    PreferredColorHex: "preferredColorHex",
    PreferredFaceIndex: "preferredFaceIndex",
  };

  public static MobileModeValues = {
    none: "none",
    chat: "chat",
    attendees: "attendees",
    messages: "messages",
    qa: "qa",
    presenterFeeds: "presenterFeeds",
  };

  public static ARSType = {
    question: "question",
  };

  public static ChangeEventNames = {
    Camera: "Camera",
    Mic: "Mic",
    CameraEnabled: "CameraAndMic",
    LaserEnabled: "LaserEnabled",
    DeviceDebug: "DeviceDebug",
    ForceMute: "ForceMute",
    LoadComplete: "LoadComplete",
    FaceNumber: "FaceNumber",
    ColorNumber: "ColorNumber",
    NameTag: "NameTag",
    TeleportAvailability: "TeleportAvailability",
    AdditionalData: "AdditionalData",
    IsPresenting: "IsPresenting",
  };
}

(window as any).StringVariableHelper = StringVariableHelper;
