import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileNotSupportedErrorStyles = makeStyles((theme: any) =>
  createStyles({
    mobileNotSupportedMessage: {
      color: "white",
      fontSize: 19,
      width: "76%",
    },
    warningIcon: {
      color: "#fac800",
      fontSize: 30,
    },
  })
);

export { MobileNotSupportedErrorStyles };