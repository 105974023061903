import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import BoxIcon from "@material-ui/icons/Crop54";

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";

import { MobileJumbotronViewStyles } from "./MobileJumbotronViewStyles";
import styles from "./MobileJumbotronView.module.css";
import { UIHelper } from "../../../Utilities/UIHelper";

type Props = {};

export default function MobileJumbotronView({}: Props) {
  const {
    mobileJumbotronView,
    setMobileJumbotronView,
    mobileJumbotronFullscreen,
    setMobileJumbotronButtonPressed,
    jumbotronContentType,
    setJumbotronContentType,
    jumbotronElement,
    setJumbotronElement,
    jumbotronElementSrc,
    setJumbotronElementSrc,
    handleShowAudioUnlock,
    audioUnlockPressedForJumbotron,
    setAudioUnlockPressedForJumbotron,
    showNewQAItem,
    showNewMessage,
    mobileShowSendPrivateMessage,
  }: MobileContextType = useContext(MobileContext);

  const [showPlayButton, setShowPlayButton] = useState(false);
  const [playHasBeenCalled, setPlayHasBeenCalled] = useState(false);

  const showNewQAItemRef = useRef(showNewQAItem);
  const showNewMessageRef = useRef(showNewMessage);
  const showSendPrivateMessageRef = useRef(mobileShowSendPrivateMessage);

  //Ensure shadow refs stay up to date with context values
  useEffect(() => {
    showNewQAItemRef.current = showNewQAItem;
    showNewMessageRef.current = showNewMessage;
    showSendPrivateMessageRef.current = mobileShowSendPrivateMessage;
  }, [showNewQAItem, showNewMessage, mobileShowSendPrivateMessage]);

  useEffect(() => {
    //Listen for jumbotron changes
    SHOWBOAT.JumbotronHelper.OnDisplayJumbotronContent.Add(
      handleJumbotronDisplayChange
    );

    //Listen for jumbotron pauses
    SHOWBOAT.JumbotronHelper.OnJumbotronPaused.Add(
      handleJumbotronPlayFailedOrPaused
    );

    //Listen for jumbotron fails
    SHOWBOAT.JumbotronHelper.OnJumbotronPlayFailed.Add(
      handleJumbotronPlayFailedOrPaused
    );

    //Listen for jumbotron plays
    SHOWBOAT.JumbotronHelper.OnJumbotronPlayed.Add(handleJumbotronPlayed);

    return function cleanup() {
      SHOWBOAT.JumbotronHelper.OnDisplayJumbotronContent.Remove(
        handleJumbotronDisplayChange
      );

      SHOWBOAT.JumbotronHelper.OnJumbotronPaused.Add(
        handleJumbotronPlayFailedOrPaused
      );

      SHOWBOAT.JumbotronHelper.OnJumbotronPlayFailed.Remove(
        handleJumbotronPlayFailedOrPaused
      );

      SHOWBOAT.JumbotronHelper.OnJumbotronPlayed.Remove(handleJumbotronPlayed);
    };
  }, []);

  const handleJumbotronPlayFailedOrPaused = () => {
    SHOWBOAT.Logger.Log("JUMBOTRON PLAY FAILED OR PAUSED");
    
    //If QA, DM, or Chat overlays are open, show play button
    if (
      showNewMessageRef.current ||
      showNewQAItemRef.current ||
      showSendPrivateMessageRef.current
    ) {
      setShowPlayButton(true);
    } else {
      //Otherwise, show audio unlock popup
      handleShowAudioUnlock(true, "jumbotron");
    }
  };

  const handleJumbotronPlayed = () => {
    //Hide play button
    setShowPlayButton(false);
  };

  useEffect(() => {
    if (mobileJumbotronFullscreen === false) {
      clearJumbotronPreviewAndAddContent();
    }
  }, [jumbotronElement, mobileJumbotronView, mobileJumbotronFullscreen]);

  useEffect(() => {
    //When jumbotron view is opened, attempt to play the video
    if (mobileJumbotronView) {
      SHOWBOAT.JumbotronHelper.playCurrentJumbotronElement();
    }
  }, [mobileJumbotronView]);

  const clearJumbotronPreviewAndAddContent = () => {
    let container = document.getElementById("mobileJumbotronHolder");

    if (container) {
      //Ensure the container div is cleared of any non-images
      for (let i = 0; i < container.childNodes.length; i++) {
        if (
          container.childNodes[i].nodeName !== "IMG" &&
          container.childNodes[i].nodeName !== "BUTTON"
        ) {
          container.removeChild(container.childNodes[i]);
        }
      }

      if (
        jumbotronElement !== null &&
        (jumbotronContentType === SHOWBOAT.JumbotronContentType.Video ||
          jumbotronContentType === SHOWBOAT.JumbotronContentType.Canvas)
      ) {
        if (
          jumbotronContentType === SHOWBOAT.JumbotronContentType.Video &&
          jumbotronElement.play !== undefined
        ) {
          //Set necessary video styles and attributes
          jumbotronElement.style.height = "100%";
          jumbotronElement.style.width = "auto";
          jumbotronElement.style.display = "inlineBlock";
          jumbotronElement.style.position = "relative";
          jumbotronElement.style.maxWidth = "100%";
          jumbotronElement.style.objectFit = "contain";
          jumbotronElement.controls = false;
          (jumbotronElement as any).removeAttribute("controls");
          (jumbotronElement as any).setAttribute("playsinline", "");
          (jumbotronElement as any).setAttribute("webkit-playsinline", "");
        }
        container.appendChild(jumbotronElement);
      } else if (jumbotronContentType === SHOWBOAT.JumbotronContentType.Image) {
        //Hide play button if it is an image coming in
        setShowPlayButton(false);
      }
    }
  };

  const handleJumbotronDisplayChange = (
    type: SHOWBOAT.JumbotronContentType,
    content: string | HTMLVideoElement,
    mode: SHOWBOAT.JumbotronMode
  ) => {
    //If livestream comes through, allow unlock popup to show again
    if (mode === SHOWBOAT.JumbotronMode.livestream) {
      setAudioUnlockPressedForJumbotron(false);
    }

    setJumbotronContentType(type);

    //Check if type is image or video
    if (type === SHOWBOAT.JumbotronContentType.Image) {
      //Set image src in state
      setJumbotronElementSrc(content as string);

      //Create dummy image element to force state change
      let newImg: HTMLImageElement = document.createElement("img");
      (newImg as HTMLImageElement).crossOrigin = "anonymous";
      (newImg as HTMLImageElement).src = content as string;

      setJumbotronElement(newImg);
    } else if (
      type === SHOWBOAT.JumbotronContentType.Video ||
      type === SHOWBOAT.JumbotronContentType.Canvas
    ) {
      //Set styles for the new video

      //Set state with the passed video element
      setJumbotronElement(content);
    }
  };

  const handlePlayVideo = () => {
    //Unlock audio
    SHOWBOAT.UIEventManager.OnAudioUnlockEvent.Raise();

    SHOWBOAT.JumbotronHelper.playCurrentJumbotronElement();
  };

  const classes = MobileJumbotronViewStyles();

  return (
    <div
      className={styles.mobileJumbotronHolder}
      id="mobileJumbotronHolder"
      style={{ display: mobileJumbotronView ? "inline-block" : "none" }}
    >
      {showPlayButton && (
        <Button className={classes.jumbotronButton} onClick={handlePlayVideo}>
          <BoxIcon className={classes.boxIcon} />
          START FEED
        </Button>
      )}

      {jumbotronContentType === SHOWBOAT.JumbotronContentType.Image &&
        jumbotronElementSrc && (
          <img
            src={jumbotronElementSrc}
            className={styles.twoDJumbotronImage}
            crossOrigin="anonymous"
          />
        )}
    </div>
  );
}
