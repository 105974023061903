import { makeStyles, createStyles } from "@material-ui/core/styles";

const MobileDisconnectOverlayStyles = makeStyles((theme: any) =>
  createStyles({
    disconnectPopup: {
      width: "90%",
      height: 240,
      display: "inline-block",
      margin: 0,
      overflow: "hidden",
      backgroundColor: "#DDDDDD",
      borderRadius: 10,
    },
    disconnectText: {
      fontSize: 20,
      color: "#272727",
    },
    loadingSpinner: {
      width: 40,
      height: 40,
      color: "#272727",
      position: "relative",
      bottom: 10,
    },
  })
);

export { MobileDisconnectOverlayStyles };
