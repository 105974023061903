import { Drawer, Typography, Link, Button } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { StringVariableHelper } from '../../Utilities/StringVariableHelper';

import styles from "./CookieNotification.module.css";
import { CookieNotificationStyles } from './CookieNotificationStyles';

interface Props {
  hideCookieNotification: boolean,
}

export default function CookieNotification(props: Props): ReactElement {

  const [ cookieNotification, toggleCookieNotification ] = useState(false);

  const cookieTimeoutRef = useRef(null);

  useEffect(() => {

    //only cookie warning if hasn't been shown before
    if (!localStorage.getItem(StringVariableHelper.LocalStorageProperties.CookieWarning)) {
      //start cookie notification ref
      cookieTimeoutRef.current = setTimeout(function() {
        toggleCookieNotification(true);
      },500);
    }

    return function cleanup() {
      if (cookieTimeoutRef.current) {
        clearTimeout(cookieTimeoutRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (props.hideCookieNotification) {
      toggleCookieNotification(false);
    }
  }, [props.hideCookieNotification])

  const handleOKClick = () => {
    localStorage.setItem(StringVariableHelper.LocalStorageProperties.CookieWarning, "true");

    if (cookieTimeoutRef.current) {
      clearTimeout(cookieTimeoutRef.current);
    }

    toggleCookieNotification(false);
  }

  const classes = CookieNotificationStyles();
  
  return (
    <Drawer
      classes={{
        paper: classes.cookieNotification,
      }}
      anchor="bottom"
      variant="persistent"
      open={cookieNotification}
    >
      <div className={styles.cookieNotificationHolder}>
        <Typography variant="body1" className={classes.cookieText}>
          {
            "We use cookies to ensure that we give you the best experience on our website. Click OK, or continue to use the site to agree to our"
          }
            <Link
              className={classes.link}
              underline="always"
              href="https://showboat.live/privacy"
              rel="noopener"
              target="_blank"
              color="inherit"
              style={{
                marginLeft: 5,
              }}
            >
              Privacy Policy
            </Link>

            and

            <Link
              underline="always"
              href="https://showboat.live/terms"
              target="_blank"
              color="inherit"
              style={{
                marginLeft: 5
              }}
            >
              Terms of Use
            </Link>
            .
        </Typography>

        <Button onClick={handleOKClick} className={classes.okButton}>
          OK
        </Button>
      </div>
    </Drawer>
  );
}
