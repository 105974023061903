import React, { useContext, useEffect } from 'react';
import { orderBy } from 'natural-orderby';

import {
  MobileContext,
  MobileContextType,
} from "../../../context/MobileContext";
import { QAContext, QAContextType } from '../../../context/QAContext';
import { UIHelper } from '../../../Utilities/UIHelper';
import QAItem from '../../components/ControlCenter/QASidebar/QAItem/QAItem';

import styles from "./MobileQA.module.css";

type Props = {}

export default function MobileAppQA({}: Props) {

  const {
    qaItems
  } : QAContextType = useContext(QAContext);
  
  const { setShowNewQAItem } : MobileContextType = useContext(MobileContext);

  useEffect(() => {
    //On mount, scroll to the top of the QA holder
    let container = document.getElementById("mobileQAHolder");

    if (container) {
      container.scrollTop = 0;
    }
  }, [])

  let qaItemsOrdered = orderBy(
    qaItems.filter((item) => item.status === SHOWBOAT.QuestionStatus.Approved),
    [(q) => q.lastUpdatedTime],
    ["desc"]
  );
  
  let qaItemComponentArray = [];

  qaItemsOrdered.forEach((item : SHOWBOAT.Question) => {
    qaItemComponentArray.push(
      <QAItem qaItem={item} key={item.ID} tab={UIHelper.QATabEnum.APPROVED} />
    );
  });

  
  return (
    <div className={styles.mobileQAHolder} id="mobileQAHolder">
      {[qaItemComponentArray].map((item) => {
        return item;
      })}
    </div>
  );
}