import { IconButton, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import textFit from "textfit";
import ExpandIcon from "@material-ui/icons/Menu";
import CollapseIcon from "@material-ui/icons/MenuOpen";
import { ServerHelper } from "../../Utilities/ServerHelper";
import ErrorIcon from "@material-ui/icons/Error";
import Linkify from "react-linkify";

import styles from "./MobileEventHeader.module.css";
import { MobileEventHeaderStyles } from "./MobileEventHeaderStyles";
import MobileHeaderMenu from "../../ui/mobile/MobileHeaderMenu/MobileHeaderMenu";
import { StringVariableHelper } from "../../Utilities/StringVariableHelper";

interface Props {
  welcome?: boolean;
  failReason?: string;
  onEnterClick?: () => any;
  handleHomeClick?: () => any;
  mobileIntakeCompleted?: boolean;
  enterClicked: boolean;
}

export default function MobileEventHeader(props: Props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const showMobileUnsupported =
    !ServerHelper.enableMobile && !ServerHelper.useMobileHybridApp;

  useEffect(() => {
    let eventNameHolder = document.getElementById("eventNameHolder");

    if (eventNameHolder) {
      textFit(document.getElementById("eventNameHolder"), {
        minFontSize: 14,
        maxFontSize: 16,
      });
    }
  }, []);

  const handleMenuOpenChange = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const classes = MobileEventHeaderStyles();

  return (
    <React.Fragment>
      <div className={styles.mobileEventHeader}>
        <div className={styles.logoHolder}>
          <img
            className={styles.showboatLogo}
            src={SHOWBOAT.ApplicationSkin.landingPageThumbnailURL}
            alt="header logo"
          />
        </div>

        <div
          className={
            props.mobileIntakeCompleted
              ? `${styles.eventNameHolder} ${styles.eventNameHolderPostIntake}`
              : styles.eventNameHolder
          }
          id="eventNameHolder"
        >
          {SHOWBOAT.ApplicationSkin.eventName}
        </div>

        {props.mobileIntakeCompleted && (
          <IconButton
            className={classes.expandIconButton}
            onClick={handleMenuOpenChange}
          >
            {menuOpen ? <CollapseIcon /> : <ExpandIcon />}
          </IconButton>
        )}
      </div>

      {menuOpen && <MobileHeaderMenu handleCloseMenu={handleCloseMenu} />}

      {/* Make sure mobile unsupported message is not showing before rendering error holder */}
      {!showMobileUnsupported && (
        <div style={{ width: "100%", textAlign: "center" }}>
          {ServerHelper.loginFailReason ===
            StringVariableHelper.LoginFailReasons.Ended &&
            !props.enterClicked &&
            !props.mobileIntakeCompleted && (
              <Paper
                className={
                  ServerHelper.useMobileApp
                    ? `${classes.errorMessageHolderMobile} ${classes.errorMessageHolder}`
                    : classes.errorMessageHolder
                }
              >
                <Typography variant="body1" className={classes.errorMessage}>
                  This event has ended.
                </Typography>
              </Paper>
            )}

          {ServerHelper.loginFailReason ===
            StringVariableHelper.LoginFailReasons.CapacityFull && (
            <Paper
              className={
                ServerHelper.useMobileApp
                  ? `${classes.errorMessageHolderMobile} ${classes.errorMessageHolder}`
                  : classes.errorMessageHolder
              }
            >
              <Typography variant="body1" className={classes.errorMessage}>
                Event currently at capacity. This page will update if space
                becomes available.
              </Typography>
            </Paper>
          )}

          {ServerHelper.loginFailReason ===
            StringVariableHelper.LoginFailReasons.DeletedLoginCode && (
            <Paper
              className={
                ServerHelper.useMobileApp
                  ? `${classes.errorMessageHolderMobile} ${classes.errorMessageHolder}`
                  : classes.errorMessageHolder
              }
            >
              <Typography variant="body1" className={classes.errorMessage}>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      target="_blank"
                      href={decoratedHref}
                      key={key}
                      style={{
                        color: "#FFB700",
                        textDecoration: "none",
                      }}
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  {ServerHelper.errorMsg}
                </Linkify>
              </Typography>
            </Paper>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
