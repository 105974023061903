import { Paper, Typography } from "@material-ui/core";
import { EFBIG } from "constants";
import React, { useEffect, useRef, useState } from "react";
import { TwoDHelper, TwoDObject } from "../../../Utilities/TwoDHelper";

import styles from "./TwoDOverlayCameraItem.module.css";
import { TwoDOverlayCameraItemStyles } from "./TwoDOverlayCameraItemStyles";

interface Props {
  twoDObject: TwoDObject;
  handleCamerasViewResize: () => void;
}

export default function TwoDOverlayCameraItem(props: Props) {
  const [userColor, setUserColor] = useState(props.twoDObject.avatarData.color);
  const [userName, setUserName] = useState(
    props.twoDObject.avatarData.lastName !== ""
      ? `${
          props.twoDObject.avatarData.firstName
        } ${props.twoDObject.avatarData.lastName.charAt(0).toUpperCase()}.`
      : props.twoDObject.avatarData.firstName
  );

  const [userAvatarFace, setUserAvatarFace] = useState(
    props.twoDObject.avatarData.face
  );

  const [userCameraEnabled, setUserCameraEnabled] = useState(
    props.twoDObject.avatarData.cameraEnabled
  );

  const [containerWidthHeight, setContainerWidthHeight] = useState(245);

  const cameraVideoRef = useRef(
    props.twoDObject.downstreamController.getHTMLVideoElement()
  );

  const cameraContainerRef = useRef(null);

  const cameraItemHolderRef = useRef(null);

  useEffect(() => {
    cameraContainerRef.current = document.getElementById(
      `twoDCameraItemVideo-${props.twoDObject.avatarData.userID}`
    );

    //Append this user's camera video if camera is enabled
    if (props.twoDObject.avatarData.cameraEnabled)
      appendCameraVideo(
        props.twoDObject.downstreamController.getHTMLVideoElement()
      );

    //Listen for color changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.twoDObject.avatarData.userID,
      SHOWBOAT.ChangeReason.ColorNumber,
      handleColorChange
    );

    //Listen for nametag changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.twoDObject.avatarData.userID,
      SHOWBOAT.ChangeReason.NameTag,
      handleNametagChange
    );

    //Listen for face changes
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.twoDObject.avatarData.userID,
      SHOWBOAT.ChangeReason.FaceNumber,
      handleFaceChange
    );

    //Listen for the camera stream becoming available
    props.twoDObject.downstreamController.OnCameraFeedAvailable.Add(
      handleCameraFeedAvailable
    );

    //Listen for camera view resizing
    TwoDHelper.OnCameraItemResize.Add(handleCameraItemResize);

    props.handleCamerasViewResize();

    return function cleanup() {
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.twoDObject.avatarData.userID,
        SHOWBOAT.ChangeReason.ColorNumber,
        handleColorChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.twoDObject.avatarData.userID,
        SHOWBOAT.ChangeReason.NameTag,
        handleNametagChange
      );

      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.twoDObject.avatarData.userID,
        SHOWBOAT.ChangeReason.FaceNumber,
        handleFaceChange
      );

      props.twoDObject.downstreamController.OnCameraFeedAvailable.Remove(
        handleCameraFeedAvailable
      );

      TwoDHelper.OnCameraViewResize.Remove(handleCameraItemResize);

      TwoDHelper.OnCameraItemResize.Remove(handleCameraItemResize);
    };
  }, []);

  const handleColorChange = (avatarData: SHOWBOAT.AvatarData) => {
    setUserColor(avatarData.color);
  };

  const handleNametagChange = (avatarData: SHOWBOAT.AvatarData) => {
    if (avatarData.lastName !== "") {
      setUserName(
        `${avatarData.firstName} ${avatarData.lastName
          .charAt(0)
          .toUpperCase()}.`
      );
    } else {
      setUserName(avatarData.firstName);
    }
  };

  const handleFaceChange = (avatarData: SHOWBOAT.AvatarData) => {
    setUserAvatarFace(avatarData.face);
  };

  const clearCameraContainer = (container) => {
    for (let i = 0; i < container.childNodes.length; i++) {
      container.removeChild(container.childNodes[i]);
    }
  };

  const appendCameraVideo = (video) => {
    if (cameraContainerRef.current && video !== null) {
      //Ensure the container is cleared
      clearCameraContainer(cameraContainerRef.current);

      cameraContainerRef.current.appendChild(video);

      video.play();
    }
  };

  const handleCameraFeedAvailable = (
    showVideo: boolean,
    el: HTMLVideoElement,
    stream: MediaStream
  ) => {
    if (showVideo) {
      setUserCameraEnabled(true);

      //Check if element is not the same as previous
      if (cameraVideoRef.current != el) {
        cameraVideoRef.current = el;
      }

      appendCameraVideo(cameraVideoRef.current);
    } else {
      setUserCameraEnabled(false);
    }
  };

  const handleCameraItemResize = (heightWidth: number) => {
    setContainerWidthHeight(heightWidth);
  };

  const classes = TwoDOverlayCameraItemStyles();

  return (
    <Paper
      className={`${classes.cameraItemHolder} twoDCameraItem`}
      id={`cameraItemHolder-${props.twoDObject.avatarData.userID}`}
      style={{
        width: `${containerWidthHeight - 5}px`,
        height: `${containerWidthHeight - 5}px`,
      }}
      /* ref={cameraItemHolderRef} */
    >
      <div
        className={styles.cameraFeedHolder}
        id={`twoDCameraItemVideo-${props.twoDObject.avatarData.userID}`}
      >
        {!userCameraEnabled && (
          <img
            className={styles.cameraFace}
            src={`assets/images/faces/${SHOWBOAT.ApplicationSkin.faceThumbnailURLs[userAvatarFace]}`}
            style={{
              backgroundColor:
                SHOWBOAT.ApplicationSkin.secondaryAvatarColors[userColor],
            }}
            onLoad={props.handleCamerasViewResize}
          />
        )}
      </div>

      <div className={styles.nameHolder}>
        <Typography variant="body1" className={classes.nameText}>
          {userName}
        </Typography>
      </div>
    </Paper>
  );
}
