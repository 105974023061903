import { orderBy } from "natural-orderby";
import React, { useContext, useEffect, useRef, useState } from "react";
import { QAContext, QAContextType } from "../../../../../context/QAContext";
import { UIHelper } from "../../../../../Utilities/UIHelper";
import QAItem from "../QAItem/QAItem";

import styles from "./QAItemList.module.css";
import { QAItemListStyles } from "./QAItemListStyles";

type Props = {
  tab: string;
  sort: string;
  role: string;
};

export default function QAItemList(props: Props) {
  const { qaItems, qaRole }: QAContextType = useContext(QAContext);

  const [qaItemsApprovedIDsState, setQaItemsApprovedIDsState] =
    useState(qaItems);

  useEffect(() => {

    //Below code does not work for removed items - need way to get height before unmounting
    
    /* let qaItemsApprovedIDs = [];
    qaItems
      .filter((item) => item.status === SHOWBOAT.QuestionStatus.Approved)
      .forEach((item) => qaItemsApprovedIDs.push(item.questionID));

    SHOWBOAT.Logger.Log("QA items approved:", qaItemsApprovedIDs);
    SHOWBOAT.Logger.Log("QA items approved state:", qaItemsApprovedIDsState);

    let itemToMoveBy;

    //Compare new context value with state
    if (qaItemsApprovedIDs.length > qaItemsApprovedIDsState.length) {
      //When a new approved item comes in, scroll the list down by the new item's height
      itemToMoveBy = qaItemsApprovedIDs.filter(
        (i) => !qaItemsApprovedIDsState.includes(i)
      );
    } else if (qaItemsApprovedIDs.length < qaItemsApprovedIDsState.length) {
      //When an approved item is removed, scroll the list up by the old item's height
      itemToMoveBy = qaItemsApprovedIDsState.filter(
        (i) => !qaItemsApprovedIDs.includes(i)
      );
    }

    if (itemToMoveBy) {
      let item = document.getElementById(itemToMoveBy);

      if (item) {
        SHOWBOAT.Logger.Log("ITEM HEIGHT:", item.clientHeight);
      }
    }

    setQaItemsApprovedIDsState(qaItemsApprovedIDs); */
  }, [qaItems]);

  const getQAItems = (tab) => {
    let qaList;

    if (
      qaRole === UIHelper.QARole.PRESENTER ||
      qaRole === UIHelper.QARole.ATTENDEE
    ) {
      //For presenter and attendee role, only show Approved questions
      qaList = qaItems.filter(
        (item) => item.status === SHOWBOAT.QuestionStatus.Approved
      );
    } else if (qaRole === UIHelper.QARole.MODERATOR) {
      switch (tab) {
        case UIHelper.QATabEnum.PENDING:
          qaList = qaItems.filter(
            (item) => item.status === SHOWBOAT.QuestionStatus.Unapproved
          );
          break;
        case UIHelper.QATabEnum.APPROVED:
          qaList = qaItems.filter(
            (item) => item.status === SHOWBOAT.QuestionStatus.Approved
          );
          break;
        case UIHelper.QATabEnum.DONE:
          qaList = qaItems.filter(
            (item) => item.status === SHOWBOAT.QuestionStatus.Answered
          );
          break;
        case UIHelper.QATabEnum.PASS:
          qaList = qaItems.filter(
            (item) => item.status === SHOWBOAT.QuestionStatus.Deleted
          );
          break;
      }
    }

    return qaList;
  };

  let qaItemsLocal: Array<SHOWBOAT.Question> = getQAItems(props.tab);

  let qaItemsOrdered;

  if (props.sort === UIHelper.QASortEnum.NEWEST) {
    qaItemsOrdered = orderBy(
      qaItemsLocal,
      [(q) => q.lastUpdatedTime],
      ["desc"]
    );
  } else if (props.sort === UIHelper.QASortEnum.UPVOTES) {
    qaItemsOrdered = orderBy(qaItemsLocal, [(q) => q.votes], ["desc"]);
  }

  let qaItemComponentArray = [];

  qaItemsOrdered.forEach((item : SHOWBOAT.Question) => {
    qaItemComponentArray.push(
      <QAItem qaItem={item} key={item.ID} tab={props.tab} />
    );
  });

  //Render lists based on which tab is selected
  return (
    <div
      className={
        qaRole === UIHelper.QARole.ATTENDEE
          ? `${styles.qaItemsHolder} ${styles.qaItemsHolderAttendee}`
          : styles.qaItemsHolder
      }
      id="qaItemsHolder"
    >
      {[qaItemComponentArray].map((item) => {
        return item;
      })}
    </div>
  );
}
