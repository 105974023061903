import { makeStyles, createStyles } from "@material-ui/core/styles";

const TwoDOverlayCameraItemStyles = makeStyles((theme: any) =>
  createStyles({
    cameraItemHolder: {
/*       height: 240,
      width: 240, */
      position: "relative",
      marginRight: 5,
      marginBottom: 1,
      backgroundColor: "#404040",
      display: "inline-block"
    },
    nameText: {
      fontSize: 17,
      position: "absolute",
      bottom: -6,
      left: 0,
      padding: "inherit",
      textAlign: "left",
      width: 180,
      textShadow: "2px 3px 5px rgba(0,0,0,0.5)",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  })
);

export { TwoDOverlayCameraItemStyles };
