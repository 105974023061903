import React, { ReactElement } from 'react'

interface Props {
  disabled: boolean;
}

export default function EnterIcon(props: Props): ReactElement {
  return (
    <svg
      id="login_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_555" data-name="Group 555">
        <rect
          id="Rectangle_819"
          data-name="Rectangle 819"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g id="Group_556" data-name="Group 556">
        <path
          id="Path_2219"
          data-name="Path 2219"
          d="M11,7,9.6,8.4,12.2,11H2v2H12.2L9.6,15.6,11,17l5-5Zm9,12H12v2h8a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H12V5h8Z"
          fill={props.disabled ? "rgba(255, 255, 255, 0.3)" : "white"}
        />
      </g>
    </svg>
  );
}
