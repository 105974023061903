import React, { ReactElement, useEffect, useState } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { isSafari } from "react-device-detect";

import { ServerHelper } from "../../Utilities/ServerHelper";
import useBypassIntake from "../../hooks/useBypassIntake";
import { UIHelper } from "../../Utilities/UIHelper";

import styles from "./FullScreenLoadingScreen.module.css";
import { FullScreenLoadingScreenStyles } from "./FullScreenLoadingScreenStyles";

export default function FullScreenLoadingScreen(): ReactElement {
  const { executeBypass } = useBypassIntake();

  const [loadingProgress, setLoadingProgress] = useState("");

  const classes = FullScreenLoadingScreenStyles();

  useEffect(() => {
    SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Add(
      handleLoadingProgressChange
    );

    return function cleanup() {
      SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Remove(
        handleLoadingProgressChange
      );
    };
  }, []);

  const handleLoadingProgressChange = (progress: string) => {
    setLoadingProgress(progress);
  };

  return (
    <Paper square className={classes.paper}>
      <div
        className={
          ServerHelper.useMobileApp
            ? `${styles.loadingSpinnerHolder} ${styles.loadingSpinnerHolderMobile}`
            : styles.loadingSpinnerHolder
        }
      >
        <React.Fragment>
          <img
            src="assets/images/sb-logo.png"
            alt="showboat logo"
            className={styles.imageBack}
          ></img>
          <img
            src="assets/images/sb-spinner.png"
            alt="showboat loading spinner"
            className={styles.imageSpinner}
          ></img>

          <Typography
            variant="h1"
            className={
              ServerHelper.useMobileApp
                ? `${classes.loadingProgress} ${classes.loadingProgressMobile}`
                : classes.loadingProgress
            }
          >
            {ServerHelper.useMobileApp ? "Entering Event" : loadingProgress}
          </Typography>
        </React.Fragment>
      </div>
    </Paper>
  );
}
