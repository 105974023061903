import { IconButton, Paper, Typography } from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Rnd } from "react-rnd";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../context/PresenterContext";
import Screenshare from "./Screenshare/Screenshare";
import LocalVideo from "./LocalVideo/LocalVideo";
import WebcamShare from "./WebcamShare/WebcamShare";
import LivestreamShare from "./LivestreamShare/LivestreamShare";
import { UIHelper } from "../../../Utilities/UIHelper";
import { PresenterHelper } from "../../../Utilities/PresenterHelper";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";

import styles from "./PresenterToolsHolder.module.css";
import { PresenterToolsHolderStyles } from "./PresenterToolsHolderStyles";
import { PopupContext, PopupContextType } from "../../../context/PopupContext";

interface Props {}

export default function PresenterToolsHolder(props: Props): ReactElement {
  const [padding, setPadding] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [handleBottom, setHandleBottom] = useState(0);

  const currentPresenterTooLRef = useRef("");

  const {
    currentPresenterTool,
    setCurrentPresenterTool,
  }: PresenterContextType = useContext(PresenterContext);

  const {
    setToolHeight,
    setToolWidth,
    setToolXPosition,
    setToolYPosition,
    toolHeight,
    toolWidth,
    toolYPosition,
    toolXPosition,
  }: PopupContextType = useContext(PopupContext);

  const setPopupDimensionsAndPositionInContext = () => {
    //Capture X/Y/Width/Height and store in context
    let popup = document.getElementById("presenterToolPopup") as HTMLDivElement;
    if (popup) {
      let viewportOffset = popup.getBoundingClientRect();
      setToolXPosition(viewportOffset.left);
      setToolYPosition(viewportOffset.top);
      setToolWidth(popup.clientWidth);
      setToolHeight(popup.clientHeight);
    }
  };

  useEffect(() => {
    UIHelper.OnForceLivestreamOff.Add(handleForceLivestreamOff);

    return function cleanup() {
      UIHelper.OnForceLivestreamOff.Remove(handleForceLivestreamOff);
    };
  }, []);

  useEffect(() => {
    currentPresenterTooLRef.current = currentPresenterTool;

    //Change padding of content holder based on which tool is selected
    let padding;
    let marginBottom;
    let handleBottom;

    switch (currentPresenterTool) {
      case StringVariableHelper.PresenterToolNames.livestream:
        padding = "70px";
        marginBottom = "70px";
        handleBottom = "60px";
        break;
      case StringVariableHelper.PresenterToolNames.screenshare:
        padding = "30px";
        marginBottom = "30px";
        handleBottom = "15px";
        break;
      case StringVariableHelper.PresenterToolNames.webcam:
        padding = "0px";
        marginBottom = "0px";
        handleBottom = "-15px";
        break;
      case StringVariableHelper.PresenterToolNames.localVideo:
        padding = "38px";
        marginBottom = "38px";
        handleBottom = "23px";
        break;
    }

    setPadding(padding);
    setMarginBottom(marginBottom);
    setHandleBottom(handleBottom);

    //Check if offset bottom is negative. If so, move up by that amount
    let header = document.getElementById("presenterToolsHeaderHolder");
    let contentHolder = document.getElementById("presenterToolsContentHolder");
    let popup = document.getElementById("presenterToolPopup");

    if (header && contentHolder && popup) {
      let popupHeight = header.clientHeight + contentHolder.clientHeight;

      let viewportOffset = popup.getBoundingClientRect();
      let space = window.innerHeight - viewportOffset.top - popupHeight;

      if (space < 0) {
        popup.style.marginTop = `${space}px`;
      }
    }
  }, [currentPresenterTool]);

  const handleForceLivestreamOff = () => {
    //If livestream is forced off and we do not have livestream tool open, mute the preview
    if (
      currentPresenterTooLRef.current !==
      StringVariableHelper.PresenterToolNames.livestream
    ) {
      if (PresenterHelper.LivestreamVideo)
        PresenterHelper.LivestreamVideo.muted = true;
    }
  };

  const handleDragStart = () => {
    let popup = document.getElementById("presenterToolPopup");

    if (popup) popup.style.marginTop = "0px";
  };

  //Close popup
  const handleClosePresenterTools = () => {
    //Set dimensions in context when the component is closed
    setPopupDimensionsAndPositionInContext();
    setCurrentPresenterTool("");
  };

  const classes = PresenterToolsHolderStyles();

  //Calculate initial X/Y position of popup
  let yPosition;
  let windowHeight = window.innerHeight;

  yPosition = windowHeight - 330;

  //Calculate initial preview size based on screen size
  let previewWidth;
  let previewHeight;

  previewWidth = 370;
  previewHeight = 318;

  let yAddition;
  switch (currentPresenterTool) {
    case StringVariableHelper.PresenterToolNames.screenshare:
      yAddition = 30;
      break;
    case StringVariableHelper.PresenterToolNames.livestream:
      yAddition = 70;
      break;
    case StringVariableHelper.PresenterToolNames.webcam:
      yAddition = 0;
      break;
    case StringVariableHelper.PresenterToolNames.localVideo:
      yAddition = 38;
      break;
    default:
      yAddition = 30;
      break;
  }

  const renderPresenterToolHeader = (currentPresenterTool) => {
    let headerName;

    switch (currentPresenterTool) {
      case StringVariableHelper.PresenterToolNames.localVideo:
        headerName = "LOCAL VIDEO";
        break;
      case StringVariableHelper.PresenterToolNames.screenshare:
        headerName = "SCREENSHARE";
        break;
      case StringVariableHelper.PresenterToolNames.livestream:
        headerName = "LIVESTREAM";
        break;
      case StringVariableHelper.PresenterToolNames.webcam:
        headerName = "WEBCAM";
        break;
    }

    return headerName;
  };

  //Only render presenter tool holder if a tool is selected (other than slides)
  if (
    currentPresenterTool !== "" &&
    currentPresenterTool !== StringVariableHelper.PresenterToolNames.slides
  ) {
    return (
      <Rnd
        id="presenterToolPopup"
        lockAspectRatio={185 / 159}
        default={{
          width: toolWidth ? toolWidth : previewWidth,
          height: toolHeight ? toolHeight : previewHeight,
          x: toolXPosition ? toolXPosition : 10,
          y: toolYPosition ? toolYPosition + yAddition : yPosition,
        }}
        bounds="window"
        minWidth={322}
        minHeight={277}
        className={styles.presenterToolsHolder}
        resizeHandleWrapperClass={"resizeHandleWrapper"}
        resizeHandleStyles={{
          bottomLeft: {
            top: `calc(100% + ${handleBottom})`,
          },
          bottomRight: {
            top: `calc(100% + ${handleBottom})`,
          },
          left: {
            display: "none",
          },
          right: {
            display: "none",
          },
          top: {
            display: "none",
          },
          bottom: {
            display: "none",
          },
        }}
        style={{ top: `-${marginBottom}` }}
        onDragStop={setPopupDimensionsAndPositionInContext}
        onDragStart={handleDragStart}
        cancel=".cancelDrag"
      >
        <Paper
          className={classes.presenterToolHeaderHolder}
          id="presenterToolsHeaderHolder"
        >
          <Typography variant="h2" className={classes.presenterToolHeader}>
            {renderPresenterToolHeader(currentPresenterTool)}
          </Typography>

          {currentPresenterTool !==
            StringVariableHelper.PresenterToolNames.localVideo && (
            <IconButton
              onClick={handleClosePresenterTools}
              className={`${classes.closeButton} cancelDrag`}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Paper>

        <Paper
          className={classes.presenterToolContentHolder}
          style={{ paddingTop: padding }}
          id="presenterToolsContentHolder"
        >
          {/* Show correct presenter tool based on which is chosen */}
          {
            {
              screenshare: <Screenshare />,
              localVideo: <LocalVideo />,
              webcam: <WebcamShare />,
              livestream: <LivestreamShare />,
            }[currentPresenterTool]
          }
        </Paper>
      </Rnd>
    );
  } else {
    return null;
  }
}
