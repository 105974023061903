import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileHeaderMenuStyles = makeStyles((theme: any) =>
  createStyles({
    mobileHeaderButton: {
      padding: 0,
      width: 60,
      height: 40,
      backgroundColor: "#404040",
      color: "white",
      borderRadius: 4,
      "&:hover": {
        backgroundColor: "#404040",
      },
    },
    mobileHeaderButtonSettings: {
      marginLeft: 12,
    }
  })
);

export { MobileHeaderMenuStyles };