import { Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { isIOS, isMobileOnly, isSafari } from "react-device-detect";

import styles from "./MobileAndTabletErrorPage.module.css";
import { MobileAndTabletErrorPageStyles } from "./MobileAndTabletErrorPageStyles";

interface Props {
}

export default function MobileAndTabletErrorPage(props: Props): ReactElement {
  const classes = MobileAndTabletErrorPageStyles();

  return (
    <Paper square className={classes.mobileAndTabletErrorMessageWrapper}>
      <div className={styles.mobileAndTabletErrorMessageHolder}>
        <img
          className={styles.errorLogo}
          alt="Showboat logo"
          src="assets/images/sb-logo-error.png"
        />

        {isMobileOnly && isIOS && !isSafari ? (
          <Typography variant="body1" className={classes.errorText}>
           {/*  Showboat is not currently supported on tablets and mobile devices */}
            Showboat on iPhone is only supported via Safari
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.errorText}>
            Showboat is not currently supported on tablets
            {/* Showboat is currently only supported on desktops, laptops, tablets via Safari, iPhones via Safari, and other mobile devices */}
          </Typography>
        )}
      </div>
    </Paper>
  );
}
