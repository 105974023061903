import React, {useState, useEffect, useRef} from 'react';

interface NearbyAttendeeContextType {
  
}

export const NearbyAttendeeContext = React.createContext(null);

export const NearbyAttendeeContextProvider = (props) => {

  const nearbyRef = useRef([]);
  const [ attendeesNearby, setAttendeesNearby ] = useState([]);

 
  //handle player entering nearby list
  const handlePlayerJoinedNearby = (userID: string) => {
    //Get avatar data from map
    if (nearbyRef.current.some(e => e.userID === userID)) {
      return;
    }

    //Retrieve this user's avatarData object from RemoteAvatarDataManager
    let avatarData = SHOWBOAT.RemoteAvatarDataManager.getAvatarData(userID);

    if(avatarData != null){
      nearbyRef.current = [...nearbyRef.current, avatarData];
    }

    //Append to nearby array
    setAttendeesNearby(nearbyRef.current);
  }

  //Handle player leaving nearby list
  const handlePlayerLeftNearby = (userID: string) => {

    if (nearbyRef.current.some(e => e.userID === userID)) {
      nearbyRef.current = nearbyRef.current.filter(attendee => attendee.userID !== userID);
       //remove avatarData that matches userID from attendeesNearby
      setAttendeesNearby(nearbyRef.current);
    }
   
  }

  useEffect(() => {
    //player enters nearby
    SHOWBOAT.UIEventManager.OnJoinedNearbyList.Add(handlePlayerJoinedNearby);
    //player leaves nearby
    SHOWBOAT.UIEventManager.OnLeftNearbyList.Add(handlePlayerLeftNearby);

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnJoinedNearbyList.Remove(handlePlayerJoinedNearby);
      SHOWBOAT.UIEventManager.OnLeftNearbyList.Remove(handlePlayerLeftNearby);
    }
  }, []);

  const nearbyAttendeeProviderValue = {
    attendeesNearby,
    setAttendeesNearby,
  }

  return (
    <NearbyAttendeeContext.Provider
      value={nearbyAttendeeProviderValue}
    >
      {props.children}
    </NearbyAttendeeContext.Provider>
  )
}