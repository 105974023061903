import { makeStyles, createStyles } from '@material-ui/core/styles';

const LocalVideoStyles = makeStyles((theme: any) =>
  createStyles({
    videoPlayerHolder: {
      zIndex: 9,
      position: "absolute",
      backgroundColor: theme.palette.background.default,
      width: 430,
      height: 320,
      marginLeft: "calc(50% - 215px)",
      bottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: "bottom 215ms",
      [theme.breakpoints.down("md")]: {},
      "@media(max-width: 1100px)": {
        width: 365,
        marginLeft: "calc(50% - 182.5px)",
        height: 305,
      },
      [theme.breakpoints.down("sm")]: {
        width: 330,
        marginLeft: "calc(50% - 165px)",
      },
    },
    videoPlayerHolderCollapsed: {
      visibility: "hidden",
    },
    chooseFileButton: {
      position: "absolute",
      left: "5%",
      bottom: "6%",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {
        left: 15,
      },
    },
    streamVideoLabel: {
      fontSize: 14,
      fontWeight: 500,
    },
    videoTabBar: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    livestreamURLTextField: {
      position: "absolute",
      top: 65,
      width: 300,
      right: 100,
      "@media(max-width: 1100px)": {
        width: 240,
      },
      [theme.breakpoints.down("sm")]: {
        right: 70,
      },
    },
    livestreamURLInput: {
      height: 40,
    },
    arrowIconButton: {
      position: "absolute",
      left: 360,
      top: 60,
      "@media(max-width: 1100px)": {
        left: 300,
      },
      [theme.breakpoints.down("sm")]: {
        left: 270,
      },
    },
    arrowIcon: {
      color: theme.palette.text.main,
    },
    loadingSpinner: {
      color: theme.palette.text.primary,
      width: "80px !important",
      height: "80px !important",
      position: "absolute",
      margin: "auto",
      top: 60,
      left: 0,
      bottom: 0,
      right: 0,
    },
    livestreamError: {
      fontSize: 16,
      position: "absolute",
      top: 175,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      display: "inline-block",
      "@media(max-width: 1100px)": {
        width: 220,
        top: 160,
      },
    },
    tabIndicator: {
      transition: "left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    },
    shareVideoToggle: {
      position: "absolute",
      bottom: "6%",
      right: "6%",
    },
    closeButton: {
      position: "absolute",
      top: -38,
      right: 2,
      padding: 4,
      color: theme.palette.text.primary,
    },
    twoDModeInfo: {
      color: "white",
      fontSize: 15,
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      top: "24%",
      textAlign: "center",
      width: 220,
    },
    loopToggle: {
      bottom: "19.5%",
    }
  })
);

export { LocalVideoStyles };