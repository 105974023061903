import { Typography } from "@material-ui/core";
import React, { useContext } from "react";

import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

import styles from "./MobileMessagesBadge.module.css";
import { MobileMessagesBadgeStyles } from "./MobileMessagesBadgeStyles";

type Props = {};

export default function MobileMessagesBadge({}: Props) {
  const { currentMobileMode, privateMessageCount }: MobileContextType =
    useContext(MobileContext);

  const classes = MobileMessagesBadgeStyles();

  //Only show the count if we have more than 0 messages
  if (privateMessageCount > 0) {
    return (
      <div
        className={styles.messagesBadge}
        style={{
          backgroundColor:
            currentMobileMode === StringVariableHelper.MobileModeValues.messages
              ? "#404040"
              : SHOWBOAT.ApplicationSkin.primaryThemeColor,
        }}
      >
        <Typography className={classes.number}>
          {privateMessageCount}
        </Typography>
      </div>
    );
  } else {
    return null;
  }
}
