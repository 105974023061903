import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterToolsHolderStyles = makeStyles((theme: any) => createStyles({
  presenterToolHeaderHolder: {
    backgroundColor: theme.palette.plain.main,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 42,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  presenterToolHeader: {
    fontSize: 14,
    fontWeight: 500,
    position: "absolute",
    top: 14,
    left: 22,
  },
  closeButton: {
    position: "absolute",
    top: 4,
    right: 2,
    padding: 4,
    color: theme.palette.text.primary
  },
  presenterToolContentHolder: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    height: "calc(100% - 42px)",
    top: 42,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  }
})
)

export { PresenterToolsHolderStyles };