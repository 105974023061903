import { Paper, IconButton, Typography, Tabs, Tab } from "@material-ui/core";
import React, { useContext, useEffect, useRef } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import ScrollContainer from "react-indiana-drag-scroll";

import { QAHeaderStyles } from "./QAHeaderStyles";

import styles from "./QAHeader.module.css";
import { AppContext, AppContextType } from "../../../../../context/AppContext";
import { UIHelper } from "../../../../../Utilities/UIHelper";
import { QAContext, QAContextType } from "../../../../../context/QAContext";

type Props = {
  handleSettingsClick: () => void;
  tab: string;
  handleTabChange: (e: any, tab: string) => void;
  settings: boolean;
};

export default function QAHeader(props: Props) {
  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const { qaRole, attendeesSeeAllQs }: QAContextType = useContext(QAContext);
  const classes = QAHeaderStyles();

  const dragPositionRef = useRef({ left: 0, x: 0 });

  useEffect(() => {
    //Add mouse down handler for tabs
    document.addEventListener("mousedown", mouseDownHandler);

    return function cleanup() {
      document.removeEventListener("mousedown", mouseDownHandler);
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    let ele = document.getElementsByClassName("MuiTabs-scroller")[0];

    if (ele) {
      // How far the mouse has been moved
      const dx = e.clientX - dragPositionRef.current.x;

      // Scroll the element
      ele.scrollLeft = dragPositionRef.current.left - dx;
    }
  };

  const mouseDownHandler = (e) => {
    let ele = document.getElementsByClassName("MuiTabs-scroller")[0];

    if (ele && ele.contains(e.target)) {
      if (ele.contains(e.target)) {
        let newPos = {
          // The current scroll
          left: ele.scrollLeft,
          // Get the current mouse position
          x: e.clientX,
        };

        dragPositionRef.current = newPos;

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      }
    }
  };

  return (
    <Paper className={classes.qaHeaderHolder}>
      <QuestionAnswerIcon className={classes.qaIcon} />

      <Typography variant="h1" className={classes.qaHeader}>
        Q&A
      </Typography>

      <div
        className={
          isPresenter
            ? styles.headerButtonHolder
            : `${styles.headerButtonHolder} ${styles.headerButtonHolderAttendee}`
        }
      >
        {isPresenter && (
          <IconButton
            className={
              props.settings
                ? `${classes.closeIconButton} ${classes.closeIconButtonSettingsActive}`
                : classes.closeIconButton
            }
            onClick={() => props.handleSettingsClick()}
          >
            <SettingsIcon />
          </IconButton>
        )}

        <IconButton
          className={classes.closeIconButton}
          onClick={() => setCurrentSidebarMode("")}
        >
          <RightArrowIcon />
        </IconButton>
      </div>

      {/* Only show tabs when in moderator view and not in settings*/}
      {qaRole === UIHelper.QARole.MODERATOR && !props.settings && (
        <Tabs
          value={props.tab}
          onChange={(e, newValue) => props.handleTabChange(e, newValue)}
          className={classes.tabs}
          classes={{
            indicator: classes.tabIndicator,
            flexContainer: classes.flexContainer,
          }}
          color="primary"
          variant="scrollable"
          scrollButtons="off"
          id="qaTabBar"
        >
          <Tab
            className={classes.qaTab}
            label="PENDING"
            value={UIHelper.QATabEnum.PENDING}
            style={{
              display: attendeesSeeAllQs ? "none" : "initial"
            }}
          />
          <Tab
            className={
              attendeesSeeAllQs
                ? `${classes.qaTab} ${classes.qaTabNoModeration}`
                : classes.qaTab
            }
            label="APPROVED"
            value={UIHelper.QATabEnum.APPROVED}
          />
          <Tab
            className={
              attendeesSeeAllQs
                ? `${classes.qaTab} ${classes.qaTabNoModeration}`
                : classes.qaTab
            }
            label="PASS"
            value={UIHelper.QATabEnum.PASS}
          />
          <Tab
            className={
              attendeesSeeAllQs
                ? `${classes.qaTab} ${classes.qaTabNoModeration}`
                : classes.qaTab
            }
            label="DONE"
            value={UIHelper.QATabEnum.DONE}
          />
        </Tabs>
      )}
    </Paper>
  );
}
