import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileMessagesStyles = makeStyles((theme: any) =>
  createStyles({
    noMessagesText: {
      fontSize: 20,
      color: "#808080"
    }
  })
);

export { MobileMessagesStyles };