import React, { ReactElement } from "react";
import { Typography, Paper } from "@material-ui/core";

import { EventEndedPageStyles } from "./EventEndedPageStyles";
import styles from "./EventEndedPage.module.css";
import { ServerHelper } from "../../../Utilities/ServerHelper";

export default function EventEndedPage(): ReactElement {
  const classes = EventEndedPageStyles();

  return (
    <div className={styles.eventEndedPageWrapper}>
      <Paper
        className={
          ServerHelper.useMobileApp
            ? `${classes.eventEndedMessageHolder} ${classes.eventEndedMessageHolderMobile}`
            : classes.eventEndedMessageHolder
        }
      >
        <img
          className={
            ServerHelper.useMobileApp
              ? `${styles.showboatLogo} ${styles.showboatLogoMobile}`
              : styles.showboatLogo
          }
          alt="showboat logo"
          src="assets/images/sb-logo.png"
        />

        <Typography
          variant="body1"
          className={
            ServerHelper.useMobileApp
              ? `${classes.eventEndedMessage} ${classes.endTimeErrorMessageMobile}`
              : classes.eventEndedMessage
          }
        >
          Thank you for joining us. This event has ended.
        </Typography>
      </Paper>
    </div>
  );
}
