import { makeStyles, createStyles } from '@material-ui/core/styles';

const MiscSettingsStyles = makeStyles((theme: any) => createStyles({
  miscSettingsHolder: {
    backgroundColor: theme.palette.settingsPaper.main,
    width: "100%",
    height: 211,
    borderRadius: 4,
    marginTop: 5,
    marginBottom: 0
  },
  miscSettingsHolderNotPresenter: {
    height: 135,
  },
  chatLabel: {
    fontWeight: 400,
    position: "relative",
    float: "left",
    marginLeft: 0,
    marginRight: "50%",
    marginBottom: 25,
  },
  chatSwitch: {
    right: -200,
    position: "absolute",
  },
  debugLabel: {
    fontWeight: 400,
    position: "relative",
    float: "left",
    marginRight: "50%",
    marginLeft: 0,
    marginBottom: 25,
  },
  debugModeSwitch: {
    position: "absolute",
    right: -206,
  },
  teleportLabel: {
    fontWeight: 400,
    position: "relative",
    float: "left",
    marginLeft: 0,
    marginRight: "40%",
    marginBottom: 25,
  },
  teleportSwitch: {
    right: -172,
    position: "absolute",
  },
})
)

export { MiscSettingsStyles };