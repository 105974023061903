import { Typography } from '@material-ui/core';
import React from 'react'
import BioIntakeForm from '../../Intake/BioIntakeForm/BioIntakeForm';

import styles from "./MobileBioIntake.module.css";
import { MobileBioIntakeStyles } from './MobileBioIntakeStyles';

type Props = {
  onBioIntakeComplete: () => void,
}

export default function MobileBioIntake(props: Props) {

  const handleBioFormSubmit = () => {
    props.onBioIntakeComplete();
  }

  const classes = MobileBioIntakeStyles();
  
  return (
    <div className={styles.mobileBioIntakeWrapper}>
      <Typography variant="h2" classes={{ root: classes.bioFormHelperText }}>
        Please fill out the required fields
      </Typography>

      <BioIntakeForm standalone={false} onBioFormSubmit={handleBioFormSubmit} />
    </div>
  );
}