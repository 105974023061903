import { makeStyles, createStyles } from '@material-ui/core/styles';

const TimeoutErrorPageStyles = makeStyles((theme: any) =>
  createStyles({
    errorMessageHolder: {
      display: "inline-block",
      width: 700,
      height: 200,
      textAlign: "center",
      position: "relative",
      backgroundColor: "black",
      top: 150,
    },
    errorMessage: {
      fontWeight: 400,
      fontSize: 16,
      color: "white",
      position: "relative",
      top: 85,
      right: 30,
    },
    errorMessageHolderMobile: {
      width: "90%",
      maxWidth: 850
    },
    errorMessageMobile: {
      top: 75,
    },
  })
);

export { TimeoutErrorPageStyles };