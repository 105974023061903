import React, { useState, useEffect, useRef } from "react";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";

export interface MessageContextType {
  chatMessages: Array<SHOWBOAT.SocketMessage>;
  setMessages: (messages: Array<SHOWBOAT.SocketMessage>) => void;
  alerts: Array<SHOWBOAT.SocketMessage>;
  setAlerts: (alerts: Array<SHOWBOAT.SocketMessage>) => void;
  newMessage: boolean;
  toggleNewMessage: (newMessage: boolean) => void;
  chatMessageInfoMap: Map<string, any>,
  setChatMessageInfoMap : (map: Map<string, any>) => void
}

export const MessageContext = React.createContext(null);

export const MessageContextProvider = (props) => {
  const [chatMessages, setMessages] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [newMessage, toggleNewMessage] = useState(false);
  const [chatMessageInfoMap, setChatMessageInfoMap] = useState(new Map());

  const chatMessagesRef = useRef([]);
  const chatMessageInfoMapRef = useRef(new Map());

  const firstRender = useRef(true);

  let msgID: number = 0;

  useEffect(() => {
    getChatHistory();

    //Get chat history when player reconnects
    SHOWBOAT.WebSocketController.OnLocalPlayerLoggedIn.Add(getChatHistory);

    //Listen for booking chat
    SHOWBOAT.WebSocketController.OnGroupChat.Add(handleOnGlobalChatMessage);

    //Append to global broadcast array when broadcast occurs
    SHOWBOAT.WebSocketController.OnGroupMessage.Add(
      "alert",
      handleOnGlobalAlertMessage
    );


    //Listen for nametag changes
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Add(
      SHOWBOAT.UpdateType.indexOf(
        StringVariableHelper.ChangeEventNames.NameTag
      ),
      handlePlayerNameTagChange
    );

    return function cleanup() {
      SHOWBOAT.WebSocketController.OnGroupMessage.Remove(
        "chat",
        handleOnGlobalChatMessage
      );

      SHOWBOAT.WebSocketController.OnGroupMessage.Remove(
        "alert",
        handleOnGlobalAlertMessage
      );

      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDataUpdate.Remove(
        SHOWBOAT.ChangeReason.NameTag,
        handlePlayerNameTagChange
      );
    };
  }, []);

  useEffect(() => {
    if (chatMessages.length >= 50) {
      chatMessages.shift();

      setMessages(chatMessages);
      chatMessagesRef.current = chatMessages;
    }
  }, [chatMessages]);

  /*Message handlers */

  const handlePlayerNameTagChange = (avatarData: SHOWBOAT.AvatarData) => {
    let messagesClone = [...chatMessagesRef.current];

    for (let i = 0; i < messagesClone.length; i++) {
      if (messagesClone[i].userID === avatarData.userID) {
        messagesClone[
          i
        ].message.name = `${avatarData.firstName} ${avatarData.lastName}`;
      }
    }

    setMessages(messagesClone);
    chatMessagesRef.current = messagesClone;

    //Set value in map
    let mapClone = chatMessageInfoMapRef.current;
    mapClone.set(avatarData.userID, {
      firstName: avatarData.firstName,
      lastName: avatarData.lastName,
      color: avatarData.color,
    });
    setChatMessageInfoMap(mapClone);
    chatMessageInfoMapRef.current = mapClone;
  };

  const handleOnGlobalChatMessage = (data: SHOWBOAT.GroupChat) => {
    SHOWBOAT.Logger.Log("Chat message received:", data);
    if (data.groupType === SHOWBOAT.GroupTypes.booking) {
      toggleNewMessage(true);

      let newMessage = data;

      setMessages((chatMessages) => [...chatMessages, newMessage]);
      chatMessagesRef.current = [...chatMessagesRef.current, newMessage];

      addPlayerDataToChatInfoCache(data);
    }
  };

  const handleOnGlobalAlertMessage = (data: SHOWBOAT.MessageReportData) => {
    setAlerts((alerts) => [...alerts, data]);
  };

  //Get booking chats
  const getChatHistory = async () => {
    const getChatResponse =
      await SHOWBOAT.WebSocketController.GetRecentBookingChats();

    SHOWBOAT.Logger.Log("Get chat response:", getChatResponse);

    if (getChatResponse.errorData.error) {
      SHOWBOAT.Logger.Error("Error retrieving recent booking chats");
      return false;
    }

    if (getChatResponse.recentChats) {
      SHOWBOAT.Logger.Log("Got recent chats:", getChatResponse.recentChats);

      let chats: SHOWBOAT.Chat[] = getChatResponse.recentChats;

      //Add each chat to state array
      for (const chat of chats) {
        //Make sure chat array does not include this message already
        if (!chatMessagesRef.current.some(existingChat => existingChat.ID === chat.ID)) {
          let chatObj = {
            ID: chat.ID,
            groupType: SHOWBOAT.GroupTypes.booking,
            message: chat.message,
            senderName: chat.senderName,
            senderUserID: chat.senderUserID,
            senderColor: chat.senderColor,
            timeCreated: chat.timeCreated,
          };

          setMessages((chatMessages) => [...chatMessages, chatObj]);
          chatMessagesRef.current = [...chatMessagesRef.current, chatObj];

          addPlayerDataToChatInfoCache(chat);
        }
      }
    }
  };

  const addPlayerDataToChatInfoCache = (chat: SHOWBOAT.GroupChat | SHOWBOAT.Chat) => {
    //Add player data to info cache
    let mapClone = chatMessageInfoMapRef.current;
    let avatarData: SHOWBOAT.AvatarData =
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(chat.senderUserID);
    if (avatarData) {
      mapClone.set(chat.senderUserID, {
        firstName: avatarData.firstName,
        lastName: avatarData.lastName,
        color: avatarData.color,
      });

      setChatMessageInfoMap(mapClone);
      chatMessageInfoMapRef.current = mapClone;
    }
  }
  /**/

  const messagesProviderValue: MessageContextType = {
    chatMessages,
    setMessages,
    alerts,
    setAlerts,
    newMessage,
    toggleNewMessage,
    chatMessageInfoMap,
    setChatMessageInfoMap,
  };

  return (
    <MessageContext.Provider value={messagesProviderValue}>
      {props.children}
    </MessageContext.Provider>
  );
};
