import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileLeaveMeetingStyles = makeStyles((theme: any) =>
  createStyles({
    mobileLeaveMeetingText: {
      color: "white",
      fontSize: 14,
      position: "relative",
      top: 8,
    },
    mobileLeaveMeetingButton: {
      width: "100%",
      padding: 0,
      height: 60,
      color: "white",
    },
    mobileLeaveMeetingButtonExit: {
      backgroundColor: "#4B4B4B",
      "&:hover": {
        backgroundColor: "#4B4B4B",
      },
    },
    phoneIcon: {
      position: "relative",
      bottom: 2,
      marginRight: 8,
    },
  })
);

export { MobileLeaveMeetingStyles };