import React, { ReactElement } from "react";
import { Typography, Paper } from "@material-ui/core";

import styles from "./TimeoutErrorPage.module.css";
import { TimeoutErrorPageStyles } from "./TimeoutErrorPageStyles";
import { ServerHelper } from "../../../Utilities/ServerHelper";

interface Props {
  failCopy: string;
}

export default function TimeoutErrorPage(props: Props): ReactElement {
  const classes = TimeoutErrorPageStyles();

  return (
    <div className={styles.errorPageHolder}>
      <Paper
        className={
          ServerHelper.useMobileApp
            ? `${classes.errorMessageHolder} ${classes.errorMessageHolderMobile}`
            : classes.errorMessageHolder
        }
      >
        <img
          className={
            ServerHelper.useMobileApp
              ? `${styles.showboatLogo} ${styles.showboatLogoMobile}`
              : styles.showboatLogo
          }
          alt="showboat logo"
          src="assets/images/sb-logo.png"
        />

        <Typography
          variant="body1"
          className={
            ServerHelper.useMobileApp
              ? `${classes.errorMessage} ${classes.errorMessageMobile}`
              : classes.errorMessage
          }
        >
          {props.failCopy === ""
            ? "An error occurred loading, please reload the page to try again."
            : props.failCopy}
        </Typography>
      </Paper>
    </div>
  );
}
