import {
  Button,
  IconButton,
  Paper,
  Typography,
  Tab,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { isMobile } from "react-device-detect";
import AddIcon from "@material-ui/icons/AddBoxOutlined";
import LocationIcon from "@material-ui/icons/LocationOn";
import styles from "./RoomOptionsSidebar.module.css";
import { RoomOptionsSidebarStyles } from "./RoomOptionsSidebarStyles";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import RoomOptionsSidebarItem from "./RoomOptionsSidebarItem/RoomOptionsSidebarItem";
import AttendeeList from "../AttendeeList/AttendeeList";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";
import { orderBy } from "natural-orderby";
import RoomNamePopup from "../../Popups/RoomNamePopup/RoomNamePopup";

export default function RoomOptionsSidebar(): ReactElement {
  const { setCurrentSidebarMode, isPresenter, setShowRoomNameMenu } : AppContextType = useContext(AppContext);

  const { currentMode, toggleIsOnStage }: PresenterContextType =
    useContext(PresenterContext);

  const [currentTab, setCurrentTab] = useState(0);
  const [roomList, setRoomList] = useState([]);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const [roomID, setRoomID] = useState(SHOWBOAT.LocalAvatarDataManager.roomID);

  const getRoomTimeoutRef = useRef(null);

  const handleGetRoomList = async () => {
    let roomList = await SHOWBOAT.RemoteAvatarDataManager.getAvatarRoomCounts(
      true
    );

    //Hide loading spinner
    toggleLoadingSpinner(false);

    //Order room list
    let roomListSorted = orderBy(
      roomList,
      [r => r.name],
      ['asc']
    );


    setRoomList(roomListSorted);
  };

  useEffect(() => {
    //If we are on mobile, add mobile hack property for css
    let roomContentHolder = document.getElementById("roomContentHolder");
    if (roomContentHolder) {
      if (isMobile) {
        if (!isPresenter) {
          roomContentHolder.classList.add(
            `${styles.roomContentHolderNonPresenterMobile}`
          );
        } else {
          roomContentHolder.classList.add(`${styles.roomContentHolderMobile}`);
        }
      }
    }

    //Show loading spinner
    toggleLoadingSpinner(true);

    //On mount, get list of rooms
    handleGetRoomList();
    getRoomTimeoutRef.current = setInterval(handleGetRoomList, 5000);

    SHOWBOAT.UIEventManager.OnRoomChange.Add(handleRoomChange);

    return function cleanup() {
      if (getRoomTimeoutRef.current) {
        clearTimeout(getRoomTimeoutRef.current);
      }

      SHOWBOAT.UIEventManager.OnRoomChange.Remove(handleRoomChange);
    };
  }, [isPresenter]);

  const handleRoomChange = (roomID) => {
    //Store state copy of roomID
    setRoomID(roomID);
  }

  //Open room
  const handleOpenRoomClick = async () => {
    //Show room name popup
    setShowRoomNameMenu(true);
  };

  //Re-get room list on room change
  const handleChangeRoom = () => {
    handleGetRoomList();
  };

  //Tab select
  const handleTabSelect = (tab) => {
    setCurrentTab(tab);
  };

  const classes = RoomOptionsSidebarStyles();

  return (
    <React.Fragment>
      <Paper className={classes.roomOptionsHeaderHolder}>
        <Paper elevation={0} className={classes.roomOptionsHeaderHolderTop}>
          <LocationIcon className={classes.peopleIcon}></LocationIcon>

          <Typography variant="h1" className={classes.roomOptionsHeader}>
            Change Rooms
          </Typography>

          <IconButton
            className={classes.closeSidebarButton}
            onClick={() => setCurrentSidebarMode("")}
          >
            <ArrowIcon />
          </IconButton>
        </Paper>

        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.tabIndicator,
          }}
          indicatorColor="primary"
          value={currentTab}
          variant="fullWidth"
        >
          <Tab
            onClick={() => handleTabSelect(0)}
            classes={{ root: classes.roomOptionsTab }}
            label="TO ROOM"
          />
          <Tab
            onClick={() => handleTabSelect(1)}
            classes={{
              root: `${classes.roomOptionsTab} ${classes.roomOptionsTabPerson}`,
            }}
            label="TO PERSON"
          />
        </Tabs>
      </Paper>

      {currentTab === 0 &&
        SHOWBOAT.LocalAvatarDataManager.avatarData.role === "presenter" && (
          <Button
            className={classes.openRoomButton}
            variant="contained"
            onClick={handleOpenRoomClick}
          >
            <AddIcon className={classes.addIcon} />
            ADD ROOM
          </Button>
        )}

      {currentTab === 0 ? (
        <div
          className={
            isPresenter
              ? styles.roomContentHolder
              : `${styles.roomContentHolder} ${styles.roomContentHolderNonPresenter}`
          }
          id="roomContentHolder"
        >
          {loadingSpinner && (
            <CircularProgress className={classes.loadingSpinner} />
          )}

          {roomList.map((item) => {
            return (
              <RoomOptionsSidebarItem
                roomName={item.name}
                roomCount={item.count}
                roomID={item.roomID}
                roomCapacity={item.capacity}
                key={item.roomID}
                handleChangeRoom={handleChangeRoom}
                attendeeRoomID={roomID}
              />
            );
          })}
        </div>
      ) : (
        <AttendeeList roomSwitchVariant={true} />
      )}

      {SHOWBOAT.LocalAvatarDataManager.avatarData.role === "presenter" && (
        <RoomNamePopup handleGetRoomList={handleGetRoomList}/>
      )}
    </React.Fragment>
  );
}
