import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileCookieNotificationStyles = makeStyles((theme: any) =>
  createStyles({
    cookieNotification: {
      width: "100vw",
      height: 180,
      backgroundColor: theme.palette.intakePaper.main,
      border: "none",
      overflow: "hidden",
      textAlign: "center",
    },
    cookieText: {
      fontSize: 14,
      position: "relative",
      display: "inline-block",
      textAlign: "center",
      width: "95%",
      marginBottom: 15,
    },
    okButton: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.intakePaper.main,
      width: 93,
      height: 63,
      position: "relative",
      display: "inline-block",
      zIndex: 999,
      fontSize: 28,
    },
    link: {
      marginRight: 5,
    },
  })
);

export { MobileCookieNotificationStyles };