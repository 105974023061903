import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileLandscapeOverlayStyles = makeStyles((theme: any) =>
  createStyles({
    landscapeText: {
      color: "white",
    }
  })
);

export { MobileLandscapeOverlayStyles };