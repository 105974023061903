import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import $ from "jquery";
import LocationIcon from "@material-ui/icons/LocationOn";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { TeleportMessagePopupStyles } from "./TeleportMessagePopupStyles";
import {
  AttendeeContext,
  AttendeeContextType,
} from "../../../../context/AttendeeContext";

interface Props {
  askingUserID: string;
  respondToTeleportRequest: (respondingToUserID: string) => any;
  reactToAttendeeLeaving: (leavingUserID: string) => any;
  id: number;
}

export default function TeleportMessagePopup(props: Props): ReactElement {
  const { attendeesInEvent }: AttendeeContextType = useContext(AttendeeContext);

  /* State store */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  /**/

  const autoDeclineTimer = useRef(null);
  const displayTimer = useRef(null);

  //Destructure props for useEffect dependencies
  let { askingUserID, id, reactToAttendeeLeaving, respondToTeleportRequest } =
    props;

  useEffect(() => {
    //If attendee leaves the event, hide teleport request from them
    if (!SHOWBOAT.RemoteAvatarDataManager.getAvatarData(askingUserID)) {
      var $thisParent = $(`#${id}`).closest("div");

      $thisParent.nextAll().each(function () {
        $(this)
          .stop()
          .animate(
            {
              top: `-=${$thisParent.outerHeight(true)}`,
            },
            300
          );
      });

      reactToAttendeeLeaving(askingUserID);

      clearTimeout(autoDeclineTimer.current);
    }
  }, [attendeesInEvent, askingUserID, id, reactToAttendeeLeaving]);

  //get bio details, and set up auto decline after 60 seconds
  useEffect(() => {
    if (
      SHOWBOAT.RemoteAvatarDataManager.getAvatarData(askingUserID) !== undefined
    ) {
      setFirstName(
        SHOWBOAT.RemoteAvatarDataManager.getAvatarData(askingUserID).firstName
      );
      setLastName(
        SHOWBOAT.RemoteAvatarDataManager.getAvatarData(askingUserID).lastName
      );
    }

    autoDeclineTimer.current = setTimeout(function () {
      var $thisParent = $(`#${id}`).closest("div");

      $thisParent.nextAll().each(function (index) {
        $(this)
          .stop()
          .animate(
            {
              top: `-=${$thisParent.outerHeight(true)}`,
            },
            300
          );
      });

      respondToTeleportRequest(askingUserID);
    }, 30000);

    displayTimer.current = setTimeout(function () {
      document.getElementById(id.toString()).style.display = "inline-block";
    }, 300);

    return function cleanup() {
      clearTimeout(autoDeclineTimer.current);
      clearTimeout(displayTimer.current);
    };
  }, [askingUserID, id, respondToTeleportRequest]);

  //handle accept
  const handleAcceptButtonClick = async () => {
    try {
      await SHOWBOAT.WebSocketController.AnswerTeleportRequest({
        askerUserID: props.askingUserID,
        isAccepted: true,
      });

      props.respondToTeleportRequest(props.askingUserID);
    } catch (error) {
      SHOWBOAT.Logger.Error("Error responding to teleport request");
      SHOWBOAT.UIEventManager.OnUIError.Raise("Error teleporting")
    }
  };

  //handle decline
  const handleDeclineButtonClick = async () => {
    //Make sure attendee is still in event before declining teleport request
    await SHOWBOAT.WebSocketController.AnswerTeleportRequest({
      askerUserID: props.askingUserID,
      isAccepted: false,
    });

    props.respondToTeleportRequest(props.askingUserID);
  };

  //MaterialUI classes
  const classes = TeleportMessagePopupStyles();

  return (
    <React.Fragment>
      <Paper
        elevation={5}
        classes={{ root: classes.teleportMessage }}
        id={props.id.toString()}
        className="notification"
      >
        <LocationIcon
          fontSize="small"
          classes={{ root: classes.locationIcon }}
        />
        <Typography classes={{ root: classes.teleportMessageText }}>
          Teleport request from
        </Typography>
        <Typography classes={{ root: classes.teleportMessageName }}>
          {firstName} {lastName}
        </Typography>

        <Button
          variant="text"
          classes={{ root: classes.acceptButton }}
          className="dismissButton"
          startIcon={<CheckIcon classes={{ root: classes.acceptIcon }} />}
          onClick={handleAcceptButtonClick}
        >
          Accept
        </Button>
        <Button
          variant="text"
          classes={{ root: classes.declineButton }}
          className="dismissButton"
          startIcon={<CancelIcon classes={{ root: classes.declineIcon }} />}
          onClick={handleDeclineButtonClick}
        >
          Decline
        </Button>
      </Paper>
    </React.Fragment>
  );
}
