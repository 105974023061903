import React, { ReactElement, useState } from "react";

import CameraAndMicSettings from "../../CameraAndMicSettings/CameraAndMicSettings";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

interface Props {
  onCameraAndMicPageComplete: any;
  onCameraAndMicPageBack: any;
  cameraAndMicChosen: any;
  backClicked: any;
}

export default function CameraAndMicPage(props: Props): ReactElement {
  const [, toggleShowEchoWarning] = useState(false);

  const handleCameraAndMicSettingsComplete = () => {
    props.onCameraAndMicPageComplete();
  };

  const handleCameraAndMicSettingsBack = () => {
    props.onCameraAndMicPageBack();
  };

  const handleToggleShowEchoWarning = (hasEchoPotential: boolean) => {
    toggleShowEchoWarning(hasEchoPotential);
  };

  return (
    <IntakeContentHolder marginBottom={"0px"}>
      <CameraAndMicSettings
        onCameraAndMicSettingsComplete={handleCameraAndMicSettingsComplete}
        onCameraAndMicSettingsBack={handleCameraAndMicSettingsBack}
        cameraAndMicChosen={props.cameraAndMicChosen}
        backClicked={props.backClicked}
        intake={true}
        toggleShowEchoWarning={handleToggleShowEchoWarning}
      />
    </IntakeContentHolder>
  );
}
