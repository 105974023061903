import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import $ from "jquery";

import { TwoDHelper } from "../../../Utilities/TwoDHelper";

import TwoDOverlayCameraItem from "../TwoDOverlayCameraItem/TwoDOverlayCameraItem";
import styles from "./TwoDCameras.module.css";
import TwoDCamerasBadge from "../TwoDCamerasBadge/TwoDCamerasBadge";

type Props = {
  resizePanelSize: number;
};

export default function TwoDCameras(props: Props) {
  const [cameraItems, setCameraItems] = useState([]);

  const [showHiddenBadge, setShowHiddenBadge] = useState(true);
  const [hiddenCount, setHiddenCount] = useState(1);

  const cameraItemsRef = useRef([]);

  const cameraTimeoutMap = useRef(new Map());
  const heightWidthRef = useRef(null);
  const resizePanelSizeRef = useRef(props.resizePanelSize);
  const scrollContainerRef = useRef(null);

  useEffect(() => {

    let container = document.getElementById("scrollContainerInnerWrapper");
    if (container) {
      scrollContainerRef.current = container; 
    }
    //Get initial list of 2D cameras
    setCameraItems(TwoDHelper.getTwoDObjects());
    cameraItemsRef.current = TwoDHelper.getTwoDObjects();

    //Sign up to listen for camera updates
    TwoDHelper.OnTwoDViewUpdate.Add(handleTwoDCamerasViewUpdate);

    //Listen for resizing the cameras container
    TwoDHelper.OnCameraViewResize.Add(handleCameraViewResize);

    //Listen for browser window width resizing
    window.addEventListener("resize", handleCameraViewResize);

    //Check for hidden items while scrolling
    window.addEventListener("wheel", checkForHiddenItems);

    handleCameraViewResize();

    return function cleanup() {
      TwoDHelper.OnTwoDViewUpdate.Remove(handleTwoDCamerasViewUpdate);

      TwoDHelper.OnCameraViewResize.Remove(handleCameraViewResize);

      window.removeEventListener("resize", handleCameraViewResize);

      window.removeEventListener("wheel", checkForHiddenItems);
    };
  }, []);

  useEffect(() => {
    resizePanelSizeRef.current = props.resizePanelSize
  }, [props.resizePanelSize])

  const handleTwoDCamerasViewUpdate = (twoDCameras) => {
    setCameraItems(twoDCameras);
    cameraItemsRef.current = twoDCameras;

    //Resize the camera items
    handleCameraViewResize();
  };

  const getHeightInViewport = (el) => {
    let elH = el.outerHeight(),
      H = $(window).height(),
      r = el[0].getBoundingClientRect(),
      t = r.top,
      b = r.bottom;
    return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
  };

  const handleCameraViewResize = () => {
    let camerasContainer = document.getElementById(
      "scrollContainerInnerWrapper"
    );

    if (camerasContainer) {
      //Calculate new height/width of camera items
      let heightWidth = TwoDHelper.getTwoDSquarePixelSize(
        camerasContainer.clientWidth - 5,
        getHeightInViewport($("#scrollContainerInnerWrapper")) - 5,
        cameraItemsRef.current.length
      );

      //Enforce min/max of 140px && 240px, respectively
      if (heightWidth > 245) {
        heightWidth = 245;
      } else if (heightWidth < 145) {
        heightWidth = 145;
      }

      heightWidthRef.current = heightWidth;

      //Raise event with new square size for the camera items
      TwoDHelper.OnCameraItemResize.Raise(heightWidth);

      checkForHiddenItems();
    }
  };

  const calculateContainerHeight = () => {
    let camerasContainer = document.getElementById(
      "scrollContainerInnerWrapper"
    );

    if (camerasContainer) {
      let cellsPerRow = Math.floor(
        camerasContainer.clientWidth / heightWidthRef.current
      );
      let totalRows = Math.ceil(cameraItemsRef.current.length / cellsPerRow);

      return totalRows * heightWidthRef.current;
    }
  }

  const checkForHiddenItems = () => {
    //Check which items are hidden by >50% of their own height
    let container = document.getElementsByClassName(
      "twoDCamerasScrollContainer"
    )[0];

    let calculatedScrollHeight = calculateContainerHeight();

    if (container) {
      if (
        calculatedScrollHeight >
        container.clientHeight + heightWidthRef.current / 2
      ) {
        TwoDHelper.OnHideCameraBadge.Raise(false);
      } else {
        TwoDHelper.OnHideCameraBadge.Raise(true);
      }
    }
  };

  return (
    <ScrollContainer
      horizontal={false}
      vertical={true}
      className={`${styles.scrollContainer} twoDCamerasScrollContainer`}
    >
      <div
        className={styles.scrollContainerInnerWrapper}
        id="scrollContainerInnerWrapper"
      >
        <div className={styles.twoDCamerasHolder} id="twoDCamerasHolder">
          {cameraItems.map((item) => {
            return (
              <TwoDOverlayCameraItem
                twoDObject={item}
                handleCamerasViewResize={handleCameraViewResize}
                key={item.avatarData.userID}
              />
            );
          })}
        </div>
      </div>
    </ScrollContainer>
  );
}
