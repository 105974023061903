import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { QAContext, QAContextType } from "../../../../context/QAContext";
import {
  MobileContext,
  MobileContextType,
} from "../../../../context/MobileContext";

import { MobileNewQAItemStyles } from "./MobileNewQAItemStyles";
import styles from "./MobileNewQAItem.module.css";

let Filter = require("bad-words");

type Props = {};

export default function MobileNewQAItem({}: Props) {
  const {
    submitAnonymously,
    setSubmitAnonymously,
    questionIDsPosted,
    setQuestionIDsPosted,
    allowAnonymousQs,
  }: QAContextType = useContext(QAContext);

  const { setShowNewQAItem, showNewQAItem }: MobileContextType = useContext(MobileContext);

  const [qaText, setQaText] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);

  let filter = new Filter();

  useEffect(() => {
    if (allowAnonymousQs === false) {
      setSubmitAnonymously(false);
    }
  }, [allowAnonymousQs]);

  useEffect(() => {
    //If showNewQAItem is false, ensure text field is hidden
    if (!showNewQAItem) {
      let textField = document.getElementById("mobileNewQAItemTextField");

      if (textField) {
        textField.style.visibility = "hidden";
      }
      
    }
  }, [showNewQAItem])

  const handleQAChange = (e) => {
    setQaText(e.target.value);
  };

  const handleQAMessageSubmit = async (e) => {
    e.preventDefault();

    //Don't allow submission if text field is blank
    if (qaText === "") return;

    try {
      //Submit message to server
      const addQuestionResponse = await SHOWBOAT.WebSocketController.AddQuestion({
        questionText: qaText,
        isAnonymous: submitAnonymously
      })
      
      if (addQuestionResponse.errorData.error) {
        SHOWBOAT.UIEventManager.OnUIError.Raise("Error submitting question");
        return;
      }
      
      let questionIDsClone = questionIDsPosted;
      questionIDsClone.push(addQuestionResponse.questionID);
      setQuestionIDsPosted(questionIDsClone);

      setQaText("");
      
      //Un-focus the text box
      let textBox = document.getElementById("mobileNewQAItemTextField");
      if (textBox) {
        textBox.blur();
      }
      
      //Close this menu
      setShowNewQAItem(false);
    } catch (error) {
      SHOWBOAT.Logger.Error("Error submitting question:", error);
      SHOWBOAT.UIEventManager.OnUIError.Raise("Error submitting question");
    }
  };

  const handleSubmitAnonymouslyChange = () => {
    setSubmitAnonymously(!submitAnonymously);
  };

  const handleDismissClick = () => {
    setQaText("");
    setShowNewQAItem(false);
  };

  const classes = MobileNewQAItemStyles();

  return (
    <div 
      className={styles.mobileNewQAItemHolder}
      style={{
        visibility: showNewQAItem ? "visible" : "hidden"
      }}
    >
      <div className={styles.qaItemTextHolder}>
        <img
          src="assets/images/QAIcon.svg"
          alt="qa icon"
          style={{
            width: "24px",
            height: "auto",
            marginRight: "14px",
          }}
        />

        <Typography className={classes.newQAItemText}>
          SUBMIT QUESTION
        </Typography>
      </div>

      <Typography className={classes.qaItemHelperText}>
        Question will be submitted to the moderator
      </Typography>

      <TextField
        multiline
        rows={3}
        value={qaText}
        variant="outlined"
        onChange={handleQAChange}
        className={classes.qaTextField}
        placeholder="Send a message"
        inputProps={{
          maxLength: 240,
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleQAMessageSubmit(e);
          }
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.noBorder,
            root: classes.chatInput,
            inputAdornedEnd: classes.chatInputAdornedEnd,
          },
          id: "mobileNewQAItemTextField",
          type: "text",
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={submitAnonymously}
            onChange={handleSubmitAnonymouslyChange}
            name="submit anonymously"
            color="primary"
          />
        }
        disabled={!allowAnonymousQs}
        classes={{
          root: classes.anonymousCheckbox,
          label: classes.anonymousLabel,
        }}
        label="Submit Anonymously"
        color="primary"
        style={{
          visibility: allowAnonymousQs && showNewQAItem ? "visible" : "hidden",
        }}
        labelPlacement="end"
      />

      <Typography variant="body1" className={classes.characterCount}>
        {`${qaText.length} / 240`}
      </Typography>

      <div className={styles.newQAItemButtonHolder}>
        <Button onClick={handleQAMessageSubmit} className={classes.chatButton}>SUBMIT</Button>
        <Button
          className={`${classes.chatButton} ${classes.chatButtonDismiss}`}
          onClick={handleDismissClick}
        >
          DISMISS
        </Button>
      </div>
    </div>
  );
}
