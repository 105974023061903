import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileToolbarStyles = makeStyles((theme: any) =>
  createStyles({
    "@keyframes pulse": {
      "0%": {
        boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
      },
      "100%": {
        boxShadow: `0 0 0 100px ${theme.palette.primary.main}`,
      },
    },
    toolbarButton: {
      padding: 0,
      width: 60,
      height: 40,
      backgroundColor: "#404040",
      color: "white",
      position: "relative",
      overflow: "hidden",
      boxSizing: "border-box",
      borderRadius: 4,
      marginRight: 4,
      "&:hover": {
        backgroundColor: "#404040",
      },
      "@media (max-width: 385px)": {
        width: 54
      },
      "&:last-child": {
        marginRight: 0,
      }
    },
    toolbarButtonActive: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    boxIcon: {
      width: 34,
      height: "auto",
    },
    badge: {
      width: 1,
      height: 1,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      margin: "auto",
      animation: `$pulse 1s infinite cubic-bezier(.36, .11, .39, .59)`,
      WebkitAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
      MozAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
    },
    chatIcon: {
      zIndex: 1
    }
  })
);

export { MobileToolbarStyles };